import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { I18NService } from "../../../core/services/i18n.service";
import { CampaignsService } from "../../../core";
import * as _ from "underscore";

@Component({
  selector: "app-earn-and-redeem",
  templateUrl: "./earn-and-redeem.component.html",
  styleUrls: ["./earn-and-redeem.component.scss"],
})
export class PrivilegesComponent implements OnInit {
  tableConfig = [
    {
      points: "10,000",
      value: "$200",
    },
    {
      points: "20,000",
      value: "$400",
    },
    {
      points: "50,000",
      value: "$1,200",
    },
    {
      points: "80,000",
      value: "$2,400",
    },
    {
      points: "100,000",
      value: "$3,500",
    },
    {
      points: "150,000",
      value: "$6,000",
    },
    {
      points: "200,000",
      value: "$9,000",
    },
    {
      points: "300,000",
      value: "$15,000",
    },
  ];

  flow_2A_subTitle: string;
  here_link: string;
  concierge_link: string;
  link: string ;
  newImage:string ;
  earnNew:string ;
  objClass: string;
  rewardSchemeLink:string;

  constructor (public i18nService: I18NService, private route: ActivatedRoute, private campaignsService: CampaignsService) {
    this.i18nService.selectedLanguage.subscribe((value)=>{
      this.here_link = value + '/earn',
      this.concierge_link =  value + '/contact-us',
      this.newImage = `/assets/images/about-programme/earn-and-redeem/new-${value}.svg`
      this.earnNew = value === "en_US" ? "earn-new_en" : "earn-new_zh"
      this.objClass = this.earnNew + " column item-container"
      this.getCampaigns(value)
    })
  }

  ngOnInit(): void {
    this.route.data.subscribe((routeData: { 0: any[] }) => {
      if (routeData['0'] && routeData['0'][0]) {
          this.flow_2A_subTitle = routeData['0'][0].Content__c;
      }
    });
  }

  scrollTo(elementId): void {
    const elementPos: number = document.getElementById(elementId).getBoundingClientRect().top;
    const headerOffset = 200;
    const pageOffset = window.pageYOffset;
    // for desktop
    window.scrollTo({
      top: elementPos + pageOffset - headerOffset,
      behavior: "smooth"
    });
    // for mobile
    document.getElementById("app-container").scrollTo({
      top: elementPos + pageOffset - headerOffset,
      behavior: "smooth"
    });
  }

  getCampaigns(language) {
    this.campaignsService.getAllCampaigns().subscribe(campaigns => {
      campaigns.filter(item => {
        if(item.name === "2023Dec_SmartPOS_What'sOn Post"){
          this.link =  language + '/campaigns/' + item.permalink
        }
        if(item.name === "2024Jan_NewScheme_HKairport_WhatsOn"){
          this.rewardSchemeLink = language + '/campaigns/' + item.permalink
        }
      });
    });
  }
}

