import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthStorage, OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService, private authStorage: OAuthStorage) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig = {};
    if (req.body instanceof FormData) {
    } else if (req.url.indexOf("oauth2") !== -1) {
      headersConfig = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
    } else {
      headersConfig = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }

    // Append header only when header not present in original request
    let additionalHeadersConfig = {};
    Object.keys(headersConfig).forEach((header: string) => {
      if (!req.headers.has(header)) {
        additionalHeadersConfig[header] = headersConfig[header]
      }
    })
  
    const token = this.authStorage.getItem("access_token");
    // Append OAuth Token to all request
    if (token && req.responseType !== "text") {
      additionalHeadersConfig["Authorization"] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: additionalHeadersConfig });
    return next.handle(request);
  }
}
