import { ApiServiceName } from "./api.constants";
import { ErrorService } from "../errors/error.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
// import { AlertService } from '../alert.service';
import { HkiaApiErrorService } from "../hkaa-dollar/error.service";
// import { AlertService } from '../alert.service';
@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private errorService: ErrorService, private hkiaApiErrorService: HkiaApiErrorService) {}

  // Member Portal Generic Error
  formatErrors(error: any) {
    return throwError(this.errorService.handleApiError(error));
  }

  showErrorMessage(messages: string = "Something when wrong. Please try again") {
    this.errorService.createMessage(messages);
  }

  serviceApi(targetService: string): string {
    switch (targetService) {
      case "member": {
        return environment.apiEndpoints.memberService;
      }
      case "loyalty": {
        return environment.apiEndpoints.loyaltyService;
      }
      case "oauth": {
        return environment.apiEndpoints.oAuthService;
      }
      case "storage": {
        return environment.apiEndpoints.storageService;
      }
      case "livechat": {
        return environment.apiEndpoints.livechatService;
      }
      case "data-sync": {
        return environment.apiEndpoints.dataSyncService;
      }
      case "hkiadollar": {
        return environment.apiEndpoints.hkiadollarService;
      }
      case "campaignengine": {
        return environment.apiEndpoints.campaignengineService;
      }
      default: {
        return environment.apiEndpoints.apiUrl;
      }
    }
  }

  get(
    path: string,
    targetService?: ApiServiceName,
    params: HttpParams = new HttpParams(),
    headers?: { [header: string]: string },
    responseType?: "json" | "arraybuffer",
    errorHandle?: boolean
  ): Observable<any> {
    const endPoint = targetService ? this.serviceApi(targetService) : environment.apiEndpoints.apiUrl;

    if (targetService === "storage") {
      return this.http.get(`${endPoint}${path}`, { responseType: "text" }).pipe(
        catchError((err) => {
          return this.formatErrors(err);
        })
      );
    } else if (responseType == "arraybuffer") {
      return this.http.get(`${endPoint}${path}`, { headers: headers, params: params, responseType: "arraybuffer" }).pipe(
        catchError((err) => {
          return this.formatErrors(err);
        })
      );
    } else if (errorHandle) {
      return this.http.get(`${endPoint}${path}`, { headers: headers, params: params }).pipe(
        catchError((err) => {
          const error: any = this.formatErrors(err);
          this.hkiaApiErrorService.handleApiError(err, error);
          return error;
        })
      );
    } else {
      return this.http.get(`${endPoint}${path}`, { headers: headers, params: params }).pipe(
        catchError((err) => {
          return this.formatErrors(err);
        })
      );
    }
  }

  put(path: string, targetService?: ApiServiceName, body: Object = {}): Observable<any> {
    const endPoint = targetService ? this.serviceApi(targetService) : environment.apiEndpoints.apiUrl;
    return this.http.put(`${endPoint}${path}`, JSON.stringify(body)).pipe(
      catchError((err) => {
        return this.formatErrors(err);
      })
    );
  }

  post(path: string, targetService?: ApiServiceName, body: Object = {}, headers?: { [header: string]: string }, errorHandle?: boolean): Observable<any> {
    const endPoint = targetService ? this.serviceApi(targetService) : environment.apiEndpoints.apiUrl;

    if (body instanceof FormData) {
      return this.http.post(`${endPoint}${path}`, body, { headers: headers }).pipe(
        catchError((err) => {
          return this.formatErrors(err);
        })
      );
    } else if (path.indexOf("oauth2") !== -1) {
      return this.http.post(`${endPoint}${path}`, body.toString(), { headers: headers }).pipe(
        catchError((err) => {
          return this.formatErrors(err);
        })
      );
    } else if (errorHandle) {
      return this.http.post(`${endPoint}${path}`, body.toString(), { headers: headers }).pipe(
        catchError((err) => {
          const error: any = this.formatErrors(err);
          this.hkiaApiErrorService.handleApiError(err, error);
          return error;
        })
      );
    } else {
      return this.http.post(`${endPoint}${path}`, JSON.stringify(body), { headers: headers }).pipe(
        catchError((err) => {
          return this.formatErrors(err);
        })
      );
    }
  }

  delete(path, targetService?: ApiServiceName): Observable<any> {
    const endPoint = targetService ? this.serviceApi(targetService) : environment.apiEndpoints.apiUrl;
    return this.http.delete(`${endPoint}${path}`).pipe(catchError(this.formatErrors));
  }

  patch(path: string, targetService?: ApiServiceName, body: Object = {}, errorHandle?: boolean): Observable<any> {
    const endPoint = targetService ? this.serviceApi(targetService) : environment.apiEndpoints.apiUrl;
    if (errorHandle) {
      return this.http.patch(`${endPoint}${path}`, JSON.stringify(body)).pipe(
        catchError((err) => {
          const error: any = this.formatErrors(err);
          this.hkiaApiErrorService.handleApiError(err, error);
          return error;
        })
      );
    }
    return this.http.patch(`${endPoint}${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors.bind(this)));
  }
}

enum ResponseStatus {
  error = 400,
  success = 200,
}
