import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { LiveChatService, CommonService } from "../../../core";
import { environment } from '../../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "app-livechat",
  templateUrl: "./livechat.component.html",
  styleUrls: ["./livechat.component.scss"],
})
export class LivechatComponent implements OnInit {
  isDimmed: boolean = false;
  chatType: "Member Portal Enquiry" | "Luxury Reserve Service";
  // isOpenedFailedMsgBox: boolean = false;
  isLiveChatActivated: boolean = false;

  constructor(private spinner: NgxSpinnerService,
    private liveChatService: LiveChatService,
    private ref: ChangeDetectorRef,
    private commonService: CommonService
  ) {
    this.liveChatService.chatTypeObservable.subscribe(value => this.chatType = value);
    this.commonService.shouldShowLiveChat$.subscribe((value)=>{
      this.isLiveChatActivated = value;
    })
  }

  ngOnInit() {
    this.liveChatService.isChatStarted.subscribe(value => {
      this.isDimmed = value;
      this.ref.detectChanges();
    }
    );
    this.isLiveChatActivated = environment.LIVE_CHAT_CONFIG.isLiveChatActivated;
  }

  load(chatType: "Member Portal Enquiry" | "Luxury Reserve Service") {
    
    this.liveChatService.openChatbox(chatType);

    /* check agent availability */
    //     this.liveChatService.getAgentStatus("Visitor", environment.LIVE_CHAT_CONFIG.ids, environment.LIVE_CHAT_CONFIG.deploymentId, environment.LIVE_CHAT_CONFIG.orgId).subscribe((data) => {
    //     this.liveChatService.initChat(chatType, this.liveChatService.checkAgentStatus(data));
    //     // this.isOpenedFailedMsgBox = !this.liveChatService.checkAgentStatus(data);
    // })

    /* loading animation hadling */
    // this.spinner.show("live-chat");
    // setTimeout(() => {
    //   this.spinner.hide("live-chat");
    //   this.show = true;
    // }, 2000);
  }

  /* for a made up failed msg box */
  // close(){
  //   this.isOpenedFailedMsgBox = false;
  // }

  // contactUs() {
  //   this.isOpenedFailedMsgBox = false;
  //   this.liveChatService.redirect(this.chatType);
  // }
}
