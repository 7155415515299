import { NgModule } from "@angular/core";
import { OfferDetailComponent } from "./offer-detail/offer-detail.component";
import { OfferListResolver } from "./offer-list-resolver.service";
import { OfferResolver } from "./offer-resolver.service";
import { OffersComponent } from "./offers.component";
import { OffersRoutingModule } from "./offers-routing.module";
import { SharedModule } from "../../shared";

@NgModule({
  imports: [SharedModule, OffersRoutingModule],
  declarations: [OffersComponent, OfferDetailComponent],
  providers: [OfferResolver, OfferListResolver],
})
export class OffersModule {}
