import { ApiService, ApiServiceConstant } from "./api";
import { Feedback, User } from "../models";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";

@Injectable()
export class FeedbackService {
  user: User = {} as User;
  constructor(private apiService: ApiService, private userService: UserService) {
    Object.assign(this.user, this.userService.getCurrentUser);
  }

  memberUploadFeedback(feedback: Feedback): Observable<any> {
    return this.apiService.post(`/feedbacks/${this.user.memberNumber}`, ApiServiceConstant.MEMBER, feedback).pipe(
      map(data => {
        return data;
      })
    );
  }

  nonMemberUploadFeedback(feedback: Feedback): Observable<any> {
    return this.apiService.post(`/feedbacks`, "member", feedback).pipe(
      map(data => {
        return data;
      })
    );
  }
}
