import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { UserService } from "../../core";

@Injectable()
export class AuthResolver implements Resolve<any> {
  constructor(private route: ActivatedRoute, private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.route.url.subscribe((data) => {
      const authType = data[data.length - 1].path;
      if (authType === "login") {
      } else if (authType === "logout") {
        this.userService.logoff();
      }
    });
  }
}
