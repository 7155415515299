import * as _ from "underscore";
import { CommonService, I18NService, UserService } from "../../../core";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-unsubscribe-page",
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["unsubscribe.component.scss"],
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  memberid: string;
  token: string;
  isSubmitting = false;
  contactUs: string;
  private subscription: Subscription;
  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private route: ActivatedRoute,
    private localizeRouter: LocalizeRouterService,
    private i18nService: I18NService
  ) {
    this.route.queryParams.subscribe((value) => {
      this.memberid = decodeURI(value["memberid"]);
      this.token = encodeURI(value["token"]);
    });
  }

  ngOnInit() {
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      this.contactUs = this.localizeRouter.translateRoute("/contact-us") as string;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  submitForm() {
    this.isSubmitting = true;
    let subscriberKey = this.route.snapshot.queryParams.subscriberKey;
    const successPageData = {
      displayItem: {
        title: "UNSUBSCRIBE.MESSAGE.SUCCESSFUL",
        icon: "registration_successful",
        buttonLabel: null,
      },
    };
    const errorPageData = {
      displayItem: {
        title: "UNSUBSCRIBE.MESSAGE.UNSUCCESSFUL_TITLE",
        subTitle: "UNSUBSCRIBE.MESSAGE.UNSUCCESSFUL_SUBTITLE",
        subTitleData: { hotline: "+85221900900", link: this.contactUs },
        icon: "error",
        buttonLabel: null,
      },
    };
    if(subscriberKey){
      this.userService.unsubscribeMktOptByNonMemberSubscriberKey(subscriberKey).subscribe(
        (data) => {
          if(data.result == 'OK'){
            this.commonService.redirect("/subscription/unsubscribe/result", null, { state: successPageData });
          }else{
            this.commonService.redirect("/subscription/unsubscribe/result", null, { state: errorPageData });
          }
        },
        (err) => {
          this.commonService.redirect("/subscription/unsubscribe/result", null, { state: errorPageData });
        }
      );
    }else{
      this.userService.unsubscribeMktOptByMemberNumber(this.memberid, this.token.replace(/%20/g, "+")).subscribe(
        (data) => {
          this.commonService.redirect("/subscription/unsubscribe/result", null, { state: successPageData });
        },
        (err) => {
          this.commonService.redirect("/subscription/unsubscribe/result", null, { state: errorPageData });
        }
      );
    }
  }
}
