import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api/api.service";
import { I18NService } from "./i18n.service";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class ProfilesService {
  constructor(private apiService: ApiService, private userService: UserService, private i18nService: I18NService) {}

  getUserBalance(memberId: string): Observable<any> {
    return this.apiService.get(`/member/${memberId}/balances`, "loyalty", null, null, null, true).pipe(map((data: { mpProgressBar: any; pointBalanceResponse: any }) => data));
  }

  getUserBalanceForHkaaDollar(userId: string): Observable<any> {
    // https://dev.api.iss.acnhk.com/hkiadollar/member/AA-0000008745/balances
    // hkiadollar/member/AA-0000016293/balances
    return this.apiService.get(`/member/${userId}/balances`, "hkiadollar", null, null, null, true).pipe(map((data) => data));
  }

  getDollarCalculatorConfig() {
    return this.apiService.get("/member/v1/config", "hkiadollar", null, null, null, true).pipe(map((data) => data));
  }

  generateQrCode(memberNumber: string, hkiaDollarAmount: string, version: string = "v1"): Observable<any> {
    // /member/v1/qrcode/get/{memberNumber}/{hkiaDollarAmount}
    return this.apiService.get(`/member/${version}/qrcode/get/${memberNumber}/${hkiaDollarAmount}`, "hkiadollar", null).pipe(map((data) => data));
  }

  checkScanQrCode(params: { qrCodeId: string; token: string }, version: string = "v1") {
    const queryParams = new HttpParams({
      fromObject: {
        qrCodeId: params.qrCodeId,
        token: params.token,
        // 可以添加更多参数...
      },
    });
    return this.apiService.get(`/member/${version}/qrcode/query`, "hkiadollar", queryParams).pipe(map((data) => data));
  }

  getRewards(memberId): Observable<any> {
    return this.apiService.get(`/campaigns/details/${memberId}`, "campaignengine", null, null, null, true).pipe(map((data) => data));
  }

  voidQrCode(params: { tranId: string; token: string }, version: string = "v1") {
    // /member/v1/qrcode/void
    return this.apiService.patch(`/member/${version}/qrcode/void`, "hkiadollar", params, true).pipe(map((data) => data));
  }

  /**
   * TODO: Temp use size = 1000
   * @param userId Member number
   * @param page current page
   * @param size number of records
   */
  getRedeemedOffersByStatus(
    status: string,
    page: number = 1,
    size: number = 1000,
    userId: string = this.userService.getCurrentUser.memberNumber,
    langId: string = this.i18nService.getActiveLang
  ): Observable<any> {
    return this.apiService.get(`/offers/history/member/${userId}/status/${status}?page=${page}&size=${size}&lang=${langId}`, "loyalty", null).pipe(map((data) => data));
  }

  getPointHistory(page: number = 1, size: number = 1000, userId: string = this.userService.getCurrentUser.memberNumber): Observable<any> {
    return this.apiService.get(`/member/${userId}/pointHistory?page=${page}&size=${size}`, "loyalty", null, null, null, true).pipe(map((data: { content: any }) => data));
  }
}
