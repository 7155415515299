import * as _ from "underscore";
import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { ProfilesService, OffersService, I18NService, CommonService, LaunchAppService, UserService } from "../../../core";
import { RedeemOfferConstants } from "./redeem-offer.constants";
import { NzModalService, NzModalRef } from "ng-zorro-antd";
import { STColumn, STChange, STPage } from "@delon/abc";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs";
import { environment } from '../../../../environments/environment';

@Component({
  selector: "app-redeem-offer",
  templateUrl: "./redeem-offer.component.html",
  styleUrls: ["./redeem-offer.component.scss"],
})
export class RedeemOfferComponent implements OnInit, OnDestroy {
  // TODO: List Data Model
  txnData: any;
  loading: boolean;
  isSubmitting: boolean;
  redeemOfferConstants = RedeemOfferConstants;
  columns: STColumn[] = [];

  selectedItem = null;

  verCodeModal: any;
  androidAppReminderModal: NzModalRef;
  hydirdModal: NzModalRef;
  isVerCodeInvalid = false;

  radioValue = "active";

  @ViewChild("confirmModal", { static: false }) confirmModal: TemplateRef<{}>;
  @ViewChild("desktopMyFlightModal", { static: false }) desktopMyFlightModal: TemplateRef<{}>;
  @ViewChild("openAppModal", { static: false }) openAppModal: TemplateRef<{}>;

  @ViewChild("hybridOfferModal", { static: false }) hybridOfferModal: TemplateRef<{}>;

  totalRecord: any;

  pageConfig: STPage = {
    front: true,
    placement: "center",
    show: true,
    showQuickJumper: false,
  };

  currentCategory = "active";
  isShowAppDownloadSection: boolean;
  isShowOnlineCouponReminder: boolean;

  empLinkArr = {
    en_US: `${ environment.URL_CONFIG.emp }/eshop_en/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=en_hk_Prospecting_AA-CRM-Portal`,
    zh_HK: `${ environment.URL_CONFIG.emp }/eshop_zh/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=zh_hk_Prospecting_AA-CRM-Portal`,
    zh_CN: `${ environment.URL_CONFIG.emp }/eshop_cn/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=cn_hk_Prospecting_AA-CRM-Portal`,
  };
  myHKGLinkArr = {
    en_US: `${ environment.URL_CONFIG.MyHKG }/en/flights/MyHKG-mobile-app/`,
    zh_HK: `${ environment.URL_CONFIG.MyHKG }/tc/flights/MyHKG-mobile-app/`,
    zh_CN: `${ environment.URL_CONFIG.MyHKG }/sc/flights/MyHKG-mobile-app/`,
  };
  fosLinkArr = {
    en_US: `${ environment.URL_CONFIG.fos }/court/5726866849660928?lang=en`,
    zh_HK: `${ environment.URL_CONFIG.fos }/court/5726866849660928?lang=zh`,
    zh_CN: `${ environment.URL_CONFIG.fos }/court/5726866849660928?lang=cn`,
  };

  empLink = "";
  fosLink = "";
  myHKGLink = "";
  private subscription: Subscription;

  currentBrowserBnv = true;

  constructor (
    private profileService: ProfilesService,
    private offersService: OffersService,
    private modalService: NzModalService,
    private i18nService: I18NService,
    private commonService: CommonService,
    public deviceService: DeviceDetectorService,
    private launchAppService: LaunchAppService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    // Get Point History from SessionM
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      this.getRedeemedList(1, this.currentCategory);
      this.empLink = this.empLinkArr[lang];
      this.myHKGLink = this.myHKGLinkArr[lang];
      this.fosLink = this.fosLinkArr[lang];
    });

    let userAgent = window.navigator.userAgent
    if(userAgent.indexOf("Mobile") != -1){
      if(userAgent.indexOf("Harmony") != -1 || userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Opera") != -1 || 
         userAgent.indexOf("UCBrowser") != -1 ||  userAgent.indexOf("QQ") != -1 || userAgent.indexOf("Edg") != -1 || userAgent.indexOf("sogou") != -1 || 
         userAgent.indexOf("Quark") != -1 || userAgent.indexOf("Brave") != -1 || userAgent.indexOf("Vivaldi") != -1 || userAgent.indexOf("DuckDuckgo") != -1 || 
         userAgent.indexOf("Chromium") != -1 || userAgent.indexOf("Epic") != -1 || userAgent.indexOf("Safari") != -1){
        this.currentBrowserBnv = true;
      }else{
        this.currentBrowserBnv = false;
      }
      if(userAgent.indexOf("Android") != -1){
        if(userAgent.indexOf("Safari") != -1){
          this.currentBrowserBnv = false;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initColumns(type: string) {
    this.columns = [
      {
        title: "",
        render: "offer_thumbnail",
        className: "table-offer-thumbnail",
      },
      {
        title: this.i18nService.translateLabel("profile.redeems.offer"),
        render: "offer_info",
        className: "table-offer-title",
      },
      {
        title: type === "active" ? this.i18nService.translateLabel("profile.redeems.quantity") : null,
        render: type === "active" ? "offer_quantity" : null,
        className: "table-offer-quantity text-center",
      },
      {
        title: type === "redeemed" ? this.i18nService.translateLabel("REDEEMED_OFFERS.LABEL.REDEEMED_DATE") : this.i18nService.translateLabel("REDEEMED_OFFERS.LABEL.EXPIRY_DATE"),
        render: "redemption_end_date",
        className: "table-offer-expiry-date",
      },
    ];

    if (type === "active") {
      this.columns.push({
        title: "",
        render: "offer_action",
        className: "table-offer-button",
      });
    }
  }

  getRedeemedList(page: number = 1, type: string = "active") {
    this.loading = true;
    this.initColumns(type);
    this.txnData = [];
    this.profileService.getRedeemedOffersByStatus(type, page).subscribe((offerList) => {
      if(offerList.content != null){
        this.txnData = this.transformRedeemedOffers(offerList.content);
        this.totalRecord = offerList.numberOfElements;
      }
      this.loading = false;
    });
  }

  useOffer(offer) {
    this.selectedItem = offer;
    const userOfferId = offer.userOfferHistoryRecord[0].userOfferId;
    switch (offer.redeemMethod) {
      case RedeemOfferConstants.ECOUPON: {
        if (offer.isOnlineCoupon && !offer.isOfflineCoupon && !offer.couponUseRange) {
          window.open(this.empLink, "_blank");
        } else if ((!offer.isOnlineCoupon && offer.isOfflineCoupon && !offer.couponUseRange) || (!offer.isOnlineCoupon && !offer.isOfflineCoupon && !offer.couponUseRange)) {
          this.redeemECouponOffer(offer);
        } else {
          this.isShowOnlineCouponReminder = offer && ((offer.isOnlineCoupon && offer.isOfflineCoupon) || (offer.isOnlineCoupon && !offer.isOfflineCoupon)) ? true : false;
          this.hydirdModal = this.modalService.create({
            nzContent: this.hybridOfferModal,
            nzClassName: "my-flight-reminder-modal",
            nzFooter: null,
            nzClosable: true,
          });
        }
        break;
      }
      default:
        this.commonService.redirect("/profile/redeemed-offer", userOfferId);
    }
  }


  /**
   * Redeem "Direct Use" Coupon, click and go
   * @param offerId
   */
  redeemDirectUseOffer(offerId) {
    this.isSubmitting = true;
    this.offersService.manualRedeemCoupon(offerId).subscribe(
      (response: any) => {
        this.commonService.redirect("/profile/redeemed-offer", response.offer_id);
      },
      (errors) => {
        this.isSubmitting = false;
      }
    );
  }

  redeemConfirmUseOffer(offerId) {
    this.modalService.confirm({
      nzTitle: `Are you sure to use this offer?
      Confirm to get the QR code and redeem your offer, it will only be displayed once.
      `,
      nzOkText: this.i18nService.translateLabel("COMMON.LABEL.OK"),
      nzCancelText: this.i18nService.translateLabel("COMMON.LABEL.CANCEL"),
      nzOnOk: () => {
        this.isSubmitting = true;
        this.offersService.manualRedeemCoupon(offerId).subscribe(
          (response: any) => {
            this.commonService.redirect("/profile/redeemed-offer", {
              offerId: response.offer_id,
              promoCode: response.promocode,
            });
          },
          (errors) => {
            this.isSubmitting = false;
          }
        );
      },
      nzOnCancel: () => console.log("Cancel"),
    });
  }

  manualRedeemOffer(offerId) {
    const self: any = this;
    self.isVerifyingCode = true;
    this.offersService.manualRedeemCoupon(offerId).subscribe(
      (response: any) => {
        self.isVerifyingCode = false;
        this.verCodeModal.destroy();
        this.commonService.redirect("/profile/redeemed-offer", response.offer_id);
      },
      (errors) => {
        this.isSubmitting = false;
      }
    );
  }

  /**
   * Transform SessionM's activity name to label
   * @param records
   */
  transformRedeemedOffers(records) {
    const self: any = this;
    return _.each(records, function (record: any) {
      if (record && record.userOfferHistoryRecord && record.userOfferHistoryRecord[0] && record.userOfferHistoryRecord[0].customData) {
        const customData = JSON.parse(record.userOfferHistoryRecord[0].customData);
        switch (customData.offer_type) {
          case "ECOUPON": {
            record.redeemMethod = RedeemOfferConstants.ECOUPON;
            record.isOnlineCoupon = record.userOfferHistoryRecord[0].distributionChannels && _.where(record.userOfferHistoryRecord[0].distributionChannels, { 'code': "EMKTP" }).length > 0 ? true : false;
            record.isOfflineCoupon = record.userOfferHistoryRecord[0].distributionChannels && _.where(record.userOfferHistoryRecord[0].distributionChannels, { 'code': "ORL" }).length > 0 ? true : false;
            record.couponUseRange = record.userOfferHistoryRecord[0].distributionChannels && _.where(record.userOfferHistoryRecord[0].distributionChannels, { 'code': "FOS" }).length > 0 ? true : false;
            break;
          }
          case "PARTNER_QR_CODE":
          case "PARTNER_BAR_CODE":
            record.redeemMethod = RedeemOfferConstants.COMFIRM_USE;
            break;
          case "DIRECT_USE":
            record.redeemMethod = RedeemOfferConstants.DIRECT_USE;
            break;
          case "VERIFICATION_CODE":
            record.redeemMethod = RedeemOfferConstants.VERIFICATION_CODE;
            record.passcode = customData.passcode;
            break;
          default:
            record.redeemMethod = customData.offer_type;
        }

        if (record.redeemedDate && record.redeemMethod !== RedeemOfferConstants.DIRECT_USE) {
          record.isRedeemed = true;
        } else {
          record.isRedeemed = false;
        }

        if (!record.isRedeemed && record.redemptionEndDate && new Date() > record.redemptionEndDate) {
          record.isExpired = true;
        } else {
          record.isExpired = false;
        }
        record.displayedDate = record.redemptionEndDate;
        if (self.currentCategory === "redeemed") {
          record.displayedDate = record.redeemedDate;
        } else {
          record.displayedDate = record.redemptionEndDate;
        }
      } else {
        return record;
      }
    });
  }

  /**
   * Get My Flight APP HELP Link
   */
  get getMyFlightHelpLink(): string {
    switch (this.i18nService.getActiveLang) {
      case "en_US":
        return RedeemOfferConstants.HKAA_APP_HELP_EN;
      case "zh_HK":
        return RedeemOfferConstants.HKAA_APP_HELP_TC;
      case "zh_CN":
        return RedeemOfferConstants.HKAA_APP_HELP_SC;
    }
  }

  /**
   * Enter offer detail page
   */
  viewOffer(e: STChange) {
    if (e.click) {
      // Prevent action when click use offer button
      if (e.click.e.target) {
        const element = e.click.e.target as HTMLElement;
        const elementAttribute = element.getAttribute("data-buttonType");
        if (elementAttribute && elementAttribute === "useCoupon") {
          return;
        }
      }
      const offerItem = e.click.item;
      // UAT #296 Not able to view expired offer
      if (!offerItem.isExpired) {
        switch (offerItem.redeemMethod) {
          case RedeemOfferConstants.ECOUPON: {
            // this.useOffer(offerItem);
            if (offerItem.userOfferHistoryRecord && offerItem.userOfferHistoryRecord[0].terms) {
              this.modalService.create({
                nzContent: offerItem.userOfferHistoryRecord[0].terms,
                nzClassName: "my-flight-reminder-modal",
                nzBodyStyle: {
                  maxHeight: 'calc(100vh - 200px)',
                  overflow: 'auto'
                },
                nzFooter: null,
                nzClosable: true,
              });
            }
            break;
          }
          default: {
            this.commonService.redirect("/profile/redeemed-offer", offerItem.userOfferHistoryRecord[0].userOfferId);
            break;
          }
        }
      }
    }
  }

  /**
   * Redeem E-Coupon, another type of "Direct Use" offer, get offer detail from e-coupon system
   * @param offerId
   */
  redeemECouponOffer(offerItem?: any) {
    // Not allow use e-coupon on desktop
    if (this.deviceService.isDesktop()) {
      if(this.userService.inMyHkgWebView && !offerItem.isOnlineCoupon && !offerItem.couponUseRange && !!offerItem.isOfflineCoupon){
        /**
         * in myhkg app browser, not popup window, directly go to the Myhkg coupon page
         */
        this.launchAppService.launchApp()
        return
      }

      this.isShowOnlineCouponReminder =
        offerItem && ((offerItem.isOnlineCoupon && offerItem.isOfflineCoupon) || (offerItem.isOnlineCoupon && !offerItem.isOfflineCoupon)) ? true : false;
      this.hydirdModal = this.modalService.create({
        nzContent: this.hybridOfferModal,
        nzClassName: "my-flight-reminder-modal",
        nzFooter: null,
        nzClosable: true,
      });
    } else {
      if(!this.currentBrowserBnv){
        /**
         * in-app browser, pop-up prompt, do not open the app
         */
        this.hydirdModal = this.modalService.create({
          nzContent: this.hybridOfferModal,
          nzClassName: "my-flight-reminder-modal",
          nzFooter: null,
          nzClosable: true,
        });
        return
      }
      this.launchMyFlightApp(offerItem);
    }
  }

  launchMyFlightApp(offerItem?: any) {
    if (this.hydirdModal) {
      this.hydirdModal.destroy();
    }
    if (offerItem && offerItem.isOnlineCoupon && !offerItem.isOfflineCoupon && !offerItem.couponUseRange) {
      window.open(this.empLink, "_blank");
      return;
    }
    this.isShowOnlineCouponReminder =
      offerItem && ((offerItem.isOnlineCoupon && offerItem.isOfflineCoupon) || (offerItem.isOnlineCoupon && !offerItem.isOfflineCoupon)) ? true : false;
    if (this.deviceService.os === "Android") {
      this.isShowAppDownloadSection = false;
      this.androidAppReminderModal = this.modalService.create({
        nzContent: this.openAppModal,
        nzClassName: "my-flight-reminder-modal",
        nzFooter: null,
        nzClosable: false,
      });
      setTimeout(() => {
        this.isShowAppDownloadSection = true;
      }, 3000);
    }
    this.launchAppService.launchApp().then((launchAppResult) => {
      if (!launchAppResult && (this.deviceService.os === "iOS" || this.deviceService.os === "Mac")) {
        this.isShowAppDownloadSection = true;
        this.androidAppReminderModal = this.modalService.create({
          nzContent: this.openAppModal,
          nzClassName: "my-flight-reminder-modal",
          nzFooter: null,
          nzClosable: false,
        });
      }
    });
  }

  changePage(pageNumber: number) {
    this.getRedeemedList(pageNumber);
  }

  changeEnquiryType(event) {
    this.currentCategory = event.value;
    this.getRedeemedList(1, event.value);
  }

  openDownloadAppLink() {
    // iPhone - iOS , iPad - Mac
    if (this.deviceService.os === "iOS" || this.deviceService.os === "Mac") {
      window.open("https://apps.apple.com/hk/app/my-hkg-official/id1488720261", "_blank");
    } else {
      window.open(this.getMyFlightHelpLink, "_blank");
    }
  }

  closeAndroidModal() {
    this.androidAppReminderModal.destroy();
  }

  getMessage = () => {
    const EMKTP_MSG = this.selectedItem.isOnlineCoupon ? this.i18nService.translateLabel("REDEEMED_OFFERS.MESSAGE.EMKTP_COUPON_REMINDER", { link: this.empLink }) : '';
    const FOS_MSG = this.selectedItem.couponUseRange ? this.i18nService.translateLabel("REDEEMED_OFFERS.MESSAGE.FOS_COUPON_REMINDER", { link: this.fosLink }) : '';
    let ORL_MSG = this.selectedItem.isOfflineCoupon ? this.i18nService.translateLabel("REDEEMED_OFFERS.MESSAGE.ORL_COUPON_REMINDER",
      { link: this.deviceService.isDesktop() ? this.myHKGLink : environment.MYHKG_UNIVERSAL_LINK + 'ecouponlist' }) : '';

    if(this.userService.inMyHkgWebView){
      ORL_MSG = this.selectedItem.isOfflineCoupon ? this.i18nService.translateLabel("REDEEMED_OFFERS.MESSAGE.ORL_COUPON_REMINDER",{ link: environment.MYHKG_UNIVERSAL_LINK + 'ecouponlist' }) : '';
    }
    return ORL_MSG + EMKTP_MSG + FOS_MSG
  }
}
