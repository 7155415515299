import * as _ from "underscore";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ProfilesService, UserService, User, I18NService, CommonService, OffersService, ClaimPromoCodeRequest, ApiError, ErrorService } from "../../../core";

@Component({
  selector: "app-promocode-redemption",
  templateUrl: "./promocode-redemption.component.html",
  styleUrls: ["./promocode-redemption.component.scss"],
})

export class PromocodeRedemptionComponent implements OnInit, OnDestroy {
  user: User = {} as User;
  isPromoCodeRedeemSuccess: boolean = null;
  promoCodeRedemptionForm: FormGroup;
  isSubmitting = false;
  noActivePolicy = false;
  isClaimed = false;
  isFailed = false;
  isExpired = false;

  @Input() formConfig;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private offerService: OffersService,
    private i18nService: I18NService,
    private profileService: ProfilesService,
    private route: ActivatedRoute,
    private userService: UserService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.initFrom();
    this.loadUserInfo();
    this.route.firstChild.params.subscribe((value) => {
      if(value["code"]){
        this.commonService.redirect("/profile/reward-code");
        this.promoCodeRedemptionForm.controls.promoCode.setValue(value['code']);
      };
    });
  };

  initFrom() {
    this.promoCodeRedemptionForm = this.fb.group(
      {
        promoCode: [null]
      },
      { updateOn: "blur" }
    );
  }

  loadUserInfo() {
    Object.assign(this.user, this.userService.getCurrentUser);
  }

  ngOnDestroy() { }

  claimPromoCode(): void {
    const formData = this.transformPromoCodeForm();
    this.isPromoCodeRedeemSuccess = false
    this.offerService.claimPromoCode(formData).subscribe(async data => {
      this.isPromoCodeRedeemSuccess = data;
      const successPageData = {
        displayItem: {
          title: "profile.promoCode.successTitle",
          subTitle: "profile.promoCode.successContent",
          icon: "registration_successful",
          buttonLabel: "profile.promoCode.viewMyRewards",
          actionType: "LINK",
          link: "/profile"
        },
      };
      this.commonService.redirect("/profile/reward-code/redemption/success", null, { state: successPageData });
    },
      (error: ApiError) => {
        if (error.errorCode === "E2028") {
          this.promoCodeRedemptionForm.controls.promoCode.setErrors({
            noActivePolicy: true,
          });
        } else if (error.errorCode === "E2029") {
          this.promoCodeRedemptionForm.controls.promoCode.setErrors({
            isClaimed: true,
          });
        } else if (error.errorCode === "E2030") {
           this.promoCodeRedemptionForm.controls.promoCode.setErrors({
            isExpired: true,
           });
        }else {
          this.promoCodeRedemptionForm.controls.promoCode.setErrors({
            isFailed: true,
          });
        }
      });
  }

  transformPromoCodeForm(): ClaimPromoCodeRequest {
    const requestBody = {
      memberNumber: this.user.memberNumber,
      promocode: this.promoCodeRedemptionForm.controls.promoCode.value.trim(),
    };
    return _.pick(requestBody, _.identity);
  }

  submitForm() {
    // tslint:disable-next-line:forin
    for (const i in this.promoCodeRedemptionForm.controls) {
      this.promoCodeRedemptionForm.controls[i].markAsDirty();
      this.promoCodeRedemptionForm.controls[i].updateValueAndValidity();
    }

    if (this.promoCodeRedemptionForm.valid) {
      this.claimPromoCode();
    }
  }

}
