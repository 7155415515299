import { Injectable, } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { OffersService, I18NService } from "../../core";
import { catchError } from "rxjs/operators";

@Injectable()
export class OfferListResolver implements Resolve<any> {
  constructor(
    private offerService: OffersService,
    private router: Router,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    /**
     * Get offer detail by offerId
     */
    return this.offerService.getAllOffers()
      .pipe(catchError((err) => this.router.navigateByUrl("/")));
  }
}
