// Hong Kong Airport Concierge Location
export const conciergeLocations = [
  {
    index: 0,
    floor: "L6",
    area: {
      en_US: "HKairport Rewards Concierge (Departures)",
      zh_HK: "HKairport Rewards 服務中心 (離港層)",
      zh_CN: "HKairport Rewards 服务中心 (离港层)",
    },
    location: {
      en_US: "Near Gate 5, L6, Departures Level, Terminal 1 (Restricted Area)",
      zh_HK: "一號客運大樓離港層6樓近5號閘口 (禁區)",
      zh_CN: "一号客运大楼离港层6楼近5号闸口 (禁区)",
    },
    mapsImg: {
      en_US: "/assets/images/support-media/maps/L6/L6_EN.png",
      zh_HK: "/assets/images/support-media/maps/L6/L6_TC.png",
      zh_CN: "/assets/images/support-media/maps/L6/L6_SC.png",
    },
    openingHours: {
      en_US: "Opening hours: 07:00 – 24:00",
      zh_HK: "服務時間：07:00 – 24:00",
      zh_CN: "服务时间：07:00 – 24:00",
    },
  },
  // {
  //   index: 1,
  //   floor: "L6",
  //   isClosed: false,
  //   area: {
  //     en_US: "HKairport Rewards Concierge (Departures)",
  //     zh_HK: "HKairport Rewards 服務中心 (離港層)",
  //     zh_CN: "HKairport Rewards 服务中心 (离港层)",
  //   },
  //   location: {
  //     en_US: "Near Gate 36, L6, Departures Level, Terminal 1 (Restricted Area)",
  //     zh_HK: "一號客運大樓離港層6樓近36號閘口 (禁區)",
  //     zh_CN: "一号客运大楼离港层6楼近36号闸口 (禁区)",
  //   },
  //   openingHours: {
  //     en_US: "Opening hours: 07:00 – 24:00",
  //     zh_HK: "服務時間：07:00 – 24:00",
  //     zh_CN: "服务时间：07:00 – 24:00",
  //   },
  // },
  {
    index: 2,
    floor: "L6",
    isClosed: false,
    area: {
      en_US: "HKairport Rewards Concierge (Departures)",
      zh_HK: "HKairport Rewards 服務中心 (離港層)",
      zh_CN: "HKairport Rewards 服务中心 (离港层)",
    },
    location: {
      en_US: "Airline Services Desk A (Near Louis Vuitton), L6, Departures Level, Terminal 1 (Restricted Area)",
      zh_HK: "一號客運大樓離港層6樓航空公司服務櫃檯A (近Louis Vuitton) (禁區)",
      zh_CN: "一号客运大楼离港层6楼航空公司服务柜台A (近Louis Vuitton) (禁区)",
    },
    openingHours: {
      en_US: "Opening hours: 07:00 – 24:00",
      zh_HK: "服務時間：07:00 – 24:00",
      zh_CN: "服务时间：07:00 – 24:00",
    },
  },
  // {
  //   index: 3,
  //   floor: "L5",
  //   isClosed: false,
  //   area: {
  //     en_US: "HKairport Rewards Concierge (Arrivals)",
  //     zh_HK: "HKairport Rewards 服務中心 (抵港層)",
  //     zh_CN: "HKairport Rewards 服务中心 (抵港层)",
  //   },
  //   location: {
  //     en_US: "Near Transfer Desk E1, L5, Arrivals Level, Terminal 1 (Restricted Area)",
  //     zh_HK: "一號客運大樓抵港層5樓近E1轉機櫃檯 (禁區)",
  //     zh_CN: "一号客运大楼抵港层5楼近E1转机柜台 (禁区)",
  //   },
  //   mapsImg: {
  //     en_US: "/assets/images/support-media/maps/L5/L5_EN.png",
  //     zh_HK: "/assets/images/support-media/maps/L5/L5_TC.png",
  //     zh_CN: "/assets/images/support-media/maps/L5/L5_SC.png",
  //   },
  //   openingHours: "06:00 - 01:00",
  // },
  {
    index: 4,
    floor: "L7",
    area: {
      en_US: "HKairport Rewards Concierge (Non-restricted area)",
      zh_HK: "HKairport Rewards 服務中心 (非禁區)",
      zh_CN: "HKairport Rewards 服务中心 (非禁区)",
    },
    location: {
      en_US: "Customer Services Centre, between Aisles E & F, L7, Check-in Hall, Departures Level, Terminal 1",
      zh_HK: "一號客運大樓7樓旅客登記大堂E/F行段之間的旅客服務中心",
      zh_CN: "一号客运大楼7楼旅客登记大堂E/F行段之间的旅客服务中心",
    },
    mapsImg: {
      en_US: "/assets/images/support-media/maps/L7/L7_EN.png",
      zh_HK: "/assets/images/support-media/maps/L7/L7_TC.png",
      zh_CN: "/assets/images/support-media/maps/L7/L7_SC.png",
    },
    openingHours: {
      en_US: "Opening hours: 07:00 – 24:00",
      zh_HK: "服務時間：07:00 – 24:00",
      zh_CN: "服务时间：07:00 – 24:00",
    },
  },
];
