import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { CommonService } from "./common.service";
declare var $: any;
// jQuery Selector
@Injectable()
export class FormService {
  constructor(private deviceService: DeviceDetectorService, private commonService: CommonService) {}
  scrollToError(): void {
    // It's workaround for IE, usually use ScrollIntoView
    if ($(".ng-invalid[formControlName]").length > 0 && $(".ng-invalid[formControlName]").eq(0).attr("type") !== "hidden") {
      $("html, #app-container").animate(
        {
          scrollTop: $(".ng-invalid[formControlName]").offset().top - 150,
        },
        300
      );
    } else {
      if ($(".ng-trigger-helpMotion").length > 0) {
        $("html, #app-container").animate(
          {
            scrollTop: $(".ng-trigger-helpMotion").offset().top - 250,
          },
          300
        );
      }
    }
  }

  scrollElementToTop(elementId: string, obj?: any) {
    if (!this.deviceService.isDesktop()) {
      const element = document.getElementById(elementId);
      element.scrollIntoView();
    }
  }

  inputBlur() {
    if (!this.deviceService.isDesktop()) {
      $("input").blur();
    }
  }

  async inputFocus(fieldId) {
    if (!this.deviceService.isDesktop()) {
      await this.commonService.browserDelay(200);
      $("#" + fieldId + " input").focus();
    }
  }
}
