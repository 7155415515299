import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../core';

@Component({
  selector: 'app-earn-now-expired',
  templateUrl: './earn-point-expired.component.html',
  styleUrls: ['./earn-point-expired.component.css']
})
export class EarnPointExpiredComponent implements OnInit {

  @ViewChild('message', null) messageTemplate: ElementRef;
  messageType: string = "expired";
  message: string;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<EarnPointExpiredComponent>
  ) { 
    this.userService.getEarnPointMessage(this.messageType).subscribe(
      (data) => {
        this.message = data.responseInfo.replace(/\\n/g,"<br>");
        this.messageTemplate.nativeElement.innerHTML = this.message;
      }
    );
  }

  ngOnInit() {
  }

}
