import { ApiError } from "..";
import { Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterRouteChange = false;

  constructor(private notificationService: NzNotificationService) { }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.notificationService.success(message, "");
    this.subject.next({ type: "success", text: message });
  }

  error(apiError: ApiError, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.notificationService.error(apiError.errorCode, apiError.errorDetail);
    this.subject.next({ type: "error", text: apiError.errorDetail });
  }

  warning(message: string, duration: number = 3, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.notificationService.warning(message, "", { nzDuration: duration });
    this.subject.next({ type: "warning", text: message });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this.subject.next();
  }
}
