import * as _ from "underscore";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { FormService, UserService, passwordValidator, ApiError, ErrorService, CommonService, I18NService } from "../../../core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Subscription } from "rxjs";
import { ModalService } from '../../../core/services/modal.service';
import { PPUrlArr } from '../../../shared';

@Component({
  selector: "app-activate-member",
  templateUrl: "./activate-member.component.html",
  styleUrls: ["./activate-member.component.scss"],
})
export class ActivateMemberComponent implements OnInit, OnDestroy {
  emailTitle: string;
  forgotPasswordForm: FormGroup;
  forgotPasswordLink: string;
  isShowEmailResult = false;
  isSubmitting = false;
  isTokenValid: boolean;
  mode: any;
  pageInfo: any;
  picsLink: string;
  private subscription: Subscription;
  repeatedPassword = false;
  resetPasswordForm: FormGroup;
  securityQuestion: string;
  tncLink: string;
  userProfile: any;
  PPUrlArr = PPUrlArr;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private formService: FormService,
    private errorService: ErrorService,
    private localizeRouter: LocalizeRouterService,
    private commonService: CommonService,
    private modalService: ModalService,
    private i18nService: I18NService,
  ) {
    this.route.data.subscribe((data: { isValidToken: boolean }) => {
      this.isTokenValid = data.isValidToken;
      if (this.isTokenValid) {
        this.resetPasswordForm = this.fb.group(
          {
            agreeTerms: [false, [Validators.requiredTrue]],
            confirmPassword: [null, [Validators.required, this.passwordConfirmationValidator]],
            password: [null, [Validators.required, passwordValidator]],
            token: [null, [Validators.required]],
            userId: [null, [Validators.required]],
          },
          { updateOn: "blur" }
        );

        let userid;
        let token;
        this.route.queryParams.subscribe((value) => {
          userid = decodeURI(value["userid"]);
          token = encodeURI(value["token"]);
        });
        if (userid && token) {
          this.resetPasswordForm.controls.userId.setValue(userid);
          this.resetPasswordForm.controls.token.setValue(token.replace(/%20/g, "+"));
        }
        this.tncLink = this.localizeRouter.translateRoute("/legal/terms-and-condition") as string;
        this.picsLink = this.localizeRouter.translateRoute("/legal/personal-information-collection-statement") as string;
      } else {
        const errorPageData = {
          displayItem: {
            title: "auth.MESSAGE.INVALID_ACTIVATION_TOKEN",
            icon: "error",
            buttonLabel: "COMMON.LABEL.LOGIN_NOW",
            actionType: "LOGIN",
          },
        };
        this.commonService.redirect("/account/reset/error", null, { state: errorPageData });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  transformResetPasswordFormData() {
    const requestBody = {
      newPassword: this.resetPasswordForm.controls.password.value.trim(),
      token: this.resetPasswordForm.controls.token.value,
      userId: this.resetPasswordForm.controls.userId.value,
      // acknowledgedTnC: true
    };
    return _.pick(requestBody, _.identity);
  }

  submitForm() {
    if (this.resetPasswordForm.valid) {
      this.isSubmitting = true;
      const formData = this.transformResetPasswordFormData();
      this.userService.changePasswordByEmail(formData).subscribe(
        (response) => {
          const successPageData = {
            displayItem: {
              title: "ACTIVATION.MESSAGE.SUCCESSFULLY_RESET_PASSWORD",
              subTitle: "ACTIVATION.MESSAGE.SUCCESSFULLY_RESET_PASSWORD_SUBTITLE",
              icon: "password_changed",
              buttonLabel: "COMMON.LABEL.LOGIN_NOW",
              actionType: "LOGIN",
            },
          };
          this.commonService.redirect("/account/reset/success", null, { state: successPageData });
        },
        (error: ApiError) => {
          this.isSubmitting = false;
          if (error.errorCode === "E1023") {
            this.resetPasswordForm.controls.password.setErrors({
              repeatedPassword: true,
            });
          } else if (error.errorCode === "E1039") {
            this.errorService.redirectToErrorPage(error);
          } else {
            this.errorService.redirectToErrorPage(error);
          }
        }
      );
    } else {
      for (const i in this.resetPasswordForm.controls) {
        this.resetPasswordForm.controls[i].markAsDirty();
        this.resetPasswordForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  passwordConfirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    }
    if (this.resetPasswordForm.controls.password.value !== this.resetPasswordForm.controls.confirmPassword.value) {
      return { passwordMismatch: true };
    }
    return null;
  };

  showModal(modalType: "TC" | "PP" | "CU") {
    if (modalType === 'PP') {
      window.open(this.PPUrlArr[this.i18nService.getActiveLang], '_blank');
    } else {
      this.modalService.showModal(modalType);
    }
  };
}
