import { AuthGuard, GeneralGuard } from "../../core";
import { NgModule } from "@angular/core";
import { ProfileComponent } from "./profile.component";
import { RedeemedOfferDetailComponent } from "./redeemed-offer-detail/redeemed-offer-detail.component";
import { RedeemedOfferDetailResolver } from "./redeemed-offer-detail/redeemed-offer-detail-resolver.service";
import { RegistrationResolver } from "../account/registration/registration-resolver.service";
import { RouterModule, Routes } from "@angular/router";
import { UpdateAraStatusComponent } from "./update-ara-status/update-ara-status.component";
import { UpdateProfileComponent } from "./update-profile/update-profile.component";
import { SystemMessageComponent } from "../../shared";
import { PromocodeRedemptionComponent } from "./promocode-redemption/promocode-redemption.component";
import { MemberInfoComponent } from "./member-info/member-info.component";
import { PointHistoryComponent } from "./point-history/point-history.component";
import { RedeemOfferComponent } from "./redeem-offer/redeem-offer.component";
import { AssociatedMemberComponent } from "./associated-member/associated-member.component";
import { CalculatorComponent } from "./hkaa-dollar/calculator/calculator.component";
import { TransactionHistoryComponent } from "./hkaa-dollar/transaction-history/transaction-history.component";
import { AccCampaignCalculator } from "./acc-campaign-calculator/acc-campaign-calculator.component";

const routes: Routes = [
  {
    path: "",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      listData: RegistrationResolver,
    },
    data: {
      isProtected: true, // For session timeout checking
      title: {
        en_US: "Profile | HKairport Rewards",
        zh_HK: "會員賬戶 | HKairport Rewards 香港國際機場獎賞計劃 ",
        zh_CN: "会员账户 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `Profile | HKairport Rewards`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `會員賬戶 | HKairport Rewards 香港國際機場獎賞計劃 `,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `会员账户 | HKairport Rewards 香港国际机场奖赏计划`,
          },
        ],
      },
    },
    children: [
      {
        path: "personal-info",
        pathMatch: "full",
        component: MemberInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "my-rewards",
        component: RedeemOfferComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "point-history",
        component: PointHistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "transaction-history",
        component: TransactionHistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reward-code",
        component: PromocodeRedemptionComponent,
        canActivate: [AuthGuard],
        children: [{ path: ":code" }],
      },
      {
        path: "",
        canActivate: [AuthGuard],
        redirectTo: "personal-info",
      },
    ],
  },
  {
    path: "edit",
    component: UpdateProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      listData: RegistrationResolver,
    },
    data: {
      title: "Profile",
      isProtected: true, // For session timeout checking
      isShowBack: true,
      backRoute: "/profile", // TODO: See if can use router state
    },
  },
  // {
  //   path: "associated-member",
  //   component: AssociatedMemberComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "update-ara",
    component: UpdateAraStatusComponent,
    canActivate: [AuthGuard, GeneralGuard],
    resolve: {
      listData: RegistrationResolver,
    },
    data: {
      title: "Profile",
      isProtected: true, // For session timeout checking
      isShowBack: true,
      backRoute: "/profile", // TODO: See if can use router state
    },
  },
  {
    path: "redeemed-offer/:userOfferId",
    component: RedeemedOfferDetailComponent,
    canActivate: [AuthGuard, GeneralGuard],
    data: {
      isProtected: true, // For session timeout checking
      isShowBack: true,
      backRoute: "/profile", // TODO: See if can use router state
    },
    resolve: {
      offer: RedeemedOfferDetailResolver,
    },
  },
  {
    path: "reward-code/redemption/success",
    component: SystemMessageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "calculator",
    component: CalculatorComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Profile",
      isProtected: true, // For session timeout checking
      isShowBack: true,
      backRoute: "/profile",
    },
  },
  {
    path: "acc-campaign-calculator",
    component: AccCampaignCalculator,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
