import { Component } from "@angular/core";
import { FooterItem, CommonService, I18NService } from "../../../core";
import { firstFooterItem, secondFooterItem } from "./footer.config";

@Component({
  selector: "app-layout-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  today: number = Date.now();
  firstFooterItem: FooterItem[] = firstFooterItem.filter((item) => item.enable); // Only show enabled menu item
  secondFooterItem: FooterItem[] = secondFooterItem.filter((item) => item.enable); // Only show enabled menu item

  constructor(private commonService: CommonService, private i18nService: I18NService) {
    // this.visTimestamp
  }

  openLink(item: FooterItem) {
    if (item.externalLink) {
      const externalLink = item.link[this.i18nService.getActiveLang];
      window.open(externalLink, "_blank");
    } else {
      this.commonService.redirect(item.link);
    }
  }

  get visTimestamp() {
    return window.localStorage['exitAppWithValidTokenTimestamp'];
  }

}
