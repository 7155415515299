import { ApiError } from "../../models/errors.model";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { NzMessageService } from "ng-zorro-antd/message";
import { Router } from "@angular/router";
import { UserService } from "../user.service";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ErrorService {

  constructor(private injector: Injector, private nzMessage: NzMessageService) { }

  /**
   * Handel Error
   *
   */

  // TODO: Handle Type, Add logger
  handleError(error: any) {
    if (typeof(error) === "undefined"){
      console.log("ignored error");   
      return;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.status === 403) {
        // this.sessionTimeout();
      }
    } else if (this.isApiError(error)) {
      // TODO: Handle API Error
      /**
       * 1. Make error code and message to constant
       */
      if (error.errorCode === "E0403") {
        // this.sessionTimeout();
      }
    } else {
      // TODO
      if (error.detail && !environment.production) {
        const errorCode = error.detail.split("--")[0];
        const errorDetail = error.detail.substr(error.detail.indexOf("--"), error.detail);
        if (error.status === 500) {
          this.createMessage(errorCode);
        } else {
          this.createMessage(errorCode);
        }
      } else if (error.detail && environment.production) {
        const errorCode = error.detail.split("--")[0];
        this.createMessage(errorCode);
      }
    }
    // router.navigate(["error"]);
  }

  createMessage(errorCode: string, errorDetail?: string) {
    // TODO: Add Error Type
    this.nzMessage.error(errorCode);
  }

  handleApiError(error: any): ApiError {
    if (error instanceof HttpErrorResponse) {
      // if (error.status === 403) {
      //   // this.sessionTimeout();
      //   return;
      // }
      if (error.error && error.error.detail) {
        const fullError = error.error.detail;
        return {
          errorCode: fullError.split("--")[0].trim(),
          errorDetail: null
        };
      } else if (error.error.responseInfo) {
        return error.error;
      } else {
        return {
          errorCode: null,
          errorDetail: null
        };
      }
    } else {
    }
  }

  redirectToErrorPage(error?: ApiError) {
    const router = this.injector.get(Router);
    const localizeRouter = this.injector.get(LocalizeRouterService);
    const localizePath = localizeRouter.translateRoute("/error") as string;
    if (error) {
      router.navigate([localizePath], { state: { error } });
    } else {
      router.navigate([localizePath]);
    }
  }

  isApiError(o: any): o is ApiError {
    const u: ApiError = o;
    return typeof u.errorCode === "string" && typeof u.errorDetail === "string";
  }
}
