import { environment } from '../../../../environments/environment';
import { MenuItem } from "../../../core";
export const menuItems: MenuItem[] = [
    {
        name: "Home", // Just for reference
        label: "COMMON.header.menu.home",
        link: "/home",
        enable: false,
        mobileEnable: true,
        matchRouter: true,
        exactMatchRouter: true
    },
    {
        name: "About Us",
        label: "COMMON.header.menu.aboutProgram",
        link: "/about-programme",
        enable: true,
        mobileEnable: true,
        matchRouter: true,
        exactMatchRouter: true
    },
    {
        name: "What's On",
        label: "COMMON.header.menu.whatsOn",
        link: "/campaigns",
        enable: true,
        mobileEnable: true,
        matchRouter: true,
        exactMatchRouter: true
    },
    {
        name: "Offers",
        label: "COMMON.header.menu.rewards",
        link: "/offers",
        enable: true,
        mobileEnable: true,
        matchRouter: true,
        exactMatchRouter: false
    },
    {
        name: "Spending Registration",
        label: "COMMON.header.menu.earn",
        link: "/earn",
        enable: true,
        mobileEnable: true,
        matchRouter: true,
        exactMatchRouter: true
    },
    {
        name: "HKAirport Shop",
        label: "COMMON.header.menu.onlineShop",
        link: {
            en_US: `${environment.URL_CONFIG.emp}/eshop_en/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=en_hk_Prospecting_AA-CRM-Portal`,
            zh_HK: `${environment.URL_CONFIG.emp}/eshop_zh/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=zh_hk_Prospecting_AA-CRM-Portal`,
            zh_CN: `${environment.URL_CONFIG.emp}/eshop_cn/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=cn_hk_Prospecting_AA-CRM-Portal`,
        },
        externalLink: true,
        enable: true,
        mobileEnable: true,
        matchRouter: false,
        exactMatchRouter: false
    },
    {
        name: "Directory",
        label: "COMMON.header.menu.directory",
        link: {
            en_US: "https://www.hongkongairport.com/en/shop-dine/highlights-promotions/",
            zh_HK: "https://www.hongkongairport.com/tc/shop-dine/highlights-promotions/",
            zh_CN: "https://www.hongkongairport.com/sc/shop-dine/highlights-promotions/",
        },
        externalLink: true,
        enable: true,
        mobileEnable: true,
        matchRouter: true,
        exactMatchRouter: true
    },
    {
        name: "Support",
        label: "COMMON.header.menu.support",
        link: "/faq",
        enable: true,
        mobileEnable: false,
        matchRouter: true,
        exactMatchRouter: true
    }
];
