import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-redirect',
  templateUrl: './logout-redirect.component.html',
  styleUrls: ['./logout-redirect.component.scss']
})
export class LogoutRedirectComponent {

  constructor() {}

}
