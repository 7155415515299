import * as _ from "underscore";
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import {UserService, I18NService, WalletService, User, TimeService} from "../../../../core";
import { MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
declare var $: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})

export class WalletComponent implements OnInit {
  public myAngularxQrCode: string = null;
  userName: string = null;
  userPersonTitle: string = null;
  userCNPersonTitle: string = null;
  memberNumber: string = null;
  detailProfile: any;
  tier = [];
  tierInfo: any;
  memberShip: string = null;
  deviceInfo: any;
  isIOS: boolean;
  isAndroid: boolean;
  isENUS: boolean;
  isVisible: boolean = false;
  copyMessage: string;
  user: User = {} as User;
  expiryDate: string;
  isHarmony: boolean = true;

  constructor(
    private userService: UserService,
    private i18nService: I18NService,
    private walletService: WalletService,
    private deviceDetectorService: DeviceDetectorService,
    private timeService: TimeService,
    public dialogRef: MatDialogRef<WalletComponent>
  ) {
      this.loadLoyalty();
      this.myAngularxQrCode = this.userService.getCurrentUser.memberNumber; 
      this.deviceInfo = this.deviceDetectorService;
      this.checkHarmonyOS();
    }

  ngOnInit() {
    this.getUserInfo();
    this.checkIOS();
    this.checkAndroid();
  }
  
  getUserInfo(): void {
    this.detailProfile = this.userService.getCurrentUser;
    const currentLang = this.i18nService.getActiveLang;
    this.userPersonTitle = this.detailProfile.memberProfile.personTitle;
    if (currentLang === "en_US") {
      this.userName = this.userPersonTitle + " " + this.detailProfile.memberProfile.firstName + " " + this.detailProfile.memberProfile.lastName;
      this.isENUS = true;
    } else {
      if (this.userPersonTitle && currentLang !== "en_US") {
        switch (this.userPersonTitle) {
          case "Mr.": {
            this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.mr");
            break;
          }
          case "Mrs.": {
            this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.mrs");
            break;
          }
          case "Ms.": {
            this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.ms");
            break;
          }
          case "Miss": {
            this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.miss");
            break;
          }
          default: {
            this.userPersonTitle = this.userPersonTitle;
          }
        }
        this.userName = this.detailProfile.memberProfile.lastName + " " + this.detailProfile.memberProfile.firstName + " " + this.userCNPersonTitle;
      }
      this.isENUS = false;
    }
    this.memberNumber = this.detailProfile.memberNumber;
  }

  addIdToClipboard(): void {
    if (this.memberNumber === null) { return; }
    navigator.clipboard.writeText(this.memberNumber);
    this.isVisible = true;
    const currentLang = this.i18nService.getActiveLang;
    if (currentLang === "en_US") {
      this.copyMessage = "Copied";
    } else if (currentLang == "zh_HK") {
      this.copyMessage = "已複製";
    } else if (currentLang == "zh_CN") {
      this.copyMessage = "已复制";
    }
    setTimeout (()=>{
      $(".copyMessage").fadeOut(2000);
      setTimeout(() => {
        this.isVisible = false;
      }, 2000);
    }, 3000);
  }

  transformMemberTitle(tierInfo): void {
    if (tierInfo.currentTier.name) {
      this.tier = tierInfo.currentTier.name.split(';');
      if (tierInfo.currentTier.name.includes('invitation')) {
        this.memberShip = this.i18nService.translateLabel("MEMBER_PROFILE.TIER.BY_INVITATION");
      } else if (tierInfo.currentTier.name.includes('VIP')){
        this.memberShip = this.i18nService.translateLabel("MEMBER_PROFILE.TIER.VIP");
      } else {
        this.memberShip = this.i18nService.translateLabel("MEMBER_PROFILE.TIER.GENERAL");
      }
    }
  }

  addGoogleWallet(): void {
    this.walletService.getGoogleWallet().subscribe(result => {
      window.open(result.data)
    })   
  }

  addAppleWallet(): void {
    this.walletService.getAppleWallet().subscribe(result => {
      saveAs(result, "aareward.pkpass");
    })   
  }

  checkIOS(): void {
    if (this.deviceInfo.isMobile() == true && this.deviceInfo.getDeviceInfo().os == "iOS" && sessionStorage.getItem("disableDisplayAppleWalletPassButton") !== "true") {
      this.isIOS = true;
    } else {
      this.isIOS = false;
    }
  }

  checkAndroid(): void {
    if (this.isHarmony == false && this.deviceInfo.isMobile() == true && this.deviceInfo.getDeviceInfo().os == "Android" && sessionStorage.getItem("disableDisplayGoogleWalletPassButton") !== "true") {
      this.isAndroid = true;
    } else {
      this.isAndroid = false;
    }
  }

  checkHarmonyOS(): void {
    if (this.deviceInfo.userAgent.includes("Harmony")) {
      this.isHarmony = true;
    } else {
      this.isHarmony = false;
    }
  }

  loadLoyalty() {
    Object.assign(this.user, this.userService.getCurrentUser);
    const memberNumber = this.user.memberNumber;
    const currentLang = this.i18nService.getActiveLang;
    this.userService.getLoyalty(memberNumber).subscribe(
      (data) => {
        this.expiryDate = this.timeService.dateFormat2(new Date(data.expiryDate));
        if (this.expiryDate == "Invalid Date") {
          this.expiryDate = null;
        }
        if (data) {
          data.tierDetails.tierLevels.forEach((item,index)=>{
            if(item.currentTier.name.includes('VIP')){
              data.tierDetails.tierLevels[0].currentTier.name = "VIP Member";
            }
          })
          this.transformMemberTitle(_.first(data.tierDetails.tierLevels)); 
        }
      })
  }
}

