export const GlobalConfig = {
  APP_TITLE: {
    en_US: "HKairport Rewards",
    zh_HK: "HKairport Rewards 香港國際機場獎賞計劃",
    zh_CN: "HKairport Rewards 香港国际机场奖赏计划",
  },
  PHOTO_UPLOAD: {
    accept_types: ".jpg,.jpeg,.png", // Use String for nzAccept
    size_limit: 8, // in MB
  },
  RELEASE_CONFIG: {
    releaseDate: "2020-05-26"
  }
};
