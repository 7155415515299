import { ApiService } from "./api/api.service";
import { I18NService } from "./i18n.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UploadFile } from "ng-zorro-antd";
import { map } from "rxjs/operators";
declare var ImageCompressor: any;

@Injectable()
export class MediaService {
  constructor(private apiService: ApiService, private i18nService: I18NService) {}

  getAll(): Observable<[string]> {
    return this.apiService.get("/tags").pipe(map(data => data.tags));
  }

  // TODO
  transformToBase64(img: any, callback: (img: string) => void): void {
    const reader = new FileReader();
    // tslint:disable-next-line: no-non-null-assertion
    reader.addEventListener("load", () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  compressPhoto(file: any, p_quality?: number): Promise<UploadFile> {
    return new Promise((resolve, reject) => {
      return new ImageCompressor(file, {
        quality: p_quality ? p_quality : 0.25,
        convertSize: 2000000,
        checkOrientation: false,
        success(result) {
          resolve(result);
        },
        error(e) {
          reject(e.message);
        }
      });
    });
  }

  public checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      if (this.isIE) {
        const img = new Image(); // create image
        img.src = window.URL.createObjectURL(file);
        img.onload = function() {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src!);
          resolve(width * height <= 25000000);
        };
        img.onerror = function() {
          resolve(false);
        };
      } else {
        resolve(true);
      }
    });
  }

  get isIE() {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf("MSIE "); // IE 10 or older
    const trident = ua.indexOf("Trident/"); // IE 11
    return msie > 0 || trident > 0;
  }

  public uploadPhotoPreCheck(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const isCorrectFormat = ["image/jpeg", "image/jpg", "image/png"].indexOf(file.type);
      if (isCorrectFormat === -1) {
        reject("COMMON.message.NOT_VALID_IMAGE_FORMAT");
      }
      const isLt8M = file.size / 1024 / 1024 <= 8;
      if (!isLt8M) {
        reject("COMMON.message.imageSizeExceed");
      }
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          reject("COMMON.message.overDimension");
        }
        resolve(isCorrectFormat !== -1 && isLt8M && dimensionRes);
      });
    });
  }
}
