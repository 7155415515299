import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";

export const CTA_CONFIG = {
  guest: {
    title: "CTA.CONTENT.NON_MEMBER_TITLE",
    description: "",
    ctaLabel:"CTA.CONTENT.GUEST_CTA_LABEL"
  },
  member: {
    title: "CTA.LABEL.MEMBER_LOGIN_TITLE",
    description: "",
    ctaLabel:"CTA.CONTENT.MEMBER_CTA_LABEL"
  },
  guestAction: {
    type: "login"
  },
  memberAction: {
    type: "link",
    link: "/earn"
  },
};

export const CAROUSEL_CONFIG: PromotionCarouselConfig[] = [
  {
    index: 0,
    isHideText: true,
    title: {
      "en_US": "You Shop It, We Reward It",
      "zh_HK": "隨時買 隨時賞",
      "zh_CN": "随时买 随时赏",
    },
    content: {
      /*
      "en_US": `Up to 6% spending rewards`,
      "zh_HK": `消費獎賞高達6%`,
      "zh_CN": `消费奖赏高达6%`,
       */
    },
    CTA_BUTTON_LABEL: {
      "en_US": `Join Now`,
      "zh_HK": `立即加入`,
      "zh_CN": `立即加入`,
    },
    CTA_BUTTON_LABEL_MEMBER: {
      "en_US": `More details`,
      "zh_HK": `查看更多`,
      "zh_CN": `查看更多`,
    },
    guestAction: {
      type: "login"
    },
    memberAction: {
      type: "link",
      link: "/offers"
    },
    coverImg: "/assets/images/banner/release/homepage_hero_banner_01.jpg",
    mobileCoverImg: "/assets/images/banner/release/homepage_hero_banner_01_m.jpg",
    bgColor: "#3D50E4",
  }
];
