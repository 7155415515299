import * as _ from "underscore";
import { Component, OnInit, HostListener } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from "@angular/forms";
import { GlobalConfig } from "../../core/app-global.config";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Router, ActivatedRoute } from "@angular/router";
import { UploadFile } from "ng-zorro-antd";
import { User, UserService, MediaService, FormService, I18NService, CommonService, TimeService, ModalService, GeneralGuard } from "../../core";
import { differenceInCalendarDays, format } from "date-fns";
import { environment } from "../../../environments/environment";
import { ModalComponent } from '../../shared/modal/modal.component';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Location } from "@angular/common";
import { fromEvent } from 'rxjs';
import { RegisterPurchaseService } from '../../core/services/register-purchase.service';
import { PPUrlArr } from '../../shared';

@Component({
  selector: "app-register-purchase-page",
  templateUrl: "./register-purchase.component.html",
  styleUrls: ["register-purchase.component.scss"],
})
export class RegisterPurchaseComponent implements OnInit {
  avatarUrl: string;
  contactUs: string;
  creditCardFileList: UploadFile[] = [];
  dateFormat = environment.APP_CONFIG.dataFormat;
  earnHistory = [];
  errors: Object = {};
  isDesktop = this.deviceService.isDesktop();
  isLoading = true;
  isSubmitting = false;
  loading = false;
  picsLink = "";
  pointBalance: any;
  previewImage: string | undefined = "";
  previewVisible = false;
  purchaseDateStatus = "";
  rejectReasonPickList = [];
  selfEarnForm: FormGroup;
  settingsForm: FormGroup;
  shopInvoiceFileList: UploadFile[] = [];
  shopList = [];
  style = { width: "100%", "text-align": "center", "font-size": "18px", "border-top": "1px solid #e8e8e8" };
  tabSelectedIndex = 0;
  tncLink = "";
  uploadAcceptTypes = GlobalConfig.PHOTO_UPLOAD.accept_types;
  user: User = {} as User;

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };
  isOpen: boolean = false;
  tooltipOpen_six: boolean = false;
  tooltipOpen_five: boolean = false
  minimumAmount: number = 0;
  PPUrlArr = PPUrlArr;

  @HostListener("window:scroll", []) onWindowScroll() {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    //  || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 170) {
      element.classList.add("tab-sticky");
    } else {
      element.classList.remove("tab-sticky");
    }
  }

  maskConfig = {
    mask: Number,
    scale: 2, // digits after point, 0 for integers
    signed: false, // disallow negative
    thousandsSeparator: ",", // any single char
    padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
    normalizeZeros: true, // appends or removes zeros at ends
    radix: ".", // fractional delimiter
    mapToRadix: ["."], // symbols to process as radix
    min: 0,
    max: 9999999.99,
  };

  constructor(
    private fb: FormBuilder,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    public formService: FormService,
    private i18nService: I18NService,
    private localizeRouter: LocalizeRouterService,
    private deviceService: DeviceDetectorService,
    private commonService: CommonService,
    private modalService: ModalService,
    private authStorage: OAuthStorage, 
    private generalGuardService: GeneralGuard,
    private _location: Location,
    private registerPurchaseSvc: RegisterPurchaseService
  ) {
    this.route.data.subscribe((data: { shopList: any[]; rejectReasonList: any[] }) => {
      if (data.shopList) {
        this.shopList = data.shopList[this.i18nService.getActiveLang];
      }
      if (data.rejectReasonList) {
        this.rejectReasonPickList = data.rejectReasonList[this.i18nService.getActiveLang];
      }
      this.tncLink = this.localizeRouter.translateRoute("/legal/terms-and-condition") as string;
      this.picsLink = this.localizeRouter.translateRoute("/legal/personal-information-collection-statement") as string;
      this.contactUs = this.localizeRouter.translateRoute("/contact-us") as string;
    });
    // TODO: Global config for minimum & maximum amount
    Object.assign(this.user, this.userService.getCurrentUser);
    // Subscribe to changes on the form
  }

  ngOnInit() {
    
    this.route.queryParams.subscribe((value) => {
      if (value["page"] && value["page"] === "status") {
        this.tabSelectedIndex = 1;
      };
    });

    this.selfEarnForm = this.fb.group({
      amount: [
        "",
        {
          validators: [Validators.required, Validators.max(9999999.99), this.getMinimumAmountValidation()],
          updateOn: "blur",
        },
      ],
      purchaseDate: ["", [Validators.required]],
      shop: [null, [Validators.required]],
      agreeTerms: [false, [Validators.requiredTrue]],
      creditCardFileList: [false, [Validators.requiredTrue]],
      shopInvoiceFileList: [false, [Validators.requiredTrue]],
      luxReserve: [false]
    });
    this.purchaseDate.valueChanges.pipe().subscribe((value) => {
      this.getMinimumAmount();
      if (differenceInCalendarDays(new Date(), value) > 14) {
        this.purchaseDateStatus = "warning";
      } else {
        this.purchaseDateStatus = "";
      }
    });

    fromEvent(window, 'touchstart').subscribe((event) => {
      if(this.tooltipOpen_six){
        this.tooltipOpen_six = false;
      }
      if(this.tooltipOpen_five){
        this.tooltipOpen_five = false;
      }
    });
  }

  tabClicked() {
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }
  }

  get purchaseDate() {
    return this.selfEarnForm.get("purchaseDate") as FormControl;
  }

  get shop() {
    return this.selfEarnForm.get("shop") as FormControl;
  }

  // Date Picker Config
  disabledDate = (current: Date): boolean => {
    return (
      differenceInCalendarDays(current, new Date(GlobalConfig.RELEASE_CONFIG.releaseDate)) < 0 ||
      differenceInCalendarDays(current, new Date()) > 0 ||
      differenceInCalendarDays(new Date(), current) > 20
    );
  };

  submitForm() {
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }
    
    const timeService = new TimeService()
    // Validate Photos
    if (this.creditCardFileList.length > 0) {
      this.selfEarnForm.get("creditCardFileList").setValue(true);
    } else {
      this.selfEarnForm.get("creditCardFileList").setValue(false);
    }

    if (this.shopInvoiceFileList.length > 0) {
      this.selfEarnForm.get("shopInvoiceFileList").setValue(true);
    } else {
      this.selfEarnForm.get("shopInvoiceFileList").setValue(false);
    }

    if (this.selfEarnForm.valid) {
      this.isSubmitting = true;
      // Convert From to Formdata
      const formData = new FormData();
      this.creditCardFileList.forEach((file: any) => {
        formData.append("receipts", file);
      });
      this.shopInvoiceFileList.forEach((file: any) => {
        formData.append("invoices", file);
      });
      formData.append("amount", this.selfEarnForm.get("amount").value);
      formData.append("purchaseDate", this.selfEarnForm.get("purchaseDate").value);
      formData.append("purchaseDateString", timeService.dateFormat(this.selfEarnForm.get("purchaseDate").value));
      formData.append("shop", this.selfEarnForm.get("shop").value);
      formData.append("memberNumber", this.user.memberNumber);
      formData.append("luxReserve", this.selfEarnForm.get("luxReserve").value);

      this.userService.createEarnCase(this.user.memberNumber, formData).subscribe(
        (data) => {
          if (data) {
            this.isSubmitting = false;
            const path = this.localizeRouter.translateRoute("/earn/success");
            this.router.navigate([path, { ref: data.caseNumber }]);

            const successPageData = {
              displayItem: {
                title: "earn.message.success",
                subTitle: "earn.message.SUCCESS_SUBTITLE",
                icon: "receipt_added",
                buttonLabel: "EARN.LABEL.CHECK_RECEIPT_STATUS",
                actionType: "LINK",
                link: "/earn", // TODO: Add queryParams,
                linkRouterData: { queryParams: { page : "status" } },
              },
            };
            this.commonService.redirect("/earn/success", null, { state: successPageData });
          }
        },
        (err) => {
          if(err.errorCode === "E1005"){
            alert(this.i18nService.translateLabel("COMMON.message.imageSizeExceed"));
          } else alert(this.i18nService.translateLabel("COMMON.message.ReceiptFailedToAdd"));

          this.isSubmitting = false;
        }
      );
    } else {
      for (const i in this.selfEarnForm.controls) {
        this.selfEarnForm.controls[i].markAsDirty();
        this.selfEarnForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }
  
  processCreditCardInvoice = (file: File): boolean => {
    this.mediaService.uploadPhotoPreCheck(file).then(
      async (isFileValid) => {
        if (isFileValid) {
          let isGreater = true;
          let compressedFile: UploadFile;
          try {
            compressedFile = await this.mediaService.compressPhoto(file,0.25);
            if (compressedFile.size > 2097152) { // Greater than 2MB
              compressedFile = await this.mediaService.compressPhoto(file,0.1);
            }
            isGreater = (compressedFile.size > 2097152)
          } catch(e){
            alert(this.i18nService.translateLabel("COMMON.message.imageCompressionFail"));
          }
          if(!isGreater){
            this.mediaService.transformToBase64(compressedFile, (img: string) => {
              compressedFile.url = img;
              compressedFile.thumbUrl = img;
              compressedFile.uid = Math.random().toString();
              this.creditCardFileList = this.creditCardFileList.concat(compressedFile);
            });
          }else {
            alert(this.i18nService.translateLabel("COMMON.message.imageCompressionFail"));
          }
        }
      },
      (error) => {
        alert(this.i18nService.translateLabel(error));
      }
    );
    return false;
  };

  processShopInvoice = (file: File): boolean => {
    this.mediaService.uploadPhotoPreCheck(file).then(
      async (isFileValid) => {
        if (isFileValid) {
          let isGreater = true;
          let compressedFile: UploadFile;
          try {
            compressedFile = await this.mediaService.compressPhoto(file,0.25);
            if (compressedFile.size > 2097152) { // Greater than 2MB
              compressedFile = await this.mediaService.compressPhoto(file,0.1);
            }
            isGreater = (compressedFile.size > 2097152)
          } catch(e){
            alert(this.i18nService.translateLabel("COMMON.message.imageCompressionFail"));
          }
          if(!isGreater){
            this.mediaService.transformToBase64(compressedFile, (img: string) => {
              compressedFile.url = img;
              compressedFile.thumbUrl = img;
              compressedFile.uid = Math.random().toString();
              this.shopInvoiceFileList = this.shopInvoiceFileList.concat(compressedFile);
            });
          }else {
            alert(this.i18nService.translateLabel("COMMON.message.imageCompressionFail"));
          }
        }
      },
      (error) => {
        alert(this.i18nService.translateLabel(error));
      }
    );
    return false;
  };

  handlePreview = (file: File) => {
    this.mediaService.transformToBase64(file, (img: string) => {
      this.previewImage = img as string;
      this.previewVisible = true;
    });
  };

  // TODO: Make it globally
  trackByFn(index, item) {
    return index;
  }

  onClickTabHeader() {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    // || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 170) {
      desktopContainer.scrollTop = 171;
      // mobileContainer.scrollTop = 81;
    }
  }

  clickTerms() {
    this.selfEarnForm.controls.agreeTerms.setValue(!this.selfEarnForm.value.agreeTerms);
  }

  showModal(modalType: "TC" | "PP" | "CU") {
    if (modalType === 'PP') {
      window.open(this.PPUrlArr[this.i18nService.getActiveLang], '_blank');
    } else {
      this.modalService.showModal(modalType);
    }
  }


  getMinimumAmount(): void {
    if (this.purchaseDate && this.purchaseDate.value) {
      const timeService = new TimeService()
      this.registerPurchaseSvc.getSpendingAmount(timeService.dateFormat(this.purchaseDate.value)).subscribe(data => {
        if (data && data.amount!= null) {
          this.minimumAmount = data.amount < 100000 ? data.amount : 0;
        }
        this.selfEarnForm.get('amount').updateValueAndValidity();
      })
    } else {
      this.minimumAmount = 0;
      this.selfEarnForm.get('amount').updateValueAndValidity();
    }
  }


  getMinimumAmountValidation(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && !!this.minimumAmount && (control.value < this.minimumAmount)) {
        return { min: true };
      }
      if (control.hasError('min')) {
        return {}
      }
      return null;
    }
  }
}
