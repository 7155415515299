import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EarnNowRoutingModule } from "./earn-now-routing.module";

import { MatDialogModule } from "@angular/material/dialog";
import { EarnNowComponent } from './earn-now.component';
import { EarnPointSuccessComponent } from './earn-point-success/earn-point-success.component';
import { EarnPointFailComponent } from './earn-point-fail/earn-point-fail.component';
import { EarnPointExpiredComponent } from './earn-point-expired/earn-point-expired.component';
import { EarnPointCancelledComponent } from './earn-point-cancelled/earn-point-cancelled.component';
import { EarnPointEarnedComponent } from './earn-point-earned/earn-point-earned.component';
import { EarnNowUploadComponent } from './earn-now-upload/earn-now-upload.component';

@NgModule({
  declarations: [EarnNowComponent, EarnPointSuccessComponent, EarnPointFailComponent, EarnPointExpiredComponent, EarnPointCancelledComponent, EarnPointEarnedComponent, EarnNowUploadComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    EarnNowRoutingModule,
  ],
  exports: [
    RouterModule,
  ],
  entryComponents: [EarnPointSuccessComponent, EarnPointFailComponent, EarnPointCancelledComponent, EarnPointExpiredComponent, EarnPointEarnedComponent, EarnNowUploadComponent]
})
export class EarnNowModule { }
