declare var $: any;
import * as _ from "underscore";
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, ElementRef, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MenuItem, UserService, User, CommonService, I18NService } from "../../../core";
import { mainMenuItems, subMenuItems } from "./mobile-drawer.config";

@Component({
  selector: "app-mobile-drawer",
  templateUrl: "./mobile-drawer.component.html",
  styleUrls: ["./mobile-drawer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDrawerComponent implements OnInit {
  @ViewChild("mobileDrawerRef", { static: false }) mobileDrawerRef: ElementRef<{}>;

  availableLangs: any;
  currentLang: string;
  currentUser: User = {} as User;
  isDrawerVisible = false;
  isShowProfileSection = true;
  mainMenuItems: MenuItem[] = mainMenuItems.filter((item) => item.mobileEnable); // Only show enabled menu item
  subMainMenuItems: MenuItem[] = subMenuItems.filter((item) => item.mobileEnable); // Only show enabled menu item

  bodyStyle = {
    color: "#FFFFFF",
  };

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.isDrawerVisible && window.innerWidth > 1223) {
      this.closeDrawer();
    }
  }

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private i18nService: I18NService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.userService.currentUser.subscribe((userData) => {
      this.currentUser = userData;
    });
    // Common with header
    const _this = this;

    // Control mobile view close button
    commonService.isShowCloseButton$.subscribe((result) => {
      this.isDrawerVisible = result;
      this.setAvailableLanguage();
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnInit() {
    this.setAvailableLanguage();
  }

  logout() {
    this.userService.logoff();
  }

  setAvailableLanguage() {
    this.availableLangs = this.i18nService.getLangs().filter((item) => item.langId !== this.i18nService.getActiveLang);
  }

  reloadProfileSection() {
    this.isShowProfileSection = false;
    setTimeout(() => {
      this.isShowProfileSection = true;
      this.closeDrawer();
      this.changeDetectorRef.markForCheck();
    }, 50);
  }

  closeDrawer() {
    this.commonService.isShowCloseButton(false);
  }

  openDrawer() {
    this.isDrawerVisible = true;
    this.commonService.isShowCloseButton(true);
    $(".ant-drawer").animate({ scrollTop: 0 }, "fast");
    this.changeDetectorRef.markForCheck();
  }

  async goToLink(link: string) {
    // TODO: 0.5s delay workaround for https://github.com/angular/angular/issues/24547
    await this.commonService.browserDelay(800);
    this.commonService.redirect(link);
  }

  async changeLang(lang) {
    this.closeDrawer();
    this.changeDetectorRef.markForCheck();
    await this.commonService.browserDelay(500);
    this.currentLang = lang.text;
    this.i18nService.use(lang.langId);
    sessionStorage.setItem("MP_SELECTED_LANGUAGE", lang.langId);
  }

  openLink(item: MenuItem) {
    if (item.externalLink) {
      const externalLink = item.link[this.i18nService.getActiveLang];
      window.open(externalLink, "_blank");
    } else {
      this.commonService.redirect(item.link);
    }
  }
}
