import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CommonService } from './common.service';
import { UserService } from './user.service';
import { I18NService } from "./i18n.service";

@Injectable({
  providedIn: 'root'
})
export class LogoutRedirectGuard implements CanActivate {
  constructor(private commonService: CommonService, private userService: UserService, private cookieService: CookieService, private i18nService:I18NService,
 private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Handle SSO Logout
    const callBackParam = next.queryParams.cb;
    const currentPath = next.parent.firstChild.url[0].path;
    if (currentPath === 'logout' && callBackParam) {
      const redirectUrl = decodeURI(callBackParam);
      this.userService.purgeAuth(true).subscribe(() => {
        sessionStorage.setItem("cb", redirectUrl);
        window.location.href = environment.logoutUrl;
      })
      // return true;
    } else if (currentPath === 'logout-redirect') {
      // Do logout redirect
      const localLang = sessionStorage.getItem("MP_SPPL_LANGUAGE");
      const redirectUrl = sessionStorage.getItem("cb");
      let selectedLang = sessionStorage.getItem('MP_SELECTED_LANGUAGE');      
      if(selectedLang && localLang !== selectedLang){   
        this.i18nService.use(selectedLang, false);
      }else{
        selectedLang = localLang;
      }
      if (redirectUrl && redirectUrl !== environment.baseUrl) {
        window.location.href = redirectUrl;
      } else {
        // this.commonService.redirect(`/${selectedLang}/home`);
        this.router.navigateByUrl(`/${selectedLang}/home`);
      };
      // return true;
    } else {
      return true;
    }
  }
}
