import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalizedRouter, LocalizeParser, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { I18NService } from '../../../core';

@Component({
  selector: "app-common-error-page",
  templateUrl: "./common-error.component.html",
  styleUrls: ["./common-error.component.scss"],
})
export class CommonErrorComponent implements OnInit {
  error: any;
  externalError: boolean;
  externalErrorMessage = "";
  constructor(private router: Router, private route: ActivatedRoute, private localizeService: LocalizeRouterService, private i18nService: I18NService) {
    let errorCode;
    let lang;
    this.route.queryParams.subscribe((value) => {
      if (value["ErrorDescription"]) {
        this.externalError = true;
        errorCode = decodeURI(value["ErrorDescription"]);
        if (!String(errorCode).includes("-")) {
          lang = "en_US";
          errorCode = String(errorCode);
        } else {
          lang = errorCode.split("-")[1];
          errorCode = errorCode.split("-")[0];
        };
        if (lang === "en_US" || lang === "zh_HK" || lang === "zh_CN"){
          this.localizeService.changeLanguage(lang);
        }
      } else {
        errorCode = value["ErrorState"] && decodeURI(value["ErrorState"]);
      }
       //E9+ 3 digits
      if (/^E9\d{3}$/.test(errorCode)){
        this.externalErrorMessage = `ERRORS.${errorCode}`;
      } else {
        this.externalErrorMessage = 'ERRORS.E9002'
        
      }
    });
  }

  ngOnInit() { }

  handleExternalError() {
    let errorCode;
    this.route.queryParams.subscribe((value) => {
      errorCode = decodeURI(value["ErrorCode"]);
    });
  }
}
