import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-reward-journey-page",
  templateUrl: "./reward-journey.component.html",
  styleUrls: ["./reward-journey.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RewardJourneyComponent implements OnInit, OnDestroy {
  version;
  constructor(private route: ActivatedRoute) {
    this.version = this.route.snapshot.paramMap.get('version');
  }

  get version2Display(): boolean {
    return true;
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
