import { AuthConfig, OAuthStorage, OAuthModule, OAuthModuleConfig } from "angular-oauth2-oidc";
import { CommonModule } from "@angular/common";
import { FeedbackService } from "./services/feedback.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpTokenInterceptor as HttpInterceptor } from "./interceptors/http.token.interceptor";
import { NgModule, ModuleWithProviders, Optional, SkipSelf } from "@angular/core";
import { authConfig } from "../pages/account/auth.config";
import { authModuleConfig } from "./auth-module-config";

import {
  AlertService,
  ApiService,
  AuthGuard,
  GeneralGuard,
  CampaignsService,
  CommonService,
  ErrorService,
  FaqService,
  FormService,
  JwtService,
  MediaService,
  OffersService,
  ProfilesService,
  RemoteConfigService,
  TimeService,
  LaunchAppService,
  QrCodeService,
  LiveChatService,
  AssociateMemberService,
  ModalService,
  WalletService,
  HkiaApiErrorService
} from "./services";

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot()],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    AlertService,
    ApiService,
    HkiaApiErrorService,
    AuthGuard,
    CampaignsService,
    CommonService,
    ErrorService,
    FaqService,
    FeedbackService,
    FormService,
    GeneralGuard,
    JwtService,
    LaunchAppService,
    MediaService,
    OffersService,
    ProfilesService,
    QrCodeService,
    RemoteConfigService,
    TimeService,
    LiveChatService,
    AssociateMemberService,
    ModalService,
    WalletService
  ],
  declarations: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useFactory: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }
}
