import { ContactFormComponent } from "./contact-form.component";
import { ContactFormResolver } from "./contact-form-resolver.service";
import { ContactFormRoutingModule } from "./contact-form-routing.module";
import { MapsComponent } from "./maps/maps.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";3

@NgModule({
  imports: [SharedModule, ContactFormRoutingModule],
  declarations: [ContactFormComponent, MapsComponent],
  entryComponents: [MapsComponent],
  providers: [ContactFormResolver],
})
export class ContactFormModule {}
