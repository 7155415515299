import { Component, Input } from "@angular/core";
import { UserService, CommonService } from "../../core";

@Component({
  selector: "app-join-now-cta",
  styleUrls: ["join-now-banner.component.scss"],
  templateUrl: "./join-now-banner.component.html",
})
export class JoinNowBannerComponent {
  constructor(private userService: UserService, private commonService: CommonService) {}

  joinNowSetting: any;

  noDescription: boolean;

  @Input() set joinNowConfig(config) {
    if (config) {
      this.joinNowSetting = config;
    }
  }

  ngOnInit() {
    this.controlDesc();
  }

  controlDesc() {
    if (this.joinNowSetting.description === "") {
      this.noDescription = true;
    } else {
      this.noDescription = false;
    }
  }

  login() {
    this.userService.login();
  }

  emitEvent() {
    if (this.userService.isLoggedIn) {
      if (this.joinNowSetting.memberAction.type === "link") {
        this.commonService.redirect(this.joinNowSetting.memberAction.link);
      }
    } else {
      if (this.joinNowSetting.guestAction.type === "login") {
        this.userService.login();
      }
    }
  }
}
