import { AbstractControl } from "@angular/forms";

export function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEXP.test(control.value) ? null : { invalidFormat: true };
}

export function phoneValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  const PHONE_REGEXP = /^[0-9]*$/;
  return PHONE_REGEXP.test(control.value) ? null : { invalidPhoneFormat: true };
}

export function otpValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  setTimeout(function() {
    const OTP_REGEXP = /^((\\+91-?)|0)?[0-9]{6}$/;
    return OTP_REGEXP.test(control.value) ? null : { invalidFormat: true };
  }, 1000);
}

/**
 *
 * Airport Authority Password Policy
 * 1. User passwords expire in: <Never expires>
 * 2. Enforce password history: <3 passwords remembered>
 * 3. Minimum password length: <8>
 * 4. Password complexity requirement: < Must include numbers and uppercase and lowercase letters>*
 * 5. Password question requirement: <Cannot contain password>
 * 6. Maximum invalid login attempt: <6>#
 */

export function passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  const trimmedPassword = control.value.trim();
  const hasNumber = /\d/.test(trimmedPassword);
  const hasUpper = /[A-Z]/.test(trimmedPassword);
  const hasLower = /[a-z]/.test(trimmedPassword);
  const hasSpecialChar = /\W|_/.test(trimmedPassword);
  const matchAtLeast3 = [hasNumber, hasUpper, hasLower, hasSpecialChar].filter(v => v).length >= 3;
  const valid = matchAtLeast3;
  if (trimmedPassword.length < 8) {
    return { minLength: true };
  }
  if (!valid) {
    return { mismatchPolicy: true };
  }
  return null;
}
