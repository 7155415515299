import { ApiService } from "./api/api.service";
import { HighlightedOfferList, OffersList, OffersRequest, RewardStore, RewardStoreOffer, ClaimPromoCodeRequest, FilteringCategoryTranslationResponse, RewardStoreOfferCountResponse } from "../models";
import { I18NService } from "./i18n.service";
import { Injectable } from "@angular/core";
import { Observable, zip } from "rxjs";
import { UserService } from "./user.service";
import { map, switchMap } from "rxjs/operators";

@Injectable()
export class OffersService {
  constructor(private apiService: ApiService, private userService: UserService, private i18nService: I18NService) { }

  getHighlightedOffers(langId: string): Observable<Array<HighlightedOfferList>> {
    return this.apiService.get(`/offers?detailed=true&highlighted=true&lang=${langId}`, "loyalty", null).pipe(map(data => data));
  }

  getOfferList(params): Observable<Array<HighlightedOfferList>> {
    return this.apiService.get(`/offers?detailed=true&lang=${this.i18nService.getActiveLang}&${params}`, "loyalty", null).pipe(map(data => data.content));
  }

  getGeneralRewardStoreOffers(
    params,
    sortingCategory?: string,
    sortingParam?: string,
    offerFilterShortCode?: string,
    startingPoint?: number,
    endingPoint?: number): Observable<Array<HighlightedOfferList>> {
    let sortingCategoryParam = sortingCategory ? `&sortingCategory=${sortingCategory}` : "";
    let sortingParamPart = sortingParam ? `&sortingPara=${sortingParam}` : "";
    let offerFilterShortCodeParam = offerFilterShortCode ? `&offerFilterShortCode=${offerFilterShortCode}` : "";
    let startingPointParam = startingPoint || startingPoint === 0 ? `&startingPoint=${startingPoint}` : "";
    let endingPointParam = endingPoint || endingPoint === 0 ? `&endingPoint=${endingPoint}` : "";
    return this.apiService.get(`/offers/rewardStoreOffers?lang=${this.i18nService.getActiveLang}&page=1&size=100&${params}${sortingParamPart}${offerFilterShortCodeParam}${sortingCategoryParam}${startingPointParam}${endingPointParam}`, "loyalty", null).pipe(map(data => data.content));
  }

  getMemberOffers(userId): Observable<OffersList> {
    return this.apiService.post(`/offers/member/${userId}`, "loyalty", null).pipe(map(data => data));
  }

  getMemberECoupons(userId): Observable<OffersList> {
    return this.apiService.post(`/offers/member/${userId}/ecoupons`, "loyalty", null).pipe(map(data => data));
  }

  issueRewardStoreOffer(rewardStoreId, offerId): Observable<OffersList> {
    return this.apiService.post(`/rewardstores/${rewardStoreId}/issue/${offerId}`, "loyalty", null).pipe(map(data => data));
  }

  getRewardStoreOffers(
    params,
    userId: string = this.userService.getCurrentUser.memberNumber,
    langId: string = this.i18nService.getActiveLang,
    sortingCategory?: string,
    sortingParam?: string,
    offerFilterShortCode?: string,
    startingPoint?: number,
    endingPoint?: number,
    rewardStoreOffer?: number
  ): Observable<Array<RewardStoreOffer>> {
    let sortingCategoryParam = sortingCategory ? `&sortingCategory=${sortingCategory}` : "";
    let sortingParamPart = sortingParam ? `&sortingPara=${sortingParam}` : "";
    let offerFilterShortCodeParam = offerFilterShortCode ? `&offerFilterShortCode=${offerFilterShortCode}` : "";
    let startingPointParam = startingPoint || startingPoint === 0 ? `&startingPoint=${startingPoint}` : "";
    let endingPointParam = endingPoint || endingPoint === 0 ? `&endingPoint=${endingPoint}` : "";
    let rewardStoreOfferParam = rewardStoreOffer ? `&rewardStoreOffer=${rewardStoreOffer}` : "";
    return this.apiService.get(`/rewardstores/offers/member/${userId}?lang=${langId}&page=1&size=100&${params}${sortingParamPart}${offerFilterShortCodeParam}${sortingCategoryParam}${startingPointParam}${endingPointParam}${rewardStoreOfferParam}`, "loyalty", null).pipe(map(data => data.content));
  }

  getRewardsStoreOfferFilteringTranslation(offerFilterShortCode: string): Observable<Array<FilteringCategoryTranslationResponse>> {
    return this.apiService.get(`/offers/category?offerFilterShortCode=${offerFilterShortCode}`, "loyalty", null).pipe(map(data => data.content));
  }

  getAllRewardsStoreOfferFilteringTranslation(): Observable<Array<FilteringCategoryTranslationResponse>> {
    return this.apiService.get(`/offers/all/category`, "loyalty", null).pipe(map(data => data));
  }

  getMemberRewardsStoreOfferCount(
      userId: string = this.userService.getCurrentUser.memberNumber,
      langId: string = this.i18nService.getActiveLang,
      params,
      sortingParam?: string,
      offerFilterShortCode?: string,
      startingPoint: number = 0,
      endingPoint: number = 1000000
  ):Observable<Array<RewardStoreOfferCountResponse>>{
     return this.apiService.get(`/rewardstores/offers/count/member/${userId}?offerFilterShortCode=${offerFilterShortCode}&page=1&size=100&lang=${langId}&sortingPara=${sortingParam}&${params}&startingPoint=${startingPoint}&endingPoint=${endingPoint}`, "loyalty", null).pipe(map(data => data));
  }

  getRewardStoreOffersCount(
      langId: string = this.i18nService.getActiveLang,
      params,
      sortingParam?: string,
      offerFilterShortCode?: string,
      startingPoint: number = 0,
      endingPoint: number = 1000000
  ):Observable<Array<RewardStoreOfferCountResponse>>{
     return this.apiService.get(`/offers/count?offerFilterShortCode=${offerFilterShortCode}&page=1&size=100&lang=${langId}&sortingPara=${sortingParam}&${params}&startingPoint=${startingPoint}&endingPoint=${endingPoint}`, "loyalty", null).pipe(map(data => data));
  }


  getUserRewardStores(params, userId: string = this.userService.getCurrentUser.memberNumber, langId: string = this.i18nService.getActiveLang): Observable<Array<RewardStore>> {
    return this.apiService.get(`/rewardstores/member/${userId}?lang=${langId}&page=1&size=100&${params}`, "loyalty", null).pipe(map(data => data.payload.reward_stores));
  }

  redeemCoupon(offersRequest: OffersRequest): Observable<OffersList> {
    return this.apiService.post(`/offers/redemption/redeem`, "loyalty", offersRequest).pipe(map(data => data));
  }

  claimPromoCode(claimPromoCodeRequest: ClaimPromoCodeRequest): Observable<boolean> {
    return this.apiService.post(`/offers/redemption/promocode`, "loyalty", claimPromoCodeRequest).pipe(map(data => data));
  }

  manualRedeemCoupon(offerId: string, promocodeType: boolean = false): Observable<OffersList> {
    const offerRequest: OffersRequest = {
      memberNumber: this.userService.getCurrentUser.memberNumber,
      userOfferId: offerId,
      promocodeType: promocodeType
    };
    return this.apiService.post(`/offers/redemption/manual_redeem`, "loyalty", offerRequest).pipe(map(data => data.payload));
  }

  issueOffer(userId, offerId): Observable<OffersList> {
    return this.apiService.post(`/offers`, "loyalty", { memberNumber: userId, offerId: offerId }).pipe(map(data => data));
  }

  redeemOffer(userId, offerId): Observable<any> {
    return this.apiService.post(`/offers/member/${userId}/redeem/${offerId}`, "loyalty", { memberNo: userId, userOfferId: offerId }).pipe(map(data => data));
  }

  getAllOffers(): Observable<OffersList[]> {
    return this.apiService.get("/offers?detailed=true", "loyalty", null).pipe(map(data => data));
  }

  getOfferDetail(offerId: string, langId: string = this.i18nService.getActiveLang): Observable<OffersList[]> {
    return this.apiService.get(`/offers/${offerId}?lang=${langId}`, "loyalty", null).pipe(map(data => data.payload.offer));
  }

  getOfferStatusByUserOfferId(userOfferId: string, langId: string = this.i18nService.getActiveLang): Observable<any> {
    return this.apiService.get(`/offers/member/${this.userService.getCurrentUser.memberNumber}/${userOfferId}?lang=${langId}`, "loyalty", null).pipe(map(data => data));
  }

  getRedeemedOfferDetail(userOfferId: string, langId: string = this.i18nService.getActiveLang): Observable<any> {
    return zip(
      this.getOfferStatusByUserOfferId(userOfferId, langId).pipe(
        switchMap(userOfferStatus => this.getOfferDetail(userOfferStatus.offer_id).pipe(map(offerDetail => ({ userOfferStatus, offerDetail }))))
      )
    ).pipe(result => result);
  }

  getOfferByMemberId(
    params,
    userId: string = this.userService.getCurrentUser.memberNumber,
    langId: string = this.i18nService.getActiveLang,
    sortingCategory?: string, sortingParam?: string,
    offerFilterShortCode?: string,
    startingPoint?: number,
    endingPoint?: number,
    rewardStoreOffer?: number
  ): Observable<any> {
    return zip(
      this.getUserRewardStores(params, userId, langId).pipe(
        switchMap(rewardStoreList => this.getRewardStoreOffers(
          params,
          undefined,
          undefined,
          sortingCategory,
          sortingParam,
          offerFilterShortCode,
          startingPoint,
          endingPoint,
          rewardStoreOffer
        ).pipe(map(offerList => ({ rewardStoreList, offerList }))))
      )
    ).pipe(result => result);
  }
}
