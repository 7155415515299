import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef, ViewChild, Input } from "@angular/core";
import { I18NService, ProfilesService, UserService, User, CommonService, ErrorService, AlertService } from "./../../../../core";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import { NzMessageService } from "ng-zorro-antd/message";
@Component({
  selector: "app-mobile-member-info-page",
  templateUrl: "./member-info.component.html",
  styleUrls: ["./member-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MobileMemberInfoComponent implements OnInit, OnDestroy {
  pointBalance: any = null;
  user: User = {} as User;
  config = {
    conversionRate: 0,
    maxAmount: 0,
    minAmount: 0,
  };
  memberId: string;
  equivalentVisible: boolean = false;
  rewardsVisible: boolean = false;
  calculatorVisible: boolean = false;
  calculateRewardsVisible: boolean = false;
  language;
  rewardInfo = {
    progress: {
      tierGoal: 0,
      currentAmount: 0,
      currentTier: "",
      outcome: 0,
      couponAmount: 0,
      percentage: 0,
      remainingAmount: 0,
    },
    campaignDetail: {},
    status: "",
  };
  version;
  showCalculator: boolean = false;
  balanceFontSize: string | null = null;
  equivalentFontSize: string | null = null;
  equivalentSmallIcon: boolean;
  private langSubscription: Subscription;
  constructor(
    private i18nService: I18NService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private messageService: NzMessageService,
    private errorService: ErrorService,
    private profileService: ProfilesService,
    private userService: UserService,
    private deviceDetectorService: DeviceDetectorService,
    private commonService: CommonService,
    private alertService: AlertService
  ) {
    // this.language = this.i18nService.getActiveLang;
    this.langSubscription = this.i18nService.currentLang.subscribe((lang) => {
      this.language = lang;
    });
    this.version = sessionStorage.getItem("hkia-dollar-memberInfo-version");
    Object.assign(this.user, this.userService.getCurrentUser);
    this.memberId = this.user.memberNumber;
    this.profileService.getDollarCalculatorConfig().subscribe((config: any) => {
      this.config = config;
    });
    this.getUserBalance();
    this.getRewards();
  }

  get updateBalanceFontSize(): string {
    const number = this.version2Display ? this.pointBalance.totalPoint : this.pointBalance.mpProgressBar.currentECouponPoint;
    const formattedValue = number.toLocaleString();
    if (formattedValue.length >= 13) {
      return "fs-22 lh-33";
    }
    if (formattedValue.length >= 10) {
      return "fs-24 lh-33";
    }
    return "";
  }

  get couponAndDollarFontSize(): string {
    const number = this.version2Display ? this.pointBalance.totalHKDollar : this.pointBalance.mpProgressBar.currentECoupon;
    const formattedValue = number.toLocaleString();
    if (formattedValue.length >= 11) {
      return "fs-22 lh-33";
    }
    if (formattedValue.length >= 9) {
      return "fs-24 lh-33";
    }
    return "";
  }

  get couponAndDollarSmallIcon(): boolean {
    const number = this.version2Display ? this.pointBalance.totalHKDollar : this.pointBalance.mpProgressBar.currentECoupon;
    const formattedValue = number.toLocaleString();
    if (this.version2Display && formattedValue.length >= 9) {
      return true;
    }
    return false;
  }

  get version2Display(): boolean {
    return true;
  }

  get isMobile(): boolean {
    return this.checkMobile();
  }

  get isVersion2AndMobile(): boolean {
    return this.isMobile && this.version2Display;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  closeModal() {
    if (this.equivalentVisible) {
      this.equivalentVisible = false;
    }
    if (this.rewardsVisible) {
      this.rewardsVisible = false;
    }
    if (this.calculatorVisible) {
      this.calculatorVisible = false;
    }
    if (this.calculateRewardsVisible) {
      this.calculateRewardsVisible = false;
      // this.isActive = false;
      // this.spendValue = null;
      // this.spendLastValue = null;
      // this.doubleCampaign = false;
    }
  }

  redirectToPage(type: string) {
    if (type === "redeem") {
      if (!this.pointBalance.showRedeemECoupon) {
        return;
      }
      this.commonService.redirect(`/offers/rewards-choices`);
      return;
    }
    if (type === "calculator") {
      if (this.isVersion2AndMobile) {
        // this.router.navigate([`/profile/${type}`]);
        this.commonService.redirect(`/profile/${type}`);
        return;
      }
      this.calculatorVisible = true;
    }
  }

  getUserBalance() {
    if (this.version2Display) {
      this.profileService.getUserBalanceForHkaaDollar(this.memberId).subscribe((pointBalance) => {
        this.pointBalance = pointBalance;
        this.balanceFontSize = this.updateBalanceFontSize;
        this.equivalentFontSize = this.couponAndDollarFontSize;
        this.equivalentSmallIcon = this.couponAndDollarSmallIcon;
      });
      return;
    } else {
      this.profileService.getUserBalance(this.memberId).subscribe((pointBalance) => {
        const subPoints = _.map(pointBalance.pointExpiry, (item: any) => {
          return {
            availableBalance: item.points,
            expireDate: item.tillDate,
          };
        });

        this.pointBalance = {
          ...pointBalance,
          showRedeemECoupon: pointBalance.mpProgressBar.hasEarnedECoupon,
          subPoints: subPoints,
        };
        this.balanceFontSize = this.updateBalanceFontSize;
        this.equivalentFontSize = this.couponAndDollarFontSize;
      });
    }
  }

  getRewards() {
    this.profileService.getRewards(this.memberId).subscribe(
      (rewards: any) => {
        // const campaignDetail = _.values(rewards.campaignDetail);
        const { progress, status, campaignDetail } = rewards;
        this.rewardInfo = {
          progress: progress || {},
          campaignDetail,
          status,
        };
      },
      (error) => {}
    );
  }

  infoOpen() {
    this.equivalentVisible = true;
  }

  checkMobile(): boolean {
    const userAgent = navigator.userAgent;
    const mobileKeywords = ["Android", "iPhone", "iPad", "iPod", "BlackBerry", "Windows Phone", "Harmony", "myhkg"];
    const isMobile = mobileKeywords.some((keyword) => userAgent.includes(keyword));

    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const isSmallScreen = screenWidth < 768;

    return isMobile && isTouchDevice && isSmallScreen;
  }
}
