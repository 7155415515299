/* entryComponents: NzModalCustomComponent */

import { Component, OnInit, ElementRef, ViewChild, TemplateRef, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { User, ProfilesService, MediaService, FormService, emailValidator, phoneValidator, I18NService, UserService, CommonService } from "../../core";
import { FeedbackService } from "../../core/services/feedback.service";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { NzModalService, NzModalRef } from "ng-zorro-antd";
import { MapsComponent } from "./maps/maps.component";
import { isEmpty, differenceBy } from "lodash";
import _ = require("underscore");
import { conciergeLocations } from './contact-form.constants';
import { environment } from '../../../environments/environment';
import { LivechatComponent } from '../../shared/layout/livechat/livechat.component';

@Component({
  selector: "app-contact-form-page",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit {

  caseReasonPickLists = [];
  isSubmitting = false;
  memberCaseReason: any[];
  memberFeedbackForm: FormGroup;
  nonMemberCaseReason: any[];
  nonMemberFeedbackForm: FormGroup;
  style = { width: "100%", "text-align": "center", "font-size": "18px" };
  user: User = {} as User;
  prefilledType: string;
  checkIsPrefilled: boolean;

  locationData = conciergeLocations;

  @ViewChild("checkBox", { static: false }) checkBox: ElementRef;
  @ViewChild("email", { static: false }) email: ElementRef;
  @ViewChild("mapContent", { static: false }) mapContent: TemplateRef<{}>;
  @ViewChild("name", { static: false }) name: ElementRef;
  isLoading = true;
  isModal: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private profileService: ProfilesService,
    private fb: FormBuilder,
    public i18nService: I18NService,
    private modalService: NzModalService,
    private formService: FormService,
    private localizeRouter: LocalizeRouterService,
    private userService: UserService,
    private commonService: CommonService
  ) {

    const asyncCall = async () => {
      // if(this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.type !== null){
      //   this.prefilledType = await this.router.getCurrentNavigation().extras.state.type;
      // }
      this.route.queryParams.subscribe((value) => {
        if (decodeURI(value['enq']) === "lx") {
          this.prefilledType = "Luxury Reserve Service";
        }
        this.getCaseReason();
      });
    };

    this.nonMemberFeedbackForm = this.fb.group(
      {
        name: [null, [Validators.required]],
        emailAddress: [null, [Validators.required, emailValidator]],
        mobilePhone: [null, [Validators.required, phoneValidator]],
        enquiryType: [null, [Validators.required]],
        subject: [null, [Validators.required]],
        description: [null, [Validators.required]],
      },
      { updateOn: "blur" }
    );

    this.memberFeedbackForm = this.fb.group(
      {
        enquiryType: [null, [Validators.required]],
        subject: [null, [Validators.required]],
        description: [null, [Validators.required]],
      },
      { updateOn: "blur" }
    );

    asyncCall();
    // Subscribe to changes on the form
  }

  ngOnInit() {
    // Fill the formUploadXHRArgs
    this.nonMemberFeedbackForm.controls.emailAddress.valueChanges.subscribe((value) => {
      if (value && this.nonMemberFeedbackForm.controls.emailAddress.valid) {
        this.nonMemberFeedbackForm.controls.mobilePhone.setValidators([phoneValidator]);
        this.nonMemberFeedbackForm.controls.mobilePhone.updateValueAndValidity();
      }
    });

    this.nonMemberFeedbackForm.controls.mobilePhone.valueChanges.subscribe((value) => {
      if (value && this.nonMemberFeedbackForm.controls.mobilePhone.valid) {
        this.nonMemberFeedbackForm.controls.emailAddress.setValidators([emailValidator]);
        this.nonMemberFeedbackForm.controls.emailAddress.updateValueAndValidity();
      }
    });

    if (window.location.pathname.indexOf('modal') !== -1){
      this.commonService.setShowHeaderFooter(false);
      this.commonService.setLiveChat(false);
      this.isModal = true;
    }
  }

  shouldPrefilled() {
    if (this.prefilledType && this.prefilledType !== null) {
      return true;
    } else return false;
  }

  getCaseReason() {
    if (isEmpty(this.userService.getCurrentUser)) {
      this.route.data.subscribe((data: { caseReasonPickLists: any[] }) => {
        this.caseReasonPickLists = data.caseReasonPickLists[this.i18nService.getActiveLang];
        if (this.shouldPrefilled() === true) {
          const luxuryTranslatedObj = this.caseReasonPickLists.filter(item => item.value === "Luxury Reserve Service")[0];
          this.nonMemberFeedbackForm.controls.enquiryType.setValue(luxuryTranslatedObj.value);
          this.nonMemberCaseReason = [{ label: luxuryTranslatedObj.label, value: luxuryTranslatedObj.value }];
        } else if (this.shouldPrefilled() === false) {
          if (!isEmpty(this.caseReasonPickLists)) {
            const nonMemExceptionList = [
              {
                label: "Airport Staff Approval",
                value: "Airport Staff Approval",
              },
              {
                label: "Member Receipt Upload",
                value: "Member Receipt Upload",
              },
              {
                label: "Member Profile Update",
                value: "Member Profile Update",
              },
              {
                label: "Member Point Adjustment",
                value: "Member Point Adjustment",
              },
              {
                label: "Reset Security Answer",
                value: "Reset Security Answer",
              },
              {
                label: "Unsubscribe",
                value: "Unsubscribe",
              },
              {
                label: "Luxury Reserve Service",
                value: "Luxury Reserve Service",
              },
            ];
            this.nonMemberCaseReason = differenceBy(this.caseReasonPickLists, nonMemExceptionList, "value");
          }
        }
      });
    } else {
      this.route.data.subscribe((data: { caseReasonPickLists: any[] }) => {
        this.caseReasonPickLists = data.caseReasonPickLists[this.i18nService.getActiveLang];
        if (this.shouldPrefilled()) {  
          const luxuryTranslatedObj = this.caseReasonPickLists.filter(item => item.value === "Luxury Reserve Service")[0];
          this.memberFeedbackForm.controls.enquiryType.setValue(luxuryTranslatedObj.value);
          this.memberCaseReason = [{ label: luxuryTranslatedObj.label, value: luxuryTranslatedObj.value }];
        } else {
          if (!isEmpty(this.caseReasonPickLists)) {
            const memExceptionList = [
              {
                label: "Airport Staff Approval",
                value: "Airport Staff Approval",
              },
              {
                label: "Member Receipt Upload",
                value: "Member Receipt Upload",
              },
              {
                label: "Member Profile Update",
                value: "Member Profile Update",
              },
              {
                label: "Member Point Adjustment",
                value: "Member Point Adjustment",
              },
              {
                label: "Reset Security Answer",
                value: "Reset Security Answer",
              },
              {
                label: "Luxury Reserve Service",
                value: "Luxury Reserve Service",
              },
            ];
            this.memberCaseReason = differenceBy(this.caseReasonPickLists, memExceptionList, "value");
          }
        }
      });
    }
  }


  submitNonMemberForm() {
    // H O N E Y H A
    if (this.name.nativeElement.value !== "" || this.email.nativeElement.value !== "" || this.checkBox.nativeElement.value !== "0") {
      const path = this.localizeRouter.translateRoute("/contact-us/success");
      this.router.navigate([path]);
      return;
    }
    const formData = {
      description: this.nonMemberFeedbackForm.value.description,
      nonMemberEmailAddress: this.nonMemberFeedbackForm.value.emailAddress,
      nonMemberName: this.nonMemberFeedbackForm.value.name,
      phone: this.nonMemberFeedbackForm.value.mobilePhone,
      reason: this.nonMemberFeedbackForm.value.enquiryType,
      subject: this.nonMemberFeedbackForm.value.subject,
    };
    
    if (this.nonMemberFeedbackForm.valid) {
      this.isSubmitting = true;
      this.feedbackService.nonMemberUploadFeedback(formData).subscribe(
        (data) => {
          if (data) {
            // TODO: Common Router
            const path = this.localizeRouter.translateRoute("/contact-us/success");
            const successPageData = {
              displayItem: {
                title: "faq.message.SUCCESS_MESSAGE_TITLE",
                subTitle: "faq.message.SUCCESS_MESSAGE_SUBTITLE",
                subTitleData: { refNum: data.CaseNumber },
                icon: "contact_us_successful",
                buttonLabel: "COMMON.LABEL.BACK",
                actionType: "LINK",
                link: "/contact-us"
              }
            };
            this.router.navigate([path], { state: successPageData });
          }
        },
        (err) => {
          this.isSubmitting = false;
        }
      );
    } else {
      for (const i in this.nonMemberFeedbackForm.controls) {
        this.nonMemberFeedbackForm.controls[i].markAsDirty();
        this.nonMemberFeedbackForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  submitMemberForm() {
    // tslint:disable-next-line: forin
    const formData = {
      description: this.memberFeedbackForm.value.description,
      reason: this.memberFeedbackForm.value.enquiryType,
      subject: this.memberFeedbackForm.value.subject,
    };
    if (this.memberFeedbackForm.valid) {
      this.isSubmitting = true;
      this.feedbackService.memberUploadFeedback(formData).subscribe(
        (data) => {
          if (data) {
            const path = this.localizeRouter.translateRoute("/contact-us/success");
            const successPageData = {
              displayItem: {
                title: "faq.message.SUCCESS_MESSAGE_TITLE",
                subTitle: "faq.message.SUCCESS_MESSAGE_SUBTITLE",
                subTitleData: { refNum: data.CaseNumber },
                icon: "contact_us_successful",
                buttonLabel: "COMMON.LABEL.BACK",
                actionType: "LINK",
                link: "/contact-us"
              }
            };
            this.router.navigate([path], { state: successPageData });
          }
        },
        (err) => {
          this.isSubmitting = false;
        }
      );
    } else {
      for (const i in this.memberFeedbackForm.controls) {
        this.memberFeedbackForm.controls[i].markAsDirty();
        this.memberFeedbackForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  openMap(index: number) {
    // ISSUE#1902: user request remove open map 
    // const location = this.locationData.filter(item => item.index === index)[0];
    // if (location.mapsImg === undefined) {
    //   return;
    // }
    // this.modalService.create({
    //   nzContent: MapsComponent,
    //   nzComponentParams: {
    //     img: this.locationData[index].mapsImg[this.i18nService.getActiveLang],
    //   },
    //   nzMaskClosable: true,
    //   nzClosable: true,
    //   nzFooter: null,
    //   nzWidth: "100%",
    // });
  }
}
