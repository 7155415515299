import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UnsubscribeComponent } from "./unsubscribe/unsubscribe.component";
import { SystemMessageComponent } from "../../shared/system-message/system-message.component";

const routes: Routes = [
  {
    path: "unsubscribe",
    component: UnsubscribeComponent,
  },
  {
    path: "unsubscribe/non-member",
    component: UnsubscribeComponent,
  },
  {
    path: "unsubscribe/result",
    component: SystemMessageComponent,
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
