import { AuthGuard } from "../../core";
import { NgModule } from "@angular/core";
import { RegisterPurchaseComponent } from "./register-purchase.component";
import { RegisterPurchaseResolver } from "./register-purchase-resolver.service";
import { RouterModule, Routes } from "@angular/router";
import { SystemMessageComponent } from '../../shared';
import { RejectReasonResolver } from './reject-reason-resolver.service';

const routes: Routes = [
  {
    path: "",
    component: RegisterPurchaseComponent,
    canActivate: [AuthGuard],
    resolve: {
      shopList: RegisterPurchaseResolver,
      rejectReasonList: RejectReasonResolver,
    },
    data: {
      isProtected: true, // For session timeout checking
      title: {
        en_US: "Spending Registration | HKairport Rewards",
        zh_HK: "登記消費 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "登记消费 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `Spending Registration | HKairport Rewards`,
          },
          {
            name: "description",
            content: `Simply upload your electronic spending receipt at Hong Kong International Airport to earn points. Purchase on HKairportshop.com will be registered automatically.`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `登記消費 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
          {
            name: "description",
            content: `上載香港國際機場電子消費單據，即可獲取獎賞積分；HKairportshop.com的消費會於確認收貨後自動完成登記。`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `登记消费 | HKairport Rewards 香港国际机场奖赏计划`,
          },
          {
            name: "description",
            content: `上载香港国际机场电子消费收据，即可获取奖赏积分；HKairportshop.com的消费会于确认收货后自动完成登记。`,
          },
        ],
      },
    },
  },
  {
    path: "success",
    component: SystemMessageComponent,
    canActivate: [AuthGuard],
    data: {
      isProtected: true, // For session timeout checking
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegisterPurchaseRoutingModule {}
