import { Injectable, } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { RemoteConfigService } from "../../core";

@Injectable()
export class RejectReasonResolver implements Resolve<any> {
  constructor(
    private remoteConfigService: RemoteConfigService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.remoteConfigService.getRejectReasonPickLists()
      .pipe(catchError((err) => this.router.navigateByUrl("/")));
      
  }
}
