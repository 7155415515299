import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UserService } from "../../../core";

@Injectable()
export class ActivateMemberResolver implements Resolve<any> {
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const userId = decodeURI(route.queryParams["userid"]);
    const token = encodeURI(route.queryParams["token"].replace(/%20/g, "+")).replace(/%20/g, "%2B");
    return this.userService.validateResetPasswordToken(token, userId).pipe(
      map((data) => {
        return true;
      }),
      catchError((err) => of(false))
    );
  }
}
