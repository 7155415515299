import { AlertService } from "./alert.service";
import { ApiService } from "./api/api.service";
import { CommonService } from "./common.service";
import { I18NService } from "./i18n.service";
import { Injectable, Injector, NgZone } from "@angular/core";
import { JwtService } from "./jwt.service";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Location } from "@angular/common";
import { OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { Observable, BehaviorSubject, ReplaySubject, combineLatest, of } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { SfdcUser, NewProfile, User, ChangePassword, ResetPassword } from "../models";
import { isEmpty } from "lodash";
import { map, distinctUntilChanged, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CookieService } from 'ngx-cookie-service';

/**
 * Step for getting AA's user profile
 * 1. Load user's Salesforce profile
 * 2. Get the member id (AA-XXX..) by Salesforce user id
 * 3. Get sessionM profile by member id
 */
@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.oauthService.hasValidAccessToken());
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private isSessionTimeoutSubject = new BehaviorSubject<boolean>(false);
  public isSessionTimeout = this.isSessionTimeoutSubject.asObservable().pipe(distinctUntilChanged());

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated, this.isDoneLoading$]).pipe(map((values) => values.every((b) => b)));

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private oauthService: OAuthService,
    private injector: Injector,
    private authStorage: OAuthStorage,
    private commonService: CommonService,
    private _location: Location,
    private zone: NgZone,
    private cookieService: CookieService
  ) {
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener("storage", (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== "access_token" && event.key !== null) {
        return;
      }

      console.warn("Noticed changes to access_token (most likely from another tab), updating isAuthenticated");
      // this.checkSessionActiveness(true);
    });
  }

  async runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // Set Profile
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search);
    let cb = params.get('cb');
    // Session Timeout Object

    const exitAppWithValidTokenTimestamp = localStorage.getItem("exitAppWithValidTokenTimestamp");
    const exitAppWithValidToken = localStorage.getItem("exitAppWithValidToken");

    let isDisconnectedSession: boolean;
    var t0 = Number(window.localStorage['exitAppWithValidTokenTimestamp']);
    if (isNaN(t0)) t0 = 0;
    var t1 = new Date().getTime();
    var duration = t1 - t0;
    if (duration < 3 * 1000) {
      isDisconnectedSession = false;
    } else {
      isDisconnectedSession = true;
    }
    
    let authorization = params.get('code')
    let accessToken = this.cookieService.get('myhkgaccesstoken')
    let refreshToken = this.cookieService.get('myhkgrefreshtoken')
    let expirationTime = new Date();
    expirationTime.setHours(expirationTime.getHours() + 2);
    if(this.inMyHkgWebView){
      if(!!authorization){
        this.cookieService.set('myhkgauthorization',authorization,expirationTime,'/')
      }
      if(!!accessToken){
        this.authStorage.setItem('access_token',accessToken)
      }
      if(!!refreshToken){
        this.authStorage.setItem('refresh_token',refreshToken)
        await this.renewAccessToken()
      }
    }

    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .then((_) => {
        let myhkgRedirectUrl = null
        if(this.inMyHkgWebView && this.oauthService.hasValidAccessToken()){
          myhkgRedirectUrl = url.pathname
          this.cookieService.set('myhkgaccesstoken',this.oauthService.getAccessToken(),expirationTime,'/')
        }
        if (cb && !this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        } else if (this.authStorage.getItem("access_token") && isDisconnectedSession && exitAppWithValidToken === "true") {
          return this.loadAppUserProfile(true, true).then((profile) => {
            return Promise.resolve();
          }).catch((error) => {
            this.checkSessionActivenessInitApp(true).subscribe(
              (response: boolean) => {}, (error) => {
                this.purgeAuth(true).subscribe();
                return Promise.resolve();
              });
          });
        } else if (this.oauthService.hasValidAccessToken()) {
          return this.loadAppUserProfile(true).then((profile) => {
            if(this.inMyHkgWebView){
              this.router.navigateByUrl(myhkgRedirectUrl);
            }
            return Promise.resolve();
          }).catch((error) => {
            console.log('load profile error', error);
          });
        }

        this.inAppAutoLoginCheck();
        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        if (location.hash === "#login") {
          return this.oauthService
            .silentRefresh()
            .then(() => {
              return this.loadAppUserProfile(true).then(() => {
                return Promise.resolve();
              });
            })
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = ["interaction_required", "login_required", "account_selection_required", "consent_required"];

              if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                // this.login();
                //
                // Instead, we'll now do this:
                console.warn("User interaction is needed to log in, we will wait for the user to manually log in.");
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        }
      })
      .then(() => {
        const state = decodeURIComponent(this.oauthService.state.split(/[#]/)[0]);
        if (
          state &&
          state !== "undefined" &&
          state !== null &&
          state.trim() !== "" &&
          !state.includes("session-timeout") &&
          !state.includes("account/register") &&
          !state.includes("account/reset") &&
          !state.includes("unsubscribe")
        ) {
          if (this.isMemberProfileIncomplete && window.location.href.indexOf("legal") === -1) {
            // let redirectUrl: string;
            // if (String(state).includes("cb")) {
            //   redirectUrl = String(state).split("cb=")[1];
            //   redirectUrl = "?cb=".concat(redirectUrl);
            // }
            // this.redirectToSupplementProfile(redirectUrl);
          } else if (this.isAllowResetSecurityQuestion) {
            this.redirectToResetSecurityPage();
          } else {
            if (this.getCurrentUser.memberProfile) {
              const userLang = this.switchLang(this.getCurrentUser.memberProfile.preferredLanguage);
              const locationLang = this.localizeRouter.parser.getLocationLang(state);
              // const targetUrl = state.replace(locationLang, userLang);
              const targetUrl = state.replace('en_US', userLang);
                setTimeout(() => {
                  this.router.navigateByUrl(targetUrl);
                }, 1000);
                sessionStorage.setItem('MP_SELECTED_LANGUAGE', userLang);
              this.i18nService.use(userLang, false);
            } else {
              this.router.navigateByUrl(state);
            }
          }
        } else if (state && state !== "undefined" && state !== null && state.trim() !== "") {
          // Fallback
          const userLang = this.switchLang(this.getCurrentUser.memberProfile.preferredLanguage);
          this.router.navigateByUrl(`/${userLang}/home`);
          this.i18nService.use(userLang, false);
        }
        if (this.isShowResetPasswordAlert) {
          this.commonService.setImportantNoticeSection(true);
        }
        this.isDoneLoadingSubject$.next(true);
      })
      .catch((error) => {
        console.log("OAuth Error", error);
        this.isDoneLoadingSubject$.next(true);
        // return Promise.reject();
      });
  }

  // TODO: Temp use
  switchLang(langName) {
    switch (langName) {
      case "Simplified Chinese":
        return "zh_CN";
      case "Traditional Chinese":
        return "zh_HK";
      case "English":
        return "en_US";
    }
  }

  getSessionMProfile(): Observable<any> {
    return this.apiService.get(`/member/${this.getCurrentUser.memberNumber}/loyalty`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<SfdcUser> {
    return this.apiService.put("/user", "member", { user }).pipe(
      map((data) => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      })
    );
  }

  logoff() {
    this.purgeAuth().subscribe(
      () => {
        this.jwtService.destroyToken();
        this.oauthService.logOut(true);
        this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
      },
      (error) => {
        this.jwtService.destroyToken();
        this.oauthService.logOut(true);
        this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
      }
    );
  }


  verifySpecialCode(specialCode: string): Observable<any> {
    return this.apiService.post(`/specialCode/verify`, "member", {specialCode:specialCode}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // TODO: Set OTP Service
  checkDuplicatedEmail(identifier: string, serviceNatureString: string, memberNumberString: string) {
    const requestBody = { action: "VALIDATE", email: identifier, serviceNature: serviceNatureString, memberNumber: memberNumberString };
    return this.apiService.post(`/user/email`, "member", requestBody).pipe(map((data) => data));
  }

  checkDuplicatedMobile(identifier: string): Observable<any> {
    const requestBody = { action: "VALIDATE", phone: identifier };
    return this.apiService.post(`/user/phone`, "member", requestBody).pipe(map((data) => data));
  }

  getValidationTime(sessionId:string): Observable<any> {
    return this.apiService.get(`/otp/getValidationTime${sessionId ? `/?id=${sessionId}`:""}`, "member",null).pipe(map((data) => data));
  }

  getCaptchaCode(sessionId:string): Observable<any> {
    return this.apiService.get(`/otp/getCode${sessionId ? `/?code=${sessionId}`:""}`, "member",null).pipe(map((data) => data));
  }

  // TODO: Set OTP Service
  getVerificationCode(formData: any) {
    return this.apiService.post(`/otp/send`, "member", formData).pipe(map((data) => data));
  }

  // verifyOTPRequest
  getVerificationCodeStatus(identifier: string, code: string, type: string): Observable<any> {
    let requestBody;
    if (type === "Email") {
      requestBody = { userEmail: identifier, verCode: code };
    } else if (type === "Mobile") {
      requestBody = { userPhone: identifier, verCode: code };
    }
    return this.apiService.post(`/otp/verify`, "member", requestBody).pipe(map((data) => data));
  }

  verificationEmailOtp(member_id: string, otp: string): Observable<any> {
    let requestBody = {
      member_id: member_id,
      otp: otp,
    }
    return this.apiService.post(`/otp/addEmailOtpMap`, "member", requestBody).pipe(map((data) => data));
  }

  createProfile(profileForm: NewProfile): Observable<any> {
    return this.apiService.post(`/member`, "member", profileForm).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateProfile(profileForm): Observable<any> {
    return this.apiService.patch(`/member/${profileForm.memberNumber}`, "member", profileForm).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateAraPermit(formData): Observable<any> {
    return this.apiService.post(`/member/${this.getCurrentUser.memberNumber}/permit`, "member", formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  isMemberHasPendingCase(): Observable<boolean> {
    return this.apiService.get(`/member/hasPendingCase`, "member").pipe(
      map((data) => {
        return data;
      })
    );
  }

  changePasswordByMemberNumber(formData: ChangePassword): Observable<any> {
    return this.apiService.post(`/member/resetPassword/${this.getCurrentUser.memberNumber}`, "member", formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  unsubscribeMktOptByMemberNumber(memberId: string, token: string): Observable<any> {
    return this.apiService.get(`/member/unsubscribe?memberId=${memberId}&memberToken=${token}`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  unsubscribeMktOptByNonMemberSubscriberKey(subscriberKey: string): Observable<any> {
    const requestBody = { code:subscriberKey };
    return this.apiService.post(`/member/unsubscribe/nonmember`, "member", requestBody).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Forget password by mobile phone number
   * @param phone
   */
  getSecurityQuestionByPhone(phone: string): Observable<any> {
    return this.apiService.get(`/member/resetPassword/phone/securityQuestion?Phone=${phone}`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  changePasswordBySecurityQuestion(resetPassword: ResetPassword): Observable<any> {
    return this.apiService.post(`/member/resetPassword/phone`, "member", resetPassword).pipe(
      map((data) => {
        return data;
      })
    );
  }

  resetSecurityQuestion(formData): Observable<any> {
    return this.apiService.post(`/member/securityQA/reset`, "member", formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  verifySecurityAnswer(formData: ResetPassword): Observable<any> {
    return this.apiService.post(`/member/resetPassword/phone/answer/verify`, "member", formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  resetPasswordEmail(email: string): Observable<any> {
    return this.apiService.get(`/member/resetPassword/email/${email}`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  changePasswordByEmail(resetPassword: ResetPassword): Observable<any> {
    return this.apiService.post(`/member/resetPassword/email/`, "member", resetPassword).pipe(
      map((data) => {
        return data;
      })
    );
  }

  validateResetPasswordToken(token, userId): Observable<any> {
    return this.apiService.post(`/member/validate/token?token=${token}&userId=${userId}`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  get isSecurityQuestionExist() {
    if (this.getCurrentUser.sfdcProfile) {
      const custom_attributes = this.getCurrentUser.sfdcProfile["custom_attributes"];
      return custom_attributes && custom_attributes["Missing_Security_Q_A"] === "false";
    }
  }

  getEarnHistory(memberNumber: string = this.getCurrentUser.memberNumber, page: number = 1, size: number = 1000): Observable<any> {
    const formData = {
      page: page,
      size: size,
    };
    return this.apiService.post(`/member/${memberNumber}/earn/history`, "member", formData).pipe(
      map((data) => {
        return data.content;
      })
    );
  }

  createEarnCase(memberNumber: string, formData: FormData): Observable<any> {
    return this.apiService.post(`/member/${memberNumber}/earn`, "member", formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  createEarnNowCase(receiptNumber: string): Observable<any> {
    return this.apiService.post(`/v1/memberPortal/createPurchaseTransaction/${receiptNumber}`, "data-sync", {}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getEarnPointMessage(messageType: string): Observable<any> {
    return this.apiService.get(`/configuration/picklist/earnPointMessage/${messageType}`, "member", null).pipe(
      map((data) => {
        return data;
      })
    )
  }

  getMember(memberNumber: string = this.getCurrentUser.memberNumber): Observable<any> {
    return this.apiService.get(`/member/${memberNumber}`, "member", null).pipe(
        map((data) => {
          if (data && data.disableDisplayAppleWalletPassButton) {
            sessionStorage.setItem("disableDisplayAppleWalletPassButton", "true");
          }
          if (data && data.disableDisplayGoogleWalletPassButton) {
            sessionStorage.setItem("disableDisplayGoogleWalletPassButton", "true");
          }
          return data;
        })
    );
  }

  getLoyalty(memberNumber: string): Observable<any> {
    return this.apiService.get(`/member/${memberNumber}/loyalty`, "member", null).pipe(map((data) => data));
  }

  loadAppUserProfile(isReload: boolean = false, isSkipError = false): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const oauthId = this.identityClaims;
      const currentUser = this.getCurrentUser;
      if (isReload || (isEmpty(currentUser) && (isEmpty(oauthId) || !oauthId.user_id))) {
        return this.oauthService.loadUserProfile().catch((e) => {
          if (e.status === 0 && e.statusText === "Unknown Error") {
            console.error("Ignoring error caused by xhr termination during oauth profile loading", e);
          } else {
            throw e;
          }
        }).then(
          async (data) => {
            if(this.inMyHkgWebView){
              this.cookieService.set('myhkgsalesforceid',data['sub'],180,'/')
              if(data["custom_attributes"]["Remember_Me"] == 'true'){
                this.cookieService.set('myhkgrefreshtoken',this.oauthService.getRefreshToken(),180,'/')
              }
            }
            const memberNumber = data["custom_attributes"]["External_ID"];
            let userProfile = {
              memberNumber: memberNumber,
              sfdcProfile: this.identityClaims,
              memberProfile: null,
            };
            await this.setUser(userProfile);
            this.getMember(memberNumber).subscribe(
              (memberData) => {
                if (memberData) {
                  userProfile = {
                    memberNumber: memberNumber,
                    sfdcProfile: this.identityClaims,
                    memberProfile: memberData,
                  };
                  this.setUser(userProfile);
                  if (this.isMemberProfileIncomplete && window.location.href.indexOf("legal") === -1) {
                    const state = decodeURIComponent(this.oauthService.state.split(/[#]/)[0]);
                    let redirectUrl: string;
                    if (String(state).includes("cb")) {
                      redirectUrl = String(state).split("cb=")[1];
                      redirectUrl = "?cb=".concat(redirectUrl);
                    }
                    this.redirectToSupplementProfile(redirectUrl);
                    resolve(userProfile);
                  } else {
                    resolve(userProfile);
                  }
                }
              },
              (error) => {
                reject(error);
              }
            );
            if (this.isMemberProfileIncomplete && window.location.href.indexOf("legal") === -1) {
              const state = decodeURIComponent(this.oauthService.state.split(/[#]/)[0]);
              let redirectUrl: string;
              if (String(state).includes("cb")) {
                redirectUrl = String(state).split("cb=")[1];
                redirectUrl = "?cb=".concat(redirectUrl);
              }
              this.redirectToSupplementProfile(redirectUrl);
            }
          },
          (error) => {
            // Incomplete profile
            if (!isSkipError) {
              if (error.errorCode === "E1007") {
                const path = this.localizeRouter.translateRoute("/account/incomplete");
                this.router.navigate([path]);
              } else if (this.isSessionTimeoutValue) {
                this.sessionTimeoutPurgeAuth();
              } else {
                this.purgeAuth().subscribe(() => {
                  this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
                });
              }
            }
            reject(error);
          }
        );
      } else if (isEmpty(currentUser) && !isEmpty(oauthId) && oauthId.user_id) {
        const memberNumber = oauthId["custom_attributes"]["External_ID"];
        const userProfile = {
          memberNumber: memberNumber,
          sfdcProfile: this.identityClaims,
        };
        // Set session token and user profile to JWT
        await this.setUser(userProfile);
        if (this.isMemberProfileIncomplete) {
          const state = decodeURIComponent(this.oauthService.state.split(/[#]/)[0]);
          let redirectUrl: string;
          if (String(state).includes("cb")) {
            redirectUrl = String(state).split("cb=")[1];
            redirectUrl = "?cb=".concat(redirectUrl);
          }
          this.redirectToSupplementProfile(redirectUrl);
          return resolve();
        } else {
          resolve(userProfile);
        }
      }
    });
  }

  redirectToSupplementProfile(redirectUrl?: string): void {
    /**
    * Set the language of the redirect page
    * 1.Get "preferredLang", "loaclLang", and the language used in the current link
    * 2."preferredLang" has a value and "loaclLang" has no value, use "preferredLang"
    * 3."loaclLang" has a value, Priority use "loaclLang"
    */
    let preferredLang: any = this.userPreferredLanguage;
    let loaclLang: any = sessionStorage.getItem("MP_SELECTED_LANGUAGE") || sessionStorage.getItem("MP_SPPL_LANGUAGE");
    let currentLang: any = this.localizeRouter.translateRoute("/");
    if(!!preferredLang && !loaclLang){
      currentLang = preferredLang
    }
    if(!!loaclLang){
      currentLang = loaclLang
    }
    this.i18nService.use(currentLang,false);
    let path: any  = `/${currentLang}/account/incomplete`
    if (redirectUrl != null) {
      path = this.localizeRouter.translateRoute(`${currentLang}/account/incomplete`).concat(redirectUrl);
    }
    if (this.router.url !== "/" && !this.router.url.includes("incomplete")) {
      this.router.navigateByUrl(path);
    }
  }

  redirectToResetSecurityPage() {
    const path = this.localizeRouter.translateRoute("/account/reset-security-question");
    this.router.navigate([path]);
  }

  /**
   * Reload User Info from OAuth
   */
  async reloadUserInfo() {
    await this.loadAppUserProfile(true);
  }

  /**
   *
   * @param targetUrl
   */
  login(targetUrl?: string, channel? :string) {
    sessionStorage.setItem("MP_SELECTED_LANGUAGE", this.i18nService.getActiveLang);
    // Workaround For SSO
    if (channel) {
      this.oauthService.initLoginFlow(targetUrl || this.router.url, { display: 'page', login_hint: `${this.i18nService.getActiveLang}@${channel}` });
    } else {
      const sessionStorageLoginHint = window.sessionStorage.getItem("login_hint_channel");
      if (sessionStorageLoginHint) {
        this.oauthService.initLoginFlow(targetUrl || this.router.url, { display: 'page', login_hint: `${this.i18nService.getActiveLang}@${sessionStorageLoginHint}` });
      } else {
        const url = '/en_US/profile'
        channel = 'memberportal'
        if(this.inMyHkgWebView){
          channel = 'memberportal.myhkg'
        }
        this.oauthService.initLoginFlow(targetUrl || url, { display: 'page', login_hint: `${this.i18nService.getActiveLang}@${channel}.com` });
      }
    }
    // this.oauthService.initImplicitFlow(encodeURIComponent(targetUrl || this.router.url));
  }

  async setUser(user: User) {
    if (this.oauthService.hasValidAccessToken() && localStorage.getItem("expireObject") !== undefined) {
      localStorage.removeItem("expireObject");
    }
    this.currentUserSubject.next(user);
    await this.isAuthenticatedSubject.next(true);
  }

  purgeAuth(isSkipRevokeToken: boolean = false): Observable<any> {
    const token = this.authStorage.getItem("access_token");
    const body = new URLSearchParams();
    if (token) {
      body.set("token", token);
    }

    if(this.inMyHkgWebView){
      this.cookieService.delete('myhkgaccesstoken')
      this.cookieService.delete('myhkgrefreshtoken')
      this.cookieService.delete('myhkgauthorization')
      this.cookieService.delete('myhkgsalesforceid')
    }

    if (isSkipRevokeToken) {
      // this.authStorage.removeItem("access_token");
      this.oauthService.logOut(true);
      this.isAuthenticatedSubject.next(false);
      this.currentUserSubject.next({} as User);
      return of(null);
    } else {
      return this.apiService.post(`/services/oauth2/revoke`, "oauth", body).pipe(
        map((data) => {
          this.authStorage.removeItem("access_token");
          this.isAuthenticatedSubject.next(false);
          this.currentUserSubject.next({} as User);
          return data;
        }),
        catchError((err, caught) => {
          this.authStorage.removeItem("access_token");
          this.isAuthenticatedSubject.next(false);
          this.currentUserSubject.next({} as User);
          return of(null);
        })
      );
    }

  }

  checkSessionActiveness(isInitApp = false) {
    if (this.authStorage.getItem("access_token") && !this.isSessionTimeoutValue) {
      this.apiService.get(`/user/active`, "member", null).subscribe(
        (data) => {
        },
        (error) => {
       // Purge Token if session timeout
          this.sessionTimeoutPurgeAuth(isInitApp);
        }
      );
    }
  }

  checkSessionActivenessInitApp(isInitApp = false): Observable<any> {
    return this.apiService.get(`/user/active`, "member", null).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  renewAccessToken(): Promise<Boolean> {
    return new Promise<Boolean>((resolve) => {
      if(this.inMyHkgWebView && this.cookieService.get('myhkgrefreshtoken')){
        const body = new URLSearchParams();
        body.set('grant_type','refresh_token')
        body.set('refresh_token',this.oauthService.getRefreshToken())
        body.set('client_id',environment.authClientId)

        let expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 2);

        this.apiService.post(`/services/oauth2/token`, "oauth", body).subscribe((res)=>{
          this.cookieService.set('myhkgaccesstoken',res.access_token,expirationTime,'/')
          this.cookieService.set('myhkgrefreshtoken',this.oauthService.getRefreshToken(),180,'/')
          this.cookieService.set('myhkgsalesforceid',res.id,180,'/')
          this.authStorage.setItem('access_token', res.access_token )
          resolve(true)
        },(error)=>{
          console.log('error: use refresh token get access token to fail');
          resolve(false)
        })
      }else{
        resolve(false)
      }
    })
  }


  async sessionTimeoutPurgeAuth(isInitApp = false) {
    if(await this.renewAccessToken()) return
    this.isSessionTimeoutSubject.next(true);
    // Temp solution
    this.isAuthenticatedSubject.next(false);
    this.currentUserSubject.next({} as User);

    const targetUrl = this.commonService.isPageProtected ? `/${this.i18nService.getActiveLang}/home` : decodeURI(this._location.path());
    const expireObject = {
      value: true,
      targetUrl: targetUrl,
    };

    this.purgeAuth().subscribe(
      () => {
        if (!isInitApp) {
          this.isSessionTimeoutSubject.next(false);
          localStorage.setItem("expireObject", JSON.stringify(expireObject));
          this.zone.run(() => {
            this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`).then(() => {
              localStorage.removeItem("exitAppWithValidToken");
            });
          });
        }

      },
      (error) => {
        if (!isInitApp) {
          this.isSessionTimeoutSubject.next(false);
          localStorage.setItem("expireObject", JSON.stringify(expireObject));
          this.zone.run(() => {
            this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`).then(() => {
              localStorage.removeItem("exitAppWithValidToken");
            });
          });
        }
      }
    );
  }

  checkSecurityQuestionStatus() {
    return this.apiService.get(`/user/sq/`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkUserInfo(firstName: string, lastName: string, personEmail: string, registrationChannel: string, verificationCode: string) {
    const requestBody = {
      firstName,
      lastName,
      personEmail,
      registrationChannel,
      verificationCode,
    };
    const result = this.apiService.post(`/user/decrypt`, "member", requestBody).pipe(map((data) => data));
    return result;
  }

  inAppAutoLoginCheck() {
    if (!location.hash.includes("sc")) {
      return;
    }
    if (location.hash.includes("sc")) {
      const params = new URLSearchParams(location.hash.substr(1));
      const sc = params.get("sc");
      const token = params.get("access_token");
      const claimsObj = { sub: params.get("sub"), aud: environment.authClientId };
      localStorage.setItem("sc", sc);
      localStorage.setItem("access_token", token);
      localStorage.setItem("id_token_claims_obj", JSON.stringify(claimsObj));
      setTimeout(() => {
        location.replace(`${environment.baseUrl}`);
      }, 1000);
    }
  }

  getUserStaffStatus() {
    return this.apiService.get(`/configuration/picklist/staffstatus`, "member", null).pipe(
      map((data) => {
        return data;
      })
    );
  }

  get inMyHkgWebView() {
    let userAgent = window.navigator.userAgent
    return userAgent.indexOf("myhkg") != -1 || userAgent.indexOf("myhkg@android") != -1 || userAgent.indexOf("myhkg@ios") != -1
  }

  get userPreferredLanguage() {
    return this.switchLang(this.getCurrentUser.memberProfile.preferredLanguage);
  }

  get identityClaims(): SfdcUser {
    return this.oauthService.getIdentityClaims() as SfdcUser;
  }

  get getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  get isLoggedIn(): boolean {
    return !isEmpty(this.getCurrentUser);
  }

  get isSfUserProfileExist(): boolean {
    return !isEmpty(this.identityClaims) && typeof (this.identityClaims) !== undefined;
  }

  get isSessionTimeoutValue() {
    return this.isSessionTimeoutSubject.value;
  }

  get isMemberProfileIncomplete() {
    if (this.getCurrentUser.sfdcProfile || this.identityClaims) {
      let custom_attributes;
      if (this.getCurrentUser.sfdcProfile) {
        custom_attributes = this.getCurrentUser.sfdcProfile["custom_attributes"];
      } else if (this.identityClaims) {
        custom_attributes = this.identityClaims["custom_attributes"];
      }
      return custom_attributes && custom_attributes["Member_Info_Completed"] === "false";
    }
  }

  get isAllowResetSecurityQuestion() {
    if (this.getCurrentUser.sfdcProfile) {
      const custom_attributes = this.getCurrentUser.sfdcProfile["custom_attributes"];
      return custom_attributes && custom_attributes["Allow_to_change_security_Q_A"] === "true";
    }
  }

  get isMemberFromSocialLogin() {
    if (this.getCurrentUser.sfdcProfile) {
      const socialLoginType = ["Facebook", "Google", "Apple", "Wechat"];
      const custom_attributes = this.getCurrentUser.sfdcProfile["custom_attributes"];
      return custom_attributes && socialLoginType.indexOf(custom_attributes["Login_Type"]) !== -1;
    }
  }

  get isMemberFromEMP() {
    if (this.getCurrentUser.sfdcProfile) {
      const memberProfile = this.getCurrentUser.memberProfile;
      return memberProfile["registrationChannel"] === "Emarketplace";
    }
  }

  get isTncChecked() {
    if (this.getCurrentUser.sfdcProfile) {
      const custom_attributes = this.getCurrentUser.sfdcProfile["custom_attributes"];
      const tnc = custom_attributes["Acknowledged_T_C"];
      return tnc;
    }
  }

  get isShowResetPasswordAlert() {
    if (this.getCurrentUser.sfdcProfile) {
      const custom_attributes = this.getCurrentUser.sfdcProfile["custom_attributes"];
      return custom_attributes && custom_attributes["Last_Password_Change_Date_Reminder_Alert"] === "true";
    }
  }

  /**
   * Inject required service
   */

  get i18nService(): I18NService {
    return this.injector.get(I18NService);
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  get localizeRouter(): LocalizeRouterService {
    return this.injector.get(LocalizeRouterService);
  }

  get route(): ActivatedRoute {
    return this.injector.get(ActivatedRoute);
  }

  get alertService(): AlertService {
    return this.injector.get(AlertService);
  }
}
