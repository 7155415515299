import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "./user.service";
import { take, tap } from "rxjs/operators";
import { CommonService } from "./common.service";
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Location } from "@angular/common";

@Injectable()
export class AuthGuard implements CanActivate {
  loggedInBefore: boolean = false;
  initLogin(targetUrl?:string, data?:any, channel?:string){
    if(targetUrl.includes('/earn-now/')){
      localStorage.setItem("earn-now", data && data.id);
      this.userService.login(targetUrl,"smartpos.com");
    }
    this.userService.login(targetUrl);
  }
  
  constructor(private userService: UserService, private commonService: CommonService, private oauthService: OAuthService, private authStorage: OAuthStorage, private _location: Location, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isAuthenticated.pipe(
      take(1),
      tap((isAuthenticated) => {
        this.commonService.isProtectedPage(true);
        if (isAuthenticated) {
          this.loggedInBefore = true;
        }
        this.userService.checkSessionActiveness();
        if (this.loggedInBefore && !this.authStorage.getItem("access_token")) {
          this.userService.logoff();
          this.loggedInBefore = false;
        } else if (!isAuthenticated && !this.oauthService.hasValidAccessToken() && !this.userService.isSessionTimeoutValue) {
          this.initLogin(state.url,route.params)
        } else if (!isAuthenticated && !this.oauthService.hasValidAccessToken() && this.userService.isSessionTimeoutValue) {
          this.initLogin(state.url,route.params)
        } else if (isAuthenticated && this.userService.isSessionTimeoutValue) {
          this.userService.logoff();
          this.loggedInBefore = false;
        } else if (
          // Check on profile completeness
          isAuthenticated &&
          this.userService.isMemberProfileIncomplete &&
          route.routeConfig.path !== "incomplete"
        ) {
          this.userService.redirectToSupplementProfile();
        }
      })
    );
  }
}
