import { ActivateMemberComponent } from './activate-member/activate-member.component';
import { ActivateMemberResolver } from './activate-member/activate-member-resolver.service';
import { AuthResolver } from "./account-resolver.service";
import { AuthRoutingModule } from "./account-routing.module";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ExternalAuthResolver } from "./supplement-profile/supplement-profile-resolver.service";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NgModule } from "@angular/core";
import { NoAuthGuard } from "./no-auth-guard.service";
import { RegistrationComponent } from "./registration/registration.component";
import { RegistrationResolver } from "./registration/registration-resolver.service";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ResetPasswordResolver } from "./reset-password/reset-password-resolver.service";
import { ResetSecurityQuestionComponent } from "./reset-security-question/reset-security-question.component";
import { SharedModule } from "../../shared";
import { SocialLoginComponent } from "./social-login/social-login.component";
import { SupplementProfileComponent } from "./supplement-profile/supplement-profile.component";
import { UpgradeAccountComponent } from './upgrade-account/upgrade-account.component';

@NgModule({
  imports: [SharedModule, AuthRoutingModule],
  declarations: [
    ChangePasswordComponent,
    ForgotPasswordComponent,
    RegistrationComponent,
    ResetPasswordComponent,
    ActivateMemberComponent,
    SocialLoginComponent,
    ResetSecurityQuestionComponent,
    SupplementProfileComponent,
    UpgradeAccountComponent
  ],
  providers: [
    AuthResolver,
    ExternalAuthResolver,
    NoAuthGuard,
    RegistrationResolver,
    ResetPasswordResolver,
    ActivateMemberResolver
  ]
})
export class AccountModule {}
