import { Component, Input } from "@angular/core";
import { I18NService } from "../../core";
import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
@Component({
  selector: "app-about-programme-carousel",
  styleUrls: ["about-programme-carousel.component.scss"],
  templateUrl: "./about-programme-carousel.component.html",
})
export class AboutProgrammeCarouselComponent {
  constructor(private i18nService: I18NService) {}

  @Input() limit: number;
  @Input()
  set carouselArray(config: PromotionCarouselConfig[]) {
    if (config) {
      this.slides = config;
    }
  }

  swiperConfig: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 3,
    spaceBetween: 50,
    touchRatio: 0,
    pagination: true,
    breakpoints: {
      1359: {
        a11y: true,
        slidesPerView: 2,
        spaceBetween: 20,
        touchRatio: 1,
      },
      768: {
        a11y: true,
        slidesPerView: 1,
        spaceBetween: 20,
        touchRatio: 1,
      }
    },
  };

  slides: PromotionCarouselConfig[];
  loading = false;
  currentPage = 1;

  cards = [
    {
      title: "",
      cardImg: "/assets/images/home/key_benefit_A.png",
    },
    {
      title: "",
      cardImg: "/assets/images/home/key_benefit_B.png",
    },
    {
      title: "",
      cardImg: "/assets/images/home/key_benefit_C.png",
    },
  ];


  setPageTo(pageNumber) {
    this.currentPage = pageNumber;
  }

}
