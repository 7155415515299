import {
  UserService,
  TimeService,
  FormService,
  AlertService,
  I18NService,
  CommonService,
  PatchProfileForm,
  emailValidator,
  phoneValidator,
  OtpConfig,
  ValidateEmailNotTaken,
} from "../../../core";

import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, of, Subscription } from "rxjs";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from "@angular/forms";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { OtpBoxComponent } from "../../../shared";
import { map, catchError, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: "app-external-auth-setup",
  templateUrl: "./update-profile.component.html",
  styleUrls: ["./update-profile.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate("0.4s ease-out", style({ height: 203, opacity: 1 }))]),
      transition(":leave", [style({ height: 300, opacity: 1 }), animate("0.4s ease-in", style({ height: 0, opacity: 0 }))]),
    ]),
    trigger("inOutAnimation2", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate("0.4s ease-out", style({ height: 203, opacity: 1 }))]),
      transition(":leave", [style({ height: 100, opacity: 1 }), animate("0.2s ease-in", style({ height: 0, opacity: 0 }))]),
    ]),
    trigger("inOutAnimation3", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate("0.2s ease-out", style({ height: 50, opacity: 1 }))]),
      transition(":leave", [style({ height: 100, opacity: 1 }), animate("0.2s ease-in", style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class UpdateProfileComponent implements OnInit, OnDestroy {
  @ViewChild("emailOtpBox", { static: false }) private emailOtpBox: OtpBoxComponent;
  @ViewChild("phoneOtpBox", { static: false }) private phoneOtpBox: OtpBoxComponent;
  areaList = [];
  companyList = [];
  countryCodeList = [];
  getOtpCount = 0;
  hoursDisplay: string;
  isDesktop = this.deviceService.isDesktop();
  isEmailReadOnly: boolean;
  isFirstTimeGetOtp = false;
  isMobileReadOnly: boolean;
  isPhoneReadOnly: boolean;
  // isSecurityQuestionExist: boolean;
  isSubmitting: boolean;
  isSubmittingOtp = false;
  minutesDisplay: string;
  monthPickList = this.timeService.monthList;
  otpModal: any;
  private isUserDataReady = false;
  public timerToggle$ = new BehaviorSubject(false);
  salutationList = [];
  preferenceList = [];
  secondsDisplay: string;
  // securityQuestionList = [];
  updateProfileForm: FormGroup;
  userProfile: any;
  verificationForm: FormGroup;
  yearPickList = this.timeService.yearList;
  isOpen: boolean = false;
  isMemberFromSocialLogin = this.userService.isMemberFromSocialLogin;
  isShowDirectMktOptIn: boolean = true;
  redirectPlatformLink: string = null;
  verificationChannel:string = '';
  private subscription: Subscription;
  transferPhone = '';

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private timeService: TimeService,
    private router: Router,
    public formService: FormService,
    private alertService: AlertService,
    private localizeRouter: LocalizeRouterService,
    private i18nService: I18NService,
    private commonService: CommonService,
    private deviceService: DeviceDetectorService
  ) {
    this.route.data.subscribe((data: { listData: any[]; userProfile: any }) => {
      if (data.listData) {
        // this.securityQuestionList = data.listData[0][this.i18nService.getActiveLang];
        this.countryCodeList = data.listData[1][this.i18nService.getActiveLang];
        this.areaList = data.listData[3][this.i18nService.getActiveLang];
        this.salutationList = data.listData[4][this.i18nService.getActiveLang];
        this.preferenceList = data.listData[5][this.i18nService.getActiveLang];
      }
    });
    this.subscription = this.userService.getMember().subscribe((data) => {
      if (data) {
        if (data.shoppingPreference) {
          data.shoppingPreference = data.shoppingPreference.split(";");
        }
        this.initFrom(data.memberNumber);
        this.updateProfileForm.patchValue(data);
        this.userProfile = data;        
        this.patchForm();
        if (data.directMktOptIn === true) {
          this.isShowDirectMktOptIn = false;
        }
      }
    });

    // this.userService.checkSecurityQuestionStatus().subscribe((data) => {
    //   this.isSecurityQuestionExist = data;
    // });
  }

  ngOnInit(): void {
    // Redirection for external platform
    this.route.queryParams.subscribe((value) => {
      if (value["cb"]) {
        this.redirectPlatformLink = decodeURI(value["cb"]);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // TODO: Init Form
  initFrom(memberNumber: string) {
    this.updateProfileForm = this.fb.group({
      area: [null],
      preferredLanguage: [null, [Validators.required]],
      birthMonth: [null],
      birthYear: [null, [this.birthYearValidator]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      personEmail: [
        null,
        {
          validators: [Validators.required, emailValidator],
          asyncValidators: ValidateEmailNotTaken.createValidator(this.userService, "update", memberNumber),
          updateOn: "blur",
        },
      ],
      personTitle: [null, [Validators.required]],
      phoneNumber: [
        null,
        {
          validators: [Validators.required, phoneValidator],
          asyncValidators: this.validatePhoneNotTaken,
          updateOn: "blur",
        },
      ],
      phoneNumberPrefix: ["852_Hong Kong (+852)"],
      shoppingPreference: [null],
      directMktOptIn: [false],
    });

    // if (!this.isSecurityQuestionExist) {
    //   this.updateProfileForm.addControl("securityQuestion", new FormControl(null));
    //   this.updateProfileForm.addControl("securityAnswer", new FormControl(null));
    // }

    this.personEmail.statusChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value === "VALID") {
        if(this.phoneNumber.errors && this.phoneNumber.errors.required){
          this.phoneNumber.setValidators([Validators.minLength(1), phoneValidator]);
          this.phoneNumber.updateValueAndValidity();
        }
      } else if (value === "INVALID") {
        this.phoneNumber.setValidators([Validators.required, Validators.minLength(1), phoneValidator]);
        this.phoneNumber.updateValueAndValidity({ emitEvent: false });
        if(!this.personEmail.value && !this.phoneNumber.value){
          this.updateDirty()
        }
      }
    });

    this.phoneNumber.statusChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      if (value === "VALID") {
        const validators = [Validators.required];
        if(this.personEmail.errors && this.personEmail.errors.required || this.userProfile.phoneAddressVerified && this.phoneNumber.value && !this.phoneNumber.dirty && !this.phoneNumberPrefix.dirty){
          this.personEmail.setValidators([Validators.minLength(3), emailValidator]);
          this.personEmail.updateValueAndValidity();
        }
        // Set phone number as mandatory field
        // if (!this.isSecurityQuestionExist) {
        //   this.securityQuestion.setValidators([Validators.required]);
        //   this.securityAnswer.setValidators([Validators.required]);
        //   this.securityQuestion.updateValueAndValidity();
        //   this.securityAnswer.updateValueAndValidity();
        // }
      } else if (value === "INVALID") {
        this.personEmail.setValidators([Validators.required, Validators.minLength(3), emailValidator]);
        this.personEmail.updateValueAndValidity({ emitEvent: false });
        if(!this.personEmail.value && !this.phoneNumber.value){
          this.updateDirty()
        }
        if(!this.phoneNumber.value){
          this.phoneNumber.setValidators([Validators.minLength(1), phoneValidator]);
          this.phoneNumber.updateValueAndValidity();
        }
        // if (!this.isSecurityQuestionExist) {
        //   this.securityQuestion.setValidators(null);
        //   this.securityAnswer.setValidators(null);
        //   this.securityQuestion.updateValueAndValidity();
        //   this.securityAnswer.updateValueAndValidity();
        // }
      }
    });

    this.phoneNumberPrefix.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      if(this.userProfile.phoneNumberPrefix != value){
        this.verificationChannel = "phone"
      }else{
        if(this.userProfile.phoneAddressVerified && !this.userProfile.emailAddressVerified && !this.phoneNumber.dirty){
          this.verificationChannel = "email"
        }else if (!this.userProfile.phoneAddressVerified && this.userProfile.emailAddressVerified){
          this.verificationChannel = "phone"
        }
      }
      this.phoneNumber.updateValueAndValidity();
    });

    // Subscribe field changes
    this.personEmail.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        if (this.personEmail.dirty && this.userProfile.personEmail === value) {
          this.personEmail.markAsPristine();
        }
      }
      if(value == ""){
        this.personEmail.setValue(null)
        this.personEmail.setValidators([Validators.required, Validators.minLength(3), emailValidator]);
        this.personEmail.updateValueAndValidity({ emitEvent: false });
      }
    });

    this.phoneNumber.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        if (this.phoneNumber.dirty && this.userProfile.phone === value) {
          this.phoneNumber.markAsPristine();
        } else {
        }
      } else {
        if(value == "" && !this.personEmail.value){
          this.personEmail.setValue(null)
          this.personEmail.setValidators([Validators.required, Validators.minLength(3), emailValidator]);
          this.personEmail.updateValueAndValidity({ emitEvent: false });
        }
        // if (!this.isSecurityQuestionExist) {
        //   this.securityQuestion.setValidators(null);
        //   this.securityAnswer.setValidators(null);
        //   this.securityQuestion.updateValueAndValidity();
        //   this.securityAnswer.updateValueAndValidity();
        // }
      }
    });

    this.phoneNumberPrefix.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        if (this.phoneNumberPrefix.dirty && this.userProfile.phoneNumberPrefix === value) {
          this.phoneNumberPrefix.markAsPristine();
        }
      }
    });

    this.updateProfileForm.get("birthYear").valueChanges.subscribe((value) => {
      if(value != null){
        this.updateProfileForm.get("birthMonth").setValidators([Validators.required]);
      }else{
        this.updateProfileForm.get("birthMonth").setValidators(null);;
      }
      this.updateProfileForm.get("birthMonth").updateValueAndValidity();
    });

  }

  patchForm() {
    // Disable name, email(Phase 1)
    this.firstName.disable();
    this.lastName.disable();
    // Member E-mail
    if (this.userProfile.value) {
    } else {
    }
    if (this.userProfile.phone) {
      this.phoneNumber.setValue(this.userProfile.phone);
    }
  }

  validatePhoneNotTaken = (control: AbstractControl) => {
    if (
      control &&
      !control.pristine &&
      control.value &&
      control.value.trim() !== "" &&
      !(control.value === this.userProfile.phone && this.phoneNumberPrefix.value === this.userProfile.phoneNumberPrefix)
    ) {
      return this.userService.checkDuplicatedMobile(this.phoneNumberPrefix.value.split("_")[0] + control.value).pipe(
        map((isValid) => null),
        catchError(() => of({ error: true, duplicated: true }))
      );
    } else {
      return of(null);
    }
  };

  clickDirectMktOptIn() {
    this.updateProfileForm.controls.directMktOptIn.setValue(this.updateProfileForm.value.directMktOptIn);
  }

  beforeSubmitForm() {
    let existBox = this.emailOtpBox ? 'emailOtpBox':'phoneOtpBox';
    if (this[existBox] && this[existBox].verificationForm.pending) {
      let sub = this[existBox].verificationForm.statusChanges.subscribe((res) => {
        if (this[existBox].verificationForm.valid) {
          this.preSubmitForm();
        }
        sub.unsubscribe();
      });
    } else {
      this.preSubmitForm();
    }
  }

  preSubmitForm() {
    if(this.phoneNumber.dirty && this.personEmail.dirty){
      if(this.verificationChannel == "email" && this.emailOtpBox.verCode.invalid){
        this.emailOtpBox.verCode.setErrors({ invalidVerificationCode: true });
        this.emailOtpBox.verCode.markAsDirty();
        this.emailOtpBox.verCode.updateValueAndValidity();
        this.formService.scrollToError();
        return;
      }

      if(this.verificationChannel == "phone" && this.phoneOtpBox.verCode.invalid){
        this.phoneOtpBox.verCode.setErrors({ invalidVerificationCode: true });
        this.phoneOtpBox.verCode.markAsDirty();
        this.phoneOtpBox.verCode.updateValueAndValidity();
        this.formService.scrollToError();
        return;
      }
    }

    if (!this.personEmail.value && this.phoneNumber.value.length > 0 && this.phoneNumber.dirty && this.phoneOtpBox.verificationForm.invalid) {
      this.phoneNumber.setErrors({ requiredVerify: true });
      this.formService.scrollToError();
      return;
    }

    if (this.emailOtpBox && this.emailOtpBox.getOtpCount > 0 && this.emailOtpBox.verCode.invalid || this.emailOtpBox && (this.phoneNumber.dirty || this.phoneNumberPrefix.dirty) && !this.userProfile.emailAddressVerified && this.emailOtpBox.verCode.value.length == 0) {
      this.emailOtpBox.verCode.setErrors({ invalidVerificationCode: true });
      this.emailOtpBox.verCode.markAsDirty();
      this.emailOtpBox.verCode.updateValueAndValidity();
      this.formService.scrollToError();
      return;
    }

    if (this.phoneOtpBox && this.phoneOtpBox.getOtpCount > 0 && this.phoneOtpBox.verCode.invalid || this.phoneOtpBox  && (this.phoneNumber.dirty || this.phoneNumberPrefix.dirty) && !this.userProfile.emailAddressVerified && this.phoneOtpBox.verCode.value.length == 0) {
      this.phoneOtpBox.verCode.setErrors({ invalidVerificationCode: true });
      this.phoneOtpBox.verCode.markAsDirty();
      this.phoneOtpBox.verCode.updateValueAndValidity();
      this.formService.scrollToError();
      return;
    }
    if(this.updateProfileForm.controls.personEmail.value != this.userProfile.personEmail && !this.emailOtpBox.verCode.valid){
      this.emailOtpBox.verCode.setErrors({ invalidVerificationCode: true });
      this.emailOtpBox.verCode.markAsDirty();
      this.emailOtpBox.verCode.updateValueAndValidity();
      this.formService.scrollToError();
      return;
    }
    
    // if(this.personEmail.value && !this.phoneNumber.value && !this.isSecurityQuestionExist){
    //   /**
    //    * When the email has a value, the mobile phone number has no value. Security question is set to not required.
    //    */
    //   this.securityQuestion.setValidators(null);
    //   this.securityAnswer.setValidators(null);
    //   this.securityQuestion.updateValueAndValidity();
    //   this.securityAnswer.updateValueAndValidity();
    // }

    if (this.updateProfileForm.valid) {
      this.submitForm();
    } else {
      this.updateDirty();
      this.formService.scrollToError();
    }
  }

  updateDirty(){
    const exceptionList = [this.personEmail, this.phoneNumber, this.phoneNumberPrefix];
    for (const i in this.updateProfileForm.controls) {
      this.updateProfileForm.controls[i].markAsDirty();
      if (
        exceptionList.indexOf(this.updateProfileForm.controls[i] as FormControl) !== -1 &&
        this.updateProfileForm.controls[i].value &&
        this.updateProfileForm.controls[i].value.length > 1 &&
        this.updateProfileForm.controls[i].valid
      ) {
        this.updateProfileForm.controls[i].markAsPristine();
      }
      this.updateProfileForm.controls[i].updateValueAndValidity();
    }
  }
  private submitForm(): void {
    // Remove airport staff section
    const formData = this.transformUpdateProfileForm();
    this.isSubmitting = true;
    this.userService.updateProfile(formData).subscribe(
      async (feedback) => {
        if (feedback) {
          this.alertService.success(this.i18nService.translateLabel("profile.form.updated"));
          if(this.phoneNumber.dirty && this.emailOtpBox && this.emailOtpBox.verCode.valid && !this.userProfile.emailAddressVerified){
            let newFormData = {...formData}
            // newFormData.verCode = undefined;
            newFormData.verificationChannel = undefined;
            newFormData.phone = this.transferPhone;
            await new Promise((resolve, reject)=>{
              this.userService.updateProfile(newFormData).subscribe(()=>{
                resolve(null);
              },()=>{
                reject();
              })
            })
          }
          await this.userService.reloadUserInfo();
          if (this.redirectPlatformLink != null) {
            window.open(this.redirectPlatformLink, "_self");
          } else {
            const path = this.localizeRouter.translateRoute("/profile");
            this.router.navigate([path]);
          }
        } else {
          this.isSubmitting = false;
        }
      },
      async (err) => {
        this.isSubmitting = false;
        if (err.errorCode === "E1048") {
          if (this.emailOtpBox) {
            this.emailOtpBox.verCode.setErrors({ otpExpired: true });
          } else if (this.phoneOtpBox) {
            this.phoneOtpBox.verCode.setErrors({ otpExpired: true });
          }
          await this.commonService.browserDelay(100);
          this.formService.scrollToError();
        } else if (err.errorCode === "E1013") {
          // this.updateProfileForm.controls.securityQuestion.setErrors({ sqRequired: true });
          // this.updateProfileForm.controls.securityAnswer.setErrors({ saRequired: true });
        }
      }
    );
  }

  birthYearValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    }
    if (
      control.value &&
      this.birthMonth &&
      this.birthMonth.value &&
      Number(control.value) === new Date().getFullYear() &&
      Number(this.birthMonth.value) > new Date().getMonth() + 1
    ) {
      return { error: true };
    }
    return null;
  };

  // TODO: Separated transformer?
  transformUpdateProfileForm() {
    const requestBody: PatchProfileForm = {
      area: this.updateProfileForm.controls.area.value || null,
      birthMonth: this.updateProfileForm.controls.birthMonth.value || null,
      birthYear: this.updateProfileForm.controls.birthYear.value || null,
      personEmail: this.updateProfileForm.controls.personEmail.value || null,
      personTitle: this.updateProfileForm.controls.personTitle.value || null,
      preferredLanguage: this.updateProfileForm.controls.preferredLanguage.value || null,
      memberNumber: this.userProfile.memberNumber || null,
      shoppingPreference: this.updateProfileForm.controls.shoppingPreference.value ? this.updateProfileForm.controls.shoppingPreference.value.toString().replaceAll(",", ";") : null || null,
      directMktOptIn: this.updateProfileForm.controls.directMktOptIn.value,
    };


    if (this.phoneNumber.dirty || this.phoneNumberPrefix.dirty || (this.phoneOtpBox && this.phoneOtpBox.verCode.value.length === 6 && this.phoneOtpBox.verificationForm.valid)) {
      requestBody.phone = this.updateProfileForm.controls.phoneNumber.value === "" ? "" : this.updateProfileForm.controls.phoneNumber.value;
      if (requestBody.phone) {
        requestBody.phoneNumberPrefix = this.updateProfileForm.controls.phoneNumberPrefix.value;
      }
    }

    // if (this.isShowSecurityFieldset) {
    //   requestBody.securityAnswer = this.updateProfileForm.controls.securityAnswer.value;
    //   requestBody.securityQuestion = this.updateProfileForm.controls.securityQuestion.value;
    // }

    if (this.emailOtpBox && this.emailOtpBox.verificationForm.valid) {
      requestBody.emailVerCode = this.emailOtpBox.verCode.value;
      requestBody.verificationChannel = this.emailOtpBox.verificationChannel;
    }

    if (this.phoneOtpBox && this.phoneOtpBox.verificationForm.valid) {
      requestBody.phoneVerCode = this.phoneOtpBox.verCode.value;
      requestBody.verificationChannel = this.phoneOtpBox.verificationChannel;
    }

    if(this.phoneNumber.dirty && this.emailOtpBox && this.emailOtpBox.verCode.valid && !this.userProfile.emailAddressVerified){
      this.transferPhone = requestBody.phone
      requestBody.phone = '';
    }
    return requestBody;
  }

  // trimSecurityAnswer() {
  //   const self: any = this;
  //   const value = this.securityAnswer.value;
  //   if (value && value.trim() !== value) {
  //     this.securityAnswer.patchValue(value.trim());
  //     self.isTrimmed = true;
  //   } else {
  //     self.isTrimmed = false;
  //   }
  // }

  verifyFirstInput(emailOrNumber:string){
    const firstInput = JSON.parse(sessionStorage.getItem('firstInput'))
    const currentTime = new Date().getTime()
    if(!!firstInput && firstInput.emailOrNumber === emailOrNumber && (currentTime - firstInput.time < 300000)){
      this.isSubmitting = false;
      return false
    }
    return true
  }

  onEmailCaptchaChanged(isValid: boolean) {
    if(!this.verifyFirstInput(this.personEmail.value)){
      this.personEmail.markAsDirty()
      this.personEmail.setErrors({ cannotGetCaptcha: true });
      this.personEmail.markAsTouched()
      return
    }
  }

  onEmailOtpChanged(isValid: boolean) {
    this.isEmailReadOnly = true;
    this.isMobileReadOnly = true;
    this.isPhoneReadOnly = true;
    if(this.phoneNumber.value.length > 0 && this.phoneNumber.status == "INVALID"){
      this.phoneNumber.setValue(null)
    }
    if(this.personEmail.value.length > 0 && this.personEmail.status == "INVALID"){
      this.personEmail.setValue(null)
    }
  }

  onMobileOtpChanged(isValid: boolean) {
    this.isEmailReadOnly = true;
    this.isMobileReadOnly = true;
    this.isPhoneReadOnly = true;
    if(this.phoneNumber.value.length > 0 && this.phoneNumber.status == "INVALID"){
      this.phoneNumber.setValue(null)
    }
    if(this.personEmail.value.length > 0 && this.personEmail.status == "INVALID"){
      this.personEmail.setValue(null)
    }
  }

  focusEmailInput(){
    if(!this.isEmailReadOnly && !this.isMobileReadOnly){
      this.verificationChannel = "email"
    }
  }

  focusPhoneInput(){
    if(!this.isEmailReadOnly && !this.isMobileReadOnly){
      this.verificationChannel = "phone"
    }
  }

  blurEmailInput(){

  }

  blurPhoneInput(){
    // if(!this.phoneNumber.dirty && !this.userProfile.emailAddressVerified || !this.phoneNumber.value && !this.userProfile.emailAddressVerified){
      // this.verificationChannel = "email"
    // }
  }

  get isShowPhoneOtpFieldset() {
    return (
      this.phoneNumber &&
      this.phoneNumber.value &&
      this.phoneNumber.value.length > 0 &&
      (this.phoneNumber.valid || this.phoneNumber.hasError("requiredVerify")) &&
      (!this.userProfile.phoneAddressVerified || this.phoneNumber.value !== this.userProfile.phone || this.phoneNumberPrefix.value !== this.userProfile.phoneNumberPrefix) && 
      this.verificationChannel == "phone"
    );
  }

  get isShowEmailOtpFieldset() {
    return (
      this.personEmail &&
      this.personEmail.value &&
      this.personEmail.value.length > 0 &&
      this.personEmail.valid &&
      (!this.userProfile.emailAddressVerified || this.personEmail.value !== this.userProfile.personEmail) &&
      this.verificationChannel == "email"
    );
  }

  // get isShowSecurityFieldset() {
  //   return !this.isSecurityQuestionExist && this.phoneNumber.value && this.phoneNumber.valid;
  // }

  get emailOtpConfig(): OtpConfig {
    return {
      type: "Email",
      otpType: "edit",
      id: this.personEmail.value,
    };
  }

  get phoneOtpConfig(): OtpConfig {
    return {
      type: "Mobile",
      otpType: "edit",
      id: this.phoneNumber.value,
      countryCode: this.phoneNumberPrefix.value,
    };
  }

  get birthMonth() {
    return this.updateProfileForm.get("birthMonth") as FormControl;
  }
  get area() {
    return this.updateProfileForm.get("area") as FormControl;
  }
  get birthYear() {
    return this.updateProfileForm.get("birthYear") as FormControl;
  }
  get personEmail() {
    return this.updateProfileForm.get("personEmail") as FormControl;
  }
  get personTitle() {
    return this.updateProfileForm.get("personTitle") as FormControl;
  }
  get phoneNumber() {
    return this.updateProfileForm.get("phoneNumber") as FormControl;
  }
  // get securityQuestion() {
  //   return this.updateProfileForm.get("securityQuestion") as FormControl;
  // }
  // get securityAnswer() {
  //   return this.updateProfileForm.get("securityAnswer") as FormControl;
  // }
  get phoneNumberPrefix() {
    return this.updateProfileForm.get("phoneNumberPrefix") as FormControl;
  }
  get firstName() {
    return this.updateProfileForm.get("firstName") as FormControl;
  }
  get lastName() {
    return this.updateProfileForm.get("lastName") as FormControl;
  }
  get shoppingPreference() {
    return this.updateProfileForm.get("shoppingPreference") as FormControl;
  }
  trackFunc = (index, item) => {
    return index;
  };
}
