import { ActivateMemberComponent } from './activate-member/activate-member.component';
import { ActivateMemberResolver } from './activate-member/activate-member-resolver.service';
import { AuthGuard } from "../../core";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NgModule } from "@angular/core";
import { NoAuthGuard } from "./no-auth-guard.service";
import { RegistrationComponent } from "./registration/registration.component";
import { RegistrationResolver } from "./registration/registration-resolver.service";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ResetPasswordResolver } from "./reset-password/reset-password-resolver.service";
import { ResetSecurityQuestionComponent } from "./reset-security-question/reset-security-question.component";
import { Routes, RouterModule } from "@angular/router";
import { SocialLoginComponent } from "./social-login/social-login.component";
import { SupplementProfileComponent } from "./supplement-profile/supplement-profile.component";
import { SystemMessageComponent } from '../../shared';
import { UpgradeAccountComponent } from './upgrade-account/upgrade-account.component';
import { HomeComponent } from '../home/home.component';

const routes: Routes = [
  {
    path: "register",
    component: RegistrationComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      listData: RegistrationResolver,
    },
    data: {
      title: "",
    },
    children: [
      { path: "partner"},
      { path: "partner/:shortCode" },
      { path: "mobile" },
    ]
  },
  {
    path: "register/success",
    component: SystemMessageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: "incomplete", // TODO: Not allow direct access
    component: SupplementProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      listData: RegistrationResolver,
    },
    children: [
      {
        path: "mobile"
      }
    ],
    data: {
      isProtected: true, // For session timeout checking
      isSkipGeneralGuard: true
    },
  },
  {
    path: "social-login",
    component: SocialLoginComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      isShowBack: true,
      isProtected: true, // For session timeout checking
      backRoute: "/profile", // TODO: See if can use router state
    },
  },
  // {
  //   path: "reset-security-question",
  //   component: ResetSecurityQuestionComponent,
  //   canActivate: [AuthGuard],
  //   resolve: {
  //     listData: RegistrationResolver,
  //   },
  //   data: {
  //     isProtected: true, // For session timeout checking
  //   },
  // },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      listData: RegistrationResolver,
    },
    children: [
      {
        path: "mobile"
      }
    ]
  },
  {
    path: "upgrade-account",
    component: UpgradeAccountComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      listData: RegistrationResolver,
    },
  },
  {
    path: "forgot-password/success",
    component: SystemMessageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: "reset",
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      isValidToken: ResetPasswordResolver,
    },
  },
  {
    path: "reset/success",
    component: SystemMessageComponent,
  },
  {
    path: "reset/error",
    component: SystemMessageComponent,
  },
  {
    path: "reset",
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      isValidToken: ResetPasswordResolver,
    },
  },
  {
    path: "activate",
    component: ActivateMemberComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      isValidToken: ActivateMemberResolver,
    },
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
