import { Injectable, } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { RemoteConfigService, I18NService } from "../../core";
import { FaqService } from "../../core/services/faq.service";

@Injectable()
export class FaqResolver implements Resolve<any> {
  constructor(
    private faqService: FaqService,
    private router: Router,
    private i18nService: I18NService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.faqService.getKnowledgeArticle(this.i18nService.getActiveLang, "FAQ")
      .pipe(catchError((err) => this.router.navigateByUrl("/")));
  }
}
