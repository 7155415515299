import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";

import { CampaignsService } from "../../core";
import { catchError } from "rxjs/operators";

@Injectable()
export class CampaignsResolver implements Resolve<any> {
  constructor(
    private campaignsService: CampaignsService,
    private router: Router,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.campaignsService
      .getCampaign(route.params["campaignId"], )
      .pipe(catchError(err => this.router.navigateByUrl("/")));
  }
}
