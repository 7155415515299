import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormService, UserService, ErrorService, I18NService, ApiError, CommonService, AlertService } from "../../../core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import * as _ from "underscore";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-reset-security-question",
  templateUrl: "./reset-security-question.component.html",
  styleUrls: ["./reset-security-question.component.scss"],
})
export class ResetSecurityQuestionComponent implements OnInit, OnDestroy {
  isSubmittingSecurityForm = false;
  securityQuestionForm: FormGroup;
  securityQuestionList = [];
  securityQuestionListInLang = [];

  isTrimmedAns: boolean;
  private subscription: Subscription;
  constructor(
    private errorService: ErrorService,
    private fb: FormBuilder,
    private formService: FormService,
    private i18nService: I18NService,
    private route: ActivatedRoute,
    private userService: UserService,
    private commonService: CommonService,
    private alertService: AlertService
  ) {
    this.i18nService.currentLang.subscribe((lang) => {
      this.securityQuestionListInLang = this.securityQuestionList[lang];
    });
    this.route.data.subscribe((data: { listData: any[] }) => {
      if (data.listData && this.securityQuestionList.length === 0) {
        this.securityQuestionList = data.listData[0];
        this.securityQuestionListInLang = this.securityQuestionList[this.i18nService.getActiveLang];
      }
    });
  }

  ngOnInit() {
    this.initSecurityForm();
    this.subscription = this.userService.getMember().subscribe((data) => {
      if (data) {
        this.securityQuestionForm.controls.phone.setValue(data.phoneNumberPrefix.split("_")[0] + data.phone);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initSecurityForm() {
    this.securityQuestionForm = this.fb.group(
      {
        phone: [null, [Validators.required]],
        securityQuestion: [null, [Validators.required]],
        securityAnswer: [null, [Validators.required]],
      },
      { updateOn: "blur" }
    );
  }

  trimSecurityAnswer() {
    const value = this.securityQuestionForm.controls.securityAnswer.value;
    if (value && value.trim() !== value) {
      this.securityQuestionForm.controls.securityAnswer.setValue(value.trim());
      this.isTrimmedAns = true;
    } else {
      this.isTrimmedAns = false;
    }
  }

  submitForm() {
    if (this.securityQuestionForm.valid) {
      const formData = {
        phone: this.securityQuestionForm.controls.phone.value,
        securityQuestion: this.securityQuestionForm.controls.securityQuestion.value,
        securityAnswer: this.securityQuestionForm.controls.securityAnswer.value,
      };
      this.isSubmittingSecurityForm = true;
      this.userService.resetSecurityQuestion(formData).subscribe(
        (response) => {
          this.alertService.success(this.i18nService.translateLabel("COMMON.MESSAGE.SUCCESSFULLY_RESET_SECURITY_QA"));
          this.commonService.redirect("/home");
        },
        (error: ApiError) => {
          this.isSubmittingSecurityForm = false;
          if (error.errorCode === "E1020") {
            this.securityQuestionForm.controls.securityAnswer.setErrors({
              incorrectAnswer: true,
            });
          } else {
            this.errorService.redirectToErrorPage(error);
          }
        }
      );
    } else {
      for (const i in this.securityQuestionForm.controls) {
        this.securityQuestionForm.controls[i].markAsDirty();
        this.securityQuestionForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
      return;
    }
  }

  get securityAnswer() {
    return this.securityQuestionForm.get("securityAnswer") as FormControl;
  }
}
