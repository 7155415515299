import * as _ from "underscore";
import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { UserService, ProfilesService } from "../../core";

@Component({
  selector: "app-about-programme-page",
  templateUrl: "./about-programme.component.html",
  styleUrls: ["./about-programme.component.scss"],
})
export class AboutProgrammeComponent implements OnInit {
  coverImgUrl = "/assets/images/about-programme/about_us_top_banner.jpg";
  tabSelectedIndex = 0;
  style = { width: "100%", "text-align": "center", "min-height": "60px" }; // TODO: Common this style

  @HostListener("window:scroll", []) onWindowScroll() {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    // || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 417) {
      element.classList.add("tab-sticky");
    } else {
      element.classList.remove("tab-sticky");
    }
  }

  constructor(private router: Router, private userService: UserService, private profileService: ProfilesService) {}

  ngOnInit() {
    if (location.hash === "#earn-and-redeem") {
      this.tabSelectedIndex = 1;
    } else if (location.hash === "#member-privileges") {
      this.tabSelectedIndex = 2;
    }
  }

  onClickTabHeader() {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    //  || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 417) {
      desktopContainer.scrollTop = 418;
      // mobileContainer.scrollTop = 81;
    }
  }
}
