import { ContactFormComponent } from "./contact-form.component";
import { ContactFormResolver } from "./contact-form-resolver.service";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SystemMessageComponent } from '../../shared/system-message/system-message.component';

const routes: Routes = [
  {
    path: "",
    component: ContactFormComponent,
    resolve: {
      caseReasonPickLists: ContactFormResolver
    },
    data: {
      title: {
        en_US: "Contact Us | HKairport Rewards",
        zh_HK: "聯絡我們 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "联络我们 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `Contact Us | HKairport Rewards`,
          },
          {
            name: "description",
            content: `If you require assistance, you may contact our customer service team. We will get back to you as quickly as possible.`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `聯絡我們 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
          {
            name: "description",
            content: `如需協助，請聯絡客戶服務團隊，我們將安排專人盡快回覆。`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `联络我们 |HKairport Rewards 香港国际机场奖赏计划`,
          },
          {
            name: "description",
            content: `如需协助，请联络客户服务团队，我们将安排专人尽快回复。`,
          },
        ],
      },
    },
    children: [
      {
        path: "modal"
      }
    ]
  },
  {
    path: "success",
    component: SystemMessageComponent,
  },
  {
    path: "live-chat",
    component: SystemMessageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactFormRoutingModule {}
