import { ApiService } from "./api/api.service";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, forkJoin } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";

@Injectable()
export class RemoteConfigService {
  private readonly remoteConfigSubject = new BehaviorSubject<any[]>([]);
  public remoteConfig = this.remoteConfigSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private apiService: ApiService) {}

  getRegistrationPickList(): Observable<any> {
    const securityQuestion = this.getSecurityQuestion();
    const countryCode = this.getCountryCode();
    const company = this.getCompany();
    const areaOfResidence = this.getAreaOfResidence();
    const salutation = this.getSalutation();
    const shoppingPreference = this.getShoppingPreferenceLists();
    return forkJoin([securityQuestion, countryCode, company, areaOfResidence, salutation, shoppingPreference]);
  }

  getMemberFieldPickLists(): Observable<any> {
    return this.apiService.get("/configuration/picklist/member", "member", null).pipe(map((data) => data));
  }

  getSecurityQuestion(): Observable<any> {
    return this.apiService.get("/configuration/picklist/securityquestion", "member", null).pipe(map((data) => data));
  }

  getCountryCode(): Observable<any> {
    return this.apiService.get("/configuration/picklist/countrycode", "member", null).pipe(map((data) => data));
  }

  getCompany(): Observable<any> {
    return this.apiService.get("/configuration/picklist/company", "member", null).pipe(map((data) => data));
  }

  getAreaOfResidence(): Observable<any> {
    return this.apiService.get("/configuration/picklist/area", "member", null).pipe(map((data) => data));
  }

  getShopList(): Observable<any> {
    return this.apiService.get("/configuration/picklist/shop", "member", null).pipe(map((data) => data));
  }

  getSalutation(): Observable<any> {
    return this.apiService.get("/configuration/picklist/salutation", "member", null).pipe(map((data) => data));
  }

  getCaseReasonPickLists(): Observable<any> {
    return this.apiService.get("/configuration/picklist/caseReason", "member", null).pipe(map((data) => data));
  }

  getRejectReasonPickLists(): Observable<any> {
    return this.apiService.get("/configuration/picklist/rejectionReason", "member", null).pipe(map((data) => data));
  }

  getShoppingPreferenceLists(): Observable<any> {
    return this.apiService.get("/configuration/picklist/preference", "member", null).pipe(map((data) => data));
  }
}
