import { ApiError } from "../../models";
import { Injectable, Injector } from "@angular/core";
import { AlertService } from "../alert.service";
// import { I18NService } from '../i18n.service';
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class HkiaApiErrorService {
  constructor(private translate: TranslateService, private alertService: AlertService,) { }

  handleApiError(error: any, formatError: ApiError) {
    let errorDetail = formatError.errorDetail;
    let errorCode = formatError.errorCode;
    if (!errorDetail || !errorCode) {
      errorCode = '';
      errorDetail = this.translate.instant('COMMON.errors.Something_wrong');
    }
    this.alertService.error({ errorDetail, errorCode });
  }
}
