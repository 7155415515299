import { CommonErrorComponent } from "./shared";
import { GeneralGuard } from "./core/services/general-guard.service";
import { HomeComponent } from "./pages/home/home.component";
import { HttpClient } from "@angular/common/http";
import { I18NService } from "./core/services/i18n.service";
import { LandingComponent } from "./pages/landing/landing.component";
import { LocalizeRouterModule, LocalizeParser, ManualParserLoader, LocalizeRouterSettings, LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Location, LOCATION_INITIALIZED } from "@angular/common";
import { NgModule, APP_INITIALIZER, Injector } from "@angular/core";
import { PageNotFoundComponent } from "./shared/error-pages/page-not-found/page-not-found.component";
import { Routes, RouterModule, PreloadAllModules, Router } from "@angular/router";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { concat } from "rxjs";
import { first, filter } from "rxjs/operators";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { LogoutComponent } from "./shared/logout/logout.component";
import { LogoutRedirectComponent } from "./shared/logout-redirect/logout-redirect.component";
import { LogoutRedirectGuard } from "./core/services/logout-redirect.guard";
import { EarnNowComponent } from "./pages/earn-now/earn-now.component";

export const routes: Routes = [
  {
    path: "subscription",
    loadChildren: () => import("./pages/subscription/subscription.module").then((m) => m.SubscriptionModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "legal",
    loadChildren: () => import("./pages/legal-articles/legal-articles.module").then((m) => m.LegalArticlesModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "account",
    loadChildren: () => import("./pages/account/account.module").then((m) => m.AccountModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "profile",
    loadChildren: () => import("./pages/profile/profile.module").then((m) => m.ProfileModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "about-programme",
    loadChildren: () => import("./pages/about-programme/about-programme.module").then((m) => m.AboutProgrammeModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "about-programme/:version",
    loadChildren: () => import("./pages/about-programme/about-programme.module").then((m) => m.AboutProgrammeModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "offers",
    loadChildren: () => import("./pages/offers/offers.module").then((m) => m.OffersModule),
    canActivate: [GeneralGuard],
  },
  {
    path: "campaigns",
    loadChildren: () => import("./pages/campaigns/campaigns.module").then((m) => m.CampaignsModule),
    canActivate: [GeneralGuard],
  },
  {
    path: "earn",
    loadChildren: () => import("./pages/register-purchase/register-purchase.module").then((m) => m.RegisterPurchaseModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "contact-us",
    loadChildren: () => import("./pages/contact-form/contact-form.module").then((m) => m.ContactFormModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "faq",
    loadChildren: () => import("./pages/faq/faq.module").then((m) => m.FaqModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "earn-now",
    loadChildren: () => import("./pages/earn-now/earn-now.module").then((m) => m.EarnNowModule),
    canActivateChild: [GeneralGuard],
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [GeneralGuard],
    data: {
      title: {
        en_US: "HKairport Rewards | You Shop It, We Reward It ",
        zh_HK: "HKairport Rewards 香港國際機場獎賞計劃 | 隨時買 隨時賞 ",
        zh_CN: "HKairport Rewards 香港国际机场奖赏计划 | 随时买 随时赏",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `HKairport Rewards | You Shop It, We Reward It`,
          },
          {
            name: "description",
            content: `Elevate your airport shopping and dining! From now on, you can earn points with up to 6% in everyday spending rewards simply by shopping and dining at Hong Kong International Airport and HKairportshop.com`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `HKairport Rewards 香港國際機場獎賞計劃  | 隨時買 隨時賞`,
          },
          {
            name: "description",
            content: `登記成為會員，從此於香港國際機場內商店、食肆消費，即可輕鬆獲取獎賞積分！`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `HKairport Rewards 香港国际机场奖赏计划 | 随时买 随时赏`,
          },
          {
            name: "description",
            content: `登记成为会员，从此于香港国际机场内商店、食肆或 HKairportshop.com 消费，即可轻松获取奖赏积分！`,
          },
        ],
      },
    },
  },
  {
    path: "not-found",
    component: PageNotFoundComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
    canActivate: [LogoutRedirectGuard],
  },
  {
    path: "logout-redirect",
    component: LogoutRedirectComponent,
    canActivate: [LogoutRedirectGuard],
  },
  {
    path: "error",
    component: CommonErrorComponent,
  },
  {
    path: "",
    component: LandingComponent,
    // pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/not-found",
  },
];

export function initLanguage(injector: Injector): () => void {
  return (): Promise<any> =>
    new Promise((resolve) => {
      injector.get(LocalizeRouterService).init();
      resolve();
    });
}

export function I18nHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `assets/i18n/`, ".json");
}

export function CreateTranslateLoader(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  return new ManualParserLoader(translate, location, settings, ["en_US", "zh_HK", "zh_CN"], "ROUTES.");
}

// Init router issue : https://github.com/gilsdav/ngx-translate-router/issues/28
export function initLanguageRoutes(translateService: TranslateService, localizeParser: LocalizeParser, router: Router, i18nService: I18NService): Promise<void> {
  return new Promise((resolve: any) => {
    // TODO: use constant default language
    const lang = localizeParser.getLocationLang() || "en_US";
    concat(
      translateService.use(lang).pipe(
        filter((translations) => !!translations),
        first()
      ),
      localizeParser.translateRoutes(translateService.currentLang).pipe(first())
    ).subscribe(() => {
      i18nService.use(lang);
      router.resetConfig(localizeParser.routes);
      resolve();
    });
  });
}

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "top",
      initialNavigation: "enabled",
      onSameUrlNavigation: "reload",
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: CreateTranslateLoader,
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      useCachedLang: false,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: I18nHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [RouterModule, TranslateModule, LocalizeRouterModule],
  providers: [
    {
      provide: LOCATION_INITIALIZED,
      useFactory: initLanguageRoutes,
      deps: [TranslateService, LocalizeParser, Router, I18NService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguage,
      multi: true,
      deps: [Injector],
    },
  ],
})
export class AppRoutingModule {}
