import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "underscore";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-legal-articles",
  templateUrl: "./legal-articles.component.html",
  styleUrls: ["./legal-articles.component.scss"],
})
export class LegalArticlesComponent {
  articleContent = "";
  title: string;
  massTimingsHtml: any;

  constructor(private route: ActivatedRoute, private _sanitizer: DomSanitizer, private router: Router) {
    this.route.data.subscribe((data: { content: any[]; headline: string }) => {
      /**
       * If a user visits an old privacy-policy link, the user is redirected to a new link
       */
      if(router.url.indexOf('/legal/privacy-policy') != -1){
        let redirectNewLink = {
          en_US: "https://www.hongkongairport.com/en/privacy-policy/Loyalty-Programme.page",
          zh_HK: "https://www.hongkongairport.com/tc/privacy-policy/Loyalty-Programme.page",
          zh_CN: "https://www.hongkongairport.com/sc/privacy-policy/Loyalty-Programme.page",
        }[router.config[0].path]
        console.log(redirectNewLink)
        window.location.replace(redirectNewLink)
        return
      }

      if (data.headline) {
        this.title = data.headline;
      }
      if (data.content) {
        this.articleContent = "";
        Object.keys(data.content[0]).forEach(k => {
          if (k.indexOf("Content_") !== -1) {
            this.articleContent += data.content[0][k];
          }
        });
      }
      this.massTimingsHtml = this.getInnerHTMLValue();
    });

  }

  getInnerHTMLValue() {
    return this._sanitizer.bypassSecurityTrustHtml(this.articleContent);
  }
}
