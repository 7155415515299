import { Injectable, } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { OffersService, I18NService } from "../../core";
import { catchError } from "rxjs/operators";

@Injectable()
export class OfferResolver implements Resolve<any> {
  constructor(
    private offerService: OffersService,
    private router: Router,
    private i18nService: I18NService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    /**
     * Get offer detail by offerId
     * TODO: Error handling , catch to  error page
     */
    return this.offerService.getOfferDetail(route.params["offerId"], this.i18nService.getActiveLang)
      .pipe(catchError((err) => this.router.navigateByUrl("/")));
  }
}
