import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { SubscriptionRoutingModule } from './subscription-routing.module';

@NgModule({
  imports: [SharedModule, SubscriptionRoutingModule],
  declarations: [
    UnsubscribeComponent,
  ],
  providers: []
})
export class SubscriptionModule {}
