import { Component, OnInit } from '@angular/core';
import { User, UserService, MenuItem, CommonService } from "../../core";
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { I18NService } from '../../core';


@Component({
  selector: 'app-earn-now',
  templateUrl: './earn-now.component.html'
})
export class EarnNowComponent implements OnInit {

  receiptNumber: string;
  language: string;

  constructor(
    private i18NService: I18NService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { 
    this.receiptNumber = this.activatedRoute.snapshot.params['id'];
  }

  ngOnInit() {
    localStorage.setItem("earn-now", this.receiptNumber);
    this.createTransaction();
  }

  createTransaction() {
    
    this.language = this.i18NService.getActiveLang;
    this.userService.createEarnNowCase(this.receiptNumber).subscribe(
      (data) => {
        this.router.navigate([`/${this.language}/home`],
        { queryParams: { 'earnMsg': 'SUCCESS' }});
        localStorage.removeItem("earn-now");
      },
      (err) => {
        if (err.responseInfo && err.responseInfo.developerMessage && err.responseInfo.developerMessage != "UNAUTHORIZED") {
          localStorage.removeItem("earn-now");
          this.router.navigate([`/${this.language}/home`],
              { queryParams: { 'earnMsg': err.responseInfo.developerMessage }});
        } else {
          localStorage.removeItem("earn-now");
          this.router.navigate([`/${this.language}/home`],
              { queryParams: { 'earnMsg': 'FAILED' }});
        }
      }
    )
    
  }
}


