import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChangePassword, FormService, UserService, passwordValidator, User, AlertService, ApiError, CommonService, I18NService } from "../../../core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import * as _ from "underscore";

@Component({
  selector: "app-account-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  private subscription: Subscription;
  changePasswordForm: FormGroup;
  userProfile: User = {} as User;
  isSubmitting = false;
  redirectPlatformLink: string;

  @Input() formConfig;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private formService: FormService,
    private router: Router,
    private alertService: AlertService,
    private commonService: CommonService,
    private i18nService: I18NService
  ) {
    Object.assign(this.userProfile, this.userService.getCurrentUser);
  }

  ngOnInit() {
    this.initFrom();
    this.changePasswordForm.controls.password.valueChanges.subscribe((value) => {
      this.changePasswordForm.controls.confirmPassword.updateValueAndValidity();
    });
    this.route.queryParams.subscribe((value) => {
      if (value["cb"]) {
        this.redirectPlatformLink = decodeURI(value["cb"]);
      }
    });
  }

  initFrom() {
    this.changePasswordForm = this.fb.group(
      {
        password: [null, [Validators.required, passwordValidator]],
        confirmPassword: [null, [Validators.required, this.passwordConfirmationValidator]],
      },
      { updateOn: "blur" }
    );
  }

  passwordConfirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    }
    if (this.changePasswordForm.controls.password.value !== this.changePasswordForm.controls.confirmPassword.value) {
      return { passwordMismatch: true };
    }
    return null;
  };

  transformChangePasswordForm(): ChangePassword {
    const requestBody = {
      newPassword: this.changePasswordForm.controls.password.value.trim(),
    };
    return _.pick(requestBody, _.identity);
  }

  changePasswordByMemberNumber() {
    const formData = this.transformChangePasswordForm();
    this.isSubmitting = true;
    this.userService.changePasswordByMemberNumber(formData).subscribe(
      (userData) => {
        this.alertService.success(this.i18nService.translateLabel("auth.message.passwordChangeMsg"));
        if (this.redirectPlatformLink != null) {
          window.open(this.redirectPlatformLink, "_self");
        } else {
          this.commonService.redirect("/profile");
        }
      },
      (error: ApiError) => {
        this.isSubmitting = false;
        // this.alertService.error(error);
        // TODO: Confirm others error
        if (error.errorCode === "E1006" || error.errorCode === "E1030") {
          this.changePasswordForm.controls.password.setErrors({ repeatedPassword: true });
        }
      }
    );
  }

  submitForm() {
    // tslint:disable-next-line:forin
    for (const i in this.changePasswordForm.controls) {
      this.changePasswordForm.controls[i].markAsDirty();
      this.changePasswordForm.controls[i].updateValueAndValidity();
    }

    if (this.changePasswordForm.valid) {
      this.changePasswordByMemberNumber();
    } else {
      this.formService.scrollToError();
    }
  }
}
