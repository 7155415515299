import { EarnHistoryComponent } from "./earn-history/earn-history.component";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { NzTableModule } from "ng-zorro-antd";
import { RegisterPurchaseComponent } from "./register-purchase.component";
import { RegisterPurchaseResolver } from "./register-purchase-resolver.service";
import { RegisterPurchaseRoutingModule } from "./register-purchase-routing.module";
import { SharedModule } from "../../shared";
import { RejectReasonResolver } from './reject-reason-resolver.service';
import { RegisterPurchaseService } from '../../core';

@NgModule({
  imports: [SharedModule, RegisterPurchaseRoutingModule, NzTableModule],
  declarations: [RegisterPurchaseComponent, EarnHistoryComponent],
  providers: [RegisterPurchaseResolver, RejectReasonResolver, RegisterPurchaseService],
})
export class RegisterPurchaseModule {}
