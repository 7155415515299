import { Component, Input } from "@angular/core";

import { Errors } from "../../../core";

@Component({
  selector: "app-page-not-round",
  templateUrl: "./page-not-found.component.html"
})
export class PageNotFoundComponent {

}
