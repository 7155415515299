import { Component, OnInit } from "@angular/core";
import { OAuthStorage } from 'angular-oauth2-oidc';
import { GeneralGuard, UserService } from '../../core';
import { Location } from "@angular/common";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: "app-campaigns-page",
  templateUrl: "./campaigns.component.html",
  styleUrls: ["./campaigns.component.scss"],
})
export class CampaignsComponent implements OnInit {
  style = {
    width: "100%",
    "text-align": "center",
    "font-size": "18px",
  };

  constructor(
    private authStorage: OAuthStorage, 
    private generalGuardService: GeneralGuard,
    private _location: Location,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }
  }
}
