import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { CountdownComponent, CountdownConfig, CountdownEvent } from "ngx-countdown";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd";
import { Subscription, BehaviorSubject, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { otpValidator, I18NService, UserService, OtpConfig } from "../../core";

@Component({
  selector: "app-otp-box",
  styleUrls: ["otp-box.component.scss"],
  templateUrl: "./otp-box.component.html",
})
export class OtpBoxComponent implements OnInit, OnDestroy {
  @Input() otpConfig: OtpConfig;
  @Output() valid = new EventEmitter<boolean>();
  @Output() captcha = new EventEmitter<boolean>();
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;
  private isFirstTimeGetOtp = true;
  private subscription: Subscription;
  public getOtpCount = 0;
  public isSubmittingOtp = false;
  public timerToggle$ = new BehaviorSubject(false);
  public verificationForm: FormGroup;
  public previewVisible = false;
  public picture = "";
  public sessionId = "";
  public refreshImage = false;
  public otpExtraShow = true;

  timerConfig: CountdownConfig = {
    leftTime: 300,
    format: "mm:ss",
    demand: true,
  };

  constructor(private fb: FormBuilder, private i18nService: I18NService, private userService: UserService, private modalService: NzModalService) {}

  ngOnInit() {
    this.verificationForm = this.fb.group(
      {
        verCode: [
          "",
          {
            validators: [Validators.required, otpValidator],
            asyncValidators: [this.otpAsyncValidator],
            updateOn: "blur",
          },
        ],
        captchaCode: [
          "",
          {
            validators: [],
          },
        ],
      },
      { updateOn: "blur" }
    );

    this.verCode.statusChanges.subscribe((value) => {
      if (value === "VALID" && this.verCode.value && this.verCode.value.length > 0) {
        this.valid.emit(true);
      }
    });

    if(localStorage.getItem('sessionNumber')){
      this.sessionId = localStorage.getItem('sessionNumber')
    }
    
    this.userService.getValidationTime(this.sessionId).subscribe(
      (response) => {
        if(response.limitation_times == 0){
          this.getOtpCount = 11;
          this.verificationForm.controls.verCode.markAsDirty();
          this.verificationForm.controls.verCode.setErrors({ limitExceeded: true });
        }
      },
      (error) => {
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  captchaVerify(e?: MouseEvent,verifyFirst:Boolean = false){
    if(!this.verifyFirstInput(this.otpConfig.id) && verifyFirst){
      this.captcha.emit(true);
      return
    }
    if(e){
      this.captchaCode.setValidators([Validators.required]);
      this.captchaCode.updateValueAndValidity();
    }
    this.refreshImage = true
    this.userService.getCaptchaCode(this.sessionId).subscribe(
      (response) => {
        this.previewVisible = true
        this.refreshImage = false
        this.picture = 'data:image/jpeg;base64,' + response.code 
        this.sessionId = response.id
        localStorage.setItem('sessionNumber',response.id)
      },
      (error) => {
    })
  }

  verifyFirstInput(emailOrNumber:string){
    const firstInput = JSON.parse(sessionStorage.getItem('firstInput'))
    const currentTime = new Date().getTime()
    if(!!firstInput && firstInput.emailOrNumber === emailOrNumber && (currentTime - firstInput.time < 300000)){
      return false
    }
    sessionStorage.setItem('firstInput',JSON.stringify({
      emailOrNumber: emailOrNumber,
      time: currentTime,
    }))
    return true
  }

  // TODO: Validator
  getCode(e?: MouseEvent): void {
    let formData;
    if (this.otpConfig.type === "Email") {
      formData = {
        language: this.i18nService.getActiveLang,
        userEmail: this.otpConfig.id,
        id: this.sessionId,
        code: this.captchaCode.value,
      };
    } else {
      formData = {
        language: this.i18nService.getActiveLang,
        countryCode: this.otpConfig.countryCode,
        userPhone: this.otpConfig.id,
        id: this.sessionId,
        code: this.captchaCode.value,
        type:this.otpConfig.otpType
      };
    }
    if(!this.captchaCode.value){
      this.captchaCode.markAsDirty();
      this.captchaCode.setErrors({
        required: true,
      });
      return
    }
    if(this.captchaCode.value.length < 6){
        this.captchaCode.markAsDirty();
        this.captchaCode.setErrors({
          notMatch: true,
        });
      return
    }

    this.getOtpCount++;
    if (this.getOtpCount > 10) {
      this.cancelPreview();
      this.verificationForm.controls.verCode.markAsDirty();
      this.verificationForm.controls.verCode.setErrors({ otpUnableGenerate: true });
      return;
    }
    this.isSubmittingOtp = true;
    this.userService.getVerificationCode(formData).subscribe(
      () => {
        this.isSubmittingOtp = false;
        this.cancelPreview();
        if (this.verificationForm.controls.verCode.hasError("otpTimeLimit")) {
          this.verificationForm.controls.verCode.markAsPristine();
          this.verificationForm.controls.verCode.updateValueAndValidity();
        }
        if (this.isFirstTimeGetOtp) {
          this.countdown.begin();
          this.isFirstTimeGetOtp = false;
        } else {
          this.countdown.restart();
          this.countdown.begin();
        }
      },
      (error) => {
        this.captchaVerify()
        // TODO: Change to constant bundle
        if (error.errorCode === "E1009") {
          this.modalService.error({
            nzTitle: "Email address has been registered",
            nzContent: "Please modify the email address.",
          });
          this.cancelPreview();
        }
        if (error.errorCode === "E1057") {
          this.verificationForm.controls.verCode.markAsDirty();
          this.verificationForm.controls.verCode.setErrors({
            otpTimeLimit: true,
          });
          this.cancelPreview();
        }
        if (error.errorCode === "E1097") {
          this.captchaCode.markAsDirty();
          this.captchaCode.setErrors({
            reOpenBrowser: true,
          });
        }
        if (error.errorCode === "E1098") {
          this.captchaCode.markAsDirty();
          this.captchaCode.setErrors({
            notMatch: true,
          });
        }
        if (error.errorCode === "E1099") {
          this.captchaCode.markAsDirty();
          this.captchaCode.setErrors({
            limitExceeded: true,
          });
        }
        this.isSubmittingOtp = false;
      }
    );
  }

  otpAsyncValidator = (control: AbstractControl) => {
    if (control && !control.pristine && control.value && control.value.trim() !== "") {
      let idForOtp;
      if (this.otpConfig.type === "Mobile") {
        idForOtp = this.otpConfig.countryCode.split("_")[0] + this.otpConfig.id;
      } else if (this.otpConfig.type === "Email") {
        idForOtp = this.otpConfig.id;
      }
      return this.userService.getVerificationCodeStatus(idForOtp, this.verificationForm.controls.verCode.value, this.otpConfig.type).pipe(
        map((isValid) => null),
        map((isValid) => this.timerToggle$.next(false)),
        catchError(() => of({ error: true, invalidOtp: true }))
      );
    } else {
      return of(null);
    }
  };

  handleCounterEvent(event: CountdownEvent) {
    if (event.action === "done") {
      this.timerToggle$.next(false);
      this.countdown.stop();
    } else if (event.action === "start" || event.action === "restart") {
      this.timerToggle$.next(true);
    }
  }

  cancelPreview(){
    this.previewVisible = false;
    this.captchaCode.reset()
    this.captchaCode.setValue(null)
    this.captchaCode.setValidators(null);
    this.captchaCode.updateValueAndValidity();
  }

  get verCode() {
    return this.verificationForm.get("verCode") as FormControl;
  }

  get captchaCode() {
    return this.verificationForm.get("captchaCode") as FormControl;
  }

  get verificationChannel() {
    return this.otpConfig.type;
  }
}
