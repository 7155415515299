import { Component, OnInit, HostListener, EventEmitter } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { find } from "lodash";
import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";
import { CommonService } from "../../core/services/common.service";

@Component({
  selector: "app-offers-page",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.scss"],
})
export class OffersComponent implements OnInit {
  carouselArray: PromotionCarouselConfig[];
  style = {
    width: "100%",
    "text-align": "center",
    "border-top": "1px solid #e8e8e8",
  };

  @HostListener("window:scroll", []) onWindowScroll() {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    // || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 170) {
      element.classList.add("tab-sticky");
    } else {
      element.classList.remove("tab-sticky");
    }
  }

  tabSelectedIndex = 0;

  joinNowConfig = {
    title: "ABOUT_PROGRAMME.PROGRAM_OVERVIEW.joinNow_title",
    description: "ABOUT_PROGRAMME.PROGRAM_OVERVIEW.joinNow_description",
  };

  highlightedOfferConfig = {
    offerCategory: "Rewards Store",
  };

  diningOfferConfig = {
    offerCategory: "Year-round Privileges",
  };

  retailOfferConfig = {
    offerCategory: "Monthly Travel Surprises",
  };

  leisureOfferConfig = {
    offerCategory: "Birthday Delights",
  };

  offerTypeIndexMapping = [
    // {
    //   type: 'monthly-travel-surprises',
    //   index: 0
    // },
    {
      type: 'rewards-choices',
      index: 0
    },
    {
      type: 'birthday-delights',
      index: 1
    },
    {
      type: 'year-round-privileges',
      index: 2
    }
  ];

  constructor(private router: Router, private route: ActivatedRoute, private commonService: CommonService) { }

  ngOnInit() {
    if (location.hash === "#rewards-choices") {
      this.tabSelectedIndex = 1;
    }
    const path = this.route.snapshot.data.localizeRouter.path;
    // Auto Switch Tabs
    this.tabSelectedIndex = find(this.offerTypeIndexMapping, ['type', path]).index;
  }

  onClickTabHeader(tabIndex: number) {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    // || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 170) {
      desktopContainer.scrollTop = 171;
      // mobileContainer.scrollTop = 81;
    }
    const path = find(this.offerTypeIndexMapping, ['index', tabIndex]).type;
    this.commonService.redirect(`/offers/${path}`);
  }
}
