import { environment } from '../../../../environments/environment';
import { FooterItem } from "../../../core";
import { PPUrlArr } from '../../constants';
export const firstFooterItem: FooterItem[] = [
  {
    name: "About Us",
    label: "COMMON.footer.aboutUs",
    link: "/about-programme",
    enable: true,
  },
  {
    name: "What's On",
    label: "COMMON.footer.whatsOn",
    link: "/campaigns",
    enable: true,
  },
  {
    name: "Privileges & Rewards",
    label: "COMMON.footer.privileges_n_rewards",
    link: "/offers",
    enable: true,
  },
  {
    name: "Spending Registration",
    label: "COMMON.footer.spendingReg",
    link: "/earn",
    enable: true,
  },
  {
    name: "HKairport Shop",
    label: "COMMON.footer.hkairportShop",
    externalLink: true,
    link: {
      en_US: `${ environment.URL_CONFIG.emp }/eshop_en/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=en_hk_Prospecting_AA-CRM-Portal`,
      zh_HK: `${ environment.URL_CONFIG.emp }/eshop_zh/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=zh_hk_Prospecting_AA-CRM-Portal`,
      zh_CN: `${ environment.URL_CONFIG.emp }/eshop_cn/?utm_source=AA-CRM-Portal&utm_medium=Fixed&utm_campaign=eShop-CRM2020&utm_content=cn_hk_Prospecting_AA-CRM-Portal`,
    },
    enable: true,
  },
  {
    name: "Shop & Dine",
    label: "COMMON.footer.shop_n_dine",
    externalLink: true,
    link: {
      en_US: "https://www.hongkongairport.com/en/shop-dine/highlights-promotions/",
      zh_HK: "https://www.hongkongairport.com/tc/shop-dine/highlights-promotions/",
      zh_CN: "https://www.hongkongairport.com/sc/shop-dine/highlights-promotions/",
    },
    enable: true,
  },
];

export const secondFooterItem: FooterItem[] = [
  {
    name: "Contact Us",
    label: "COMMON.footer.contact-us",
    link: "/contact-us",
    enable: true,
  },
  {
    name: "FAQ",
    label: "COMMON.footer.faq",
    link: "/faq",
    enable: true,
  },
  {
    name: "Terms and Conditions",
    label: "COMMON.footer.tnc",
    link: "/legal/terms-and-condition",
    enable: true,
  },
  {
    name: "Privacy Policy",
    label: "COMMON.footer.privatePolicy",
    externalLink: true,
    // link: "/legal/privacy-policyz", // MP Internal link
    link: PPUrlArr,
    enable: true,
  },
];

export const followUsIconList: FooterItem[] = [
  {
    name: "facebook",
    label: "COMMON.footer.facebook",
    link: {
      en_US: "https://www.facebook.com/",
      zh_HK: "https://www.facebook.com/",
      zh_CN: "https://www.facebook.com/",
    },
    enable: true,
    iconUrl: "facebook",
  },
  {
    name: "instagram",
    label: "COMMON.footer.instagram",
    link: {
      en_US: "https://www.facebook.com/",
      zh_HK: "https://www.facebook.com/",
      zh_CN: "https://www.facebook.com/",
    },
    enable: true,
    iconUrl: "",
  },
  {
    name: "youtube",
    label: "COMMON.footer.yotube",
    link: {
      en_US: "https://www.facebook.com/",
      zh_HK: "https://www.facebook.com/",
      zh_CN: "https://www.facebook.com/",
    },
    enable: true,
    iconUrl: "",
  },
  {
    name: "weibo",
    label: "COMMON.footer.weibo",
    link: {
      en_US: "https://www.facebook.com/",
      zh_HK: "https://www.facebook.com/",
      zh_CN: "https://www.facebook.com/",
    },
    enable: true,
    iconUrl: "",
  },
  {
    name: "twitter",
    label: "COMMON.footer.twitter",
    link: {
      en_US: "https://www.facebook.com/",
      zh_HK: "https://www.facebook.com/",
      zh_CN: "https://www.facebook.com/",
    },
    enable: true,
    iconUrl: "",
  },
];
