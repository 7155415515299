import { NgModule } from "@angular/core";
import { ProfileComponent } from "./profile.component";
import { SharedModule } from "../../shared";
import { ProfileRoutingModule } from "./profile-routing.module";
import { ProfileResolver } from "./profile-resolver.service";
import { UpdateProfileComponent } from "./update-profile/update-profile.component";
import { UpdateProfileResolver } from "./update-profile/update-profile-resolver.service";
import { RegistrationResolver } from "../account/registration/registration-resolver.service";
import { UpdateAraStatusComponent } from "./update-ara-status/update-ara-status.component";
import { UpdateAraStatusResolver } from "./update-ara-status/update-ara-status-resolver.service";
import { PointHistoryComponent } from "./point-history/point-history.component";
import { RedeemOfferComponent } from "./redeem-offer/redeem-offer.component";
import { MemberInfoComponent } from "./member-info/member-info.component";
import { AccCampaignCalculator } from "./acc-campaign-calculator/acc-campaign-calculator.component";
import { RedeemedOfferDetailComponent } from "./redeemed-offer-detail/redeemed-offer-detail.component";
import { NgOtpInputModule } from "ng-otp-input";
import { RedeemedOfferDetailResolver } from "./redeemed-offer-detail/redeemed-offer-detail-resolver.service";
import { PromocodeRedemptionComponent } from './promocode-redemption/promocode-redemption.component';
import { AssociatedMemberComponent } from './associated-member/associated-member.component';
import { CalculatorComponent } from "./hkaa-dollar/calculator/calculator.component";
import { TransactionHistoryComponent } from './hkaa-dollar/transaction-history/transaction-history.component';

@NgModule({
  imports: [SharedModule, ProfileRoutingModule, NgOtpInputModule],
  declarations: [
    ProfileComponent,
    UpdateProfileComponent,
    UpdateAraStatusComponent,
    PointHistoryComponent,
    RedeemOfferComponent,
    MemberInfoComponent,
    AccCampaignCalculator,
    RedeemedOfferDetailComponent,
    PromocodeRedemptionComponent,
    AssociatedMemberComponent,
    CalculatorComponent,
    TransactionHistoryComponent
  ],
  providers: [ProfileResolver, UpdateProfileResolver, UpdateAraStatusResolver, RegistrationResolver, RedeemedOfferDetailResolver],
  exports: [SharedModule, NgOtpInputModule],
})
export class ProfileModule {}
