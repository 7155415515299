import { Injectable, Injector } from "@angular/core";
import { environment } from "../../../environments/environment";
import { I18NService } from './i18n.service';
import { UserService } from './user.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api/api.service';
import { map } from "rxjs/operators";
import { CheckAgentStatusResults } from '../models';
import { CommonService } from './common.service';
import { Router } from "@angular/router";
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: "root",
})

export class LiveChatService {
  settings: any;
  surveyUrl: string;
  private isChatStartedSubject = new BehaviorSubject<boolean>(false);
  public isChatStarted = this.isChatStartedSubject.asObservable();
  private chatTypeSubject = new BehaviorSubject<"Member Portal Enquiry" | "Luxury Reserve Service">("Member Portal Enquiry");
  public chatTypeObservable = this.chatTypeSubject.asObservable();

  constructor(private injector: Injector, private router: Router) { }

  getAgentStatus(prefix: string, ids: string[], deploymentId: string, orgId: string): Observable<CheckAgentStatusResults[]> {
    const headers = {
      "Content-Type": "application/json",
      "X-LIVEAGENT-API-VERSION": "50",
    }
    const result = this.apiService
      .get(`/rest/Visitor/Availability?Availability.prefix=${prefix}&Availability.ids=${ids}&deployment_id=${deploymentId}&org_id=${orgId}`,
        "livechat", null, headers).pipe(map((data) => data.messages[0].message.results));
    return result;
  }

  getSurveyUrl(chatKey: string): Observable<string> {
    const result = this.apiService
      .get(`/survey/liveChatUrl/${chatKey}`,
        "loyalty", null, null).pipe(map((data) => data));
    return result;
  }

  getCurrentQueueNumber() {
    const result = this.apiService
      .get(`/livechat/currentQueueNumber`,
        "member", null, null).pipe(map((data) => data));
    return result;
  };

  getMaxQueueNumber() {
    const result = this.apiService
      .get(`/livechat/maxQueueNumber`,
        "member", null, null).pipe(map((data) => data));
    return result;
  };

  checkAgentStatus(dataResult: CheckAgentStatusResults[]) {
    const result = dataResult.filter(data => data.isAvailable);
    if (result.length > 0) {
      return true
    } else return false
  }

  redirect(chatType: "Member Portal Enquiry" | "Luxury Reserve Service") {
    this.commonService.redirect("/contact-us", null, { state: { type: chatType === "Luxury Reserve Service" ? chatType : "" } });
  }

  openChatbox(chatType: "Member Portal Enquiry" | "Luxury Reserve Service") {
    this.isChatStartedSubject.next(true);
    this.settings = {
      language: this.i18nService.getActiveLang, //need to be set
      channel: environment.LIVE_CHAT_CONFIG.channel, //need to be set
      membernumber: this.userService.getCurrentUser.memberNumber && this.userService.getCurrentUser.memberNumber !== null ? this.userService.getCurrentUser.memberNumber : "", //need to be set
      orgId: environment.LIVE_CHAT_CONFIG.orgId, //hardcode 
      deploymentId: environment.LIVE_CHAT_CONFIG.deploymentId, //hardcode 
      myDomainURL: environment.LIVE_CHAT_CONFIG.myDomainURL, //hardcode
      communityURL: environment.LIVE_CHAT_CONFIG.communityURL, //hardcode
      eswJSURL: environment.LIVE_CHAT_CONFIG.eswJSURL, //hardcode
      gslbBaseURL: environment.LIVE_CHAT_CONFIG.gslbBaseURL, //hardcode
      baseLiveAgentURL: environment.LIVE_CHAT_CONFIG.baseLiveAgentURL, //hardcode
      baseLiveAgentContentURL: environment.LIVE_CHAT_CONFIG.baseLiveAgentContentURL, //hardcode
      extraPrechatFormDetails: [
        { label: "Member Number", value: this.userService.getCurrentUser.memberNumber, displayToAgent: true },
        { label: "language", value: this.i18nService.getActiveLang, displayToAgent: true },
        { label: "Chat Type", value: chatType, displayToAgent: true, transcriptFields: ["Type__c"] },
        { label: "Chat Channel", value: environment.LIVE_CHAT_CONFIG.channel, displayToAgent: true, transcriptFields: ["Channel__c"] },
      ],
      buttonType: chatType,
      currentQueueNum: 0,
      maxQueueNum: 0,
    };

    this.getQueueNumber();

    if (!window["embedded_svc"]) {
      var s = document.createElement("script");
      s.setAttribute("src", this.settings.eswJSURL);
      s.setAttribute("sandbox", "allow-top-navigation allow-same-origin allow-forms allow-scripts");
      s.onload = () => {
        this.languageSwitch();
        this.initESW();
      };
      document.body.appendChild(s);
    } else {
      this.languageSwitch();
      window["embedded_svc"].settings.directToButtonRouting = function () {
        return this.settings.buttonId;
      };
      window["embedded_svc"].onHelpButtonClick();
    }
  }

  /* Use when needed to check agent availability */
  // initChat(chatType: "Member Portal Enquiry" | "Luxury Reserve Service", agentAvailable: boolean) {
  //   // Check agent status
  //   this.chatTypeSubject.next(chatType)
  //   if (agentAvailable) {
  //     this.openChatbox(chatType);
  //     this.isChatStartedSubject.next(true);
  //   }
  //   else {
  //     this.openChatbox(chatType);
  //     this.isChatStartedSubject.next(false);

  //     // Another method of redirecting to contact-us page
  //     // const path = this.localizeRouter.translateRoute("/contact-us/live-chat");
  //     // const redirectData = {
  //     //   displayItem: {
  //     //     title: "Sorry that we are unable to serve you right now.",
  //     //     subTitle: "Please leave your questions / feedback at Contact Us page, and we will get back to you as soon as possible.",
  //     //     icon: "contact_us_successful",
  //     //     buttonLabel: "Contact Us",
  //     //     actionType: "LINK",
  //     //     link: "/contact-us",
  //     //     linkRouterData: { state: { type: chatType === "Luxury Reserve Service" ? chatType : "" } }
  //     //   }
  //     // };
  //     // this.router.navigate([path], { state: redirectData });
  //   }
  // }

  languageSwitch() {
    /* use when have luxury type */
    // if (this.settings.buttonType == "Luxury Reserve Service") {
    //   console.log(environment.LIVE_CHAT_CONFIG.languageSetting.luxury[this.i18nService.getActiveLang].buttonId);
    //   this.settings.buttonId = environment.LIVE_CHAT_CONFIG.languageSetting.luxury[this.i18nService.getActiveLang].buttonId; //hardcode
    //   this.settings.eswLiveAgentDevName = environment.LIVE_CHAT_CONFIG.languageSetting.luxury[this.i18nService.getActiveLang].eswLiveAgentDevName; //hardcode
    //   this.settings.eswLiveAgentDeploymentName = environment.LIVE_CHAT_CONFIG.languageSetting.luxury[this.i18nService.getActiveLang].eswLiveAgentDeploymentName;
    // } else 
    if (this.settings.buttonType == "Member Portal Enquiry") {
      this.settings.buttonId = environment.LIVE_CHAT_CONFIG.languageSetting.general[this.i18nService.getActiveLang].buttonId; //hardcode
      this.settings.eswLiveAgentDevName = environment.LIVE_CHAT_CONFIG.languageSetting.general[this.i18nService.getActiveLang].eswLiveAgentDevName; //hardcode
      this.settings.eswLiveAgentDeploymentName = environment.LIVE_CHAT_CONFIG.languageSetting.general[this.i18nService.getActiveLang].eswLiveAgentDeploymentName;
    }
  }

  getQueueNumber() {
    this.getMaxQueueNumber().subscribe((result) => {
      this.settings.maxQueueNum = parseInt(result.maxQueueNumber);
    });
    this.getCurrentQueueNumber().subscribe((result) => {
      this.settings.currentQueueNum = parseInt(result.currentQueueNumber) + 1;
    });
  }

  showEndingMessage(msg) {
    var text = ("textContent" in document) ? "textContent" : "innerText";
    var msgParentNode = document.querySelector("body > div.modalContainer.sidebarMaximized.layout-docked.embeddedServiceSidebar > div > div > div.activeFeature.hideWhileLoading > div > div > div.messageArea > ul > li:nth-last-child(1)");
    var newMsgNode = document.querySelector("body > div.modalContainer.sidebarMaximized.layout-docked.embeddedServiceSidebar > div > div > div.activeFeature.hideWhileLoading > div > div > div.messageArea > ul > li:nth-last-child(1) > div.chatContent").cloneNode(true);
    newMsgNode.firstChild.firstChild[text] = msg;
    msgParentNode.append(newMsgNode);
  }

  initESW() {
    const self: any = this;
    const embedded_svc = window["embedded_svc"];
    embedded_svc.settings.buttonId = this.settings.buttonId;
    embedded_svc.settings.displayHelpButton = false;
    embedded_svc.settings.language = this.i18nService.getActiveLang;
    embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    embedded_svc.settings.entryFeature = "LiveAgent";
    embedded_svc.settings.extraPrechatFormDetails = this.settings.extraPrechatFormDetails;
    embedded_svc.settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            fieldName: "Member_Number__c",
            isExactMatch: true,
            label: "Member Number",
          },
        ],
        entityName: "Account",
        saveToTranscript: "AccountId",
      },
    ],

    embedded_svc.addEventHandler("featureLoaded", function () {
      embedded_svc.onHelpButtonClick();
    });

    embedded_svc.addEventHandler("onChatEstablished", function (data) {
      // call api to get the survey url
      self.getSurveyUrl(data.liveAgentSessionKey).subscribe((data) => self.surveyUrl = data);
    });

    // embedded_svc.addEventHandler("onSettingsCallCompleted", function (data) {
    //   console.log("onSettingsCallCompleted event was fired. Agent availability status is " + data.isAgentAvailable ? "online" : "offline");
    //   if (!data.isAgentAvailable || data.isAgentAvailable == "false") {
    //     (document.styleSheets[2] as CSSStyleSheet).addRule('#HKAA-APP > div.modalContainer.sidebarMaximized.layout-docked.embeddedServiceSidebar > div > div > div.activeFeature.hideWhileLoading > div > div > div > div.dialogButtonContainer > button.dialogButton.dialog-button-0.uiButton.embeddedServiceSidebarButton', 'display: none');
    //   }
    // });

    embedded_svc.addEventHandler("afterDestroy", function (data) {
      self.isChatStartedSubject.next(false);

      var targetBtn = (event.target as HTMLButtonElement).innerText;
      if (targetBtn == 'Leave a Message' || targetBtn == '提交查詢' || targetBtn == '提交查询') {
        window.open(`${environment.baseUrl}/${self.i18nService.getActiveLang}/contact-us`, "_blank");
      }

      if ((targetBtn == 'CLOSE CHAT' || targetBtn == '关闭' || targetBtn == '關閉') && self.surveyUrl && self.surveyUrl !== null) {
        let url = self.surveyUrl;
        var lan = self.i18nService.getActiveLang.replace("_", "-");
        url += "&guestUserLang=" + lan;
        window.open(url, "_blank");
      }
    });

    embedded_svc.addEventHandler("onQueueUpdate", function (data) {            
      if (self.settings.maxQueueNum < self.settings.currentQueueNum) {

        if (document.getElementById("lc-contact-btn")) return;

        var btn = document.createElement("button");
        btn.className = "dialogButton dialog-button-0 uiButton embeddedServiceSidebarButton";
        btn.id = "lc-contact-btn";
        switch (self.i18nService.getActiveLang) {
          case "zh_CN":
            btn.innerText = '提交查询';
            break;
          case "zh_HK":
            btn.innerText = '提交查詢';
            break;
          default:
            btn.innerText = 'Leave a Message';
        }
        btn.onclick = function () {
          window.open(`${environment.baseUrl}/${self.i18nService.getActiveLang}/contact-us`, "_blank");
        }
        document.querySelector("body > div.modalContainer.sidebarMaximized.layout-docked.embeddedServiceSidebar > div > div > div.activeFeature.hideWhileLoading > div > div > div > div.waitingStateButtonContainer").appendChild(btn);
      }

    });

    // embedded_svc.addEventHandler("onChatConferenceInitiated", function (data) {
    //   console.log("onChatConferenceInitiated event was fired.  liveAgentSessionKey was " + data.liveAgentSessionKey);
    // });

    embedded_svc.addEventHandler("onIdleTimeoutOccurred", function (data) {
      // console.log("onIdleTimeoutClear event was fired.  liveAgentSessionKey was " + data.liveAgentSessionKey);
      switch (self.i18nService.getActiveLang) {
        case "zh_CN":
          self.showEndingMessage("是次对话已结束。如需任何协助，欢迎随时再与我们联络。");
          break;
        case "zh_HK":
          self.showEndingMessage("是次對話已結束。如需任何協助，歡迎隨時再與我們聯絡。");
          break;
        case "en_US":
          self.showEndingMessage("This chat has been ended. Feel free to contact us again if you need any help.");
          break;
        default:
          self.showEndingMessage("This chat has been ended. Feel free to contact us again if you need any help.");
      }
    });

    embedded_svc.init(this.settings.myDomainURL, this.settings.communityURL, this.settings.gslbBaseURL, this.settings.orgId, this.settings.eswLiveAgentDeploymentName, {
      baseLiveAgentContentURL: this.settings.baseLiveAgentContentURL,
      deploymentId: this.settings.deploymentId,
      buttonId: this.settings.buttonId,
      baseLiveAgentURL: this.settings.baseLiveAgentURL,
      eswLiveAgentDevName: this.settings.eswLiveAgentDevName,
      isOfflineSupportEnabled: false,
    });
  }

  get i18nService(): I18NService {
    return this.injector.get(I18NService);
  }

  get userService(): UserService {
    return this.injector.get(UserService);
  }

  get apiService(): ApiService {
    return this.injector.get(ApiService);
  }

  get commonService(): CommonService {
    return this.injector.get(CommonService);
  }

  get localizeRouter(): LocalizeRouterService {
    return this.injector.get(LocalizeRouterService)
  }
}
