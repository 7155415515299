import { HttpClient } from "@angular/common/http";
import { I18NService } from "./i18n.service";
import { Injectable } from "@angular/core";
import { NzIconService } from "ng-zorro-antd";
import { TranslateService } from "@ngx-translate/core";
import { catchError } from "rxjs/operators";
import { zip } from "rxjs";

@Injectable()
export class StartupService {
  constructor(iconSrv: NzIconService, private translate: TranslateService, private i18nService: I18NService, private httpClient: HttpClient) {
    // iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }

  load(): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    // https://github.com/ngx-translate/core/issues/754
    return new Promise((resolve) => {
      zip(this.httpClient.get(`assets/i18n/${this.i18nService.defaultLang}.json`))
        .pipe(
          catchError(([langData, appData]) => {
            resolve(null);
            return [langData, appData];
          })
        )
        .subscribe(
          ([langData, appData]) => {
            this.translate.setTranslation(this.i18nService.defaultLang, langData);
            this.translate.setDefaultLang(this.i18nService.defaultLang);
          },
          () => {},
          () => {
            resolve(null);
          }
        );
    });
  }
}
