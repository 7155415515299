import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { map } from "rxjs/operators";
import { ApiService } from "./api/api.service";
import { GoogleWalletResponse } from "../models";

import { DomSanitizer } from '@angular/platform-browser';
import {I18NService} from "./i18n.service";

@Injectable()
export class WalletService {
  constructor(private apiService: ApiService, private i18nService: I18NService, private sanitizer: DomSanitizer) {}

  getGoogleWallet(): Observable<GoogleWalletResponse> {
    return this.apiService.get(`/member/googleWalletPass?lang=${this.i18nService.getActiveLang}`, "member", null).pipe(
      map((res) => res)
    );  
  }

  getAppleWallet(): Observable<Blob> {
    return this.apiService.get(`/member/appleWalletPass?lang=${this.i18nService.getActiveLang}`, "member", null, {
      Accept: "application/vnd.apple.pkpass"
    }, "arraybuffer").pipe(
      map((res) => new Blob([new Uint8Array(res)], { type: "application/vnd.apple.pkpass" }))
    )
  }
}
