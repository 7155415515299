import { Component, OnInit, HostListener } from "@angular/core";
import { I18NService } from "../../core";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-profile-page",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  style = { width: "100%", "text-align": "center", "font-size": "18px", "border-top": "1px solid rgb(232, 232, 232)" };
  tabSelectedIndex = 0;

  @HostListener("window:scroll", []) onWindowScroll() {
    const desktopContainer = document.scrollingElement || document.documentElement;
    // const mobileContainer = document.getElementById("app-container");
    const element = document.getElementsByClassName("ant-tabs-top-bar")[0];
    //  || mobileContainer.scrollTop > 80
    if (desktopContainer.scrollTop > 170) {
      element.classList.add("tab-sticky");
    } else {
      element.classList.remove("tab-sticky");
    }
  }
  hkiaDollarVersion: string = "v2";
  constructor(private i18nService: I18NService, private route: ActivatedRoute) {
    if (this.route.snapshot.firstChild) {
      const version = this.route.snapshot.firstChild.queryParams.version || sessionStorage.getItem("hkia-dollar-memberInfo-version");
      if (version) {
        this.hkiaDollarVersion = version;
      }
      sessionStorage.setItem("hkia-dollar-memberInfo-version", 'v2');
    }
  }

  ngOnInit() {}

  getCurrentLang() {
    return this.i18nService.getActiveLang;
  }
}
