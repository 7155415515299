import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: "root" })
export class ModalService {
    private showTnCModalSubject = new BehaviorSubject<boolean>(false);
    public showTnCModal = this.showTnCModalSubject.asObservable();

    private showPPModalSubject = new BehaviorSubject<boolean>(false);
    public showPPModal = this.showPPModalSubject.asObservable();

    private showContactUsModalSubject = new BehaviorSubject<boolean>(false);
    public showContactUsModal = this.showContactUsModalSubject.asObservable();

    constructor() { }

    showModal(modalType: "TC" | "CU"): void {
        if (modalType === "TC") {
            this.setTnCModal(true);
        } else if (modalType === "CU") {
            this.setContactUsModal(true);
        };
    };

    handleCancel(modalType: "TC" | "CU"): void {
        if (modalType === "TC") {
            this.setTnCModal(false);
        } else if (modalType === "CU") {
            this.setContactUsModal(false);
        };
    };

    setTnCModal(bool: boolean) {
        this.showTnCModalSubject.next(bool);
    };

    setContactUsModal(bool: boolean) {
        this.showContactUsModalSubject.next(bool);
    };
}
