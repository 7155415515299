import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { I18NService, UserService, User, ProfilesService, RemoteConfigService } from "../../../../core";
import { interval, Subscription, Observable, EMPTY, timer, of } from "rxjs";
import { switchMap, takeWhile, mergeMap, takeUntil, tap, finalize, catchError, take } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";

@Component({
  selector: "app-transaction-history-page",
  templateUrl: "./transaction-history.component.html",
  styleUrls: ["./transaction-history.component.scss"],
})
export class TransactionHistoryComponent implements OnInit, OnDestroy {
  user: User = {} as User;
  historyData: any = [];
  historyList: any = [];
  langId = 0;
  shopList: any = [];
  loading: boolean = false;
  page = 1;
  size = 10;
  totalElement = 0;
  isError: boolean = false;
  private subscription: Subscription;
  constructor(
    private router: Router,
    private userServer: UserService,
    private i18nService: I18NService,
    private userService: UserService,
    private profileService: ProfilesService,
    private remoteConfigService: RemoteConfigService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      if (lang === "en_US") {
        this.langId = 0;
      } else if (lang === "zh_HK") {
        this.langId = 1;
      } else if (lang === "zh_CN") {
        this.langId = 2;
      }
      this.getShopList();
      this.getHistory();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getHistory() {
    this.loading = true;
    Object.assign(this.user, this.userService.getCurrentUser);
    const memberId = this.user.memberNumber;
    this.profileService.getPointHistory(this.page, 1000, memberId).subscribe(
      (history: any) => {
        this.loading = false;
        this.isError = false;
        const { content, totalElements } = history;
        this.historyData = this.handleHistoryData(content);
        this.historyList = this.getPageData();
        this.totalElement = totalElements;
      },
      (error) => {
        this.loading = false;
        this.isError = true;
      }
    );
  }

  getPageData() {
    const startIndex = (this.page - 1) * this.size;
    let endIndex = startIndex + this.size;

    // 如果结束索引超过数据长度，则调整结束索引
    if (endIndex > this.historyData.length) {
      endIndex = this.historyData.length;
    }

    return this.historyData.slice(startIndex, endIndex);
  }

  pageChange(event) {
    this.page = event;
    // this.getHistory();
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.historyList = this.getPageData();
    }, 500);
  }

  getShopList() {
    this.remoteConfigService.getShopList().subscribe(
      (data: Array<any>) => {
        this.shopList = data[this.i18nService.getActiveLang];
      },
      (error) => {
        this.shopList = [];
      }
    );
  }

  handleHistoryData(historyData: any) {
    const data = _.cloneDeepWith(historyData);
    data.forEach((record) => {
      record.label = this.mappingLabel(record);
      record.icon = this.mappingIconTemp(record);
      if (record.shop) {
        record.shop = _.get(_.find(this.shopList, { value: record.shop }), "label", record.shop);
      }
    });
    return data;
  }

  mappingIconTemp(record) {
    const type = record.modification_type;
    const pointSourceType = record.point_source_name.split(";")[0];
    const mappingType = {
      Refund: "Refund",
      "HKIADollar.Refund": "Refund",
      Burn: "Burn",
      "Spending Rewards": "Spending",
      Behavior: "Spending",
      Spending: "Spending",
      "Bonus Rewards": "Spending",
      ACCUMULATED_CAMPAIGN_Outcomes: "Spending",
      Adjustment: "Adjustment",
      "Points Adjustment": "Adjustment",
      "Manual Point Adjustment(Deduct)": "Adjustment",
      "Manual Point Adjustment (Add)": "Adjustment",
      Redemption: "Redemption",
      redemption: "Redemption",
      "Offer Redemption": "Redemption",
      "Point expiry": "Pointexpired",
      "HKIADollar.Consume": "Dollar",
    };

    if (mappingType[type]) {
      return mappingType[type];
    }

    if (mappingType[pointSourceType]) {
      return mappingType[pointSourceType];
    }

    return "";
  }

  mappingLabel(record): string {
    const activityMapping = {
      LEGACY_STORE: "profile.transactionHistory.label.pointRedeemOffer",
      Unattributed: "profile.transactionHistory.label.membershipPoint",
      "Manual Point Adjustment(Deduct)": "profile.transactionHistory.label.point_adjustment",
      "Manual Point Adjustment (Add)": "profile.transactionHistory.label.point_adjustment",
      "Point Expiration": "profile.transactionHistory.label.pointExpiration",
      "Offer Redemption": "profile.transactionHistory.label.offerRedemption",
      "Point Transfer": "profile.transactionHistory.label.pointTransfer",
      "Point expiry": "profile.transactionHistory.label.pointExpiry",
      Refund: "profile.transactionHistory.label.refund",
      "HKIADollar.Refund": "profile.transactionHistory.label.refund",
      "HKIADollar.Consume": "profile.transactionHistory.label.dollarConsume",
      // "Bonus Rewards": "profile.transactionHistory.label.bonusRewards",
      // "ACCUMULATED_CAMPAIGN_Outcomes": "profile.transactionHistory.label.bonusRewards"
    };

    return _.get(activityMapping, record.modification_type, record.point_source_name.includes(";") ? record.point_source_name.split(";")[this.langId] : record.point_source_name);
  }
}
