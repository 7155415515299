import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { isEmpty } from "lodash";
import { UserService } from "../core";
import { take, takeLast } from "rxjs/operators";

@Directive({ selector: "[appShowAuthed]" })
export class ShowAuthedDirective implements OnInit {
  condition = false;

  constructor(private templateRef: TemplateRef<any>, private userService: UserService, private viewContainer: ViewContainerRef) { }

  ngOnInit() {
    this.reloadView();
  }

  reloadView() {
    this.userService.isAuthenticated.subscribe(isAuthenticated => {
        if ((isAuthenticated && this.condition) || (!isAuthenticated && !this.condition)) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  @Input() set appShowAuthed(condition: boolean) {
    this.condition = condition;
  }
}
