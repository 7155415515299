import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CampaignsComponent } from "./campaigns.component";
import { CampaignsResolver } from "./campaigns-resolver.service";
import { CampaignDetailComponent } from "./campaign-detail/campaign-detail.component";

const routes: Routes = [
  {
    path: "",
    component: CampaignsComponent,
    data: {
      title: {
        en_US: "What's On | HKairport Rewards",
        zh_HK: "最新推介 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "最新推介 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `What's On | HKairport Rewards`,
          },
          {
            name: "description",
            content: `Get the latest news and promotions on member-exclusive offers at Hong Kong International Airport.`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `最新推介 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
          {
            name: "description",
            content: `掌握香港國際機場的最新資訊及會員尊享禮遇。`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `最新推介 | HKairport Rewards 香港国际机场奖赏计划`,
          },
          {
            name: "description",
            content: `掌握香港国际机场的最新资讯及会员尊享礼遇。`,
          },
        ],
      },
    },
  },
  {
    path: ":campaignId",
    component: CampaignDetailComponent,
    resolve: {
      campaign: CampaignsResolver,
    },
    data:{
      title: {
        en_US: "What's On | HKairport Rewards",
        zh_HK: "最新推介 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "最新推介 | HKairport Rewards 香港国际机场奖赏计划",
      },
      backRoute: "/campaigns", 
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignsRoutingModule {}
