import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "underscore";
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-faq-page",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  faqList = [];
  isShownFaqBtn: boolean;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data: { faqList: any[] }) => {
      if (data.faqList) {
        this.faqList = _.chain(data.faqList)
          .sortBy("Section_Order__c")
          .sortBy("Item_Order__c")
          .groupBy("Section__c")
          .map(function (value, key) {
            return {
              type: key,
              items: value,
            };
          })
          .value();
      }
    });
    this.isShownFaqBtn = !environment.LIVE_CHAT_CONFIG.isLiveChatActivated;
  }
}
