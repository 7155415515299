import * as _ from "underscore";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ProfilesService, I18NService, RemoteConfigService } from "../../../core";
import { STColumn, STPage, STChange } from "@delon/abc";
import { Subscription } from "rxjs";

@Component({
  selector: "app-point-history",
  templateUrl: "./point-history.component.html",
  styleUrls: ["./point-history.component.scss"],
})
// This page has been replaced with the hkaa-dollar/transaction-history implementation.
// please check hkaa-dollar/transaction-history
export class PointHistoryComponent implements OnInit, OnDestroy {
  // TODO: List Data Model
  pointHistoryData: any;
  loading: boolean;

  columns: STColumn[] = [];

  shopList = [];
  totalRecords = 0;
  pageConfig: STPage = {
    front: true,
    placement: "center",
  };

  langId = 0;

  private subscription: Subscription;

  constructor(private profileService: ProfilesService, private i18nService: I18NService, private remoteConfigService: RemoteConfigService) {
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      if (lang === "en_US") {
        this.langId = 0;
      } else if (lang === "zh_HK") {
        this.langId = 1;
      } else if (lang === "zh_CN") {
        this.langId = 2;
      }
      this.initColumns();
      this.getShopList();
      this.getPointHistory();
    });
  }

  ngOnInit() {
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      if (lang === "en_US") {
        this.langId = 0;
      } else if (lang === "zh_HK") {
        this.langId = 1;
      } else if (lang === "zh_CN") {
        this.langId = 2;
      }
      this.initColumns();
      this.getShopList();
      this.getPointHistory();
    });
  }

  getPointHistory(page: number = 1) {
    // Get Point History from SessionM
    this.loading = true;
    this.profileService.getPointHistory(page).subscribe(
      (records) => {
        this.pointHistoryData = this.transformActivity(records.content);
        this.totalRecords = records.totalElements;
        this.loading = false;
      },
      (error) => {
        this.pointHistoryData = [];
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Init Data table
   */
  initColumns() {
    this.columns = [
      {
        title: this.i18nService.translateLabel("profile.pointHistory.date"),
        render: "custom_date",
      },
      {
        title: this.i18nService.translateLabel("profile.pointHistory.activity"),
        render: "custom_activity",
      },
      {
        title: this.i18nService.translateLabel("COMMON.LABEL.POINTS"),
        render: "custom_points",
      },
      {
        title: this.i18nService.translateLabel("COMMON.LABEL.SHOP"),
        index: "shop",
      },
      {
        title: this.i18nService.translateLabel("COMMON.LABEL.AMOUNT"),
        render: "custom_spendAmount",
      },
    ];
  }

  /**
   * Get shoplist for translation
   */
  getShopList() {
    this.remoteConfigService.getShopList().subscribe(
      (data: Array<any>) => {
        this.shopList = data[this.i18nService.getActiveLang];
      },
      (error) => {
        this.shopList = [];
      }
    );
  }

  /**
   * Transform SessionM's activity name to label
   * @param records Activity Data
   */
  transformActivity(records) {
    // TODO: Group all constants
    return _.each(records, (record: any) => {
      if (record.shop) {
        record.shop = _.first(_.where(this.shopList, { value: record.shop })) ? _.first(_.where(this.shopList, { value: record.shop }))["label"] : record.shop;
      }
      switch (record.modification_type) {
        case "LEGACY_STORE":
          record.activity = "profile.pointHistory.label.pointRedeemOffer";
          break;
        case "Unattributed":
          record.activity = "profile.pointHistory.label.membershipPoint";
          break;
        case "Manual Point Adjustment(Deduct)":
          record.activity = "profile.pointHistory.label.point_adjustment";
          break;
        case "Manual Point Adjustment (Add)":
          record.activity = "profile.pointHistory.label.point_adjustment";
          break;
        case "Point Expiration":
          record.activity = "profile.pointHistory.label.pointExpiration";
          break;
        case "Offer Redemption":
          record.activity = "profile.pointHistory.label.offerRedemption";
          break;
        case "Point Transfer":
          record.activity = "profile.pointHistory.label.pointTransfer";
          break;
        case "Refund":
          record.activity = "profile.pointHistory.label.refund";
          break;
        default: {
          record.activity = record.point_source_name.indexOf(";") !== -1 ? record.point_source_name.split(";")[this.langId] : record.point_source_name;
        }
      }
    });
  }

  tableChange(event: STChange) {
    if (event.type === "pi") {
      this.getPointHistory(event.pi);
    }
  }
}
