import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { FormService, ResetPassword, UserService, emailValidator, phoneValidator, passwordValidator, ApiError, ErrorService, I18NService, CommonService, OtpConfig, RemoteConfigService } from "../../../core";
import { Subscription } from "rxjs";
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { OtpBoxComponent } from "../../../shared";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @ViewChild("otpBox", { static: false }) public otpBox: OtpBoxComponent;

  forgotPasswordForm: FormGroup;
  private subscription: Subscription;
  securityQuestionForm: FormGroup;
  changePasswordForm: FormGroup;

  isSubmitting = false;
  isSubmittingSecurityForm = false;
  isSubmittingSecurityForm2 = false;

  securityState = "show-security-question";

  // Dropdown items

  userProfile: any;
  securityQuestion: any;
  currentEmail: string;
  isShowEmailResult = false;
  uiState = "form";
  abc: ApiError;
  // TODO:
  _userId = "";
  _token = "";
  // securityQuestionList = [];
  // securityQuestionListInLang = [];
  countryCodeList = [];
  allowDirectReset = false;

  redirectPlatformLink:string;

  hotline = "+85221835111";

  isTrimmedAns: boolean;
  contactUs: string;

  previewVisible = false;
  refreshImage = false;
  sessionId = "";
  picture = "";
  getOtpCount = 0;
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private formService: FormService,
    private i18nService: I18NService,
    private errorService: ErrorService,
    private router: Router,
    private commonService: CommonService,
    private localizeRouter: LocalizeRouterService,
    private remoteConfigService: RemoteConfigService
  ) {
    const userid = this.route.snapshot.queryParams["userid"];
    const token = this.route.snapshot.queryParams["token"];
    if (userid && token) {
      this._userId = userid;
      this._token = token;
      this.uiState = "change-password";
    }
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      // if (this.uiState === "security-check") {
      //   if (!this.allowDirectReset) {
      //     const questionRaw = this.securityQuestionForm.controls.securityQuestion.value.value;
      //     const questinContent = _.first(_.where(this.securityQuestionList[lang], { value: questionRaw })) as any;
      //     this.securityQuestionForm.controls.securityQuestion.setValue({
      //       value: questionRaw,
      //       label: questinContent.label,
      //     });
      //   }
      // }
      this.route.data.subscribe((data: { listData: any[] }) => {
        this.contactUs = this.localizeRouter.translateRoute("/contact-us") as string;
        this.countryCodeList = data.listData[1][this.i18nService.getActiveLang];          
      });
    });

    if (window.location.pathname.indexOf('mobile') !== -1){
      this.commonService.setShowHeaderFooter(false);
    }

    this.route.queryParams.subscribe(async (value) => {
      if (value["cb"]){
        this.redirectPlatformLink =  decodeURI(value["cb"]);
      }
    })
  }

  ngOnInit() {
    this.initFrom();
    this.initSecurityForm();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onOtpChanged(isValid: boolean) {
    if(this.verificationChannel.value == 'Email'){
      return
    }
    if(!this.userId.value){
      this.userId.setErrors({required:true});
      return
    }
    if(!this.verifyFirstInput(this.userId.value)){
      this.userId.setErrors({cannotGetCaptcha:true});
    }
  }

  verifyFirstInput(emailOrNumber:string){
    const firstInput = JSON.parse(sessionStorage.getItem('firstInput'))
    const currentTime = new Date().getTime()
    if(!!firstInput && firstInput.emailOrNumber === emailOrNumber && (currentTime - firstInput.time < 300000)){
      this.isSubmitting = false;
      return false
    }
    sessionStorage.setItem('firstInput',JSON.stringify({
      emailOrNumber: emailOrNumber,
      time: currentTime,
    }))
    return true
  }

  resetPasswordByPhone() {
    const phone = this.forgotPasswordForm.controls.userId.value;
    this.securityQuestionForm.controls.phone.setValue(phone);
    this.securityQuestionForm.controls.verCode.setValue(this.otpBox.verificationForm.get("verCode").value); 
    this.securityQuestionForm.controls.phoneNumberPrefix.setValue(this.phoneNumberPrefix.value);  
    this.uiState = "security-check";
    this.securityState = "reset-password-byphone";
    // this.userService.getSecurityQuestionByPhone(phone).subscribe(
    //   (response) => {
    //     this.initSecurityForm();
    //     this.securityQuestionForm.controls.phone.setValue(phone);
    //     this.allowDirectReset = response.allowDirectReset;
    //     if (!response.allowDirectReset && response.result) {
    //       const questionRaw = response.result.replace(/["]+/g, "");
    //       this.securityQuestionForm.controls.securityQuestion.setValue({
    //         value: questionRaw,
    //         label: _.first(
    //           _.where(this.securityQuestionList[this.i18nService.getActiveLang], {
    //             value: questionRaw,
    //           })
    //         )["label"],
    //       });
    //     } else if (!response.allowDirectReset && !response.result) {
    //       this.isSubmitting = false;
    //       this.forgotPasswordForm.controls.userId.setErrors({
    //         emptySecurityQA: true,
    //       });
    //       return;
    //     }
    //   },
    //   (error: ApiError) => {
    //     this.isSubmitting = false;
    //     // TODO: Handle different error code
    //     if (error.errorCode === "E1024") {
    //       this.forgotPasswordForm.controls.userId.setErrors({
    //         notVerified: true,
    //       });
    //     } else if (error.errorCode === "E1085") {
    //       this.forgotPasswordForm.controls.userId.setErrors({
    //         verifyFailed: true,
    //       });
    //     } else if (error.errorCode === "E1032") {
    //       this.forgotPasswordForm.controls.userId.setErrors({
    //         inactiveAccount: true,
    //       });
    //     }  else {
    //       this.forgotPasswordForm.controls.userId.setErrors({
    //         recordNotFound_Phone: true,
    //       });
    //     }
    //   }
    // );
  }

  transformChangePasswordByEmail(): ResetPassword {
    const requestBody = {
      newPassword: this.forgotPasswordForm.controls.password.value.trim(),
      memberNumber: this.userProfile.memberNumber,
    };
    return _.pick(requestBody, _.identity);
  }

  initSecurityForm() {
    this.securityQuestionForm = this.fb.group(
      {
        phone: [null, [Validators.required]],
        securityQuestion: [null],
        securityAnswer: [null],
        password: [null, [Validators.required, passwordValidator]],
        confirmPassword: [null, [Validators.required, this.passwordConfirmationValidator]],
        phoneNumberPrefix: ["852_Hong Kong (+852)", []],
        verCode: [null],
      },
      { updateOn: "blur" }
    );
  }

  initChangePasswordForm() {
    this.changePasswordForm = this.fb.group(
      {
        phone: [null, [Validators.required]],
        securityQuestion: [null],
        securityAnswer: [null],
        password: [null, [Validators.required, passwordValidator]],
        confirmPassword: [null, [Validators.required, this.passwordConfirmationValidator]],
      },
      { updateOn: "blur" }
    );
  }

  transformChangePasswordBySecurityQuestion(): ResetPassword {
    const requestBody = {
      phone: this.securityQuestionForm.controls.phone.value,
      securityAnswer: this.securityQuestionForm.controls.securityAnswer.value,
    };
    return _.pick(requestBody, _.identity);
  }

  transformChangePasswordByPhone(): ResetPassword {
    const requestBody = {
      newPassword: this.securityQuestionForm.controls.password.value.trim(),
      phone: this.securityQuestionForm.controls.phone.value,
      securityAnswer: this.securityQuestionForm.controls.securityAnswer.value,
      phoneVerCode: this.securityQuestionForm.controls.verCode.value,
      userPhone: this.securityQuestionForm.controls.phoneNumberPrefix.value.split("_")[0] + this.securityQuestionForm.controls.phone.value,
      countryCode: this.securityQuestionForm.controls.phoneNumberPrefix.value,
    };
    return _.pick(requestBody, _.identity);
  }

  resetPasswordByEmail() {
    const email = this.forgotPasswordForm.controls.userId.value;
    this.userService.resetPasswordEmail(email).subscribe(
      (response) => {
        this.currentEmail = email;
        const successPageData = {
          displayItem: {
            title: "auth.FORGOT_PASSOWRD.SUCCESSFUL_MESSAGE",
            subTitle: "auth.FORGOT_PASSOWRD.SUCCESSFUL_MESSAGE_SUBTITLE",
            subTitleData: { email: email },
            icon: "password_recovery_email",
            buttonLabel: "auth.LABEL.BACK_TO_LOGIN",
            actionType: this.redirectPlatformLink ? "REPLACE" : "LOGIN",
            link: this.redirectPlatformLink ? this.redirectPlatformLink : ""
          },
        };
        this.commonService.redirect("/account/forgot-password/success", null, { state: successPageData });
      },
      (error) => {
        this.isSubmitting = false;
        console.log("error", error);
        if (error.errorCode === "E1027") {
          this.forgotPasswordForm.controls.userId.setErrors({
            recordNotFound_Email: true,
          });
        } else if (error.errorCode === "E1043") {
          this.forgotPasswordForm.controls.userId.setErrors({
            notAllowResetPasswordSocialLogin: true,
          });
        } else if (error.errorCode === "E1031") {
          this.forgotPasswordForm.controls.userId.setErrors({
            notVerified: true,
          });
        } else if (error.errorCode === "E1085") {
          this.forgotPasswordForm.controls.userId.setErrors({
            verifyFailed: true,
          });
        } else if (error.errorCode === "E1032") {
          this.forgotPasswordForm.controls.userId.setErrors({
            inactiveAccount: true,
          });
        } 
      }
    );
  }

  // verifySecurityQuestion() {
  //   this.isSubmittingSecurityForm = true;
  //   if (this.allowDirectReset) {
  //     const formData = {
  //       phone: this.securityQuestionForm.controls.phone.value,
  //       securityQuestion: this.securityQuestionForm.controls.securityQuestion.value,
  //       securityAnswer: this.securityQuestionForm.controls.securityAnswer.value,
  //     };
  //     this.userService.resetSecurityQuestion(formData).subscribe(
  //       (response) => {
  //         this.securityState = "reset-password-byphone";
  //       },
  //       (error: ApiError) => {
  //         this.isSubmittingSecurityForm = false;
  //         if (error.errorCode === "E1020") {
  //           this.securityQuestionForm.controls.securityAnswer.setErrors({
  //             incorrectAnswer: true,
  //           });
  //         } else {
  //           this.errorService.redirectToErrorPage(error);
  //         }
  //       }
  //     );
  //   } else {
  //     const formData = this.transformChangePasswordBySecurityQuestion();
  //     this.userService.verifySecurityAnswer(formData).subscribe(
  //       (response) => {
  //         this.securityState = "reset-password-byphone";
  //       },
  //       (error: ApiError) => {
  //         this.isSubmittingSecurityForm = false;
  //         if (error.errorCode === "E1020") {
  //           this.securityQuestionForm.controls.securityAnswer.setErrors({
  //             incorrectAnswer: true,
  //           });
  //         } else if (error.errorCode === "E1019") {
  //           this.securityQuestionForm.controls.securityAnswer.setErrors({
  //             reachAttemptLimit: true,
  //           });
  //         } else {
  //           this.errorService.redirectToErrorPage(error);
  //         }
  //       }
  //     );
  //   }
  // }

  submitResetPasswordbyPhone() {
    if (!this.securityQuestionForm.valid) {
      for (const i in this.securityQuestionForm.controls) {
        this.securityQuestionForm.controls[i].markAsDirty();
        this.securityQuestionForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
      return;
    }
    const formData = this.transformChangePasswordByPhone();
    this.isSubmittingSecurityForm2 = true;
    if (this.allowDirectReset) {
      this.userService.changePasswordBySecurityQuestion(formData).subscribe(
        (response) => {
          const successPageData = {
            displayItem: {
              title: "auth.MESSAGE.SUCCESSFULLY_RESET_PASSWORD",
              subTitle: "auth.MESSAGE.SUCCESSFULLY_RESET_PASSWORD_SUBTITLE",
              icon: "password_changed",
              buttonLabel: "COMMON.LABEL.LOGIN_NOW",
              actionType: "LOGIN",
            },
          };
          this.commonService.redirect("/account/forgot-password/success", null, { state: successPageData });
        },
        (error: ApiError) => {
          this.isSubmittingSecurityForm2 = false;
          if (error.errorCode === "E1026") {
            this.securityQuestionForm.controls.password.setErrors({
              required: true,
            });
          } else if (error.errorCode === "E1030") {
            this.securityQuestionForm.controls.password.setErrors({
              repeatedPassword: true,
            });
          } else {
            this.errorService.redirectToErrorPage(error);
          }
        }
      );
    } else {
      this.userService.changePasswordBySecurityQuestion(formData).subscribe(
        (response) => {
          const successPageData = {
            displayItem: {
              title: "auth.MESSAGE.SUCCESSFULLY_RESET_PASSWORD",
              subTitle: "auth.MESSAGE.SUCCESSFULLY_RESET_PASSWORD_SUBTITLE",
              icon: "password_changed",
              buttonLabel: "COMMON.LABEL.LOGIN_NOW",
              actionType: "LOGIN",
            },
          };
          this.commonService.redirect("/account/forgot-password/success", null, { state: successPageData });
        },
        (error: ApiError) => {
          this.isSubmittingSecurityForm2 = false;
          if (error.errorCode === "E1026") {
            this.securityQuestionForm.controls.password.setErrors({
              required: true,
            });
          } else if (error.errorCode === "E1030") {
            this.securityQuestionForm.controls.password.setErrors({
              repeatedPassword: true,
            });
          } else {
            const errorPageData = {
              displayItem: {
                title: "auth.change-password.form.mobileDoesNotExist",
                subTitle: "auth.change-password.form.mobileDoesNotExistMeg",
                icon: "error",
                buttonLabel: "COMMON.LABEL.BACK",
                actionType: "LOGIN",
              },
            };
            this.commonService.redirect("/account/forgot-password/success", null, { state: errorPageData });
            // this.errorService.redirectToErrorPage(error);
          }
        }
      );
    }
  }

  changePasswordByEmail() {
    const formData = this.transformChangePasswordByEmail();
    this.userService.changePasswordByEmail(formData).subscribe(
      (response) => {
        this.currentEmail = response;
      },
      (error) => {
        // TODO:
      }
    );
  }

  submitForm() {
    // Check ID Type
    if (!this.isEmail(this.userId.value)){
      if (this.otpBox.verificationForm.get("verCode").valid) {
        this.resetPasswordByPhone();
      }
      return
    }
    if (this.forgotPasswordForm.valid) {
      // Check ID Type
      this.isSubmitting = true;
      this.captchaVerify(null,true);
    } else {
      for (const i in this.forgotPasswordForm.controls) {
        this.forgotPasswordForm.controls[i].markAsDirty();
        this.forgotPasswordForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  // submitSecurityForm() {
  //   if (this.securityQuestionForm.controls.securityAnswer.valid && this.securityQuestionForm.controls.securityQuestion.valid) {
  //     this.verifySecurityQuestion();
  //   } else {
  //     if (this.allowDirectReset) {
  //       this.securityQuestionForm.controls.securityQuestion.markAsDirty();
  //       this.securityQuestionForm.controls.securityQuestion.updateValueAndValidity();
  //     }
  //     this.securityQuestionForm.controls.securityAnswer.markAsDirty();
  //     this.securityQuestionForm.controls.securityAnswer.updateValueAndValidity();
  //     this.formService.scrollToError();
  //   }
  // }

  // TODO: Init Form
  initFrom() {
    // TODO: Init Reg Form
    this.forgotPasswordForm = this.fb.group(
      {
        userId: ["", {
          validators:[Validators.required, this.hybridIdValidator],
          updateOn: "blur",
        }],
        captchaCode: ["",{validators: [],}],
        phoneNumberPrefix: ["852_Hong Kong (+852)", []],
        verificationChannel: ['Email'],
        verCode: [null],
      },
      { updateOn: "change" }
    );
  }

  passwordConfirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    }
    if (this.securityQuestionForm.controls.password.value !== this.securityQuestionForm.controls.confirmPassword.value) {
      return { passwordMismatch: true };
    }
    return null;
  };

  hybridIdValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }
    // Check if email / phone
    const trimmedId = control.value.trim();
    // 1. Email
    // if (this.isEmail(control.value)) {
    if (this.verificationChannel.value == 'Email') {
      return emailValidator(control);
    } else {
      setTimeout(() => {
        this.otpBox.otpExtraShow = false
      }, 1000);
      return phoneValidator(control);
    }
  };

  // TODO: Common Trmmmied Control
  trimUserId() {
    const self: any = this;
    const value = this.userId.value;
    if (value && value.trim() !== value) {
      this.userId.patchValue(value.trim());
      self.isTrimmed = true;
    } else {
      self.isTrimmed = false;
    }
  }

  // trimSecurityAnswer() {
  //   const value = this.securityQuestionForm.controls.securityAnswer.value;
  //   if (value && value.trim() !== value) {
  //     this.securityQuestionForm.controls.securityAnswer.setValue(value.trim());
  //     this.isTrimmedAns = true;
  //   } else {
  //     this.isTrimmedAns = false;
  //   }
  // }

  isEmail(value: String) {
    if (value.indexOf("@") !== -1) {
      // TODO: Manage Constant
      return true;
    } else {
      return false; // Mobile
    }
  }

  transformChangePasswordForm() {
    const requestBody = {
      newPassword: this.securityQuestionForm.controls.password.value.trim(),
    };
    return _.pick(requestBody, _.identity);
  }

  login() {
    this.userService.login();
  }

  changePasswordByMemberNumber() {
    const self: any = this;
    const formData = this.transformChangePasswordForm();
    self.isSubmitting = true;
    this.userService.changePasswordByMemberNumber(formData).subscribe(
      (userData) => {
        // this.alertService.success(this.translocoService.translate("auth.message.passwordChangeMsg"));
        // this.router.navigateByUrl("/profile");
      },
      (error: ApiError) => {
        self.isSubmitting = false;
        // this.alertService.error(error);
        // TODO: Confirm others error
        if (error.errorCode === "E1006") {
          this.securityQuestionForm.controls.password.setErrors({
            repeatedPassword: true,
          });
        }
      }
    );
  }

  captchaVerify(e?: MouseEvent,verifyFirst:Boolean = false){
    if(!this.userId.value){
      this.userId.setErrors({required:true})
      return
    }
    if(!this.verifyFirstInput(this.userId.value) && verifyFirst){
      this.userId.setErrors({cannotGetCaptcha:true})
      return
    }
    if(!!localStorage.getItem('sessionNumber')){
      this.sessionId = localStorage.getItem('sessionNumber')
    }
    if(e){
      this.captchaCode.setValidators([Validators.required]);
      this.captchaCode.updateValueAndValidity();
    }
    this.refreshImage = true
    this.userService.getCaptchaCode(this.sessionId).subscribe(
      (response) => {
        this.previewVisible = true
        this.refreshImage = false
        this.picture = 'data:image/jpeg;base64,' + response.code 
        this.sessionId = response.id
        localStorage.setItem('sessionNumber',response.id)
        this.isSubmitting = false
      },
      (error) => {
      })
  }

  cancelPreview(){
    this.previewVisible = false;
    this.captchaCode.reset()
    this.captchaCode.setValue(null)
    this.captchaCode.setValidators(null);
    this.captchaCode.updateValueAndValidity();
  }

  next(){
    if(this.isSubmitting == false){
      this.getOtpCount++;
      this.isSubmitting = true;
    }else{
      return;
    }
    
    if (this.getOtpCount > 10) {
      this.cancelPreview();
      this.userId.markAsDirty();
      this.userId.setErrors({ limitExceeded: true });
      return;
    }
    this.userService.getVerificationCode({
      language: this.i18nService.getActiveLang,
      countryCode: null,
      userPhone: null,
      id: this.sessionId,
      code: this.captchaCode.value,
      notSendVerificationCode:"true",
    }).subscribe(
      (res) => {
        if (this.isEmail(this.userId.value)) {
          // Email
          this.resetPasswordByEmail();
        } else {
          // Mobile
          this.resetPasswordByPhone();
        }
        setTimeout(() => {
          this.isSubmitting = false
        }, 3000);
      },
      (error) => {
        this.captchaVerify()
        if (error.errorCode === "E1097") {
          this.captchaCode.markAsDirty();
          this.captchaCode.setErrors({
            reOpenBrowser: true,
          });
        }
        if (error.errorCode === "E1098") {
          this.captchaCode.markAsDirty();
          this.captchaCode.setErrors({
            notMatch: true,
          });
        }
        if (error.errorCode === "E1099") {
          this.userId.markAsDirty();
          this.userId.setErrors({ limitExceeded: true });
          this.getOtpCount = 11;
          this.cancelPreview();
        }
        this.isSubmitting = false
      }
    );
  }

  get captchaCode() {
    return this.forgotPasswordForm.get("captchaCode") as FormControl;
  }

  get newPassword() {
    return this.forgotPasswordForm.get("newPassword") as FormControl;
  }
  get phone() {
    return this.forgotPasswordForm.get("phone") as FormControl;
  }
  get email() {
    return this.forgotPasswordForm.get("email") as FormControl;
  }
  // get securityAnswer() {
  //   return this.securityQuestionForm.get("securityAnswer") as FormControl;
  // }
  get userId() {
    return this.forgotPasswordForm.get("userId") as FormControl;
  }
  get phoneNumberPrefix() {
    return this.forgotPasswordForm.get("phoneNumberPrefix") as FormControl;
  }
  get verificationChannel() {
    return this.forgotPasswordForm.get("verificationChannel") as FormControl;
  }
  get otpConfig(): OtpConfig {
    if (!this.otpBox) {
      return;
    }
    return {
      type: "Mobile",
      otpType: "resetPassword",
      id: this.forgotPasswordForm.controls.userId.value,
      countryCode: this.phoneNumberPrefix.value,
    };
  }
  trackFunc = (index, item) => {
    return index;
  };
}
