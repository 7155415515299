import { Component, Input } from "@angular/core";
import { I18NService } from "../../core";
import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";
@Component({
  selector: "app-about-programme-cards",
  styleUrls: ["about-programme-cards.component.scss"],
  templateUrl: "./about-programme-cards.component.html"
})
export class AboutProgrammeCardsComponent {
  constructor(private i18nService: I18NService) {}

  @Input() limit: number;
  @Input()
  set carouselArray(config: PromotionCarouselConfig[]) {
    if (config) {
      this.slides = config;
    }
  }

  slides: PromotionCarouselConfig[];
  loading = false;
  currentPage = 1;

  cards = [
    {
      title: "",
      cardImg: "/assets/images/home/key_benefit_A.png"
    },
    {
      title: "",
      cardImg: "/assets/images/home/key_benefit_B.png"
    },
    {
      title: "",
      cardImg: "/assets/images/home/key_benefit_C.png"
    }
  ];

  slideConfig = {
    lazyLoad: "ondemand",
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: false,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          arrows: false,
          slidesToShow: 2.1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 0,
        settings: {
          infinite: false,
          arrows: false,
          slidesToShow: 1.02,
          slidesToScroll: 1
        }
      }
    ]
  };

  setPageTo(pageNumber) {
    this.currentPage = pageNumber;
  }

}
