import * as _ from "underscore";
import { CampaignsService, I18NService } from "../../core";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-campaign-cards",
  templateUrl: "./campaign-cards.component.html",
  styleUrls: ["./campaign-cards.component.scss"],
})
export class CampaignCardsComponent implements OnInit, OnDestroy {
  @Input() offerConfig: any;
  campaigns: any;
  loading: boolean;

  private subscription: Subscription;

  constructor(private campaignsService: CampaignsService, private i18nService: I18NService) {}

  ngOnInit() {
    this.subscription = this.i18nService.currentLang.subscribe(lang => {
      this.getCampaigns();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCampaigns() {
    this.loading = true;
    this.campaignsService.getAllCampaigns().subscribe(campaigns => {
      const unsortedCampaigns = this.transformCampaign(campaigns).filter(item => item.custom_payload && item.custom_payload.showOnPage);
      this.campaigns = unsortedCampaigns.sort((compaignA,compaignB) => new Date(compaignB.start_date).valueOf() - new Date(compaignA.start_date).valueOf());
      this.loading = false;
    });
  }

  trackFunc = (index, item) => {
    return index;
  }

  transformCampaign(campaigns) {
    const i18nCode = this.i18nService.getActiveLang;
    return _.each(campaigns, (record: any) => {
      if (record.custom_payload) {
        record.customContent = {
          title: record.custom_payload[i18nCode + ".title"],
          desc: record.custom_payload[i18nCode + ".desc"],
          termsAndConditions: record.custom_payload[i18nCode + ".termsAndConditions"],
          graphics: record.custom_payload[i18nCode + ".graphics"],
          graphics_banner: record.custom_payload[i18nCode + ".graphics_banner"],
          short_desc: record.custom_payload[i18nCode + ".short_desc"],
        };
      }
    }) as any;
  }
}
