import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from "./core/core.module";
import { ErrorPagesComponent } from "./shared/error-pages/error-pages.component";
import { FooterComponent, HeaderComponent, SharedModule, MobileNavigationComponent, AlertComponent } from "./shared";
import { HomeModule } from "./pages/home/home.module";
import { MobileDrawerComponent } from "./shared/layout/mobile-view/mobile-drawer.component";
import { NZ_I18N, en_US, NzConfig, NZ_CONFIG } from "ng-zorro-antd";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgModule, APP_INITIALIZER, ErrorHandler, Injector } from "@angular/core";
import { UserService, ErrorService } from "./core";
import { CookieService } from 'ngx-cookie-service';
// #region Startup Service
import { StartupService } from "./core/";
import { LandingModule } from "./pages/landing/landing.module";
import { DELON_LOCALE, en_US as delonEnUS } from "@delon/theme";
import { LivechatComponent } from './shared/layout/livechat/livechat.component';
import { environment } from '../environments/environment';
import { EarnNowModule } from "./pages/earn-now/earn-now.module";

const ngZorroConfig: NzConfig = {
  message: { nzTop: 100 },
  notification: { nzTop: 100 },
};

export function StartupServiceFactory(startupService: StartupService) {
  return () => {
    startupService.load();
  };
}

export function initializeGuestProfileConfig(userService: UserService) {
  return () => userService.runInitialLoginSequence();
}

const APPINIT_PROVIDES = [
  StartupService,
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService],
    multi: true
  }
];
// #endregion

@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent, MobileNavigationComponent, MobileDrawerComponent, ErrorPagesComponent, AlertComponent, LivechatComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: "hkaa-member-portal" }),
    CoreModule.forRoot(),
    HomeModule,
    LandingModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule,
    MatDialogModule,
    EarnNowModule
  ],
  providers: [
    ErrorService,
    UserService,
    ...APPINIT_PROVIDES,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: DELON_LOCALE, useValue: delonEnUS },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGuestProfileConfig,
      deps: [UserService, Injector],
      multi: true,
    },
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
      if (!environment.production){
        console.log(`${environment.version} ${environment.buildImgNumber}`);
      } 
    }
}
