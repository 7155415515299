import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ProfilesService, UserService, User, I18NService, CommonService, OffersService, GeneralGuard } from "../../../core";
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Location } from "@angular/common";
import { environment } from '../../../../environments/environment';
import { NzModalService } from "ng-zorro-antd";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-member-info-page",
  templateUrl: "./member-info.component.html",
  styleUrls: ["./member-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MemberInfoComponent implements OnInit, OnDestroy {
  areaList = [];
  companyList = [];
  currentLang: string;
  nzTabPosition = "top";
  pointBalance: any;
  targetValue: number;
  nextTargetValue:number;
  pointDiff: number = 0;
  progressPercent: number = 0;
  public detailProfile: any;
  selectedIndex = 0;
  sessionMProfile: any;
  style = { width: "100%", "text-align": "center", "font-size": "18px" };
  tabs: Array<{ name: string; content: string }> = [];
  tierInfo: any;
  today: number = Date.now();
  user: User = {} as User;
  userCNPersonTitle: string;
  userName: string;
  userPersonTitle: string;
  shoppingPreference: string[];
  preferenceList: [{ label: string, value: string }];
  isShownRenewalMsg: boolean = false;
  memberTranslatedProfile: any;
  tier = [];
  staffStatusList = [];
  hideDesc = () => "";
  isLongDigit:boolean = false;
  shouldShowTarget:boolean = true;
  progressDegree = 210;
  hasEarnedECoupon:boolean = false;
  transformDegree = {
    'transform': `rotate(${this.progressDegree}deg)`
  }

  tableConfig = [
    {
      points: "10,000",
      value: "$200",
    },
    {
      points: "20,000",
      value: "$400",
    },
    {
      points: "50,000",
      value: "$1,200",
    },
    {
      points: "80,000",
      value: "$2,400",
    },
    {
      points: "100,000",
      value: "$3,500",
    },
    {
      points: "150,000",
      value: "$6,000",
    },
    {
      points: "200,000",
      value: "$9,000",
    },
    {
      points: "300,000",
      value: "$15,000",
    },
  ];

  constructor(
    private commonService: CommonService,
    private offerService: OffersService,
    private i18nService: I18NService,
    private profileService: ProfilesService,
    private route: ActivatedRoute,
    private userService: UserService,
    private authStorage: OAuthStorage,
    private generalGuardService: GeneralGuard,
    private _location: Location,
    private router: Router,
    private modalService: NzModalService,
    private deviceDetectorService: DeviceDetectorService,
  ) {
    this.route.parent.data.subscribe((data) => {
      if (data.listData) {
        this.areaList = data.listData[3][this.i18nService.getActiveLang];
        this.companyList = data.listData[2][this.i18nService.getActiveLang];
        this.preferenceList = data.listData[5][this.i18nService.getActiveLang];
        this.loadUserInfo();
        this.getUserName();
        this.userService.getUserStaffStatus().subscribe((status) => {
          this.staffStatusList = status[this.i18nService.getActiveLang];
        });
      }
    });
  }

  ngOnInit() { }


  tabClicked() {
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }
  }

  loadUserInfo() {
    Object.assign(this.user, this.userService.getCurrentUser);
    this.userService.loadAppUserProfile(true).then((data) => {
      if (data.memberProfile) {
        this.memberTranslatedProfile = JSON.parse(JSON.stringify(data.memberProfile));
        this.detailProfile = this.transformUserInfo(this.memberTranslatedProfile);
        this.userPersonTitle = this.detailProfile.personTitle;
        this.getUserName();
      }
    });

    this.userService.getSessionMProfile().subscribe((data) => {
      if (data) {
        this.sessionMProfile = data;
        data.tierDetails.tierLevels.forEach((item,index)=>{
          if(item.currentTier.name.includes("VIP")){
            data.tierDetails.tierLevels[0].currentTier.name = "VIP Member";
          }
        })
        this.tierInfo = this.transformMemberTier(_.first(data.tierDetails.tierLevels));
      }
    });

    // const memberId = this.user.memberNumber;
    // Get Balance from SessionM
    // this.profileService.getUserBalance(memberId).subscribe((pointBalance) => {
    //   this.hasEarnedECoupon =  pointBalance.mpProgressBar.hasEarnedECoupon;
    //   this.progressPercent = Math.trunc(pointBalance.mpProgressBar.percentageToNextECoupon);
    //   this.progressDegree = this.progressPercent <= 97 || this.progressPercent > 100? ((360 - 118)*(this.progressPercent/100) + 210) : ((360 - 118)*(this.progressPercent/100) + 203);
    //   this.transformDegree = {'transform': `rotate(${this.progressDegree}deg)`};
    //   this.pointBalance = pointBalance;
    //   this.targetValue = pointBalance.mpProgressBar.nextECoupon;
    //   this.nextTargetValue = pointBalance.mpProgressBar.nextNextECoupon;
    //   if (pointBalance.mpProgressBar.nextNextECoupon.toString().length > 5) {
    //     this.isLongDigit = true;
    //   }
    //   if(!this.pointBalance.pointExpiry){
    //     this.pointBalance.pointExpiry = []
    //   }
    //   this.pointDiff = pointBalance.mpProgressBar.pointDiff2NextECoupon || 0;
    // });
  }

  transformMemberTier(tierInfo) {
    if (tierInfo.currentTier.name) {
      this.tier = tierInfo.currentTier.name.split(';');
      if (tierInfo.currentTier.name.includes('invitation')) {
        tierInfo.currentTier.isByInvitation = true;
      } else {
        tierInfo.currentTier.isByInvitation = false;
      }
      if (tierInfo.currentTier.name.includes('invitation')) {
        tierInfo.currentTier.name = this.i18nService.translateLabel("MEMBER_PROFILE.TIER.BY_INVITATION");  
      } else if (tierInfo.currentTier.name.includes('VIP')){
        tierInfo.currentTier.name = this.i18nService.translateLabel("MEMBER_PROFILE.TIER.VIP");
      } else {
        tierInfo.currentTier.name = this.i18nService.translateLabel("MEMBER_PROFILE.TIER.GENERAL");
      }
      // switch (this.i18nService.getActiveLang) {
      //   case "en_US": {
      //     tierInfo.currentTier.name = this.tier[0];
      //     break;
      //   }
      //   case "zh_HK": {
      //     tierInfo.currentTier.name = this.tier[1];
      //     break;
      //   }
      //   case "zh_CN": {
      //     tierInfo.currentTier.name = this.tier[2];
      //     break;
      //   }
      // }
    }
    return tierInfo;
  }

  getUserName() {
    const currentLang = this.i18nService.getActiveLang;
    if (this.user.sfdcProfile && this.detailProfile) {
      if (currentLang === "en_US") {
        this.userName = this.userPersonTitle + " " + this.detailProfile.firstName + " " + this.detailProfile.lastName;
      } else {
        if (this.userPersonTitle && currentLang !== "en_US") {
          switch (this.userPersonTitle) {
            case "Mr.": {
              this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.mr");
              break;
            }
            case "Mrs.": {
              this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.mrs");
              break;
            }
            case "Ms.": {
              this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.ms");
              break;
            }
            case "Miss": {
              this.userCNPersonTitle = this.i18nService.translateLabel("MEMBER_PROFILE.FORM.miss");
              break;
            }
            default: {
              this.userPersonTitle = this.userPersonTitle;
            }
          }
          this.userName = this.detailProfile.lastName + " " + this.detailProfile.firstName + " " + this.userCNPersonTitle;
        }
      }
    }
  }

  ngOnDestroy() { }

  transformUserInfo(userInfo: any) {
    // Translate Area of Residence label
    if (userInfo.area) {
      userInfo.area = _.first(_.where(this.areaList, { value: userInfo.area })).label;
    }
    // Translate shoppingPreference label
    if (userInfo.shoppingPreference) {
      let prefereceLabels: string[] = [];
      userInfo.shoppingPreference.split(";").forEach(element => {
        let selectedItem = this.preferenceList.filter(item => item.value === element)[0].label;
        prefereceLabels.push(selectedItem);
      });
      this.shoppingPreference = prefereceLabels;
    }
    // Translate Preferred Communication Language
    if (userInfo.preferredLanguage) {
      switch (userInfo.preferredLanguage) {
        case "Traditional Chinese": {
          userInfo.preferredLanguage = "profile.message.traditionalChinese";
          break;
        }
        case "English": {
          userInfo.preferredLanguage = "profile.message.english";
          break;
        }
        case "Simplified Chinese": {
          userInfo.preferredLanguage = "profile.message.simplifiedChinese";
          break;
        }
      }
    }
    if (userInfo.companyName) {
      userInfo.companyNameValue = userInfo.companyName;
      userInfo.companyName = _.first(_.where(this.companyList, { value: userInfo.companyName })).label;
    }
    if (userInfo.staffStatus) {
      if (userInfo.staffStatus !== "Pending") {
        this.userService.isMemberHasPendingCase().subscribe(data => this.isShownRenewalMsg = data);
      }
      userInfo.staffStatus = (this.staffStatusList.find((status) => status.value === userInfo.staffStatus))['label'];
    }
    return userInfo;
  }

  infoOpen(infoDetail) {
    this.modalService.create({
      nzContent: infoDetail,
      nzMaskClosable: true,
      nzClosable: true,
      nzFooter: null,
      nzWidth: "840",
      nzBodyStyle: {
        padding: '35px 25px',
        background: '#eaf0f1',
      }
    });
  }

  redeem() {
    if(!this.hasEarnedECoupon){
      return
    }
    this.commonService.redirect(`/offers/rewards-choices`);
  }

  showTarget() {
    this.shouldShowTarget = !this.shouldShowTarget;
  }

  updateAraStatus(): void {
    this.commonService.redirect("/profile/update-ara");
  }

  associatedMember(): void {
    this.commonService.redirect("/profile/associated-member");
  }

  changePassword(): void {
    this.commonService.redirect("/account/change-password");
  }

  logout() {
    this.userService.logoff();
  }
  getCurrentLang (){
    return this.i18nService.getActiveLang
  }
}
