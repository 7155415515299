import { NgModule } from "@angular/core";
import { AboutProgrammeComponent } from "./about-programme.component";
import { SharedModule } from "../../shared";
import { AboutProgrammeRoutingModule } from "./about-programme-routing.module";
import { EarnAndRedeemComponent } from "./privileges/privileges-page.component";
import { PrivilegesComponent } from "./earn-and-redeem/earn-and-redeem.component";
import { ProgramOverviewComponent } from "./program-overview/program-overview.component";
import { AboutProgrammeResolverService } from './about-programme-resolver.service';

@NgModule({
  imports: [SharedModule, AboutProgrammeRoutingModule],
  declarations: [AboutProgrammeComponent, EarnAndRedeemComponent, PrivilegesComponent, ProgramOverviewComponent],
  providers: [AboutProgrammeResolverService]
})
export class AboutProgrammeModule {}
