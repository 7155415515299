import { NgModule } from "@angular/core";
import { FaqComponent } from "./faq.component";
import { SharedModule } from "../../shared";
import { FaqRoutingModule } from "./faq-routing.module";
import { FaqResolver } from "./faq-resolver.service";

@NgModule({
  imports: [
    SharedModule,
    FaqRoutingModule
  ],
  declarations: [
    FaqComponent
  ],
  providers: [
    FaqResolver
  ]
})

export class FaqModule {}
