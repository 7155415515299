import { Component, OnInit, ViewChild } from "@angular/core";
import { MobileDrawerComponent } from "./mobile-drawer.component";

@Component({
  selector: "app-layout-mobile-navigation",
  templateUrl: "./mobile-navigation.component.html",
  styleUrls: ["./mobile-navigation.component.scss"],
})
export class MobileNavigationComponent implements OnInit {
  @ViewChild("appMobileDrawer", { static: false }) appMobileDrawer: MobileDrawerComponent;

  isDrawerVisible = false;

  constructor() {}

  ngOnInit() {}

  controlDrawer() {
    this.appMobileDrawer.openDrawer();
  }
}
