import { Component, Input } from "@angular/core";
import { I18NService, UserService, CommonService } from "../../core";
import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
@Component({
  selector: "app-promotion-carousel",
  styleUrls: ["promotion-carousel.component.scss"],
  templateUrl: "./promotion-carousel.component.html",
})
export class PromotionCarouselComponent {
  constructor(public i18nService: I18NService, private userService: UserService, private commonService: CommonService) {}

  @Input() limit: number;
  @Input()
  set carouselArray(config: PromotionCarouselConfig[]) {
    if (config) {
      this.slides = config;
      this.initCarouselConfig();
    }
  }

  slides: PromotionCarouselConfig[];
  swiperConfig: SwiperConfigInterface;
  loading = false;

  initCarouselConfig() {
    this.swiperConfig = {
      a11y: true,
      direction: "horizontal",
      pagination: false,
      slidesPerView: "auto",
      touchRatio: this.slides.length > 1 ? 1 : 0,
    };
  }

  emitEvent(index) {
    if (this.userService.isLoggedIn) {
      if (this.slides[index].memberAction.type === "link") {
        this.commonService.redirect(this.slides[index].memberAction.link);
      }
    } else {
      if (this.slides[index].guestAction.type === "login") {
        this.userService.login();
      }
    }
  }
}
