import { Component } from "@angular/core";
import { UserService } from "../../../core/services/user.service";

@Component({
  selector: "app-program-overview",
  templateUrl: "./program-overview.component.html",
  styleUrls: ["./program-overview.component.scss"],
})
export class ProgramOverviewComponent {
  joinNowConfig = {
    guest: {
      title: "ABOUT_PROGRAMME.PROGRAM_OVERVIEW.joinNow_title",
      description: "ABOUT_PROGRAMME.PROGRAM_OVERVIEW.joinNow_description",
      ctaLabel: "CTA.CONTENT.GUEST_CTA_LABEL",
    },
    member: {
      title: "CTA.LABEL.MEMBER_LOGIN_TITLE",
      description: "",
      ctaLabel: "CTA.CONTENT.MEMBER_CTA_LABEL",
    },
    guestAction: {
      type: "login",
    },
    memberAction: {
      type: "link",
      link: "/earn",
    },
  };

  constructor(private userService: UserService) {}

  login() {
    this.userService.login();
  }
}
