import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "../../../environments/environment";

export function authConfig(): AuthConfig {
  const userAgent = window.navigator.userAgent
  const loginFlowConfig = {
    issuer: `${environment.apiEndpoints.oAuthService}`,
    clientId: environment.authClientId,
    redirectUri: window.location.origin,
    silentRefreshShowIFrame: false,
    scope: "openid",
    oidc: true,
    sessionChecksEnabled: true,
    strictDiscoveryDocumentValidation: false,
    silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    sessionCheckIFrameName: "check-session",
    skipIssuerCheck: true,
    showDebugInformation: true,
    silentRefreshTimeout: 5000,
    nonceStateSeparator: "semicolon", // Real semicolon gets mangled by IdentityServer"s URI encoding
    responseType: "",
  }

  if (userAgent.indexOf("myhkg") != -1 || userAgent.indexOf("myhkg@android") != -1 || userAgent.indexOf("myhkg@ios") != -1) {
    loginFlowConfig.scope = "openid offline_access";
    loginFlowConfig.responseType = "code";
    loginFlowConfig.oidc = false
  }

  return loginFlowConfig;
}