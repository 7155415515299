export abstract class EarnHistoryConstants {
  public static ECOUPON = "ECOUPON";
  public static DIRECT_USE = "DIRECT_USE";
  public static COMFIRM_USE = "COMFIRM_USE";
  public static VERIFICATION_CODE = "VERIFICATION_CODE";

  // My HKG Link
  public static HKAA_APP_HELP_EN = "https://www.hongkongairport.com/en/flights/MyHKG-mobile-app/";
  public static HKAA_APP_HELP_TC = "https://www.hongkongairport.com/tc/flights/MyHKG-mobile-app/";
  public static HKAA_APP_HELP_SC = "https://www.hongkongairport.com/sc/flights/MyHKG-mobile-app/";
}
