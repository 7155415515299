import * as _ from "underscore";
import * as df_en from "date-fns/locale/en";
import * as df_zh_cn from "date-fns/locale/zh_cn";
import * as df_zh_tw from "date-fns/locale/zh_tw";
import localZh from "@angular/common/locales/zh-Hant";
import localeEn from "@angular/common/locales/en";
import localeZhCn from "@angular/common/locales/zh";
import { Injectable, Inject, Injector } from "@angular/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Observable, BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { en_US, zh_CN, zh_TW, NzI18nService } from "ng-zorro-antd";
import { filter } from "rxjs/operators";
import { registerLocaleData, DOCUMENT } from "@angular/common";
import { SettingsService, AlainI18NService, DelonLocaleService, en_US as delonEnUS, zh_CN as delonZhCn, zh_TW as delonZhTw } from "@delon/theme";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

interface LangData {
  order: number;
  langId: string;
  text: string;
  ng: any;
  zorro: any;
  longName: string;
  dateFns: any;
  delon: any;
}

const DEFAULT = "zh_HK";
export const LANGS: { [key: string]: LangData } = {
  en_US: {
    order: 1,
    langId: "en_US",
    text: "EN",
    longName: "English",
    ng: localeEn,
    dateFns: df_en,
    delon: delonEnUS,
    zorro: en_US,
  },
  zh_HK: {
    order: 2,
    langId: "zh_HK",
    text: "繁",
    longName: "Traditional Chinese",
    ng: localZh,
    dateFns: df_zh_tw,
    delon: delonZhTw,
    zorro: zh_TW,
  },
  zh_CN: {
    order: 3,
    langId: "zh_CN",
    text: "简",
    longName: "Simplified Chinese",
    ng: localeZhCn,
    dateFns: df_zh_cn,
    delon: delonZhCn,
    zorro: zh_CN,
  },
};

@Injectable({ providedIn: "root" })
export class I18NService {
  private _default = DEFAULT;
  private currentLangSubject$ = new BehaviorSubject<string | null>(null);
  public currentLang = this.currentLangSubject$.asObservable().pipe();

  private _langs = Object.keys(LANGS).map((code) => {
    const item = LANGS[code];
    return {
      code,
      text: item.text,
      langId: item.langId,
      zorro: item.zorro,
      ng: item.ng,
      delon: item.delon,
    };
  });

  private _lightLangs = Object.keys(LANGS).map((code) => {
    const item = LANGS[code];
    return {
      text: item.text,
      langId: item.langId,
    };
  });

  constructor(
    private nzI18nService: NzI18nService,
    private translate: TranslateService,
    private localizeService: LocalizeRouterService,
    private delonLocaleService: DelonLocaleService,
    @Inject(DOCUMENT) private document: Document,
    private injector: Injector,
    private cookieService: CookieService
  ) {
    const langs = this._langs.map((item) => item.code);
    translate.addLangs(langs);
  }

  private updateLangData(lang: string) {
    const item = LANGS[lang];
    registerLocaleData(item.ng);
    this.nzI18nService.setLocale(item.zorro);
    this.nzI18nService.setDateLocale(item.dateFns);
    (window as any).__locale__ = item.dateFns;
    this.delonLocaleService.setLocale(item.delon);
    // Update HTML universal tag
    this.document.documentElement.lang = item.langId;
  }

  get selectedLanguage(): Observable<string> {
    return this.currentLangSubject$.asObservable().pipe(filter((w) => w != null)) as Observable<string>;
  }

  use(lang: string, isSetRoute: boolean = true) {
    this.updateLangData(lang);
    if (isSetRoute) {
      this.localizeService.changeLanguage(lang);
    }
    sessionStorage.setItem("MP_SPPL_LANGUAGE", lang);
    this.translate.use(lang).subscribe(() => {
      this.currentLangSubject$.next(lang);
    });
  }

  getLangs() {
    return this._lightLangs;
  }

  translateLabel(key: string, interpolateParams?: {}) {
    return this.translate.instant(key, interpolateParams);
  }

  /**
   * Get active language for display (e.g. English)
   */
  getLangIdByLongName(langName: string) {
    const langItem = _.first(_.where(LANGS as any, { longName: langName })) as any;
    return langItem.langId;
  }

  get defaultLang() {
    return this._default;
  }

  get getActiveLang() {
    return this.translate.currentLang || this.translate.getDefaultLang() || this._default;
  }

  /**
   * Get active language for display (e.g. English)
   */
  get getActiveLangLongName() {
    const currentLang = this.translate.currentLang || this.translate.getDefaultLang() || this._default;
    const item = LANGS[currentLang];
    return item.longName;
  }

  public get router(): Router {
    return this.injector.get(Router);
  }
}
