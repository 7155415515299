import { NgModule } from "@angular/core";
import { LandingComponent } from "./landing.component";
import { SharedModule } from "../../shared";

@NgModule({
  imports: [SharedModule],
  declarations: [LandingComponent],
  providers: []
})
export class LandingModule {}
