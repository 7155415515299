declare var $: any;
import * as _ from "underscore";
import { Component, OnInit, OnDestroy, Input, OnChanges } from "@angular/core";
import { OffersService, I18NService, UserService, GeneralGuard, FilteringCategoryTranslationResponse, FormService } from "../../core";
import { distinctUntilChanged } from "rxjs/operators";
import { isEmpty } from "lodash";
import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";
import { BehaviorSubject, Subscription } from 'rxjs';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-offer-cards",
  templateUrl: "./offer-cards.component.html",
  styleUrls: ["./offer-cards.component.scss"],
})
export class OfferCardsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() offerConfig: any;
  offers = [];
  dummyOffer = [0, 1, 2, 3];
  sortingList: { id: string, value: string }[] = [
    {
      id: "REC",
      value: "offers.sorting.REC"
    },
    {
      id: "ASC",
      value: 'offers.sorting.ASC'
    },
    {
      id: "DESC",
      value: "offers.sorting.DESC"
    },
    {
      id: "NEW",
      value: "offers.sorting.NEW"
    }
  ];
  filteringList: FilteringCategoryTranslationResponse[] = [];
  filtershortCodes: string = "";
  filterCategoryCount: { offerFilteringCategory: string, totalOfferCount: number }[] = [];
  private selectedCategoriesSubject = new BehaviorSubject<[]>([]);
  private selectedCategories = this.selectedCategoriesSubject.asObservable();

  private selectedPointRangeSubject = new BehaviorSubject<number[]>([]);
  private selectedPointRange = this.selectedPointRangeSubject.asObservable();

  selectedCategoriesStore = [];
  selectedPointRangeStore = [0, 1000000];
  selectedSortingItem: string;
  appliedCategoriesStore = [];
  appliedPointRangeStore = [0, 1000000];

  tierRank: string;
  tierSortingName: string;
  shouldShowIcon: boolean = true;
  categoryShortCodePara: string = "";

  constructor(
    private offersService: OffersService,
    private i18nService: I18NService,
    private userService: UserService,
    private authStorage: OAuthStorage,
    private generalGuardService: GeneralGuard,
    private router: Router,
    private _location: Location,
    private formService: FormService,
    private route: ActivatedRoute
  ) { }

  @Input() limit: number;
  @Input()
  set carouselArray(config: PromotionCarouselConfig[]) {
    if (config) {
      this.slides = config;
    }
  }

  slides: PromotionCarouselConfig[];
  loading = false;
  currentPage = 1;

  slideConfig = {
    dots: false,
    arrows: true,
    infinite: true,
    lazyLoad: "ondemand",
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1200,
        settings: {
          infinite: false,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          arrows: false,
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          infinite: false,
          arrows: false,
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
    mobileFirst: true,
  };
  private subscription: Subscription;
  private generalOfferSubscription: Subscription;
  private memberOfferSubscription: Subscription;
  filterBody = {
    "width": "951px"
  }
  shouldFilterDropdownOpen: boolean = false;

  filterOpenChange() {
    this.shouldFilterDropdownOpen = !this.shouldFilterDropdownOpen;
    setTimeout(() => {
      const overlayElement = document.getElementsByClassName("cdk-overlay-backdrop cdk-overlay-backdrop-showing nz-overlay-transparent-backdrop")[0] as HTMLElement;
      overlayElement.style.display = "none";
    }, 500);
  }

  scrollToTop() {
    if (window.screen.width < 768) {
      this.formService.scrollElementToTop("section");
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async (value) => {
      if (value["cat"]) {
        this.categoryShortCodePara = decodeURI(value["cat"]);
      }
    });
    this.categoryShortCodePara.split(",").map((item) =>{
       this.filtering(item);
    });

    this.subscription = this.i18nService.currentLang.pipe(distinctUntilChanged()).subscribe((lang) => {
      if (lang) {
        if (!isEmpty(this.userService.getCurrentUser)) {
          this.getSortingList();
        }
        this.filterAndSortApply();
        this.getFilterCategoryTranslation(lang);
      };
    });
    this.selectedCategories.subscribe((data) => {
      //this.selectedCategoriesStore = data;
      if (window.screen.width > 767) {
        this.filterAndSortApply();
      }
    });
    this.selectedPointRange.subscribe((data) => {
      if (!_.isEqual(data, [])) {
        this.selectedPointRangeStore = data;
      }
      if (window.screen.width > 767) {
        this.filterAndSortApply();
        this.getFilterCategoryTranslation(this.i18nService.getActiveLang);
      }
    });
  }

  ngOnChanges() {
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setPageTo(pageNumber) {
    this.currentPage = pageNumber;
  }

  getOffers() {
    this.loading = true;
    if (isEmpty(this.userService.getCurrentUser)) {
      this.sortingList = [
        {
          id: "ASC",
          value: 'offers.sorting.ASC'
        },
        {
          id: "DESC",
          value: "offers.sorting.DESC"
        },
        {
          id: "NEW",
          value: "offers.sorting.NEW"
        }
      ];
      this.offersService.getGeneralRewardStoreOffers($.param(this.offerConfig)).subscribe((offers) => {
        this.offers = this.transformOffers(offers, false) as any;
        this.loading = false;
      });
    } else {
      this.offersService.getOfferByMemberId($.param(this.offerConfig)).subscribe((offers) => {
        this.offers = this.transformOffers(offers[0].offerList, true) as any;
        this.loading = false;
      });
    }
  }

  getSortingList() {
    this.resetSortingList();
    let langList = { "en_US": 0, "zh_HK": 1, "zh_CN": 2 };
    this.offersService.getUserRewardStores($.param(this.offerConfig)).subscribe((result) => {
      if (result.length > 1) {
        result.map((item) => {
          this.sortingList.push({ id: JSON.parse(item.custom_data)['rewardStoreOrder'], value: item.internal_name.split(";")[langList[this.i18nService.getActiveLang]] });
        })
      }
    })
  };

  resetSortingList() {
    this.sortingList = [
      {
        id: "REC",
        value: "offers.sorting.REC"
      },
      {
        id: "ASC",
        value: 'offers.sorting.ASC'
      },
      {
        id: "DESC",
        value: "offers.sorting.DESC"
      },
      {
        id: "NEW",
        value: "offers.sorting.NEW"
      }
    ];
  }

  transformOffers(records, isAuth: boolean) {
    const self: any = this;
    return _.each(records, function (record: any) {
      const custom_data = JSON.parse(record.offer_details.custom_data);
      const currentLang = self.i18nService.getActiveLang;
      switch (custom_data.offer_category) {
        case "Rewards Store":
          custom_data.offer_category = "Rewards-Store";
          break;
        case "Year-round Privileges":
          custom_data.offer_category = "Year-round-Privileges";
          break;
        case "Monthly Travel Surprises":
          custom_data.offer_category = "Monthly-Travel-Surprises";
          break;
        case "Birthday Delights":
          custom_data.offer_category = "Birthday-Delights";
          break;
        default:
          custom_data.offer_category = "Rewards-Store";
      }
      if (!isAuth && record.offer_details) {
        record.cardContent = {
          id: record.offer_details.root_offer_id,
          title: record.offer_details.title || "",
          description: record.offer_details.description || "",
          cardImg: record.offer_details.reward_store_media.uri || "",
          points: record.offer_details.points,
          shortDescription: custom_data ? custom_data[currentLang + ".offer_card_desc"] : "",
          offer_condition: "Reserved for By-Invitation Members only",
          tagline: custom_data ? custom_data[currentLang + ".tagline"] : "",
          offer_category: custom_data ? custom_data.offer_category : "",
          oos: self.determineIfOos(record.offer_details.available_inventory_remaining),
        };
      } else if (isAuth && record.offer_details) {
        record.cardContent = {
          id: record.offer_details.root_offer_id,
          title: record.offer_details.title || "",
          description: record.offer_details.description || "",
          cardImg: record.offer_details.reward_store_media.uri || "",
          points: record.offer_details.points,
          shortDescription: custom_data ? custom_data[currentLang + ".offer_card_desc"] : "",
          offer_condition: "Reserved for By-Invitation Members only",
          tagline: custom_data ? custom_data[currentLang + ".tagline"] : "",
          offer_category: custom_data ? custom_data.offer_category : "",
          oos: self.determineIfOos(record.offer_details.available_inventory_remaining),
        };
      }
    });
  }

  trackFunc = (index, item) => {
    return index;
  };

  getFilterCategoryTranslation(lang: string) {
    let selectedItemCount = 0;
    this.offersService.getAllRewardsStoreOfferFilteringTranslation().subscribe((data) => {
      this.filteringList = [];
      let shortCodesArr: string[] = [];

      if (lang === "en_US") {
        data.forEach((details) => {
          let detailsObj = { category: details.category, categoryShortCode: details.categoryShortCode };
          this.filteringList = this.filteringList.concat(detailsObj);
        });
      } else if (lang === "zh_HK") {
        data.forEach((details) => {
          let detailsObj = { category: details.categoryTraditionalChinese, categoryShortCode: details.categoryShortCode };
          this.filteringList = this.filteringList.concat(detailsObj);
        });
      } else if (lang === "zh_CN") {
        data.forEach((details) => {
          let detailsObj = { category: details.categorySimpleChinese, categoryShortCode: details.categoryShortCode };
          this.filteringList = this.filteringList.concat(detailsObj);
        });
      };

      data.forEach((details) => {
        shortCodesArr.push(details.categoryShortCode);
      });
      this.filtershortCodes = shortCodesArr.join(",");

      if (isEmpty(this.userService.getCurrentUser)) {
        this.offersService.getRewardStoreOffersCount(undefined, $.param(this.offerConfig), "ASC", this.filtershortCodes, this.selectedPointRangeStore[0], this.selectedPointRangeStore[1]).subscribe((data: { offerFilteringCategory: string, totalOfferCount: number }[]) => {
          this.filterCategoryCount = data;

          this.filteringList.map((data, i) => {
            data.count = this.filterCategoryCount[i].totalOfferCount;
            if (i === 0 && data.count > 0 && this.categoryShortCodePara === "") {
              data.checked = true;
            }
            this.categoryShortCodePara.split(",").map((item) =>{
                // console.log(data.checked);
                if(data.categoryShortCode == item && data.checked === undefined){
                    data.checked = true;
                }
            });
          });
        });
      } else {
        this.offersService.getMemberRewardsStoreOfferCount(undefined, undefined, $.param(this.offerConfig), "ASC", this.filtershortCodes, this.selectedPointRangeStore[0], this.selectedPointRangeStore[1]).subscribe((data: { offerFilteringCategory: string, totalOfferCount: number }[]) => {
          this.filterCategoryCount = data;
          this.filteringList.map((data, i) => {
            data.count = this.filterCategoryCount[i].totalOfferCount;
            if (i === 0 && data.count > 0 && this.categoryShortCodePara === "") {
              data.checked = true;
            }
            this.categoryShortCodePara.split(",").map((item) =>{
                if(data.categoryShortCode == item && data.checked === undefined){
                    data.checked = true;
                }
            });
          });
        });
      }
    });
  };

  filtering(value) {
    this.selectedCategoriesSubject.next(value);
  };

  boxChecked(item) {
    if (item.categoryShortCode === this.filteringList[0].categoryShortCode) {
      this.filteringList = this.filteringList.map(item => {
        return {
          ...item,
          checked: false
        };
      });
      this.filteringList[0].checked = true;
    } else if (item.categoryShortCode !== this.filteringList[0].categoryShortCode && !item.checked) {
      this.filteringList[0].checked = false;
    };
  };

  sorting(item: string) {
    this.selectedSortingItem = item;
    this.filterAndSortApply();
  };

  pointSliderOnAfterChange(selectedPointRangeStore: number[]) {
    this.filteringList = this.filteringList.map(item => {
      return {
        ...item,
        checked: false
      };
    });
    this.filteringList[0].checked = true;
    this.selectedPointRangeStore = selectedPointRangeStore;
    this.selectedPointRangeSubject.next(selectedPointRangeStore);
    if (isEmpty(this.userService.getCurrentUser)) {
      this.offersService.getRewardStoreOffersCount(undefined, $.param(this.offerConfig), "ASC", this.filtershortCodes, this.selectedPointRangeStore[0], this.selectedPointRangeStore[1]).subscribe((data: { offerFilteringCategory: string, totalOfferCount: number }[]) => {
        if (data[0].totalOfferCount === 0) {
          this.shouldShowIcon = false;
        }
      });
    } else {
      this.offersService.getMemberRewardsStoreOfferCount(undefined, undefined, $.param(this.offerConfig), "ASC", this.filtershortCodes, this.selectedPointRangeStore[0], this.selectedPointRangeStore[1]).subscribe((data: { offerFilteringCategory: string, totalOfferCount: number }[]) => {
        if (data[0].totalOfferCount === 0) {
          this.shouldShowIcon = false;
        }
      });
    }
  };

  removeItem(obj){
    this.selectedCategoriesStore = this.selectedCategoriesStore.filter(item => item !== obj);
  }

  filterAndSortApply() {
    this.loading = true;
    let item = this.selectedSortingItem;
    let startingPoint = this.selectedPointRangeStore[0];
    let endingPoint = this.selectedPointRangeStore[1];
    this.selectedCategoriesStore = [];

    if(this.categoryShortCodePara  !== ""){
        this.categoryShortCodePara.split(",").map((shortCodePara) =>{
            this.selectedCategoriesStore.push(shortCodePara);
        });
    }

    this.filteringList.map((item) => {
      if(item.checked === undefined || item.checked === false){
        this.removeItem(item.categoryShortCode);
      }
      if (item.checked && item.categoryShortCode !== "ALL") {
        this.selectedCategoriesStore.push(item.categoryShortCode);
      };
    });

    this.appliedCategoriesStore = JSON.parse(JSON.stringify(this.selectedCategoriesStore));
    this.appliedPointRangeStore = JSON.parse(JSON.stringify(this.selectedPointRangeStore));
    let shortCodeList = this.selectedCategoriesStore.join(",");
    // console.log(shortCodeList);
    if(this.filteringList.length !== 0){
        if(this.filteringList[0].checked === true){
            shortCodeList = "";
            this.filteringList.map((item) => {
                if(item.categoryShortCode !== "ALL"){
                    item.checked = false;
                }
            });
        }
    }

    if(shortCodeList === "" || shortCodeList === undefined){
        this.filteringList.map((item) => {
            if(item.categoryShortCode === "ALL"){
                item.checked = true;
            }
        });
    }

    if (isEmpty(this.userService.getCurrentUser)) {
      if (this.generalOfferSubscription) {
        this.generalOfferSubscription.unsubscribe();
      }
      if (item === "ASC" || item === "DESC") {
        this.generalOfferSubscription = this.offersService.getGeneralRewardStoreOffers($.param(this.offerConfig), "POINT_VALUE", item, shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers, false) as any;
          this.loading = false;
        });
      } else if (item === "NEW") {
        this.generalOfferSubscription = this.offersService.getGeneralRewardStoreOffers($.param(this.offerConfig), "START_DATE", "DESC", shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers, false) as any;
          this.loading = false;
        });
      } else {
        this.generalOfferSubscription = this.offersService.getGeneralRewardStoreOffers($.param(this.offerConfig), undefined, undefined, shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers, false) as any;
          this.loading = false;
        });
      }
    } else {
      if (this.memberOfferSubscription) {
        this.memberOfferSubscription.unsubscribe();
      }

      if (item === "ASC" || item === "DESC") {
        this.memberOfferSubscription = this.offersService.getOfferByMemberId($.param(this.offerConfig), undefined, undefined, "POINT_VALUE", item, shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers[0].offerList, true) as any;
          this.loading = false;
        });
      } else if (item === "NEW") {
        this.memberOfferSubscription = this.offersService.getOfferByMemberId($.param(this.offerConfig), undefined, undefined, "START_DATE", "DESC", shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers[0].offerList, true) as any;
          this.loading = false;
        });
      } else if (item === "GENERAL" || item === "BY_INVITATION") {
        this.memberOfferSubscription = this.offersService.getOfferByMemberId($.param(this.offerConfig), undefined, undefined, item, "DESC", shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers[0].offerList, true) as any;
          this.loading = false;
        });
      } else if (Number.isInteger(parseInt(item)) && parseInt(item) !== 1) {
        this.memberOfferSubscription = this.offersService.getOfferByMemberId($.param(this.offerConfig), undefined, undefined, "reward_store_tier", "DESC", shortCodeList, startingPoint, endingPoint, parseInt(item)).subscribe((offers) => {
          this.offers = this.transformOffers(offers[0].offerList, true) as any;
          this.loading = false;
        });
      } else if (parseInt(item) === 1) {
        this.memberOfferSubscription = this.offersService.getOfferByMemberId($.param(this.offerConfig), undefined, undefined, "reward_store_tier", "ASC", shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers[0].offerList, true) as any;
          this.loading = false;
        });
      } else {
        this.memberOfferSubscription = this.offersService.getOfferByMemberId($.param(this.offerConfig), undefined, undefined, undefined, undefined, shortCodeList, startingPoint, endingPoint).subscribe((offers) => {
          this.offers = this.transformOffers(offers[0].offerList, true) as any;
          this.loading = false;
        });
      }
    };
  };

  resetFilters() {
    this.filteringList = this.filteringList.map(item => {
      return {
        ...item,
        checked: false
      };
    });
    this.filteringList[0].checked = true;
    this.selectedPointRangeStore = [0, 1000000];
    this.selectedCategoriesStore = [];
    this.categoryShortCodePara = "";
    this.filterAndSortApply();
    this.getFilterCategoryTranslation(this.i18nService.getActiveLang);
  };

  closeFilterDropdown() {
    this.shouldFilterDropdownOpen = false;
  };

  setLastAppliedFilteringOptions() {
    if (this.shouldFilterDropdownOpen === true || window.screen.width > 767) {
      return;
    }
    this.filteringList = this.filteringList.map(item => {
      return {
        ...item,
        checked: false
      };
    });
    this.filteringList[0].checked = this.appliedCategoriesStore.length === 0;
    this.selectedPointRangeStore = JSON.parse(JSON.stringify(this.appliedPointRangeStore));
    this.filteringList.map(item => {
      this.appliedCategoriesStore.forEach((appliedCategory) => {
        if (appliedCategory === item.categoryShortCode) {
          item.checked = true;
        }
      })
    });
  }

  determineIfOos(value){
    if(!isNaN(Number(value)) && value <= 0){
      return true
    }
    return false
  }
  getCardDisplayClass(){
    if(this.offerConfig.offerCategory=='Rewards Store'){
      return {'is-half-mobile':true, 'rewards-store':true}
    }
    else {
      return {'is-full-mobile':true}
    }
  }
  getIsRewardsStore(){
    if(this.offerConfig.offerCategory=='Rewards Store'){
      return {'rewards-store':true};
    }
  }
}

