import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, CommonService } from "../../../core";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";

@Component({
  selector: "app-social-login",
  templateUrl: "./social-login.component.html",
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private userService: UserService, private commonService: CommonService, private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.spinner.show("social-login");
    // TODO: Add retry for getting member into attempt
    this.subscription = this.userService.currentUser.subscribe((userData) => {
      if (userData && userData.sfdcProfile) {
        this.spinner.hide("social-login");
        if (this.userService.isMemberProfileIncomplete) {
          this.userService.redirectToSupplementProfile();
        } else {
          this.commonService.redirect("/home");
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
