import { BehaviorSubject } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';

@Injectable()
export class CommonService {
  private isShowCloseButtonSubject$ = new BehaviorSubject<boolean>(false);
  public isShowCloseButton$ = this.isShowCloseButtonSubject$.asObservable();

  // Important Notice
  private isShowImportantNoticeSubject$ = new BehaviorSubject<boolean>(false);
  public isShowImportantNotice$ = this.isShowImportantNoticeSubject$.asObservable();

  private isProtectedPageSubject$ = new BehaviorSubject<boolean>(false);
  public isProtectedPage$ = this.isShowCloseButtonSubject$.asObservable();

  private backLocationSubject$ = new BehaviorSubject<string>(null);
  public backLocation$ = this.isShowCloseButtonSubject$.asObservable();

  private shouldShowHeaderFooterSubject$ = new BehaviorSubject<boolean>(true);
  public shouldShowHeaderFooter$ = this.shouldShowHeaderFooterSubject$.asObservable();

  private shouldShowLiveChatSubject$ = new BehaviorSubject<boolean>(environment.LIVE_CHAT_CONFIG.isLiveChatActivated);
  public shouldShowLiveChat$ = this.shouldShowLiveChatSubject$.asObservable();

  private shouldHideComponentSubject$ = new BehaviorSubject<boolean>(true);
  public shouldHideComponent$ = this.shouldHideComponentSubject$.asObservable();

  constructor(private injector: Injector, private spinner: NgxSpinnerService, private _location: Location) {}

  setImportantNoticeSection(bool: boolean) {
    this.isShowImportantNoticeSubject$.next(bool);
  }

  isShowCloseButton(bool: boolean) {
    this.isShowCloseButtonSubject$.next(bool);
  }

  isProtectedPage(bool: boolean) {
    this.isProtectedPageSubject$.next(bool);
  }

  setBackLocation(url: string) {
    this.backLocationSubject$.next(url);
  }

  setShowHeaderFooter(bool: boolean) {
    this.shouldShowHeaderFooterSubject$.next(bool);
  }

  setLiveChat(bool: boolean) {
    this.shouldShowLiveChatSubject$.next(bool);
  }

  setHideComponent(bool: boolean) {
    this.shouldHideComponentSubject$.next(bool);
  }

  backToPreviousState() {
    const url = this.backLocationSubject$.value;
    if (url) {
      this.redirect(url);
    } else {
      this._location.back();
    }
  }

  get isPageProtected() {
    return this.isProtectedPageSubject$.value;
  }

  /**
   * Browser wait
   * @param ms
   */
  browserDelay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Browser wait
   * @param ms
   */
  browserDelayWithSpinner(ms: number) {
    return new Promise((resolve) => {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        resolve();
      }, ms);
    });
  }

  /**
   * Navigation with i18n URL support
   */
  redirect(path: string | any[], extraOptions?: any, routerData?: any) {
    const router = this.injector.get(Router);
    const localizeRouter = this.injector.get(LocalizeRouterService);
    const localizePath = localizeRouter.translateRoute(path);
    // console.log('localizePath', localizePath, extraOptions, routerData);
    if (extraOptions || routerData) {
      if (extraOptions && routerData) {
        router.navigate([localizePath, extraOptions], routerData);
      } else if (extraOptions && !routerData) {
        router.navigate([localizePath, extraOptions]);
      } else if (routerData && !extraOptions) {
        router.navigate([localizePath], routerData);
      }
    } else {
      router.navigate([localizePath]);
    }
  }
}
