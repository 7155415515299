import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutProgrammeResolverService } from './about-programme-resolver.service';
import { AboutProgrammeComponent } from "./about-programme.component";

const routes: Routes = [
  {
    path: "",
    component: AboutProgrammeComponent,
    resolve: [AboutProgrammeResolverService],
    data: {
      title: {
        en_US: "About Us | HKairport Rewards",
        zh_HK: "關於我們 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "关于我们 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `About Us | HKairport Rewards`,
          },
          {
            name: "description",
            content: `Earn up to 6% in everyday spending rewards, with monthly surprises and year-round privileges in Hong Kong International Airport.`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `關於我們 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
          {
            name: "description",
            content: `香港國際機場獎賞計劃，讓您盡享每日高達6%消費獎賞、每月出遊賞及人氣商戶的全年優惠！`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `关于我们 | HKairport Rewards 香港国际机场奖赏计划`,
          },
          {
            name: "description",
            content: `香港国际机场奖赏计划，让您尽享每日高达6%消费奖赏、每月出游赏及人气商户的全年优惠！`,
          },
        ],
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AboutProgrammeRoutingModule {}
