import { ApiService } from "./api/api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class FaqService {
  constructor(private apiService: ApiService) {}

  getKnowledgeArticle(language: string, category: string): Observable<any> {
    return this.apiService.get(`/faq?category=${category}&language=${language}`, "member", null).pipe(
      map(data => {
        return data;
      })
    );
  }
}
