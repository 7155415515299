import { ActivatedRouteSnapshot, CanActivateChild, CanActivate, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { LocalizedRouter, LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { I18NService } from "./i18n.service";

@Injectable()
export class GeneralGuard implements CanActivate, CanActivateChild {
  loggedInBefore: boolean = false;
  constructor(private userService: UserService, private oauthService: OAuthService, private authStorage: OAuthStorage, private router: Router, 
    private localizeRouter: LocalizeRouterService, private i18nService: I18NService) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let userAgent = window.navigator.userAgent
    return this.userService.isAuthenticated.pipe(
      map((isAuthenticated) => {
        // Check User Profile Completeness
        if (isAuthenticated) {
          this.loggedInBefore = true;
        }
        if ((this.loggedInBefore && !this.authStorage.getItem("access_token")) || (this.authStorage.getItem("access_token") && !this.oauthService.hasValidAccessToken())) {
          const targetUrl = state.url;
          const expireObject = {
            value: true,
            targetUrl: targetUrl,
          };
          localStorage.setItem("expireObject", JSON.stringify(expireObject));
          if(userAgent.indexOf("Mobile") != -1){
            if(userAgent.indexOf("Windows") == -1 || userAgent.indexOf("Harmony") == -1 || userAgent.indexOf("Firefox") == -1 || userAgent.indexOf("Opera") == -1 || userAgent.indexOf("UCBrowser") == -1 ||  userAgent.indexOf("QQ") == -1 || userAgent.indexOf("Edg") == -1 || userAgent.indexOf("sogou") == -1 || userAgent.indexOf("Quark") == -1){
              return true;
            }else{
            }
          }
          this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
        }
        if (this.userService.isSfUserProfileExist && this.userService.isMemberProfileIncomplete && !route.data.isSkipGeneralGuard) {
          this.userService.redirectToSupplementProfile();
        } else {
          return true;
        }
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.isAuthenticated.pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          this.loggedInBefore = true;
        }
        if ((this.loggedInBefore && !this.authStorage.getItem("access_token")) || (this.authStorage.getItem("access_token") && !this.oauthService.hasValidAccessToken())) {
          const targetUrl = state.url;
          const expireObject = {
            value: true,
            targetUrl: targetUrl,
          };
          localStorage.setItem("expireObject", JSON.stringify(expireObject));
          this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
        }
        // Check User Profile Completeness
        if (this.userService.isSfUserProfileExist && this.userService.isMemberProfileIncomplete && !route.data.isSkipGeneralGuard) {
          // const state = decodeURIComponent(this.oauthService.state.split(/[#]/)[0]);
          // const locationLang = this.localizeRouter.parser.getLocationLang(state);
          // this.i18nService.use(locationLang, false);
          /** 
           * Set the language of the redirect page
           * 1.Get "preferredLang", "loaclLang", and the language used in the current link
           * 2."preferredLang" has a value and "loaclLang" has no value, use "preferredLang"
           * 3."loaclLang" has a value, Priority use "loaclLang"
          */
          let preferredLang: any = this.userService.userPreferredLanguage;
          let loaclLang: any = sessionStorage.getItem("MP_SELECTED_LANGUAGE") || sessionStorage.getItem("MP_SPPL_LANGUAGE");
          let currentLang: any = this.localizeRouter.translateRoute("/");
          if(!!preferredLang && !loaclLang){
            currentLang = preferredLang
          }
          if(!!loaclLang){
            currentLang = loaclLang
          }
          this.i18nService.use(currentLang,false);
          let path: any  = `/${currentLang}/account/incomplete`
          return this.router.parseUrl(path);
        } else {
          return true;
        }
      })
    );
  }
}
