import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from "@angular/forms";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Subscription, of } from "rxjs";
import { TimeService } from "../../../core/services/time.service";
import { UserService, FormService, AlertService, CommonService, I18NService, phoneValidator, OtpConfig } from "../../../core";
import { trigger, transition, style, animate } from "@angular/animations";
import { ModalService } from '../../../core/services/modal.service';
import { OtpBoxComponent, PPUrlArr } from '../../../shared';
import { map, catchError } from "rxjs/operators";

@Component({
  selector: "app-supplement-profile",
  templateUrl: "./supplement-profile.component.html",
  styleUrls: ["./supplement-profile.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate("0.4s ease-out", style({ height: 203, opacity: 1 }))]),
      transition(":leave", [style({ height: 300, opacity: 1 }), animate("0.4s ease-in", style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class SupplementProfileComponent implements OnInit, OnDestroy {
  @ViewChild("otpBox", { static: false }) public otpBox: OtpBoxComponent;

  // Dropdown items
  areaList = [];
  companyList = [];
  countryCodeList = [];
  // isShowSecurityQA = false;
  isUserProfileReady = false;
  monthPickList = this.timeService.monthList;
  picsLink = "";
  private subscription: Subscription;
  public isSubmittingForm = false;
  public updateProfileForm: FormGroup;
  salutationList = [];
  // securityQuestionList = [];
  tncLink = "";
  yearPickList = this.timeService.yearList;
  showMktOpt = true;
  isShowPassword: boolean = false;
  userInfo: any;
  redirectPlatformLink: string;
  personEmail = null;
  PPUrlArr = PPUrlArr;
  isPhoneReadOnly: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private timeService: TimeService,
    private formService: FormService,
    private localizeRouter: LocalizeRouterService,
    private alertService: AlertService,
    private commonService: CommonService,
    private i18nService: I18NService,
    private modalService: ModalService
  ) {
    this.initFrom();
    this.route.data.subscribe((data: { listData: any[] }) => {
      if (data.listData) {
        // this.securityQuestionList = data.listData[0][this.i18nService.getActiveLang];
        this.countryCodeList = data.listData[1][this.i18nService.getActiveLang];
        this.salutationList = data.listData[4][this.i18nService.getActiveLang];
        this.tncLink = this.localizeRouter.translateRoute("/legal/terms-and-condition") as string;
        this.picsLink = this.localizeRouter.translateRoute("/legal/personal-information-collection-statement") as string;
      }
    });

    this.route.queryParams.subscribe((value) => {
      if (value["cb"]) {
        this.commonService.setShowHeaderFooter(false);
        this.redirectPlatformLink = decodeURI(value["cb"]);
      }
    });
  }

  ngOnInit() {
    this.subscription = this.userService.getMember().subscribe((userData) => {
      this.userInfo = userData;
      if (userData !== null && userData.personEmail !== null && userData.personEmail !== undefined && userData.personEmail !== "") {
        this.personEmail = userData.personEmail;
      }
      if(!userData.phoneAddressVerified){
        this.phoneNumber.setValidators([Validators.minLength(1), phoneValidator,Validators.required]);
      }
      // if (userData.phone && userData.phone !== null && !this.userService.isSecurityQuestionExist) {
      //   this.isShowSecurityQA = true;
      //   this.updateProfileForm.addControl("securityQuestion", new FormControl(null, Validators.required));
      //   this.updateProfileForm.addControl("securityAnswer", new FormControl(null, Validators.required));
      // }
      if (userData.directMktOptIn) {
        this.showMktOpt = false;
      }
      this.updateProfileForm.patchValue(userData);
      this.isUserProfileReady = true;
    },
      (error) => {
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initFrom() {
    this.updateProfileForm = this.fb.group({
      agreeTerms: [false, [Validators.requiredTrue]],
      directMktOptIn: [false],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      personTitle: [null, [Validators.required]],
      phoneNumber: [
        '',
        {
          validators: [Validators.minLength(1), phoneValidator],
          asyncValidators: this.validatePhoneNotTaken,
          updateOn: "blur",
        },
      ],
      phoneNumberPrefix: ["852_Hong Kong (+852)", []],
    });
  }

  preSubmitForm() {
    if(this.otpBox && !this.otpBox.verificationForm.controls.verCode.valid){
      this.otpBox.verCode.setErrors({ required: true });
      return
    }
    if (this.updateProfileForm.valid) {
      this.submitForm();
    } else {
      for (const i in this.updateProfileForm.controls) {
        this.updateProfileForm.controls[i].markAsDirty();
        this.updateProfileForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  submitForm(): void {
    const formData = this.transformRegisterForm();
    this.isSubmittingForm = true;
    this.userService.updateProfile(formData).subscribe(
      async () => {
        await this.commonService.browserDelayWithSpinner(10000);
        await this.userService.reloadUserInfo();
        this.alertService.success(this.i18nService.translateLabel("profile.form.updated"));
        if (this.redirectPlatformLink != null) {
          window.open(this.redirectPlatformLink, "_self");
        } else {
          const path = this.localizeRouter.translateRoute("/home");
          this.router.navigate([path]);
        }
      },
      (err) => {
        this.isSubmittingForm = false;
      }
    );
  }

  transformRegisterForm() {
    // Seems need to implement to back-end
    const requestBody = {
      directMktOptIn: this.updateProfileForm.controls.directMktOptIn.value,
      firstName: this.updateProfileForm.controls.firstName.value,
      lastName: this.updateProfileForm.controls.lastName.value,
      personTitle: this.updateProfileForm.controls.personTitle.value,
      memberNumber: this.userService.getCurrentUser.memberNumber,
      // securityAnswer: this.updateProfileForm.controls.securityAnswer ? this.updateProfileForm.controls.securityAnswer.value : null,
      // securityQuestion: this.updateProfileForm.controls.securityQuestion ? this.updateProfileForm.controls.securityQuestion.value : null,
      acknowledgedTnC: this.updateProfileForm.controls.agreeTerms.value,
      personEmail: this.personEmail ? this.personEmail: null,
      phoneNumberPrefix: null,
      phone: null,
      phoneNumber: null,
      userPhone: null,
      countryCode: null,
      phoneVerCode: null,
      verificationChannel:"Mobile"
    };
    if(!!this.otpBox){
      requestBody.phoneVerCode = this.otpBox.verificationForm.controls.verCode.value
      requestBody.countryCode = this.phoneNumberPrefix.value
      requestBody.phone = this.phoneNumber.value,
      requestBody.phoneNumber = this.phoneNumber.value,
      requestBody.userPhone = this.phoneNumberPrefix.value.split("_")[0] + this.phoneNumber.value,
      requestBody.phoneNumberPrefix = this.phoneNumberPrefix.value
    }
    return _.omit(requestBody, _.isNull);
  }

  trackFunc = (index, item) => {
    return index;
  };

  // trimSecurityAnswer() {
  //   const self: any = this;
  //   const value = this.securityAnswer.value;
  //   if (value && value.trim() !== value) {
  //     this.securityAnswer.patchValue(value.trim());
  //     self.isTrimmed = true;
  //   } else {
  //     self.isTrimmed = false;
  //   }
  // }

  showModal(modalType: "TC" | "PP" | "CU") {
    if (modalType === 'PP') {
      window.open(this.PPUrlArr[this.i18nService.getActiveLang], '_blank');
    } else {
      this.modalService.showModal(modalType);
    }
  };

  onCaptchaChanged(isValid: boolean) {
    if(!this.phoneNumber.value){
      this.phoneNumber.setErrors({ required: true });
      return
    }
    if(isValid && !this.verifyFirstInput(this.phoneNumber.value)){
      this.phoneNumber.setErrors({ cannotGetCaptcha: true });
      return
    }
  }
    
  onOtpChanged(isValid: boolean) {
    if(isValid){
      this.isPhoneReadOnly = true;
    }
  }

  verifyFirstInput(emailOrNumber:string){
    const firstInput = JSON.parse(sessionStorage.getItem('firstInput'))
    const currentTime = new Date().getTime()
    if(!!firstInput && firstInput.emailOrNumber === emailOrNumber && (currentTime - firstInput.time < 300000)){
      return false
    }
    return true
  }
  
  validatePhoneNotTaken = (control: AbstractControl) => {
    if (control && !control.pristine && control.value && control.value.trim() !== "") {
      return this.userService.checkDuplicatedMobile(this.phoneNumberPrefix.value.split("_")[0] + control.value).pipe(
        map((isValid) => null),
        catchError(() => of({ error: true, duplicated: true }))
      );
    } else {
      return of(null);
    }
  };

  // get securityAnswer() {
  //   return this.updateProfileForm.get("securityAnswer") as FormControl;
  // }

  get checkPassword() {
    return this.updateProfileForm.get("checkPassword") as FormControl;
  }

  get phoneNumber() {
    return this.updateProfileForm.get("phoneNumber") as FormControl;
  }

  get phoneNumberPrefix() {
    return this.updateProfileForm.get("phoneNumberPrefix") as FormControl;
  }
  get otpConfig(): OtpConfig {
    if (!this.otpBox) {
      return;
    }
    return {
      type: "Mobile",
      otpType: "upgrade",
      id: this.phoneNumber.value,
      countryCode: this.phoneNumberPrefix.value,
    };
  }
}
