import { Component } from "@angular/core";

@Component({
  selector: "app-privileges-page",
  templateUrl: "./privileges-page.component.html",
  styleUrls: ["./privileges-page.component.scss"],
})

export class EarnAndRedeemComponent {
  constructor() {}
}
