import * as _ from "underscore";
import { CommonService, UserService, I18NService, AlertService } from "../../core";
import { Component, OnInit } from "@angular/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { isEmpty } from "lodash";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing.component.html",
})
export class LandingComponent implements OnInit {
  currentLang: string;
  constructor(
    private commonService: CommonService,
    private oauthService: OAuthService,
    private userService: UserService,
    private i18nService: I18NService,
    private alertService: AlertService,
    private router: Router,
    private localizeRouter: LocalizeRouterService
  ) { }

  async ngOnInit() {
    const expireObject = localStorage.getItem("expireObject");
    this.i18nService.currentLang.subscribe(lang => this.currentLang = lang);
    if (expireObject && !expireObject.includes("earn") && !expireObject.includes("profile")) {
      const thisObject = JSON.parse(expireObject);
      const locationLang = this.localizeRouter.parser.getLocationLang(thisObject.targetUrl);
      this.i18nService.use(locationLang, false);
      this.router.navigateByUrl(thisObject.targetUrl, { replaceUrl: true });
      await this.commonService.browserDelay(500);
      this.alertService.warning(this.i18nService.translateLabel("COMMON.MESSAGE.SESSION_TIMEOUT"), 0);
      localStorage.removeItem("expireObject");
    } else {
      if (expireObject) {
        localStorage.removeItem("expireObject");
      }
      if (!this.oauthService.state || this.oauthService.state.indexOf("unsubscribe") !== -1 ||
        ((this.oauthService.state.indexOf("account") !== -1 && this.oauthService.state.indexOf("account/incomplete") === -1 && this.oauthService.state.indexOf("account/change-password") === -1 && this.oauthService.state.indexOf("profile/edit") === -1 && this.oauthService.state.indexOf("profile/update-ara") === -1)
        )) {
        if (!isEmpty(this.userService.getCurrentUser) && (this.oauthService.state.indexOf("unsubscribe") !== -1 || this.oauthService.state.indexOf("account") !== -1)) {
          this.router.navigateByUrl(`/${this.currentLang}/home`, { replaceUrl: true });
        } else {
          this.router.navigateByUrl(`/${this.currentLang}/home`, { replaceUrl: true });
        }
      } else if (this.oauthService.state.includes("home")) {
        this.router.navigateByUrl(`/${this.currentLang}/home`, { replaceUrl: true });
      } else if (this.oauthService.state) {
        console.log('this.oauthService.state - route with state [Landing]', this.oauthService.state);
        await this.commonService.browserDelay(300);
        if(this.userService.inMyHkgWebView) return
        this.router.navigateByUrl(this.oauthService.state, { replaceUrl: true }).then(() => {
        }).catch((error) => {
          console.log('route error', error);
        });
        // console.log('Redirect to state', this.oauthService.state);
      }
    }
  }
}
