import * as _ from "underscore";
import { Component, OnInit } from "@angular/core";
import { GlobalConfig } from "../../../core/app-global.config";
import { I18NService } from "../../../core/services/i18n.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { User, UserService, MenuItem, CommonService } from "../../../core";
import { isEmpty } from "lodash";
import { menuItems } from "./header.config";
import { trigger, transition, animate, style } from "@angular/animations";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { environment } from '../../../../environments/environment';
import { WalletComponent } from "./wallet/wallet.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-layout-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("0.2s ease-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("0.2s ease-in", style({ opacity: 0 }))]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  activeLangId: string;
  availableLangs: any;
  backLocation: string;
  currentLang: string;
  currentUser: User = {} as User;
  resetPasswordLink: string;
  headerMenuItems: MenuItem[] = menuItems.filter((item) => item.enable); // Only show enabled menu item
  isShowLanguageSection = false;
  isShowPasswordAlert = false;
  userName: string;
  currentPageLang: string;
  shouldHideComponent: boolean = true;
  isMobile: boolean = false;

  constructor(
    private commonService: CommonService,
    private i18nService: I18NService,
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private metaService: Meta,
    private localizeRouter: LocalizeRouterService,
    public dialog: MatDialog
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join("-");
        if (title) {
          titleService.setTitle(title);
        } else {
          titleService.setTitle(GlobalConfig.APP_TITLE[this.i18nService.getActiveLang]);
        }
      }
    });

    this.checkMobile();
  }

  ngOnInit() {
    this.availableLangs = this.i18nService.getLangs();
    this.userService.currentUser.subscribe((userData) => {
      setTimeout(() => {
        this.currentUser = userData;
        this.updateDisplayName(this.activeLangId);
      }, 100);
    });

    const _this = this;
    this.i18nService.currentLang.subscribe((lang) => {
      this.currentPageLang = lang;
      this.activeLangId = this.i18nService.getActiveLang;
      this.currentLang = _.find(this.availableLangs, function (item: any) {
        return item.langId === _this.i18nService.getActiveLang;
      }).text;
      this.updateDisplayName(this.activeLangId);
      if (this.isShowPasswordAlert) {
        this.resetPasswordLink = this.resolveHeaderPath(this.currentPageLang);
      }
    });

    this.commonService.isShowImportantNotice$.subscribe((result) => {
      this.isShowPasswordAlert = result;
      this.resetPasswordLink = this.resolveHeaderPath(this.currentPageLang);
    });

    this.commonService.shouldHideComponent$.subscribe((value) => {
      this.shouldHideComponent = value;
    });
  }

  resolveHeaderPath(currentLang :string){
    let redirectPath:string = this.localizeRouter.translateRoute("/account/change-password") as string;
    return redirectPath.replace("en_US",currentLang).replace("zh_HK",currentLang).replace("zh_CN",currentLang);
  }

  goToHome(){
    if(!this.shouldHideComponent){
      return
    }
    this.commonService.redirect('/home');
  }

  goToChangePasswordPage(){
     this.commonService.redirect("/account/change-password");
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data) {
      if (parent.snapshot.data.title) {
        data.push(parent.snapshot.data.title[this.i18nService.getActiveLang]);
      }
      if (parent.snapshot.data.metaTitle) {
        this.metaService.addTags(parent.snapshot.data.metaTitle[this.i18nService.getActiveLang]);
      }
      if (parent.snapshot.data.backRoute) {
        this.commonService.setBackLocation(parent.snapshot.data.backRoute);
      } else {
        this.commonService.setBackLocation(null);
      }
    }
    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  switchLanguage(selectedLang) {
    this.isShowLanguageSection = false;
    this.currentLang = selectedLang.text;
    this.i18nService.use(selectedLang.langId);
    sessionStorage.setItem("MP_SELECTED_LANGUAGE", selectedLang.langId);
  }

  closeLanguageBar(event) {
    if (this.isShowLanguageSection) {
      this.isShowLanguageSection = false;
    }
  }

  updateDisplayName(langID) {
    if (!isEmpty(this.userService.getCurrentUser)) {
      if (langID === "en_US") {
        this.userName = this.userService.getCurrentUser.sfdcProfile.given_name + " " + this.userService.getCurrentUser.sfdcProfile.family_name;
      } else if (langID === "zh_CN" || "zh_HK") {
        this.userName = this.userService.getCurrentUser.sfdcProfile.family_name + " " + this.userService.getCurrentUser.sfdcProfile.given_name;
      }
    } else {
      this.userName = this.i18nService.translateLabel("COMMON.LABEL.LOADING");
    }
  }

  isMatchRouter(link) {
    return this.router.url.indexOf(link) !== -1;
  }

  openLink(item: MenuItem) {
    if (item.externalLink) {
      const externalLink = item.link[this.i18nService.getActiveLang];
      window.open(externalLink, "_blank");
    } else {
      this.commonService.redirect(item.link);
    }
  }

  toggleLangSection() {
    this.isShowLanguageSection = !this.isShowLanguageSection;
  }

  login() {
    this.userService.login();
  }

  closeImportantNotice() {
    this.isShowPasswordAlert = false;
    this.commonService.setImportantNoticeSection(false);
  }

  trackFunc = (index, item) => {
    return index;
  };

  openWalletDialog() {
    this.dialog.open(WalletComponent, {
      width: "50%",
      minWidth: "350px",
      maxWidth: "500px",
      panelClass: "qrcode-dialog-container",
      disableClose: true
    })
  }


  checkMobile(): void {
    var ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      this.isMobile = false;
    }
    else if (/Mobile|Android|iP(hone|od)/.test(ua)) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

}
