declare var $: any;
import * as _ from "underscore";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { OffersService, I18NService, UserService } from "../../core";
import { distinctUntilChanged } from "rxjs/operators";
import { isEmpty } from "lodash";
import { PromotionCarouselConfig } from "../../core/models/promotion-carousel-list.model";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: "app-offer-carousel",
  templateUrl: "./offer-carousel.component.html",
  styleUrls: ["./offer-carousel.component.scss"],
})
export class OfferCarouselComponent implements OnInit, OnDestroy {
  @Input() offerConfig: any;
  offers = [];

  constructor(private offersService: OffersService, private i18nService: I18NService, private userService: UserService) {}

  @Input() limit: number;
  @Input()
  set carouselArray(config: PromotionCarouselConfig[]) {
    if (config) {
      this.slides = config;
    }
  }

  slides: PromotionCarouselConfig[];
  loading = true;
  currentPage = 1;

  swiperConfig: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 4,
    spaceBetween: 40,
    allowTouchMove: false,
    breakpoints: {
      1369: {
        pagination: true,
        slidesPerView: "auto",
        spaceBetween: 32,
        allowTouchMove: true,
      },
      767: {
        pagination: true,
        slidesPerView: 1.2,
        spaceBetween: 32,
        allowTouchMove: true,
      }
    },
  };

  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.i18nService.currentLang.pipe(distinctUntilChanged()).subscribe((lang) => {
      if (lang) {
        this.getOffers();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setPageTo(pageNumber) {
    this.currentPage = pageNumber;
  }

  getOffers() {
    this.loading = true;
    if (isEmpty(this.userService.getCurrentUser)) {
      this.offersService.getGeneralRewardStoreOffers($.param(this.offerConfig)).subscribe((offers) => {
        this.offers = this.transformOffers(offers, false) as any;
        this.loading = false;
      });
    } else {
      this.offersService.getOfferByMemberId($.param(this.offerConfig)).subscribe((offers) => {
        this.offers = this.transformOffers(offers[0].offerList, true) as any;
        this.loading = false;
      });
    }
  }

  transformOffers(records, isAuth: boolean) {
    const self: any = this;
    return _.each(records, function (record: any) {
      const custom_data = JSON.parse(record.offer_details.custom_data);
      const currentLang = self.i18nService.getActiveLang;
      switch (custom_data.offer_category) {
        case "Rewards Store":
          custom_data.offer_category = "Rewards-Store";
          custom_data.offer_category_label = "offers.rewardsStore";
          break;
        case "Year-round Privileges":
          custom_data.offer_category = "Year-round-Privileges";
          custom_data.offer_category_label = "offers.yearRoundPrivileges";
          break;
        case "Monthly Travel Surprises":
          custom_data.offer_category = "Monthly-Travel-Surprises";
          custom_data.offer_category_label = "offers.monthlyTravelSurprises";
          break;
        case "Birthday Delights":
          custom_data.offer_category = "Birthday-Delights";
          custom_data.offer_category_label = "offers.birthdayDelights";
          break;
        default:
          custom_data.offer_category = "Rewards-Store";
          custom_data.offer_category_label = "offers.rewardsStore";
      }

      if (!isAuth && record.offer_details) {
        record.cardContent = {
          id: record.offer_details.root_offer_id,
          title: record.offer_details.title || "",
          description: record.offer_details.description || "",
          cardImg: record.offer_details.reward_store_media.uri || "",
          points: record.offer_details.points,
          shortDescription: custom_data ? custom_data[currentLang + ".offer_card_desc"] : "",
          offer_condition: "Reserved for By-Invitation Members only",
          offer_category: custom_data ? custom_data.offer_category : "",
          offer_category_label: custom_data ? custom_data.offer_category_label : "",
        };
      } else if (isAuth && record.offer_details) {
        record.cardContent = {
          id: record.offer_details.root_offer_id,
          title: record.offer_details.title || "",
          description: record.offer_details.description || "",
          cardImg: record.offer_details.reward_store_media.uri || "",
          points: record.offer_details.points,
          shortDescription: custom_data ? custom_data[currentLang + ".offer_card_desc"] : "",
          offer_condition: "Reserved for By-Invitation Members only",
          offer_category: custom_data ? custom_data.offer_category : "",
          offer_category_label: custom_data ? custom_data.offer_category_label : "",
        };
      }
    });
  }

  trackFunc = (index, item) => {
    return index;
  };
}
