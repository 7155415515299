import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18NService, UserService } from '../../../core';

@Component({
  selector: 'app-earn-point-success',
  templateUrl: './earn-point-success.component.html',
  styleUrls: ['./earn-point-success.component.scss']
})
export class EarnPointSuccessComponent implements OnInit {
  @ViewChild('message', null) messageTemplate: ElementRef;
  message: string;
  messageType: string = "success";

  constructor(
    public dialogRef: MatDialogRef<EarnPointSuccessComponent>,
    private router: Router,
    private i18NService: I18NService,
    private userService: UserService
  ) {
    this.userService.getEarnPointMessage(this.messageType).subscribe(
      (data) => {
        this.message = data.responseInfo.replace(/\\n/g,"<br>");
        this.messageTemplate.nativeElement.innerHTML = this.message;
      });
   }

  ngOnInit() {
  }


  redirectToProfile() {
    const language = this.i18NService.getActiveLang;
    this.router.navigate([`/${language}/profile/personal-info`]);
  }
}
