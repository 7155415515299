const QRCode = require("qrcode");
const JsBarcode = require("jsbarcode");
export class QrCodeService {
  constructor() {}

  getQRCode(code: string): Promise<String> {
    return QRCode.toDataURL(code, {
      type: "svg",
      quality: 0.3,
      width: 300
    })
      .then(url => {
        return url;
      })
      .catch(err => {
        console.error(err);
      });
  }

  get getBarCode() {
    return JsBarcode;
  }
}
