import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { CAROUSEL_CONFIG, CTA_CONFIG} from "./home.config";
import { I18NService, UserService } from '../../core';

@Component({
  selector: "app-home-page",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  highlightedOfferConfig: any;
  carouselArray = CAROUSEL_CONFIG;
  joinNowConfig = CTA_CONFIG;
  language: string;
  receiptNumber: string;
  
  constructor(
    private router: Router,
    private userServer: UserService,
    private i18NService: I18NService,
  ) {
    this.highlightedOfferConfig = {
      highlighted: true,
    };

    this.language = this.i18NService.getActiveLang;
    this.receiptNumber = localStorage.getItem("earn-now");
    if (localStorage.getItem("earn-now")) {
      this.userServer.isAuthenticated.subscribe((isAuthenticated) => {
        if (isAuthenticated && localStorage.getItem("earn-now")) {
          this.router.navigate([`/${this.language}/earn-now/${this.receiptNumber}`]);
        }
      });
    }
  }

}
