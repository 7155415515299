import { AuthGuard } from "../../core";
import { NgModule } from "@angular/core";
import { EarnNowComponent } from "./earn-now.component";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: ":id",
    component: EarnNowComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EarnNowRoutingModule {}
