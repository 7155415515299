import { Component, OnInit, OnDestroy } from '@angular/core';
import { FaqService, I18NService } from '../../core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../core/services/modal.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  currentLangSubscription: Subscription;
  tncSub: Subscription;
  contactSub: Subscription;
  isTCModalVisible = false;
  isPPModalVisible = false;
  isContactUsVisible = false;
  contactUsUrl: any = this._sanitizer.bypassSecurityTrustResourceUrl(`${ environment.baseUrl }/en_US/contact-us/modal`);
  modalBodyStyle = {
    padding: "30px 20px",
    height: "78vh",
    "overflow-y": "auto",
    "overflow-x": "hidden"
  };
  contactUsStyle = {
    position: "relative",
    padding: "0",
    height: "78vh",
    "overflow-y": "auto",
    "overflow-x": "hidden"
  };
  positionStyle = {
    top: '20px'
  };
  tcContent: any;
  constructor(
    private faqService: FaqService,
    private i18nService: I18NService,
    private _sanitizer: DomSanitizer,
    private modalService: ModalService
  ) { 
    this.currentLangSubscription = this.i18nService.currentLang.subscribe(() => {
      this.faqService.getKnowledgeArticle(this.i18nService.getActiveLang, "TC").subscribe((data) => {

        this.tcContent = "";
        Object.keys(data[0]).forEach(k => {
          if (k.indexOf("Content_") !== -1) {
            this.tcContent += data[0][k];
          }
        });
        this.tcContent = this.getInnerHTMLValue(this.tcContent);

      });

      this.contactUsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${ environment.baseUrl }/${ this.i18nService.getActiveLang }/contact-us/modal`);
    });
  }

  ngOnInit() {

    this.tncSub = this.modalService.showTnCModal.subscribe((value) => {
      this.isTCModalVisible = value;
    });

    this.contactSub = this.modalService.showContactUsModal.subscribe((value) => {
      this.isContactUsVisible = value;
    });

  }

  ngOnDestroy() {
    this.currentLangSubscription && this.currentLangSubscription.unsubscribe();
    this.tncSub && this.tncSub.unsubscribe();
    this.contactSub && this.contactSub.unsubscribe();
  }

  getInnerHTMLValue(content: any) {
    return this._sanitizer.bypassSecurityTrustHtml(content);
  };

  handleCancel(modalType: "TC" | "CU") {
    this.modalService.handleCancel(modalType);
  };
}
