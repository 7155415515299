import { CampaignDetailComponent } from "./campaign-detail/campaign-detail.component";
import { CampaignsComponent } from "./campaigns.component";
import { CampaignsResolver } from "./campaigns-resolver.service";
import { CampaignsRoutingModule } from "./campaigns-routing.module";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";

@NgModule({
  imports: [SharedModule, CampaignsRoutingModule],
  declarations: [CampaignsComponent, CampaignDetailComponent],

  providers: [CampaignsResolver],
})
export class CampaignsModule {}
