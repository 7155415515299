import { Component, OnInit, OnDestroy, Inject, InjectionToken, HostListener } from "@angular/core";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Router, NavigationEnd, NavigationStart, RoutesRecognized, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { UserService, CommonService, JwtService } from "./core";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { Meta } from '@angular/platform-browser';
import { EarnPointFailComponent } from "./pages/earn-now/earn-point-fail/earn-point-fail.component";
import { EarnPointSuccessComponent } from "./pages/earn-now/earn-point-success/earn-point-success.component";
import { EarnPointExpiredComponent } from "./pages/earn-now/earn-point-expired/earn-point-expired.component";
import { EarnPointCancelledComponent } from "./pages/earn-now/earn-point-cancelled/earn-point-cancelled.component";
import { EarnPointEarnedComponent } from "./pages/earn-now/earn-point-earned/earn-point-earned.component";
import { EarnNowUploadComponent } from "./pages/earn-now/earn-now-upload/earn-now-upload.component";
export const EnvironmentToken = new InjectionToken("ENVIRONMENT");
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  providers: [{ provide: EnvironmentToken, useValue: environment }],
})
export class AppComponent implements OnInit, OnDestroy {
  environment = environment;
  isShowingNotice = false;
  shouldShowHeaderFooter: boolean = true;
  shouldHideComponent: boolean = true;
  previousUrl: string = '';
  setNoindexPage: Array<string> = ['not-found','earn','terms-and-condition'];
  hideComponentPage: Array<string> = ['edit','change-password','forgot-password','update-ara','upgrade-account','register','incomplete','error','not-found'];
  hideComponentKey: Array<string> = ['alipayhk','alipaycn'];
  earnPointQueryParam: string;

  @HostListener('window:visibilitychange', ['$event'])
  visibilitychange(event) {
    if (this.oauthService.hasValidAccessToken()) {
      localStorage.setItem("exitAppWithValidToken", "true");
    } else {
      localStorage.setItem("exitAppWithValidToken", "false");
    }
    localStorage.setItem("exitAppWithValidTokenTimestamp", new Date().getTime().toString());
  };

  @HostListener('window:mozvisibilitychange', ['$event'])
  mozvisibilitychange(event) {
    if (this.oauthService.hasValidAccessToken()) {
      localStorage.setItem("exitAppWithValidToken", "true");
    } else {
      localStorage.setItem("exitAppWithValidToken", "false");
    }
    localStorage.setItem("exitAppWithValidTokenTimestamp", new Date().getTime().toString());
  };


  @HostListener('window:msvisibilitychange', ['$event'])
  msvisibilitychange(event) {
    if (this.oauthService.hasValidAccessToken()) {
      localStorage.setItem("exitAppWithValidToken", "true");
    } else {
      localStorage.setItem("exitAppWithValidToken", "false");
    }
    localStorage.setItem("exitAppWithValidTokenTimestamp", new Date().getTime().toString());
  };

  @HostListener('window:webkitvisibilitychange', ['$event'])
  webkitvisibilitychange(event) {
    if (this.oauthService.hasValidAccessToken()) {
      localStorage.setItem("exitAppWithValidToken", "true");
    } else {
      localStorage.setItem("exitAppWithValidToken", "false");
    }
    localStorage.setItem("exitAppWithValidTokenTimestamp", new Date().getTime().toString());
  };

  @HostListener('window:beforeunload', ['$event'])
  beforeunload(event) {
    if (this.oauthService.hasValidAccessToken()) {
      localStorage.setItem("exitAppWithValidToken", "true");
    } else {
      localStorage.setItem("exitAppWithValidToken", "false");
    }
    localStorage.setItem("exitAppWithValidTokenTimestamp", new Date().getTime().toString());
  };

  @HostListener('window:pagehide', ['$event'])
  pagehide(event) {
    if (this.oauthService.hasValidAccessToken()) {
      localStorage.setItem("exitAppWithValidToken", "true");
    } else {
      localStorage.setItem("exitAppWithValidToken", "false");
    }
    localStorage.setItem("exitAppWithValidTokenTimestamp", new Date().getTime().toString());
  };


  @HostListener('window:pageshow', ['$event'])
  pageshow(event) {
    // console.log('[MyHKG Debug] pageshow');
  };
  
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private idle: Idle,
    private keepAlive: Keepalive,
    private router: Router,
    private userService: UserService,
    @Inject(EnvironmentToken) private env: any,
    private oauthService: OAuthService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute
  ) {
    gtag("config", this.env.APP_CONFIG.GA_MEASUREMENT_ID);
    // Check user inactivity - START
    this.userService.isAuthenticated.pipe(distinctUntilChanged()).subscribe((isAuth) => {
      if (isAuth) {
        idle.setIdle(this.env.APP_CONFIG.idleTime * 60); // IN MINS
        idle.setTimeout(this.env.APP_CONFIG.timeOut * 60); // IN MINS
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.onIdleEnd.subscribe(() => {
          this.userService.checkSessionActiveness();
          this.reset();
        });
        idle.onTimeout.subscribe(() => {
          this.userService.sessionTimeoutPurgeAuth();
        });
        keepAlive.interval(30);
        this.reset();
      }
    });
    // Check user inactivity - END
    this.routeEvent(this.router);
    this.commonService.shouldShowHeaderFooter$.subscribe((value) => {
      this.shouldShowHeaderFooter = value;
    });

    this.commonService.shouldHideComponent$.subscribe((value) => {
      this.shouldHideComponent = value;
    });

    this.handleLoginHintIfAny();
    this.earnPointSuccessDialog();
  }

  ngOnInit() {
    this.commonService.isShowImportantNotice$.subscribe((result) => {
      this.isShowingNotice = result;
    });
    this.commonService.setShowHeaderFooter(true);
  }

  ngOnDestroy() { }

  reset() {
    this.idle.watch();
  }

  /**
   * Handle on route change event
   * 1. Check active
   * @param router
   */
  routeEvent(router: Router) {
    this.setHreflangTag();
    router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        }),
        filter(
          event =>
            event instanceof NavigationStart || event instanceof NavigationEnd
        ),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.commonService.isProtectedPage(false);
        }
        if (event instanceof NavigationEnd) {
          // Check Session from ISS
          if (this.previousUrl !== '' && this.previousUrl !== '/en_US'  && this.previousUrl !== '/zh_HK' && this.previousUrl !== '/zh_CN' ) {
            this.userService.checkSessionActiveness();
          }
          this.previousUrl = event.urlAfterRedirects;
          // Google Analytics - Page view tracking
          gtag("event", "page_view", { page_path: event.urlAfterRedirects });

          const desktopContainer = document.getElementById("HKAA-APP");
          const mobileContainer = document.getElementById("app-container");
          if (desktopContainer.scrollTop > 0) {
            desktopContainer.scrollTop = 0;
          }
          if (mobileContainer.scrollTop > 0) {
            mobileContainer.scrollTop = 0;
          }
          /**
           * subscriptIdenx: Get URL "?" subscript
           * urlList: Array cut by page path
           */
          let subscriptIdenx = this.previousUrl.indexOf("?") == -1 ? this.previousUrl.length : this.previousUrl.indexOf("?");
          let urlList = this.previousUrl.substring(0,subscriptIdenx).split("/");
          if(this.setNoindexPage.indexOf(urlList[urlList.length-1]) != -1){
            this.meta.updateTag({name: 'robots', content: 'NOINDEX'});
          }else{
            this.meta.updateTag({name: 'robots', content: 'INDEX,FOLLOW'});
          }
          this.setHreflangTag();

          /**
           * hintToEnd: Get the string from "login_hint" to the end, and decode it
           * loginHint: Get the "login_hint" part
           */
          let index = event.urlAfterRedirects.indexOf('login_hint');
          if(index != -1){
            let hintToEnd = decodeURIComponent(event.urlAfterRedirects.substring(index,event.urlAfterRedirects.length));
            let loginHint = hintToEnd.substring(0,hintToEnd.indexOf('&') == -1 ? hintToEnd.length : hintToEnd.indexOf('&')).toLowerCase();
            let keywordExist  = false
            this.hideComponentKey.forEach((item,index)=>{
              if(loginHint.indexOf(item) != -1){
                keywordExist  = true
              }
            })
            if( keywordExist  && this.hideComponentPage.indexOf(urlList[urlList.length-1]) != -1){
              this.commonService.setHideComponent(false);
            }else{
              this.commonService.setHideComponent(true);
            }
          }
        }
      });
  }

  setHreflangTag(){
    document.querySelectorAll('link').forEach((element,index)=>{
      if(element.rel == 'canonical'){
        element.href = location.origin + location.pathname
      }
      if(element.rel == 'alternate'){
        element.href = {
          'en-us': location.origin + '/en_US' + location.pathname.slice(6),
          'zh-Hant': location.origin + '/zh_HK' + location.pathname.slice(6),
          'zh-Hans': location.origin + '/zh_CN' + location.pathname.slice(6),
          'x-default': location.origin + '/en_US' + location.pathname.slice(6),
        }[element.hreflang]
      }
    })
  }

  handleLoginHintIfAny() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      const loginHint = queryParams['login_hint'];
      if (loginHint) {
        window.sessionStorage.setItem("login_hint_channel", loginHint.substring(6, loginHint.length));
      }
    })
  }

  earnPointSuccessDialog() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.earnPointQueryParam = queryParams['earnMsg'];
      if (this.earnPointQueryParam === 'FAILED') {
        this.userService.getEarnPointMessage("unsuccess").subscribe(
          (data) => {
            this.dialog.open(EarnPointFailComponent, {
              width: "50%",
              minWidth: "350px",
              maxWidth: "500px",
              data: {
                dataKey: data
              }
            })
          }
        )
      } else if (this.earnPointQueryParam === 'SUCCESS') {
        this.dialog.open(EarnPointSuccessComponent, {
          width: "50%",
          minWidth: "350px",
          maxWidth: "500px",
        })
      } else if (this.earnPointQueryParam === "ERR_EARN_POINT_EXPIRED_TXN") {
        this.dialog.open(EarnPointExpiredComponent, {
          width: "50%",
          minWidth: "350px",
          maxWidth: "500px",
        })
      } else if (this.earnPointQueryParam === "ERR_EARN_POINT_ADJUSTED_TXN") {
        this.dialog.open(EarnPointCancelledComponent, {
          width: "50%",
          minWidth: "350px",
          maxWidth: "500px",
        })
      } else if (this.earnPointQueryParam === "ERR_EARN_POINT_EARNED_TXN") {
        this.dialog.open(EarnPointEarnedComponent, {
          width: "50%",
          minWidth: "350px",
          maxWidth: "500px",
        })
      } else if (this.earnPointQueryParam === "UPLOAD_RECEIPT") {
        this.userService.getEarnPointMessage("upload_receipt").subscribe(
          (data) => {
            this.dialog.open(EarnNowUploadComponent, {
              width: "50%",
              minWidth: "350px",
              maxWidth: "500px",
              data: {
                dataKey: data
              }
            })
          }
        )
      }
    })
  }

}
