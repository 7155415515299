import {Component, Inject, OnInit, ElementRef, ViewEncapsulation, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material'
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18NService} from '../../../core';

@Component({
  selector: 'app-earn-now-upload',
  templateUrl: './earn-now-upload.component.html',
  styleUrls: ['./earn-now-upload.component.css']
})
export class EarnNowUploadComponent implements OnInit {

 
  @ViewChild('message', null) messageTemplate: ElementRef;
  message: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  line6: string;
  line7: string;
  line8: string;
  line9: string;

  messageType: string = "upload_receipt";
  buttonShown: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<EarnNowUploadComponent>,
    private router: Router,
    private i18NService: I18NService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.message = data.dataKey.responseInfo;
  }

  ngOnInit() {
    if (this.message.indexOf('按此')== -1 && this.message.indexOf('here')==-1){
      this.buttonShown = false;
    }
    let breaker = this.message.indexOf('\\n');

    if (this.buttonShown) {
      breaker = this.message.indexOf('{');
      this.line2 = this.message.substring(0, breaker);
      this.message = this.message.substring(breaker+1,);
      breaker = this.message.indexOf('}');
      this.line3 = this.message.substring(0, breaker);
      this.message = this.message.substring(breaker+1,);
    }
    breaker = this.message.indexOf('\\n');
    this.line4 = this.message.substring(0, breaker);
    this.message = this.message.substring(breaker+3,);
    breaker = this.message.indexOf('\\n');
    this.line5 = this.message.substring(0, breaker);
    this.message = this.message.substring(breaker+2,);
    if (this.buttonShown) {
      breaker = this.message.indexOf('{');
      this.line6 = this.message.substring(0, breaker);
      this.message = this.message.substring(breaker+1,);
      breaker = this.message.indexOf('}');
      this.line7 = this.message.substring(0, breaker);
      this.message = this.message.substring(breaker+1,);
    }
    breaker = this.message.indexOf('\\n');
    this.line8 = this.message.substring(0, breaker);
    this.message = this.message.substring(breaker+2,);
    this.line9 = this.message;

    this.messageTemplate.nativeElement.innerHTML = this.message;
  }

  

  redirectToEarn() {
    const language = this.i18NService.getActiveLang;
    this.router.navigate([`/${language}/earn`]);
    this.dialogRef.close();
  }

}
