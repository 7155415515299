import { Component, OnInit, OnDestroy, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-coming-soon",
  templateUrl: "./coming-soon.component.html",
  styleUrls: ["./coming-soon.component.scss"],
})
export class ComingSoonComponent implements OnInit, OnDestroy {
  passCode: string;
  @Output() validate = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {
    // const element = document.getElementById("HKAA-APP");
    // element.classList.remove("has-navbar-fixed-top");
  }

  ngOnDestroy() {
    // const element = document.getElementById("HKAA-APP");
    // element.classList.add("has-navbar-fixed-top");
  }

  checkPasscode() {
    if (this.passCode === "HkgOffer2020!") {
      this.validate.emit(true);
    } else {
      alert("Invalid Passcode!");
    }
  }
}
