import { ActivatedRoute, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Campaign, User, I18NService, CommonService, UserService, CampaignsService, GeneralGuard } from "../../../core";
import { Subscription } from "rxjs";
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser";
import { GlobalConfig } from "../../../core/app-global.config";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";
import * as _ from "underscore";
import { OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';

@Component({
  selector: "app-campaign-detail",
  templateUrl: "./campaign-detail.component.html",
  styleUrls: ["./campaign-detail.component.scss"],
})
export class CampaignDetailComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private i18nService: I18NService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
    private campaignService: CampaignsService,
    private titleService: Title,
    private authStorage: OAuthStorage, 
    private generalGuardService: GeneralGuard,
    private _location: Location,
    private router: Router
  ) { }

  private subscription: Subscription;
  campaigns: any;
  campaign: Campaign;
  campaignData: Campaign;
  isLoading = true;
  isSubmitting = false;
  massTimingsHtml: any;
  surveyName: string;
  surveyLink: string = "";
  getSurveyError: boolean = false;
  showSurveyErrorMsg: boolean = false;

  ngOnInit() {
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }
    
    this.route.data.subscribe((data: { campaign: Campaign }) => {
      if (data.campaign) {
        this.campaignData = this.transformCampaign(data.campaign);
        this.titleService.setTitle(this.campaignData.customContent.title + " | " + this.titleService.getTitle());
        this.isLoading = false;
        if (data.campaign.custom_payload[this.i18nService.getActiveLang + ".tnc"]) {
          this.campaignService.getCampaignTnc(data.campaign.custom_payload[this.i18nService.getActiveLang + ".tnc"]).subscribe(
            (data) => {
              this.massTimingsHtml = this._sanitizer.bypassSecurityTrustHtml(data);
            },
            (error) => { }
          );
        }
      }
    });
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      this.getCampaigns();
    });
    //get survey link
    this.campaign = this.campaigns.filter((item) => item.custom_payload.surveyID != null);
    this.campaignService.getCampaign(this.campaignData.permalink).subscribe(
      (data) => {
        if(!data.custom_payload.surveyID) return
        this.campaignService.getSurveyLink(data.custom_payload.surveyID).subscribe(
          (surveyLinkResult: any) => {
            this.surveyLink = surveyLinkResult.surveyUrl;
          },
          (error) => {
            this.getSurveyError = true;
          }
        );
      }
    )
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  goToSurvey() { 
    //check session
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
      this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }

    if (this.getSurveyError === true) {
      this.showSurveyErrorMsg = true;
    } else if (this.surveyLink !== "") {
      let url = this.surveyLink
      var lan = this.i18nService.getActiveLang.replace("_", "-");
      url += "&guestUserLang=" + lan;
      window.open(url, "_blank");
    }
  }

  getCampaigns() {
    this.campaigns = [];
    this.campaignService.getAllCampaigns().subscribe((campaigns) => {
      this.campaigns = this.transformCampaign(campaigns).filter((item) => item.custom_payload && item.custom_payload.showOnPage);
    });
  }

  transformCampaign(record) {
    const i18nCode = this.i18nService.getActiveLang;
    if (record.custom_payload) {
      record.customContent = {
        title: record.custom_payload[i18nCode + ".title"],
        desc: record.custom_payload[i18nCode + ".desc"],
        graphics: record.custom_payload[i18nCode + ".graphics"],
        graphics_banner: record.custom_payload[i18nCode + ".graphics_banner"],
        cta_label_guest: record.custom_payload[i18nCode + ".cta_label_guest"],
        cta_type_guest: record.custom_payload[i18nCode + ".cta_type_guest"],
        cta_link_guest: record.custom_payload[i18nCode + ".cta_link_guest"],
        cta_label_member: record.custom_payload[i18nCode + ".cta_label_member"],
        cta_type_member: record.custom_payload[i18nCode + ".cta_type_member"],
        cta_link_member: record.custom_payload[i18nCode + ".cta_link_member"],
        isSurvey: record.custom_payload["surveyID"] ? true : false
      };
    }
    return record;
  }

  back() {
    this.commonService.backToPreviousState();
  }

  emitEvent() {
    if (this.userService.isLoggedIn) {
      if (this.campaignData.customContent.cta_type_member === "internal_link") {
        this.commonService.redirect(this.campaignData.customContent.cta_link_member);
      }
      if (this.campaignData.customContent.cta_type_member === "external_link") {
        window.open(this.campaignData.customContent.cta_link_member, "_blank");
      }
    } else {
      if (this.campaignData.customContent.cta_type_guest === "internal_link") {
        this.commonService.redirect(this.campaignData.customContent.cta_link_guest);
      }
      if (this.campaignData.customContent.cta_type_guest === "login") {
        this.userService.login();
      }
      if (this.campaignData.customContent.cta_type_guest === "external_link") {
        window.open(this.campaignData.customContent.cta_link_guest, "_blank");
      }
    }
  }
}
