import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18NService, UserService } from '../../../core';

@Component({
  selector: 'app-earn-point-earned',
  templateUrl: './earn-point-earned.component.html',
  styleUrls: ['./earn-point-earned.component.css']
})
export class EarnPointEarnedComponent implements OnInit {
  @ViewChild('message', null) messageTemplate: ElementRef;
  message: string;
  messageType: string = "registered";

  constructor(
    public dialogRef: MatDialogRef<EarnPointEarnedComponent>,
    private router: Router,
    private i18NService: I18NService,
    private userService: UserService,
  ) { 
    this.userService.getEarnPointMessage(this.messageType).subscribe(
      (data) => {
        this.message = data.responseInfo.replace(/\\n/g,"<br>");
        this.messageTemplate.nativeElement.innerHTML = this.message;
      });
  }

  ngOnInit() {
  }

  redirectToProfile() {
    const language = this.i18NService.getActiveLang;
    this.router.navigate([`/${language}/profile/personal-info`]);
  }

}
