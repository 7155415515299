import * as _ from "underscore";
import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges, } from "@angular/core";

@Component({
  selector: "app-hkaa-accumulated-spending",
  templateUrl: "./accumulated-spending.component.html",
  styleUrls: ["./accumulated-spending.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HkaaAccumulatedSpendingComponent implements OnInit, OnChanges {
  @Input() campaignData: any = [
    {
      totalSpending: 50000,
      outcome: 10000,
    },
    {
      totalSpending: 100000,
      outcome: 75000,
    },
    {
      totalSpending: 200000,
      outcome: 250000,
    },
    {
      totalSpending: 500000,
      outcome: 1000000,
    }
  ];
  constructor(
  ) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

}
