import { AbstractControl } from "@angular/forms";
import { UserService } from "../services";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

export class ValidateEmailNotTaken {
  static createValidator(userService: UserService, serviceNature: string, memberNumber: string) {
    return (control: AbstractControl) => {
      // TODO
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control && EMAIL_REGEXP.test(control.value) && !control.pristine && control.value && control.value.trim() !== "") {
        return userService.checkDuplicatedEmail(control.value, serviceNature, memberNumber).pipe(
          map(isValid => null),
          catchError(() => of({ error: true, duplicated: true }))
        );
      } else {
        return of(null);
      }
    };
  }
}
