export abstract class ApiServiceConstant {
  public static MEMBER: ApiServiceName = "member";
  public static LOYALTY: ApiServiceName = "loyalty";
  public static OAUTH: ApiServiceName = "oauth";
  public static STORAGE: ApiServiceName = "storage";
  public static HKAADOLLAR: ApiServiceName = "hkiadollar";
  public static CAMPAIGNENGINE: ApiServiceName = "campaignengine";
}

export declare type ApiServiceName = "member" | "loyalty" | "oauth" | "storage" | "livechat" | "data-sync" | "hkiadollar" | "campaignengine";
