import * as _ from "underscore";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { I18NService, UserService } from "../../../core";
import { EarnHistoryConstants } from "./earn-history.constants";

import { STPage } from "@delon/abc";
import { Subscription } from "rxjs";

@Component({
  selector: "app-earn-history",
  templateUrl: "./earn-history.component.html",
  styleUrls: ["./earn-history.component.scss"],
})
export class EarnHistoryComponent implements OnInit, OnDestroy {
  @Input() set shopList(list) {
    this.shopListInCurrentLang = list;
    this.getEarnHistory();
  }

  @Input() set rejectReasonList(list) {
    this.rejectReasonListInCurrentLang = list;
  }

  @Input() set contactUsLink(url) {
    this.contactUs = url;
  }

  // TODO: List Data Model
  contactUs: string;
  isSubmitting: boolean;
  redeemOfferConstants = EarnHistoryConstants;
  earnHistory: any;
  isLoading: boolean;
  shopListInCurrentLang: any;
  rejectReasonListInCurrentLang: any;
  rejectReasonPickList: any;
  columns = [];

  dataTableConfig: STPage = {
    showSize: false,
  };

  private subscription: Subscription;

  constructor(private i18nService: I18NService, private userService: UserService) {
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      this.initColumns();
      this.getEarnHistory();
    });
  }

  ngOnInit() {
    // Get Point History from SessionM
    this.initColumns();
    this.getEarnHistory();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initColumns() {
    this.columns = [
      {
        title: this.i18nService.translateLabel("earn.form.referenceNo"),
        index: "caseNumber",
      },
      {
        title: this.i18nService.translateLabel("COMMON.LABEL.SHOP"),
        index: "shop",
      },
      {
        title: this.i18nService.translateLabel("earn.form.purchaseDate"),
        render: "purchaseDate",
      },
      {
        title: this.i18nService.translateLabel("COMMON.LABEL.AMOUNT"),
        render: "amount",
      },
      {
        title: this.i18nService.translateLabel("earn.form.status"),
        render: "status",
      },
      {
        title: this.i18nService.translateLabel("earn.form.reason"),
        render: "rejectReason",
      },
    ];
  }

  getEarnHistory() {
    this.isLoading = true;
    this.userService.getEarnHistory().subscribe(
      (data: Array<any>) => {
        this.earnHistory = this.transformEarnHistory(data);
        this.isLoading = false;
      },
      (error) => {
        this.earnHistory = [];
        this.isLoading = false;
      }
    );
  }

  /**
   * Transform SessionM's activity name to label
   * @param records
   */
  transformEarnHistory(records) {
    // TODO: Group all constants
    return _.each(records, (record: any) => {
      if (record.shop) {
        record.shop = _.first(_.where(this.shopListInCurrentLang, { value: record.shop }))
          ? _.first(_.where(this.shopListInCurrentLang, { value: record.shop }))["label"]
          : record.shop;
      }
      if (record.rejectReason) {
        record.rejectReason = _.first(_.where(this.rejectReasonListInCurrentLang, { value: record.rejectReason }))
          ? _.first(_.where(this.rejectReasonListInCurrentLang, { value: record.rejectReason }))["label"]
          : record.rejectReason;
      }
      if (record.status) {
        switch (record.status) {
          case "Approved":
            record.status = "COMMON.status.approved";
            record.color = "green";
            break;
          case "Rejected":
            record.status = "COMMON.status.rejected";
            record.color = "red";
            break;
          case "Pending":
            record.status = "COMMON.status.pending";
            break;
          default:
            record.status = record.status;
        }
      } else {
        return records;
      }
    });
  }
}
