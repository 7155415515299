import { Component, Input } from "@angular/core";

@Component({
  selector: "page-title",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent {
  @Input() title: string;
  @Input() standalone: boolean;
  @Input() mode: string;

  constructor() {}
}
