import { Injectable } from "@angular/core";
import { format } from "date-fns";
@Injectable()
export class TimeService {
  constructor() {}

  // Member Portal Generic Error
  get yearList() {
    const max = new Date().getFullYear() - 19,
      min = max - 120 + 19;
    const yearArray = [];
    for (let i = max; i >= min; i--) {
      yearArray.push(i.toString());
    }
    return yearArray;
  }

  get monthList() {
    return [
      { label: "01", value: "01" },
      { label: "02", value: "02" },
      { label: "03", value: "03" },
      { label: "04", value: "04" },
      { label: "05", value: "05" },
      { label: "06", value: "06" },
      { label: "07", value: "07" },
      { label: "08", value: "08" },
      { label: "09", value: "09" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" }
    ];
  }

  dateFormat = (date: string | number | Date) => {
    return format(date, "YYYY-MM-DD");
  }

  dateFormat2 = (date: string | number | Date) => {
    return format(date, "DD/MM/YYYY");
  }
}
