/** Angular Material Module */
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRippleModule } from "@angular/material/core";
import { MatTabsModule } from "@angular/material/tabs";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { QRCodeModule } from 'angularx-qrcode';

/** NG-ZORRO Module */
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzListModule } from "ng-zorro-antd/list";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSwitchModule } from 'ng-zorro-antd/switch';


import { NzUploadModule } from "ng-zorro-antd/upload";
import { AboutProgrammeCarouselComponent } from "./about-programme-carousel";
import { CampaignCardsComponent } from "./campaign-cards";
import { MobileMemberInfoComponent, HkaaPaginationComponent, RewardJourneyComponent, HkaaAccumulatedSpendingComponent } from "./hkaa-dollar";
import { ComingSoonComponent } from "../pages/coming-soon/coming-soon.component";
import { CommonErrorComponent } from "./error-pages/common-error/common-error.component";
import { CommonModule } from "@angular/common";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { CountUpModule } from "ngx-countup";
import { CountdownModule } from "ngx-countdown";
import { DelonABCModule } from "@delon/abc";
import { DelonFormModule } from "@delon/form";
import { DeviceDetectorModule } from "ngx-device-detector";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { IMaskModule } from "angular-imask";
import { JoinNowBannerComponent } from "./join-now-banner";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LocalizeRouterModule } from "@gilsdav/ngx-translate-router";
import { OfferCardsComponent } from "./offer-cards/offer-cards.component";
import { OtpBoxComponent } from "./otp-box";
import { PageNotFoundComponent } from "./error-pages/page-not-found/page-not-found.component";
import { PromotionCarouselComponent } from "./promotion-carousel";
import { RouterModule } from "@angular/router";
import { ShowAuthedDirective } from "./show-authed.directive";
import { SystemMessageComponent } from "./system-message";
import { TranslateModule } from "@ngx-translate/core";
import { AirplaneLoaderComponent } from "./airplane-loader/airplane-loader.component";

import { AboutProgrammeCardsComponent } from "./about-programme-cards";
import { OfferCarouselComponent } from "./offer-carousel/offer-carousel.component";
import { PageHeaderComponent } from "./layout";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { SwiperModule } from "ngx-swiper-wrapper";

import { ClickOutsideModule } from "ng-click-outside";
import { NgSelectModule } from "@ng-select/ng-select";
import { LogoutComponent } from "./logout/logout.component";
import { LogoutRedirectComponent } from "./logout-redirect/logout-redirect.component";
import { ModalComponent } from "./modal/modal.component";
import { WalletComponent } from "./layout/header/wallet/wallet.component";
import { RewardsCalculatorComponent } from './rewards-calculator/rewards-calculator.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};

@NgModule({
  imports: [
    CommonModule,
    ContentLoaderModule,
    CountUpModule,
    CountdownModule,
    DelonABCModule,
    DelonFormModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    FormsModule,
    HttpClientModule,
    IMaskModule,
    LazyLoadImageModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    LocalizeRouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatTabsModule,
    NgxSpinnerModule,
    NzButtonModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzDividerModule,
    NzEmptyModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzListModule,
    NzMessageModule,
    NzModalModule,
    NzPaginationModule,
    NzRadioModule,
    NzResultModule,
    NzSelectModule,
    NzSpinModule,
    NzTabsModule,
    NzToolTipModule,
    NzUploadModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    SwiperModule,
    ClickOutsideModule,
    NzAutocompleteModule,
    NgSelectModule,
    NzSliderModule,
    NzProgressModule,
    NzSwitchModule,
    MatDialogModule,
    QRCodeModule,
  ],
  entryComponents: [WalletComponent],
  declarations: [
    AboutProgrammeCarouselComponent,
    CampaignCardsComponent,
    MobileMemberInfoComponent,
    RewardJourneyComponent,
    HkaaPaginationComponent,
    HkaaAccumulatedSpendingComponent,
    ComingSoonComponent,
    CommonErrorComponent,
    JoinNowBannerComponent,
    OfferCardsComponent,
    OfferCarouselComponent,
    OtpBoxComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    PromotionCarouselComponent,
    ShowAuthedDirective,
    SystemMessageComponent,
    AboutProgrammeCardsComponent,
    LogoutComponent,
    LogoutRedirectComponent,
    ModalComponent,
    WalletComponent,
    AirplaneLoaderComponent,
    RewardsCalculatorComponent,
  ],
  exports: [
    AboutProgrammeCarouselComponent,
    CampaignCardsComponent,
    MobileMemberInfoComponent,
    RewardJourneyComponent,
    HkaaPaginationComponent,
    HkaaAccumulatedSpendingComponent,
    ComingSoonComponent,
    CommonModule,
    ContentLoaderModule,
    CountUpModule,
    CountdownModule,
    DelonABCModule,
    DelonFormModule,
    DeviceDetectorModule,
    FormsModule,
    HttpClientModule,
    IMaskModule,
    LazyLoadImageModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    LocalizeRouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatTabsModule,
    NgxSpinnerModule,
    NzAvatarModule,
    NzButtonModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzListModule,
    NzMessageModule,
    NzModalModule,
    NzPaginationModule,
    NzRadioModule,
    NzResultModule,
    NzSelectModule,
    NzSpinModule,
    NzTabsModule,
    NzToolTipModule,
    NzUploadModule,
    OfferCardsComponent,
    OfferCarouselComponent,
    OtpBoxComponent,
    PageHeaderComponent,
    PromotionCarouselComponent,
    JoinNowBannerComponent,
    ReactiveFormsModule,
    RouterModule,
    ShowAuthedDirective,
    SystemMessageComponent,
    SwiperModule,
    TranslateModule,
    AboutProgrammeCardsComponent,
    ClickOutsideModule,
    NzDrawerModule,
    NzNotificationModule,
    NzAutocompleteModule,
    NgSelectModule,
    LogoutComponent,
    LogoutRedirectComponent,
    ModalComponent,
    NzSliderModule,
    NzProgressModule,
    NzSwitchModule,
    MatDialogModule,
    AirplaneLoaderComponent,
    RewardsCalculatorComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG,
        },
      ],
    };
  }
}
