import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LegalArticlesComponent } from "./legal-articles.component";
import { LegalArticlesResolver } from "./legal-articles-resolver.service";

const routes: Routes = [
  {
    path: "terms-and-condition",
    component: LegalArticlesComponent,
    resolve: {
      content: LegalArticlesResolver,
    },
    data: {
      isSkipGeneralGuard: true,
      headline: "COMMON.TITLE.TERMS_CONDITION",
      title: {
        en_US: "Terms & Conditions | HKairport Rewards",
        zh_HK: "條款及細則 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "条款及细则 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `Terms & Conditions | HKairport Rewards`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `條款及細則 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `条款及细则 | HKairport Rewards 香港国际机场奖赏计划`,
          },
        ],
      },
    },
  },
  {
    path: "privacy-policy",
    component: LegalArticlesComponent,
  },
  {
    path: "personal-information-collection-statement",
    component: LegalArticlesComponent,
    resolve: {
      content: LegalArticlesResolver,
    },
    data: {
      headline: "earn.message.personalInformationCollectionStatementHeader",
      isSkipGeneralGuard: true,
    },
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LegalArticlesRoutingModule {}
