import * as _ from "underscore";
import { ActivatedRoute } from "@angular/router";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { UserService, TimeService, FormService, AlertService, MediaService, I18NService, CommonService } from "../../../core";
import { differenceInCalendarDays } from "date-fns";
import { environment } from "../../../../environments/environment";
import { GlobalConfig } from '../../../core/app-global.config';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-update-ara-status",
  templateUrl: "./update-ara-status.component.html",
  styleUrls: ["./update-ara-status.component.scss"],
})
export class UpdateAraStatusComponent implements OnInit, OnDestroy {

  uploadAcceptTypes = GlobalConfig.PHOTO_UPLOAD.accept_types;

  updateAraStatusForm: FormGroup;

  companyList = [];

  // Init Pick List Value
  monthPickList = this.timeService.monthList;
  yearPickList = this.timeService.yearList;

  isFirstTimeGetOtp = false;
  otpModal: any;

  // TODO:
  userProfile: any;
  isSubmitting: boolean;

  permitPhotoFront = "";
  permitPhotoBack = "";

  formattedPermitPhotoFront = "";
  formattedPermitPhotoBack = "";

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };

  isDesktop = this.deviceService.isDesktop();

  dateFormat = environment.APP_CONFIG.dataFormat;
  redirectPlatformLink: string;

  @Input() formConfig;
  private subscription: Subscription;
  isOpen: boolean = false;
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private timeService: TimeService,
    private i18nService: I18NService,
    public formService: FormService,
    private alertService: AlertService,
    private mediaService: MediaService,
    private commonService: CommonService,
    private deviceService: DeviceDetectorService
  ) {
    this.initFrom();
    this.route.data.subscribe((data: { listData: any[]; userProfile: any }) => {
      if (data.listData) {
        this.companyList = data.listData[2][this.i18nService.getActiveLang];
      }
    });

    this.subscription = this.userService.getMember().subscribe((data) => {
      if (data) {
        this.updateAraStatusForm.patchValue(data);
        this.userProfile = data;
      }
    });
  }

  ngOnInit() {
    // Redirection for external platform
    this.route.queryParams.subscribe((value) => {
      if (value["cb"]) {
        this.redirectPlatformLink = decodeURI(value["cb"]);
      }
    });
    this.companyName.valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }
      const validators = [Validators.required];
      if (value && value === "0_Others") {
        this.companyNameOthers.setValidators([Validators.required]);
      } else {
        this.companyNameOthers.reset();
        this.companyNameOthers.setValidators(null);
      }
      this.companyNameOthers.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // TODO: Init Form
  initFrom() {
    this.updateAraStatusForm = this.fb.group({
      araPermitExpiryDate: [null, [Validators.required]],
      araPermitHKCrewMemberCertNo: [null, [Validators.required]],
      araPermitPhotoBack: [null, [Validators.requiredTrue]],
      araPermitPhotoFront: [null, [Validators.requiredTrue]],
      companyName: [null, [Validators.required]],
      companyNameOthers: [null],
    });
  }

  preSubmitForm() {
    // tslint:disable-next-line: forin
    if (this.permitPhotoFront.length > 0) {
      this.updateAraStatusForm.get("araPermitPhotoFront").setValue(true);
    } else {
      this.updateAraStatusForm.get("araPermitPhotoFront").setValue(false);
    }

    if (this.permitPhotoBack.length > 0) {
      this.updateAraStatusForm.get("araPermitPhotoBack").setValue(true);
    } else {
      this.updateAraStatusForm.get("araPermitPhotoBack").setValue(false);
    }

    if (this.araPermitHKCrewMemberCertNo.value) {
      this.araPermitHKCrewMemberCertNo.markAsDirty();
      this.araPermitHKCrewMemberCertNo.updateValueAndValidity();
    }

    if (this.updateAraStatusForm.valid) {
      this.submitForm();
    } else {
      for (const i in this.updateAraStatusForm.controls) {
        this.updateAraStatusForm.controls[i].markAsDirty();
        this.updateAraStatusForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  submitForm() {
    const formData = this.transformRegisterForm();
    this.isSubmitting = true;

    this.userService.updateAraPermit(formData).subscribe(
      async (feedback) => {
        if (feedback) {
          this.isSubmitting = false;
          this.alertService.success("Updated");
          await this.userService.reloadUserInfo();
          if (this.redirectPlatformLink != null) {
            window.open(this.redirectPlatformLink, "_self");
          } else {
            this.commonService.redirect("/profile");
          }
        } else {
          this.isSubmitting = false;
        }
      },
      async (error) => {
        this.isSubmitting = false;
        if (error.errorCode === "E1054") {
          this.updateAraStatusForm.controls.araPermitHKCrewMemberCertNo.setErrors({
            duplicateAraCertNo: true,
          });
          await this.commonService.browserDelay(100);
          this.formService.scrollToError();
        }
      }
    );
  }

  // TODO: Sperate transformer?
  transformRegisterForm() {
    const timeService = new TimeService();
    const requestBody = {
      araPermitExpiryDate: this.updateAraStatusForm.controls.araPermitExpiryDate.value,
      araPermitExpiryDateString: timeService.dateFormat(this.updateAraStatusForm.controls.araPermitExpiryDate.value),
      araPermitHKCrewMemberCertNo: this.updateAraStatusForm.controls.araPermitHKCrewMemberCertNo.value,
      araPermitPhotoBack: this.formattedPermitPhotoBack,
      araPermitPhotoFront: this.formattedPermitPhotoFront,
      companyName: this.updateAraStatusForm.controls.companyName.value,
      companyNameOthers: this.updateAraStatusForm.controls.companyNameOthers.value,
    };
    return _.pick(requestBody, _.identity);
  }

  // nzBeforeUpload function Not support IE9
  processPermitPhotoFront = (file: File): boolean => {
    this.mediaService.uploadPhotoPreCheck(file).then(
      (isFileValid) => {
        if (isFileValid) {
          this.mediaService.compressPhoto(file).then(
            (compressedFile) => {
              this.mediaService.transformToBase64(compressedFile, (img: string) => {
                const imgString = img.replace(/^data:image\/[a-z]+;base64,/, "");
                this.formattedPermitPhotoFront = imgString;
                this.permitPhotoFront = img as string;
              });
            },
            (error) => {
              alert(this.i18nService.translateLabel("COMMON.message.invalidPhotoFormat"));
            }
          );
        }
      },
      (error) => {
        alert(this.i18nService.translateLabel(error));
      }
    );
    return false;
  };

  processPermitPhotoBack = (file: File): boolean => {
    this.mediaService.uploadPhotoPreCheck(file).then(
      (isFileValid) => {
        if (isFileValid) {
          this.mediaService.compressPhoto(file).then(
            (compressedFile) => {
              this.mediaService.transformToBase64(compressedFile, (img: string) => {
                const imgString = img.replace(/^data:image\/[a-z]+;base64,/, "");
                this.formattedPermitPhotoBack = imgString;
                this.permitPhotoBack = img as string;
              });
            },
            (error) => {
              alert(this.i18nService.translateLabel("COMMON.message.invalidPhotoFormat"));
            }
          );
        }
      },
      (error) => {
        alert(this.i18nService.translateLabel(error));
      }
    );
    return false;
  };

  // Datepicker Config
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, new Date()) < 0;
  };

  get araPermitExpiryDate() {
    return this.updateAraStatusForm.get("araPermitExpiryDate") as FormControl;
  }
  get araPermitHKCrewMemberCertNo() {
    return this.updateAraStatusForm.get("araPermitHKCrewMemberCertNo") as FormControl;
  }
  get araPermitPhotoBack() {
    return this.updateAraStatusForm.get("araPermitPhotoBack") as FormControl;
  }
  get araPermitPhotoFront() {
    return this.updateAraStatusForm.get("araPermitPhotoFront") as FormControl;
  }
  get companyName() {
    return this.updateAraStatusForm.get("companyName") as FormControl;
  }
  get companyNameOthers() {
    return this.updateAraStatusForm.get("companyNameOthers") as FormControl;
  }
}
