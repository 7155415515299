import {Component, OnDestroy, OnInit} from "@angular/core";
import {CampaignsService, CommonService, I18NService} from "../../../core";
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import * as _ from "underscore";
import {Subscription} from "rxjs";

@Component({
  selector: "app-campaign-carousel",
  styleUrls: ["campaign-carousel.component.scss"],
  templateUrl: "./campaign-carousel.component.html",
})
export class CampaignCarouselComponent implements OnInit, OnDestroy {
  campaigns: any;
  loading = true;
  swiperConfig: SwiperConfigInterface;
  currentSlideIndex = 0;
  totalSlide: number;
  private subscription: Subscription;
  constructor(private campaignsService: CampaignsService, private i18nService: I18NService, private commonService: CommonService) {}

  ngOnInit() {
    this.subscription = this.i18nService.currentLang.subscribe((lang) => {
      this.getCampaigns();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCampaigns() {
    this.loading = true;
    this.campaigns = [];
    this.campaignsService.getAllCampaigns().subscribe((campaigns) => {
      const unsortedCampaigns = this.transformCampaign(campaigns).filter((item) => item.custom_payload && item.custom_payload.showOnPage);
      this.campaigns = unsortedCampaigns.sort((compaignA,compaignB)=> new Date(compaignB.start_date).valueOf() - new Date(compaignA.start_date).valueOf());
      this.totalSlide = this.campaigns.length;
      this.swiperConfig = {
        allowTouchMove: false,
        direction: "horizontal",
        loop: this.campaigns.length > 1 ? true : false,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
        },
        navigation: {
          nextEl: ".slider-pagination-prev",
          prevEl: ".slider-pagination-next",
        },
        effect: "slide",
        breakpoints: {
          1359: {
            centeredSlides: true,
            allowTouchMove: true,
          },
        },
      };
      this.loading = false;
    });
  }

  // TODO: Transform to service
  transformCampaign(campaigns) {
    const i18nCode = this.i18nService.getActiveLang;
    return _.each(campaigns, (record: any) => {
      if (record.custom_payload) {
        record.customContent = {
          title: record.custom_payload[i18nCode + ".title"],
          desc: record.custom_payload[i18nCode + ".desc"],
          termsAndConditions: record.custom_payload[i18nCode + ".termsAndConditions"],
          graphics: record.custom_payload[i18nCode + ".graphics"],
          graphics_banner: record.custom_payload[i18nCode + ".graphics_banner"],
          short_desc: record.custom_payload[i18nCode + ".short_desc"],
        };
      }
    }) as any;
  }

  changeSlideIndex(event) {
    // console.log("event", event);
  }

  trackFunc = (index, item) => {
    return index;
  };

  goToCampaign(item) {
    // console.log("item", item)
    this.commonService.redirect("/campaigns", item.permalink);
  }
}
