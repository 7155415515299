import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FaqService, I18NService } from '../../core';

@Injectable({
  providedIn: 'root'
})
export class AboutProgrammeResolverService implements Resolve<any>{

  constructor (
    private faqService: FaqService,
    private router: Router,
    private i18nService: I18NService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.faqService.getKnowledgeArticle(this.i18nService.getActiveLang, "MP")
      .pipe(catchError((err) => this.router.navigateByUrl("/")));
  }
}
