import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit, OnDestroy, TemplateRef, ViewChild, Inject, InjectionToken } from "@angular/core";
import { NzModalService, NzModalRef } from "ng-zorro-antd";
import { Offer } from "../../../core/models/offer.model";
import { OffersList, User, UserService, OffersService, ProfilesService, I18NService, CommonService, GeneralGuard } from "../../../core";
import { Subscription } from "rxjs";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { environment } from '../../../../environments/environment';
import { GlobalConfig } from '../../../core/app-global.config';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Location } from "@angular/common";
export const EnvironmentToken = new InjectionToken("ENVIRONMENT");
@Component({
  selector: "app-offer-detail",
  templateUrl: "./offer-detail.component.html",
  styleUrls: ["./offer-detail.component.scss"],
  providers: [{ provide: EnvironmentToken, useValue: environment }],
})
export class OfferDetailComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private offersService: OffersService,
    private userService: UserService,
    private i18nService: I18NService,
    private modalService: NzModalService,
    private profileService: ProfilesService,
    private commonService: CommonService,
    private _sanitizer: DomSanitizer,
    private titleService: Title,
    private authStorage: OAuthStorage,
    private generalGuardService: GeneralGuard,
    private _location: Location,
    private router: Router,
    @Inject(EnvironmentToken) private env: any
  ) {}

  private subscription: Subscription;

  rewardStoreOfferId: any;
  @Input() comment: OffersList;
  @ViewChild("closeIcon", { static: false }) closeIcon: TemplateRef<void>;
  @ViewChild("confirmModal", { static: false }) confirmModal: TemplateRef<{}>;
  currentUser: User = {} as User;
  isLoading = true;
  isPointReady = false;
  isSubmitting = false;
  issueOfferModal: NzModalRef;
  massTimingsHtml: any;
  offerData: Offer;
  pointBalance: any;
  rewardStoreId = "";
  showResult = false;
  isNotZeroPoint = true;
  oos:boolean = false;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.rewardStoreId = params["rewardStoreId"];
      this.rewardStoreOfferId = params["rewardStoreOfferId"];
    });
    this.route.data.subscribe((data: { offer: Offer }) => {
      if (data.offer) {
        this.offerData = data.offer;
        const customData = JSON.parse(data.offer.custom_data);
        if (customData) {
          this.offerData.header_banner = customData[this.i18nService.getActiveLang + ".offer_photo"];
          this.offerData.subtitle = customData[this.i18nService.getActiveLang + ".offer_card_desc"];
          this.offerData.tagline = customData[this.i18nService.getActiveLang + ".tagline"];
        }
        if (data.offer.offer_text[0]) {
          this.offerData.title = data.offer.offer_text[0]["title"];
          this.offerData.description = data.offer.offer_text[0]["description"];
          this.offerData.terms = this.getInnerHTMLValue(data.offer.offer_text[0]["terms"]);
        }
        this.titleService.setTitle(this.offerData.title + " | " + this.titleService.getTitle());
        this.isLoading = false;

        if (data.offer.points === 0 || data.offer.points === null){
          this.isNotZeroPoint = false;
        }

        this.oos = this.determineIfOos(this.offerData.available_inventory_remaining)
      }
    });
    Object.assign(this.currentUser, this.userService.getCurrentUser);
    if (this.currentUser.memberNumber) {
      this.subscription = this.profileService.getUserBalance(this.currentUser.memberNumber).subscribe((pointBalance) => {
        this.isPointReady = true;
        this.pointBalance = pointBalance.pointBalanceResponse.payload.summary.total_points;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.issueOfferModal) {
      this.issueOfferModal.destroy();
    }
  }

  /**
   * Popup Confirmation Message
   */
  onClickRedeem() {
    //check session
    this.userService.checkSessionActiveness();
    if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
      const targetUrl = decodeURI(this._location.path());
      const expireObject = {
        value: true,
        targetUrl: targetUrl,
      };
      localStorage.setItem("expireObject", JSON.stringify(expireObject));
     this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
    }

    if (this.userService.isLoggedIn) {
      this.issueOfferModal = this.modalService.create({
        nzContent: this.confirmModal,
        nzClassName: "redeem-offer-modal",
        nzFooter: null,
        nzCloseIcon: this.closeIcon
      });
    } else {
      this.userService.login();
    }
  }

  issueOffer() {
   //check session
   this.userService.checkSessionActiveness();
   if (this.generalGuardService.loggedInBefore && !this.authStorage.getItem("access_token")) {
     const targetUrl = decodeURI(this._location.path());
     const expireObject = {
       value: true,
       targetUrl: targetUrl,
     };
     localStorage.setItem("expireObject", JSON.stringify(expireObject));
     this.router.navigateByUrl(`/en_US/logout?cb=${environment.baseUrl}`);
   }

    this.isSubmitting = true;
    this.offersService.issueRewardStoreOffer(this.rewardStoreId, this.rewardStoreOfferId).subscribe(
      (data: any) => {
        // Update Point Balance
        this.profileService.getUserBalance(this.currentUser.memberNumber).subscribe((pointBalance) => {
          this.pointBalance = pointBalance.pointBalanceResponse.payload.summary.total_points;
        });
        this.isSubmitting = false;
        this.showResult = true;
      },
      (errors) => {
        this.issueOfferModal.destroy();
        if (errors.errorCode === "E2009") {
          this.modalService.info({
            nzTitle: this.i18nService.translateLabel("offer.MESSAGE.INSUFFICIENT POINTS"),
            nzOkText: this.i18nService.translateLabel("COMMON.LABEL.BACK"),
          });
        } else if (errors.errorCode === "E2018") {
          this.modalService.info({
            nzTitle: this.i18nService.translateLabel("offers.MESSAGE.CONNECTION_FAILURE"),
            nzOkText: this.i18nService.translateLabel("COMMON.LABEL.BACK"),
            nzOnOk:()=>{ location.reload() }
          });
        } else if (errors.errorCode === "E2019") {
          this.modalService.info({
            nzTitle: this.i18nService.translateLabel("offers.MESSAGE.OUT_OF_STOCK"),
            nzOkText: this.i18nService.translateLabel("COMMON.LABEL.BACK"),
          });
        } else if (errors.errorCode === "E2020") {
          this.modalService.info({
            nzTitle: this.i18nService.translateLabel("offers.MESSAGE.NOT_ACTIVE"),
            nzOkText: this.i18nService.translateLabel("COMMON.LABEL.BACK"),
          });
        } else {
          this.modalService.info({
            nzTitle: this.i18nService.translateLabel("offers.MESSAGE.CONNECTION_FAILURE"),
            nzOkText: this.i18nService.translateLabel("COMMON.LABEL.BACK"),
            nzOnOk:()=>{ location.reload() }
          });
        }
        this.isSubmitting = false;
      }
    );
  }

  back() {
    this.commonService.backToPreviousState();
  }

  getInnerHTMLValue(terms) {
    return this._sanitizer.bypassSecurityTrustHtml(terms);
  }

  determineIfOos(value){
    if(!isNaN(Number(value)) && value <= 0){
      return true
    }
    return false
  }
}
