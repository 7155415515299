import { Injectable, } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { RemoteConfigService, UserService } from "../../../core";

@Injectable()
export class UpdateProfileResolver implements Resolve<any> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.userService.getMember()
      .pipe(catchError((err) => this.router.navigateByUrl("/")));
  }
}
