import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ApiService } from './api';
import { differenceInCalendarDays, format } from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class RegisterPurchaseService {

  constructor (private apiService: ApiService) { }

  getSpendingAmount(purchaseDate: string): Observable<any> {
    return this.apiService.post("/v1/memberPortal/getSpendingAmount", "data-sync", { purchaseDate, Channel: 'MP' }).pipe(map((data) => data));
  }
}
