import { Component } from "@angular/core";

@Component({
  selector: "app-airplane-loader",
  templateUrl: "./airplane-loader.component.html",
  styleUrls: ["./airplane-loader.component.scss"],
})
export class AirplaneLoaderComponent{
;
  constructor() { }

}