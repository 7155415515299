const JsBarcode = require("jsbarcode");
import * as _ from "underscore";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { NzModalService, NzModalRef } from "ng-zorro-antd";
import { Offer } from "../../../core/models/offer.model";
import { OffersService, I18NService, LANGS, QrCodeService, CommonService } from "../../../core";
import { RedeemOfferConstants } from "../redeem-offer/redeem-offer.constants";
import { RedeemedOfferDetailConstants } from "./redeemed-offer-detail.constants";

@Component({
  selector: "app-redeemed-offer-detail",
  templateUrl: "./redeemed-offer-detail.component.html",
  styleUrls: ["./redeemed-offer-detail.component.scss"],
})
export class RedeemedOfferDetailComponent implements OnInit, OnDestroy {
  redeemOfferConstants = RedeemedOfferDetailConstants;

  offerData: Offer;
  userOfferStatus: any;

  isSubmitting = false;

  otpConfig = {
    length: 4,
    disableAutoFocus: true,
    containerStyles: {
      "text-align": "center",
    },
    inputStyles: {
      "border-radius": "0px",
      height: "50px",
      width: "50px",
    },
    inputClass: "ant-input",
    allowNumbersOnly: true,
  };

  panels = [
    {
      active: false,
      disabled: false,
      name: "Terms and Conditions",
      icon: "double-right",
      customStyle: {
        "margin-bottom": "24px",
        border: "0px",
      },
    },
  ];

  // TODO:
  _tempPassCode = null;
  _tempPassCodeAnswer = null;

  verCodeModal: any;

  tplModal: NzModalRef;
  isVerCodeInvalid = false;
  promoteCode: any;
  promoteBarCode: any;
  redeemedContent: any;
  storeName: string;
  isShownBtn: boolean = true;

  @ViewChild("enterVerCode", { static: false }) enterVerCode: TemplateRef<{}>;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInputRef: any;
  @ViewChild("enterVerCodeFooter", { static: false })
  enterVerCodeFooter: TemplateRef<{}>;

  constructor(
    private qrCodeService: QrCodeService,
    private route: ActivatedRoute,
    private offersService: OffersService,
    private modalService: NzModalService,
    private i18nService: I18NService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { offer: any }) => {
      if (data.offer.length > 0) {
        const userOfferStatus = data.offer[0]["userOfferStatus"];
        const offerDetail = data.offer[0]["offerDetail"];
        this.offerData = offerDetail;
        const customData = JSON.parse(offerDetail.custom_data);
        if (customData) {
          this.offerData.custom_data = customData;
          this.offerData.header_banner = customData[this.i18nService.getActiveLang + ".offer_photo"];
          this.storeName = this.offerData.custom_data[`${this.i18nService.getActiveLang}.offer_popup_desc`];
        }
        this.userOfferStatus = userOfferStatus;

        if (userOfferStatus.redeem_date && customData.offer_type !== "DIRECT_USE") {
          this.userOfferStatus["isRedeemed"] = true;
        } else {
          this.userOfferStatus["isRedeemed"] = false;
        }
        if (!this.userOfferStatus.isRedeemed && this.userOfferStatus.redemptionEndDate && new Date() > this.userOfferStatus.redemptionEndDate) {
          this.userOfferStatus["isExpired"] = true;
        } else {
          this.userOfferStatus["isExpired"] = false;
        }

        switch (customData.offer_type) {
          case "ECOUPON":
            this.offerData.redeemMethod = RedeemOfferConstants.ECOUPON;
            break;
          case "PARTNER_QR_CODE":
          case "PARTNER_BAR_CODE":
            this.offerData.redeemMethod = RedeemOfferConstants.COMFIRM_USE;
            break;
          case "DIRECT_USE":
            this.offerData.redeemMethod = RedeemOfferConstants.DIRECT_USE;
            break;
          case "VERIFICATION_CODE":
            this.offerData.redeemMethod = RedeemOfferConstants.VERIFICATION_CODE;
            this.offerData.passcode = customData.passcode;
            if (this.userOfferStatus.isRedeemed) {
              this.redeemedContent = customData["vcode_image"];
            }
            break;
          default:
            this.offerData.redeemMethod = customData.offer_type;
        }
      }
    });
  }

  ngOnDestroy() {}

  useOffer() {
    switch (this.offerData.redeemMethod) {
      case RedeemOfferConstants.COMFIRM_USE:
        this.redeemConfirmUseOffer();
        break;
      case RedeemOfferConstants.DIRECT_USE:
        this.redeemDirectUseOffer();
        break;
      case RedeemOfferConstants.VERIFICATION_CODE:
        this.redeemVerCodeOffer();
        break;
      default:
        this.redeemDirectUseOffer();
    }
  }

  redeemDirectUseOffer() {
    if (this.userOfferStatus.id && !this.userOfferStatus.redeem_date) {
      this.isSubmitting = true;
      this.offersService.manualRedeemCoupon(this.userOfferStatus.id).subscribe(
        (response: any) => {
          this.isSubmitting = false;
          this.redeemedContent = this.offerData.custom_data["directuse_image"];
          this.isShownBtn = false;
        },
        (errors) => {
          this.isSubmitting = false;
        }
      );
    } else {
      this.redeemedContent = this.offerData.custom_data["directuse_image"];
      this.isShownBtn = false;
    }
  }

  redeemConfirmUseOffer() {
    this.modalService.confirm({
      nzTitle:
        this.offerData.custom_data["offer_type"] === "PARTNER_BAR_CODE"
          ? this.i18nService.translateLabel("offers.MESSAGE.CONFIRM_TO_USE_BARCODE")
          : this.i18nService.translateLabel("offers.MESSAGE.CONFIRM_TO_USE_QRCODE"),
      nzOkText: this.i18nService.translateLabel("COMMON.LABEL.OK"),
      nzCancelText: this.i18nService.translateLabel("COMMON.LABEL.CANCEL"),
      nzOnOk: () => {
        this.isSubmitting = true;
        this.offersService.manualRedeemCoupon(this.userOfferStatus.id, true).subscribe(
          (response: any) => {
            if (response.promocode) {
              switch (this.offerData.custom_data["offer_type"]) {
                case "PARTNER_BAR_CODE": {
                  this.promoteBarCode = response.promocode;
                  JsBarcode("#barCode", response.promocode);
                  break;
                }
                default: {
                  this.qrCodeService.getQRCode(response.promocode).then((code) => {
                    this.promoteCode = code;
                  });
                }
              }
            }
            this.userOfferStatus.isRedeemed = true;
            this.isSubmitting = false;
          },
          (errors) => {
            this.isSubmitting = false;
          }
        );
      },
      nzOnCancel: () => console.log("Cancel"),
    });
  }

  redeemVerCodeOffer() {
    // Reset Modal
    this.isVerCodeInvalid = false;
    this._tempPassCode = null;
    this._tempPassCodeAnswer = this.offerData.custom_data["passcode"];
    this.verCodeModal = this.modalService.create({
      nzContent: this.enterVerCode,
      nzWrapClassName: "vertical-center-modal",
      nzMaskClosable: false,
      nzFooter: this.enterVerCodeFooter,
      nzBodyStyle: {
        'padding': '80px 40px', 
        'text-align': 'center'
      }
    });
  }

  manualRedeemOffer(offerId) {
    const self: any = this;
    self.isVerifyingCode = true;
    this.offersService.manualRedeemCoupon(offerId).subscribe(
      (response: any) => {
        this.userOfferStatus.isRedeemed = true;
        this.redeemedContent = this.offerData.custom_data["vcode_image"];
        self.isVerifyingCode = false;
        this.verCodeModal.destroy();
      },
      (errors) => {
        self.isVerifyingCode = false;
        this.verCodeModal.destroy();
      }
    );
  }

  onOtpChange(event) {
    this._tempPassCode = event;
  }

  verifyRedeemCode() {
    if (this._tempPassCode && this._tempPassCodeAnswer === this._tempPassCode.toString()) {
      this.manualRedeemOffer(this.userOfferStatus.id);
    } else {
      this.isVerCodeInvalid = true;
    }
  }

  back() {
    this.commonService.backToPreviousState();
  }
}
