import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { I18NService, ProfilesService, UserService, User, CommonService, ErrorService, AlertService } from "./../../core";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import { NzMessageService } from "ng-zorro-antd/message";

@Component({
  selector: "app-rewards-calculator",
  templateUrl: "./rewards-calculator.component.html",
  styleUrls: ["./rewards-calculator.component.scss"],
})
export class RewardsCalculatorComponent implements OnInit {
  @ViewChild("inputElement", { static: false }) inputElement: ElementRef;

  version: string;
  memberId: string;
  @Input() pointBalance: any;
  spendValue: number = null;
  spendLastValue: number = null;
  doubleCampaign: boolean = false;
  isFocused: boolean = false;
  isActive: boolean = false;
  openBalanceDetail: boolean = false;
  user: User = {} as User;
  @Input() rewardInfo: any;

  @Input() showClose: boolean = false;
  @Output() handleClose = new EventEmitter<Event>(); // Use specific type instead of any if possible

  constructor(
    private i18nService: I18NService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private messageService: NzMessageService,
    private errorService: ErrorService,
    private profileService: ProfilesService,
    private userService: UserService,
    private deviceDetectorService: DeviceDetectorService,
    private commonService: CommonService,
    private alertService: AlertService
  ) {
    this.version = sessionStorage.getItem("hkia-dollar-memberInfo-version");
    Object.assign(this.user, this.userService.getCurrentUser);
    this.memberId = this.user.memberNumber;
  }

  ngOnInit() {
    this.getUserBalance();
    this.getRewards();
  }

  _handleClose(event) {
    this.handleClose.emit(event);
  }

  onKeyPress(event: any) {
    const inputChar = event.key;
    const currentValue = event.target.value;

    if (/[\d\.]|Backspace/.test(inputChar)) {
      const hasDecimal = currentValue.includes(".");
      if (inputChar === ".") {
        if (currentValue === "" || currentValue === "-") {
          event.preventDefault();
        }
      } else if (
        (hasDecimal && inputChar === ".") ||
        (inputChar === "-" && currentValue !== "") ||
        (inputChar === "0" && currentValue === "0") ||
        (inputChar !== "0" && currentValue === "-")
      ) {
        event.preventDefault();
      } else if (hasDecimal) {
        const parts = currentValue.split(".");
        if (inputChar !== "Backspace" && event.target.selectionStart > parts[0].length && parts[1].length >= 1) {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  }
  handleInput(event: any) {
    let value = Number(event.target.value);
    if (value > 9999999) {
      event.target.value = 9999999;
      value = 9999999;
    }
    this.spendValue = value;
  }

  handleFocus() {
    this.isFocused = true;
    this.isActive = false;
    setTimeout(() => this.inputElement.nativeElement.focus(), 0);
  }

  handleBlur() {
    this.spendLastValue = this.spendValue;
    if (this.spendValue) {
      this.isActive = true;
      this.isFocused = false;
      return;
    }
    this.isActive = false;
    this.isFocused = false;
  }

  get version2Display(): boolean {
    return true;
  }

  getRewards() {
    console.log("this.rewardInfo", this.rewardInfo);
    if (!this.rewardInfo) {
      this.profileService.getRewards(this.memberId).subscribe(
        (rewards: any) => {
          // const campaignDetail = _.values(rewards.campaignDetail);
          const { progress, status, campaignDetail } = rewards;
          this.rewardInfo = {
            progress: progress || {},
            campaignDetail,
            status,
          };
        },
        (error) => {}
      );
    }
  }

  get bonusPoints(): number {
    const sortedItems = _.sortBy(Object.values(this.rewardInfo.campaignDetail), "totalSpending");
    const currentAmount = this.rewardInfo.progress.currentAmount;
    const spendLastValue = this.spendLastValue;
    let startIndex = _.findIndex(sortedItems, (item: any) => item.totalSpending > currentAmount);
    let endIndex = _.findLastIndex(sortedItems, (item: any) => item.totalSpending <= currentAmount + spendLastValue);

    let outcomeTotal = 0;

    if (this.spendLastValue && startIndex !== -1 && endIndex !== -1) {
      for (let i = startIndex; i <= endIndex; i++) {
        const item: any = sortedItems[i];
        if (item.totalSpending === currentAmount + spendLastValue && i !== endIndex) {
          continue;
        }
        outcomeTotal += item.outcome;
      }
    }

    return outcomeTotal;
  }

  get newPointBalance(): number {
    let newPointBalanceValue = 0;
    const spendLastValue = Math.round(this.spendLastValue);
    const pointBalance: any = this.version2Display ? this.pointBalance.totalPoint : this.pointBalance.mpProgressBar.currentECouponPoint;
    if (spendLastValue) {
      if (this.doubleCampaign) {
        let doubleSpendValue = spendLastValue * 2;
        newPointBalanceValue = pointBalance + doubleSpendValue + this.bonusPoints;
      } else {
        newPointBalanceValue = pointBalance + spendLastValue + this.bonusPoints;
      }
    }
    return newPointBalanceValue;
  }

  get eCoupon(): number {
    if (this.spendLastValue) {
      return Math.floor(Math.floor((this.newPointBalance / 5000) * 100) / 100) * 100;
    }
    return 0;
  }

  get hkaaDollar(): number {
    if (this.spendLastValue) {
      return Math.floor(Math.floor((this.newPointBalance / this.pointBalance.conversionRate) * 100) / 100);
    }
    return 0;
  }

  getUserBalance() {
    console.log("this.pointBalance", this.pointBalance);
    if (!this.pointBalance) {
      if (this.version2Display) {
        this.profileService.getUserBalanceForHkaaDollar(this.memberId).subscribe((pointBalance) => {
          this.pointBalance = pointBalance;
        });
        return;
      } else {
        this.profileService.getUserBalance(this.memberId).subscribe((pointBalance) => {
          const subPoints = _.map(pointBalance.pointExpiry, (item: any) => {
            return {
              availableBalance: item.points,
              expireDate: item.tillDate,
            };
          });

          this.pointBalance = {
            ...pointBalance,
            showRedeemECoupon: pointBalance.mpProgressBar.hasEarnedECoupon,
            subPoints: subPoints,
          };
        });
      }
    }
  }
}
