import { ApiService } from "./api/api.service";
import { Observable } from "rxjs";
import { MemberAssociation, PointTransferRequest } from "../../core";
import { map} from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable()
export class AssociateMemberService{
  constructor(
    private apiService: ApiService,
  ) {}

  isOverBondLimit(): Observable<boolean> {
    return this.apiService.get(`/member/isOverBondLimit`, "member" , null).pipe(
        map((data) => {
            return data;
        })
    );
  }

  bondInfo(): Observable<MemberAssociation> {
    return this.apiService.get(`/member/bondInfo`, "member" , null).pipe(
        map((data) => {
            return data;
        })
    );
  }

  createBond(memberNumber: string): Observable<MemberAssociation> {
    const requestBody = { memberNumber: memberNumber};
    return this.apiService.post(`/member/createBond`, "member" , requestBody).pipe(
        map((data) => {
            return data;
        })
    );
  }

  acceptBond(memberNumber: string): Observable<MemberAssociation> {
    const requestBody = { memberNumber: memberNumber};
    return this.apiService.post(`/member/acceptBond`, "member" , requestBody).pipe(
        map((data) => {
            return data;
        })
    );
  }

  rejectBond(memberNumber: string): Observable<MemberAssociation> {
    const requestBody = { memberNumber: memberNumber};
    return this.apiService.post(`/member/rejectBond`, "member" , requestBody).pipe(
        map((data) => {
            return data;
        })
    );
  }

  cancelBond(memberNumber: string): Observable<MemberAssociation> {
    const requestBody = { memberNumber: memberNumber};
    return this.apiService.post(`/member/cancelBond`, "member" , requestBody).pipe(
        map((data) => {
            return data;
        })
    );
  }

  deassociateBond(memberNumber: string): Observable<MemberAssociation> {
     const requestBody = { memberNumber: memberNumber};
    return this.apiService.post(`/member/deassociateBond`, "member" , requestBody).pipe(
        map((data) => {
            return data;
        })
    );
  }

  pointTransfer(pointTransferRequest: PointTransferRequest): Observable<any> {
    return this.apiService.post(`/member/pointTransfer`, "member" , pointTransferRequest).pipe(
        map((data) => {
            return data;
        })
    );
  }
}
