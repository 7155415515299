export const environment = {
  envName: "dev",
  production: false,
  baseUrl: "https://hkia.mp.acnhk.com",
  authClientId: "3MVG9Nvmjd9lcjRlR2OVFJuq2rjAosD0_8wIc2gO2LZM7K9Gvpw2afQjsMcM0VngvZUmCjYxPcC61TiYkyaPD",
  logoutUrl: "https://hkairportloyalty--sit.sandbox.my.site.com/hkairportrewards/services/auth/idp/oidc/logout",
  apiEndpoints: {
    apiUrl: "https://dev.api.iss.acnhk.com",
    storageService: "https://hkia-sf-data.s3-ap-southeast-1.amazonaws.com",
    oAuthService: "https://sit.api.iss.acnhk.com/proxy/sf",
    // oAuthService: "https://dev.api.iss.acnhk.com/proxy/sf",
    // memberService: "https://dev.api.iss.acnhk.com/core",
    memberService: "https://sit.api.iss.acnhk.com/core",
    // loyaltyService: "https://hkia.loyalty.dev.acnhk.com/loyalty",
    loyaltyService: "https://sit.api.iss.acnhk.com/loyalty",
    livechatService: "https://t6f2vhju5f.execute-api.ap-southeast-1.amazonaws.com/dev/proxy/chat",
    dataSyncService: "https://dev.api.iss.acnhk.com/data-sync",
    hkiadollarService: "https://sit.api.iss.acnhk.com/hkiadollar",
    campaignengineService: "https://sit.api.iss.acnhk.com/campaignengine",
  },
  LIVE_CHAT_CONFIG: {
    isLiveChatActivated: true,
    ids: ["5731y0000008OPB", "5731y0000008OP6", "5731y0000008ONy"],
    channel: "Member Portal",
    orgId: "00D1y0000008l0f",
    deploymentId: "5721y0000008OMb",
    myDomainURL: "https://hkairportloyalty--sit.sandbox.my.salesforce.com",
    communityURL: "https://hkairportloyalty--sit.sandbox.my.site.com/hkairportrewards",
    eswJSURL: "https://hkairportloyalty--sit.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    gslbBaseURL: "https://service.force.com",
    baseLiveAgentURL: "https://d.la2-c1cs-hnd.salesforceliveagent.com/chat",
    baseLiveAgentContentURL: "https://c.la2-c1cs-hnd.salesforceliveagent.com/content",
    languageSetting: {
      luxury: {
        zh_CN: {
          buttonId: "573O0000000Cb2x",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IO00000008OQ9MAM_1731266dd3e",
          eswLiveAgentDeploymentName: "Live_Chat_LUX_SC",
        },
        zh_HK: {
          buttonId: "573O0000000Cb32",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IO00000008OQEMA2_1731270d8e1",
          eswLiveAgentDeploymentName: "Live_Chat_LUX_TC",
        },
        en_US: {
          buttonId: "573O0000000Cb2s",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IO00000008OQJMA2_17312735dcb",
          eswLiveAgentDeploymentName: "Live_Chat_LUX_EN",
        },
      },
      general: {
        zh_CN: {
          buttonId: "573O0000000Cb2i",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IO00000008OPpMAM_1730da480c1",
          eswLiveAgentDeploymentName: "Live_Chat_GEN_SC",
        },
        zh_HK: {
          buttonId: "573O0000000Cb2n",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IO00000008OPuMAM_1730da4f18d",
          eswLiveAgentDeploymentName: "Live_Chat_GEN_TC",
        },
        en_US: {
          buttonId: "573O0000000Cb2d",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IO00000008OPkMAM_1730d97a711",
          eswLiveAgentDeploymentName: "Live_Chat_GEN_EN",
        },
      },
    },
  },
  URL_CONFIG: {
    emp: "https://integration2-hohc4oi-fxustpq62yv4k.ap-3.magentosite.cloud",
    fos: "https://uat-mobile.hongkongairportfood.com",
    MyHKG: "https://www.hongkongairport.com",
  },
  APP_CONFIG: {
    GA_MEASUREMENT_ID: "",
    idleTime: 5,
    timeOut: 16,
    dataFormat: "DD/MM/YYYY",
    cookieDomain: ".force.com",
  },
  version: 1723424380,
  buildImgNumber: 232,
  MYHKG_UNIVERSAL_LINK: "https://www.hkairport-sqcc.com/apps/",
};
