import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
  ActivatedRoute
} from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { OffersService } from "../../../core";

@Injectable()
export class RedeemedOfferDetailResolver implements Resolve<any> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private offersService: OffersService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.offersService
      .getRedeemedOfferDetail(route.params["userOfferId"])
      .pipe(catchError(err => this.router.navigateByUrl("/")));
  }
}
