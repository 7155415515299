import { ApiService } from "./api/api.service";
import { I18NService } from "./i18n.service";
import { Injectable } from "@angular/core";
import { Observable, zip } from "rxjs";
import { User, Campaign, HighlightedCampaign } from "../models";
import { UserService } from "./user.service";
import { map, switchMap } from "rxjs/operators";
import { DomSanitizer } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class CampaignsService {
  // currentUser: User;
  user: User = {} as User;
  constructor(private apiService: ApiService, private userService: UserService, private i18nService: I18NService, private _sanitizer: DomSanitizer) {}

  getAllCampaigns(): Observable<Campaign[]> {
    return this.apiService.get(`/campaigns?lang=${this.i18nService.getActiveLang}`, "loyalty", null).pipe(map((data) => data.content));
  }

  getMemberCampaigns(): Observable<Campaign[]> {
    return this.apiService.get(`/campaigns/member/${this.user.memberNumber}`, "loyalty", null).pipe(map((data) => data.campaigns.promotions));
  }

  getCampaign(permalink: string): Observable<Campaign> {
    return this.apiService.get(`/campaigns/${permalink}`, "loyalty", null).pipe(map((data) => data.campaign));
  }

  retrieveHighlightedCampaigns(): Observable<Array<HighlightedCampaign>> {
    return this.apiService.get(`/campaigns/highlighted`, "loyalty", null).pipe(map((data) => data));
  }

  getCampaignTnc(externalLink: string): Observable<any> {
    // let params = new HttpParams().set('responseType', 'text');    // now it has aaa
    return this.apiService.get(externalLink, "storage", null).pipe(map((data) => data));
  }

  getSurveyLink(surveyName: string): Observable<Map<string, string>> {
    return this.apiService.get(`/survey/surveyLink/${surveyName}`, "loyalty", null).pipe(map((data) => data));
  }

  getCampaignDetail(permalink: string, currentLanguage: string): Observable<any> {
    return zip(
      this.getCampaign(permalink).pipe(
        switchMap((campaignDetail) => this.getCampaignTnc(campaignDetail.custom_payload[currentLanguage + ".tnc"] as string).pipe(map((termsContent) => ({ campaignDetail, termsContent }))))
      )
    ).pipe((result) => result);
  }
}
