import * as _ from "underscore";
import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-hkaa-dollar-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HkaaPaginationComponent implements OnInit, OnChanges {
  @Input() totalPages: number = 0;
  @Input() totalElement: number = 0;
  @Input() pageSize: number = 10;
  @Output() pageChange = new EventEmitter();
  @Input() pageIndex: number = 1;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {}

  get calculateTotalPages() {
    const totalPages = Math.floor(this.totalElement / this.pageSize);
    const remainder = this.totalElement % this.pageSize;
    return remainder === 0 ? totalPages : totalPages + 1;
  }

  get visiblePages(): number[] {
    this.totalPages = this.totalPages || this.calculateTotalPages;
    if (this.totalPages <= 5) {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      if (this.pageIndex < 3) {
        return [1, 2, 3];
      } else if (this.pageIndex >= this.totalPages - 1) {
        return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
      } else {
        if (this.pageIndex >= 3 && this.pageIndex <= this.totalPages - 2) {
          return [this.pageIndex - 1, this.pageIndex, this.pageIndex + 1];
        }
        return [this.pageIndex, this.pageIndex + 1, this.pageIndex + 2];
      }
    }
  }

  changePage(page: number) {
    this.pageIndex = page;
    this.pageChange.emit(this.pageIndex);
  }

  previousPage() {
    if (this.pageIndex === 1) return;
    this.pageIndex--;
    this.pageChange.emit(this.pageIndex);
  }

  nextPage() {
    if (this.pageIndex === this.totalPages) return;
    this.pageIndex++;
    this.pageChange.emit(this.pageIndex);
  }
}
