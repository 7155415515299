import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { I18NService, UserService, User, ProfilesService, QrCodeService, CommonService } from "./../../../../core";
import { Subscription, Observable, EMPTY, timer, of, Subject } from "rxjs";
import { switchMap, takeWhile, tap, finalize, catchError, debounceTime } from "rxjs/operators";
import { isEmpty, cloneDeepWith } from "lodash";
@Component({
  selector: "app-calculator-page",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.scss"],
})
export class CalculatorComponent implements OnInit, OnDestroy {
  config: any = {
    conversionRate: 0,
    maxAmount: 0,
    minAmount: 0,
    pollingInterval: 0
  };
  private appContainer: HTMLElement | null = null;
  memberId;
  keypadsList: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "all", "0", "delete"];
  hkaaDollarValue: string = "";
  memberPoints: string | number = 0;
  totalHKDollar: string | number = 0;
  exchangeRate: number = 50;
  isVisible: boolean = false;
  loadingVisible: boolean = false;
  isLoading: boolean = false;
  unsuccessfulVisible: boolean = false;
  user: User = {} as User;
  promoteCode: any;
  qrCodeTimes: number;
  qrCodeInfo = {
    id: "",
    token: "",
    expiryTime: "",
  };
  qrScanStatus: string;
  finalizeEnd: boolean = false;
  private timerSubscription: Subscription;
  private statusSubscription: Subscription;
  requestNotReached: boolean = true;
  version;
  errorCode;
  refreshQrCodeSubject = new Subject();
  isExecuting = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userServer: UserService,
    private i18nService: I18NService,
    private userService: UserService,
    private profileService: ProfilesService,
    private qrCodeService: QrCodeService,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this.language = this.i18NService.getActiveLang;
    // this.receiptNumber = localStorage.getItem("earn-now");
    // if (localStorage.getItem("earn-now")) {
    //   this.userServer.isAuthenticated.subscribe((isAuthenticated) => {
    //     if (isAuthenticated && localStorage.getItem("earn-now")) {
    //       this.router.navigate([`/${this.language}/earn-now/${this.receiptNumber}`]);
    //     }
    //   });
    // }
    this.commonService.setHideComponent(false);
    this.version = sessionStorage.getItem("hkia-dollar-memberInfo-version");
    // this.refreshQrCodeSubject.pipe(debounceTime(300)).subscribe(() => {
    //   if (!this.isExecuting) {
    //     this.generateQrCode();
    //   }
    // });
  }

  setElementHeight() {
    const windowHeight = window.innerHeight;
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const topInset = Math.max(0, windowHeight - documentHeight);
    const bottomInset = Math.max(0, documentHeight - windowHeight);
    const elementHeight = windowHeight - topInset - bottomInset - 70;
    const calculatorWarp = this.document.getElementById("calculatorWarp");
    calculatorWarp.style.height = elementHeight + "px";
  }

  ngOnInit(): void {
    this.setElementHeight();
    this.appContainer = this.document.getElementById("app-container");
    this.appContainer.style.overflowY = "hidden";
    this.appContainer.style.paddingBottom = "0";
    this.profileService.getDollarCalculatorConfig().subscribe((config: any) => {
      this.config = config;
    });
    Object.assign(this.user, this.userService.getCurrentUser);
    this.memberId = this.user.memberNumber;
    this.getBalance();
  }

  getBalance() {
    // AA-0000009712
    this.profileService.getUserBalanceForHkaaDollar(this.memberId).subscribe((pointBalance) => {
      this.memberPoints = pointBalance.totalPoint;
      this.totalHKDollar = pointBalance.totalHKDollar;
    });
  }

  ngOnDestroy(): void {
    if (this.appContainer) {
      this.appContainer.removeAttribute("style");
    }
    this.commonService.setHideComponent(true);
  }

  refreshQrCode() {
    // this.refreshQrCodeSubject.next();
    if (!this.isExecuting) {
      this.generateQrCode();
    }
    // this.generateQrCode();
  }

  confirmPayment() {
    this.isLoading = true;
    this.loadingVisible = true;
    this.generateQrCode();
  }

  generateQrCode() {
    this.isExecuting = true;
    this.errorCode = null;
    // this.memberId AA-0000016399 AA-0000017271
    this.profileService.generateQrCode(this.memberId, this.hkaaDollarValue).subscribe(
      (res: any) => {
        const result: any = {
          token: "HKIADOLLAR:" + res.token,
          memberId: this.memberId,
          id: res.id,
        };
        this.qrCodeInfo.id = res.id;
        this.qrCodeInfo.token = res.token;
        this.qrCodeInfo.expiryTime = res.expiryTime;
        this.qrScanStatus = res.status;
        const qrCodeData = JSON.stringify(result);
        this.qrCodeService.getQRCode(qrCodeData).then((code) => {
          this.promoteCode = code;
          const currentTime = new Date();
          const expiryTime = new Date(this.qrCodeInfo.expiryTime);
          const timeDifference = expiryTime.getTime() - currentTime.getTime();
          this.qrCodeTimes = Math.floor(timeDifference / 1000);
          if (this.qrCodeTimes < 0) {
            this.qrCodeTimes = 0;
          }
          this.isLoading = false;
          this.loadingVisible = false;
          this.isVisible = true;
          this.isExecuting = false;
          this.startCountdown();
        });
      },
      (error: any) => {
        const { errorCode } = error;
        this.errorCode = errorCode;
        this.isVisible = false;
        this.isLoading = false;
        this.loadingVisible = false;
        this.unsuccessfulVisible = true;
      }
    );
  }

  cancelPayment() {
    this.isLoading = true;
    if (this.qrScanStatus === "EXPIRED" || this.qrCodeTimes <= 0) {
      this.isVisible = false;
      this.unsubscribeTimer();
      this.qrScanStatus = null;
      setTimeout(() => (this.isLoading = false), 500);
      return;
    }

    this.profileService.voidQrCode({ tranId: this.qrCodeInfo.id, token: this.qrCodeInfo.token }).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.qrScanStatus = res.status;
        if (this.qrScanStatus === "VOIDED") {
          this.isVisible = false;
          this.unsubscribeTimer();
          this.qrScanStatus = null;
        }
      },
      (error) => {
        const { errorCode } = error;
        this.errorCode = errorCode;
        this.isLoading = false;
        this.isVisible = false;
        this.qrScanStatus = null;
        this.unsubscribeTimer();
        this.unsuccessfulVisible = true;
      }
    );
  }

  startCountdown(): void {
    this.finalizeEnd = false;
    this.timerSubscription = timer(0, 1000)
      .pipe(
        takeWhile(() => this.qrCodeTimes > 0 && (this.qrCodeIsOpen || this.qrScanStatus === "LOCKED")),
        tap(() => {
          this.qrCodeTimes--;
          if (this.qrCodeTimes % this.config.pollingInterval === 0) {
            return this.checkScanQrCodeStatus().subscribe();
          }
        }),
        catchError(() => EMPTY),
        finalize(() => {
          this.finalizeEnd = true;
          if (!this.paymentSuccessVisible || this.qrScanStatus !== "FAILED") {
            this.keepQueryForScan();
          }
        })
      )
      .subscribe();
  }

  keepQueryForScan() {
    this.statusSubscription = timer(0, this.config.pollingInterval)
      .pipe(
        takeWhile(() => this.qrCodeTimes <= 0 && (!this.paymentSuccessVisible || this.qrScanStatus !== "FAILED")),
        tap(() => this.checkScanQrCodeStatus().subscribe()),
        catchError(() => EMPTY)
      )
      .subscribe();
  }

  checkScanQrCodeStatus(): Observable<any> {
    if (this.statusSubscription && !this.statusSubscription.closed) {
      this.statusSubscription.unsubscribe();
    }
    this.finalizeEnd = false;
    this.errorCode = null;
    return this.profileService.checkScanQrCode({ qrCodeId: this.qrCodeInfo.id, token: this.qrCodeInfo.token }).pipe(
      catchError((error) => {
        const { errorCode } = error;
        if (errorCode === "E0403") {
          this.errorCode = errorCode;
          this.isVisible = false;
          this.unsuccessfulVisible = true;
        }
        if (this.loadingVisible) {
          this.loadingVisible = false;
        }
        return EMPTY;
      }),
      switchMap((data: any) => {
        if (this.qrCodeTimes <= 0 && data.status === "OPEN") {
          this.checkScanQrCodeStatus().subscribe();
        }
        if (!this.finalizeEnd) {
          this.qrScanStatus = data.status;
        }
        if (this.qrCodeIsOpen) {
          this.loadingVisible = false;
        } else if (this.paymentSuccessVisible) {
          this.qrCodeTimes = 0;
          this.loadingVisible = false;
          this.unsubscribeTimer();
        } else if (this.qrScanStatus === "LOCKED") {
          this.isVisible = false;
          this.loadingVisible = true;
        } else if (this.qrScanStatus === "EXPIRED") {
          this.unsubscribeTimer();
        } else if (!this.paymentSuccessVisible && !this.qrCodeIsOpen && this.qrScanStatus !== "LOCKED" && this.qrScanStatus !== "EXPIRED") {
          this.isVisible = false;
          this.loadingVisible = false;
          this.unsuccessfulVisible = true;
          this.unsubscribeTimer();
        }
        return of(data);
      })
    );
  }

  private unsubscribeTimer(): void {
    if (this.timerSubscription && !this.timerSubscription.closed) {
      this.timerSubscription.unsubscribe();
    }
  }

  paymentSuccessConfirm() {
    this.qrScanStatus = "";
    this.isVisible = false;

    const queryParams = { paymentSuccess: true };
    this.backToHomePage(queryParams);
  }

  backToHomePage(params = {}) {
    const language = this.i18nService.getActiveLang;
    let backUrl = `${language}/profile/personal-info?version=${this.version}`;
    if (!isEmpty(params)) {
      const paramString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      backUrl += `&${paramString}`;
    }
    this.router.navigateByUrl(backUrl);
  }

  calculatorSelect(val: string) {
    if (val === "max") {
      this.hkaaDollarValue = this.totalHKDollar.toString();
      return;
    }
    this.hkaaDollarValue = val;
  }

  handleKeypadsClick(val: string) {
    if (val === "delete") {
      this.hkaaDollarValue = this.hkaaDollarValue.slice(0, -1);
      return;
    }
    if (val === "all") {
      this.hkaaDollarValue = this.totalHKDollar.toString();
      return;
    }
    this.hkaaDollarValue += val;
  }

  handleErrorClose() {
    if (this.errorCode === "E2018" || this.errorCode === "E2012" || this.errorCode === "E0403" || this.qrScanStatus === "LOCKED") {
      this.backToHomePage();
    } else {
      this.getBalance();
      this.hkaaDollarValue = "";
      this.unsuccessfulVisible = false;
    }
  }

  get usePoints() {
    return Number(this.hkaaDollarValue) * this.config.conversionRate;
  }

  get showUsePointsLabel() {
    if (this.hkaaDollarValue && !this.miniMun && !this.insufficient && !this.maxiMun) {
      return true;
    }
    return false;
  }

  get miniMun() {
    return this.hkaaDollarValue && Number(this.hkaaDollarValue) < this.config.minAmount;
  }

  get maxiMun() {
    return Number(this.totalHKDollar) > this.config.maxAmount && this.hkaaDollarValue && Number(this.hkaaDollarValue) > this.config.maxAmount;
  }

  get insufficient() {
    return this.hkaaDollarValue && Number(this.hkaaDollarValue) > Number(this.totalHKDollar) && !this.maxiMun;
  }

  get paymentSuccessVisible(): boolean {
    return this.qrScanStatus === "CONSUMED" || this.qrScanStatus === "COMPLETED";
    // return true;
  }

  // get unsuccessfulVisible(): boolean {
  //   return true;
  // }

  get showQrCodeVisible(): boolean {
    return this.isVisible && !this.paymentSuccessVisible && !this.loadingVisible;
  }

  get qrCodeIsOpen(): boolean {
    return this.qrScanStatus === "OPEN";
  }

  get isExpired(): boolean {
    return this.qrScanStatus === "EXPIRED";
  }

  get showRefreshQrCode(): boolean {
    return this.isExpired;
  }
}
