import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { ProfilesService, UserService, I18NService, AssociateMemberService, MemberAssociation, PointTransferRequest } from '../../../core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-associated-member',
  templateUrl: './associated-member.component.html',
  styleUrls: ['./associated-member.component.scss']
})
export class AssociatedMemberComponent implements OnInit, OnDestroy {

  @Input() formConfig;
  
  private haveBondingSubject = new BehaviorSubject<boolean>(false);
  haveBonding = this.haveBondingSubject.asObservable();
  // Subscriptions
  currentLangSubscription: Subscription;
  pointTransferSubscription: Subscription;
  userBalanceSubscription: Subscription;
  bondLimitSubscription: Subscription;
  bondInfoSubscription: Subscription;
  haveBondingSubscription: Subscription;
  pointsValueSubscription: Subscription;
  acceptBondSubscription: Subscription;
  createBondSubscription: Subscription;
  apiSubscription: Subscription;
  // Associated Form
  associatedForm: FormGroup;
  showFormMsg: boolean = true;
  associatedFormMsgLabel: string = "profile.associatedMember.senderAssociatedFormMsg";
  associatedFormMsg: string = this.i18nService.translateLabel("profile.associatedMember.senderAssociatedFormMsg");
  memberNumberTips: string = this.i18nService.translateLabel("profile.associatedMember.memberNumberPlaceholder");
  showMemberNumber: boolean = false;
  associatedMemberNumber: string;
  associatedMemberName: string = "-";
  status: string = "-";
  statusLabel: string;
  isSender: boolean;
  shouldConnectBtnDisabled: boolean = true;
  shouldCancelBtnDisabled: boolean = true;
  shouldShowConnectError: boolean = false;
  connectionError: string = "";
  connectionErrorLabel: string = "";
  cancelBtnCurrentLabel: string = "profile.associatedMember.cancel";
  cacelBtnLabel: string = this.i18nService.translateLabel("profile.associatedMember.cancel");
  isBonded: boolean = false;
  isExceededLimit: boolean = false;
  isConnecting: boolean = false;
  isCancelling: boolean = false;
  // Point Transfer Form
  pointTransferForm: FormGroup;
  currentPointBalance: number;
  remainingPoint: number | string = "-";
  shouldTransferBtnDisabled: boolean = true;
  shouldShowTransferError: boolean = false;
  transferPointError: string = "";
  transferPointErrorLabel: string = "";
  isTransferring: boolean = false;

  constructor(
    private profileService: ProfilesService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: NzModalService,
    private i18nService: I18NService,
    private associateMemberService: AssociateMemberService,
  ) {
    this.initForm();
    this.translateLabel();
  };

  ngOnInit() {
    this.checkIfExceedBondLimit().then(() => {
      this.getBondInfo();
    });
    this.getUserPointBalance();
    this.calculatePoint();
  };

  ngOnDestroy() {
    this.unsubscribeAll();
  };

  initForm() {
    this.associatedForm = this.formBuilder.group(
      {
        memberNumber: [null, [this.memberNumberCheckValidator]],
      },
    );
    this.pointTransferForm = this.formBuilder.group(
      {
        pointTransfer: [null, [this.pointTransferValidator]]
      },
    );
  };

  memberNumberCheckValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    };
    const MEMBER_NUMBER_REGEXP = /^(AA|AADEV)-\d{10}$/;
    return MEMBER_NUMBER_REGEXP.test(control.value) ? null : { isMemberNumberIncorrect: true };
  };

  pointTransferValidator = (control: FormControl): { [s: string]: boolean } => {
    const parsedIntValue = parseInt(control.value);
    const isFloat = /^[-+]?[0-9]+\.[0-9]+$/;
    
    if (!control.value) {
      return null;
    } else if (parsedIntValue < 0 || isNaN(control.value)) {
      return { numericOnly: true };
    } else if (isFloat.test(control.value) || control.value.toString().includes(".")) {
      return { invalidPointTransfer: true };
    }  else if (control.value < 100) {
      return { isLessThanRequiredPoints: true };
    } else if (this.currentPointBalance - parsedIntValue < 0) {
      return { insufficientPoint: true };
    };
  };

  translateLabel() {
    this.currentLangSubscription = this.i18nService.currentLang.subscribe(() => {
      this.associatedFormMsg = this.i18nService.translateLabel(this.associatedFormMsgLabel);
      this.memberNumberTips = this.i18nService.translateLabel("profile.associatedMember.memberNumberPlaceholder");
      this.connectionError = this.connectionErrorLabel && this.i18nService.translateLabel(this.connectionErrorLabel);
      this.status = this.statusLabel ? this.i18nService.translateLabel(`profile.associatedMember.${this.statusLabel}`) : "-";
      this.cacelBtnLabel = this.i18nService.translateLabel(this.cancelBtnCurrentLabel);
      this.transferPointError = this.transferPointErrorLabel && this.i18nService.translateLabel(this.transferPointErrorLabel);
    });
  };

  checkIfExceedBondLimit() {
    return new Promise((resolve, reject) => {
      this.bondLimitSubscription = this.associateMemberService.isOverBondLimit().subscribe((result: boolean) => {
        this.isExceededLimit = result;
        resolve();
      }, (error) => {
        reject();
      });
    })
  };

  getBondInfo() {
    this.bondInfoSubscription = this.associateMemberService.bondInfo().subscribe((data: MemberAssociation) => {
      if (!data) {
        this.handleBondinfo(false, false);
      } else {
        this.handleBondinfo(false, true, data);
      };
    }, (error) => {
      this.handleBondinfo(true, false);
    });
  };

  handleBondinfo(haveError: boolean, haveBondingInfo: boolean, data?: MemberAssociation) {
    if (!haveError) {
      this.haveBondingSubject.next(haveBondingInfo);
      data ? this.setBondInfo(data) : this.setBondInfo();
      this.btnStyleDetect();
    } else {
      this.haveBondingSubject.next(haveBondingInfo);
      this.btnStyleDetect();
    };
  };

  setBondInfo(data?: MemberAssociation) {
    this.isSender = data && data.isSender;
    this.associatedMemberNumber = data ? data.associatedMemberId : "-";
    this.associatedMemberName = data && data.associatedMemberName ? data.associatedMemberName : "-";
    this.statusLabel = data && data.status;
    this.status = data ? this.i18nService.translateLabel(`profile.associatedMember.${data.status}`) : "-";
    this.isBonded = data && data.status === "Bonded" ? true : false;
    this.showMemberNumber = data ? true : false;
    this.associatedFormMsgLabel = data && !data.isSender
      ? "profile.associatedMember.receiverAssociatedFormMsg"
      : "profile.associatedMember.senderAssociatedFormMsg";
    this.associatedFormMsg = this.i18nService.translateLabel(this.associatedFormMsgLabel);
    this.showFormMsg = data && (data.isSender || data.status === "Bonded") ? false : true;
  };

  //TODO: write promise
  btnStyleDetect() {
    this.haveBondingSubscription = this.haveBonding.subscribe((bool) => {
      if (!bool) {
        //no bonding
        if (this.isExceededLimit) {
          this.btnDisabled(true, true, true);
          this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.exceedLimit", true);
        } else {
          this.btnDisabled(false, true, true);
        }
      } else if (this.statusLabel === "Pending" && !this.isSender) {
        this.btnDisabled(false, false, true);
      } else if (this.statusLabel === "Pending" && this.isSender) {
        this.btnDisabled(true, false, true);
      } else if (this.statusLabel === "Bonded") {
        this.btnDisabled(true, false, false);
        this.cancelBtnCurrentLabel = "profile.associatedMember.disconnect";
        this.cacelBtnLabel = this.i18nService.translateLabel("profile.associatedMember.disconnect");
      }

    });
  };

  btnDisabled(connectBtnFlag: boolean, cancelBtnFlag: boolean, transferBtnFlag: boolean) {
    this.shouldConnectBtnDisabled = connectBtnFlag;
    this.shouldCancelBtnDisabled = cancelBtnFlag;
    this.shouldTransferBtnDisabled = transferBtnFlag;
  };

  getUserPointBalance() {
    const memberId = this.userService.getCurrentUser.memberNumber;
    this.userBalanceSubscription = this.profileService.getUserBalance(memberId).subscribe((data) => {
      const userPointBalance = data.pointBalanceResponse.payload.summary.total_points;
      this.currentPointBalance = userPointBalance;
      this.remainingPoint = userPointBalance;
    });
    this.pointTransferForm.reset();
  };

  calculatePoint() {
    this.pointsValueSubscription = this.pointTransferForm.get('pointTransfer').valueChanges.subscribe((pointTransfer: number) => {
      const isFloat = /^[-+]?[0-9]+\.[0-9]+$/;
      
      if (pointTransfer < 0 || isFloat.test(`${pointTransfer}`)) {
        this.remainingPoint = "-";
      } else if (pointTransfer <= this.currentPointBalance) {
        this.remainingPoint = this.currentPointBalance - pointTransfer;
      } else {
        this.remainingPoint = "-";
      };
    });
  };

  connect() {
    if (typeof(this.isSender) !== undefined && this.isSender === false && !this.isBonded) {
      this.isConnecting = true;
      this.acceptBondSubscription = this.associateMemberService.acceptBond(this.associatedMemberNumber).subscribe(() => {
        this.getBondInfo();
        this.isConnecting = false;
        this.shouldShowConnectError = false;
      }, (error) => {
        if (error.errorCode === "E1068") {
          this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.exceedLimit", true);
        } else if (error.errorCode === "E1077") {
          this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.noPendingAssociation", true);
        } else if (error.errorCode === "E1082") {
          this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.memberNotExist", true);
        }
        this.isConnecting = false;
      });
    } else {
      if (!this.associatedForm.controls.memberNumber.value) {
        return;
      };
      this.formControlBeforeSubmit(this.associatedForm);
      if (this.associatedForm.valid) {
        this.createBond();
      } else {
        return;
      };
    };
  };

  formControlBeforeSubmit(form: FormGroup) {
    for (const i in form.controls) {
      form.controls[i].markAsDirty();
      form.controls[i].updateValueAndValidity();
    };
  };

  createBond() {
    const targetMemberNumber = this.associatedForm.controls.memberNumber.value;
    this.isConnecting = true;
    this.createBondSubscription = this.associateMemberService.createBond(targetMemberNumber).subscribe(() => {
      this.shouldShowConnectError = false;
      this.getBondInfo();
      this.isConnecting = false;
    }, (error) => {
      if (error.errorCode === "E1074") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.associationExist", true);
      } else if (error.errorCode === "E1068") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.exceedLimit", true);
      } else if (error.errorCode === "E1076") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.cannotBondSelf", true);
      } else if (error.errorCode === "E1078") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.fiveMinsConstraint", true);
      } else if (error.errorCode === "E1081") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.inactiveMember", true);
      } else if (error.errorCode === "E1067") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.senderHasPending", true);
      } else if (error.errorCode === "E1082") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.memberNotExist", true);
      } else if (error.errorCode === "E1084") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.receiverExceedYearlyLimit", true);
      } else if (error.errorCode === "E1080") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.noExisitingBondedAssociation", true);
      } else if (error.errorCode === "E1071") {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.receiverhasPending", true);
      } else {
        this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.createBondFail", true);
      }
      this.isConnecting = false;
    });
  };

  handleConnectionOrTransferError(errorLabel: string, isConnectionError: boolean) {
    if (isConnectionError) {
      this.shouldShowConnectError = true;
      this.connectionErrorLabel = errorLabel;
      this.connectionError = this.i18nService.translateLabel(errorLabel);
    } else {
      this.shouldShowTransferError = true;
      this.transferPointErrorLabel = errorLabel;
      this.transferPointError = this.i18nService.translateLabel(errorLabel);
    };
  };

  cancelOrDisconnect() {
    if (this.isBonded) {
      this.createModal(this.i18nService.translateLabel("profile.associatedMember.disconnectConfirmation"), this.associateMemberService.deassociateBond(this.associatedMemberNumber), true);
    } else {
      if (this.isSender){
        this.createModal(this.i18nService.translateLabel("profile.associatedMember.cancelConfirmation"), this.associateMemberService.cancelBond(this.associatedMemberNumber), true);
      } else {
        this.createModal(this.i18nService.translateLabel("profile.associatedMember.declineConfirmation"), this.associateMemberService.rejectBond(this.associatedMemberNumber), true);
      }
    };
  };

  transferPoint() {
    if (!this.pointTransferForm.controls.pointTransfer.value) {
      return;
    };
    this.formControlBeforeSubmit(this.pointTransferForm);
    if (this.pointTransferForm.valid) {
      const requestBody: PointTransferRequest = {
        memberId: this.associatedMemberNumber,
        points: this.pointTransferForm.controls.pointTransfer.value
       }
      this.createModal(this.i18nService.translateLabel("profile.pointTransfer.transferConfirmation"), this.associateMemberService.pointTransfer(requestBody), false);
    } else {
      return;
    };
  };

  createModal(content: string, api: Observable<any>, isAssociateForm: boolean) {
    this.modalService.create({
      nzOkText: this.i18nService.translateLabel("COMMON.LABEL.OK"),
      nzCancelText: this.i18nService.translateLabel("COMMON.LABEL.CANCEL"),
      nzBodyStyle: {
        'padding': '80px 40px', 
        'text-align': 'center'
      },
      nzClosable: false,
      nzContent: content,
      nzOnOk: () => {
        this.isCancelling = isAssociateForm ? true : false;
        this.isTransferring = isAssociateForm ? false : true;
        this.apiSubscription = api.subscribe(() => {
          this.getBondInfo();
          this.getUserPointBalance();
          this.isCancelling = false;
          this.isTransferring = false;
          this.shouldShowConnectError = false;
          this.shouldShowTransferError = false;
        }, (error) => {
          if (error.errorCode === "E1086") {
            this.handleConnectionOrTransferError("profile.pointTransfer.errorMsg.invalidPoint", false);
          } else if (error.errorCode === "E1070") {
            this.handleConnectionOrTransferError("profile.pointTransfer.errorMsg.minimumPoint", false);
          } else if (error.errorCode === "E1071") {
            this.handleConnectionOrTransferError("profile.pointTransfer.errorMsg.exceedDailyLimit", false);
          } else if (error.errorCode === "E1072") {
            this.handleConnectionOrTransferError("profile.pointTransfer.errorMsg.insufficientPoint", false);
          } else if (error.errorCode === "E1073") {
            //general error
            this.handleConnectionOrTransferError("profile.pointTransfer.errorMsg.invalidPoint", false);
          } else if (error.errorCode === "E1077") {
            this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.noPendingAssociation", true);
          } else if (error.errorCode === "E1079") {
            this.handleConnectionOrTransferError("profile.pointTransfer.errorMsg.integerErr", false);
          } else if (error.errorCode === "E1080") {
            this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.noExisitingBondedAssociation", true);
            this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.noExisitingBondedAssociation", false);
          } else if (error.errorCode === "E1081") {
            this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.memberNotExist", false);
          } else if (error.errorCode === "E1082") {
            this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.memberNotExist", true);
          } else if (error.errorCode === "E1069") {
            this.handleConnectionOrTransferError("profile.associatedMember.errorMsg.noBondedAssociation", true);
          }
          this.isCancelling = false;
          this.isTransferring = false;
        });
      }
    });
  };

  unsubscribeAll() {
    this.currentLangSubscription && this.currentLangSubscription.unsubscribe();
    this.userBalanceSubscription && this.userBalanceSubscription.unsubscribe();
    this.bondLimitSubscription && this.bondLimitSubscription.unsubscribe();
    this.bondInfoSubscription && this.bondInfoSubscription.unsubscribe();
    this.haveBondingSubscription && this.haveBondingSubscription.unsubscribe();
    this.pointsValueSubscription && this.pointsValueSubscription.unsubscribe();
    this.acceptBondSubscription && this.acceptBondSubscription.unsubscribe();
    this.createBondSubscription && this.createBondSubscription.unsubscribe();
    this.apiSubscription && this.apiSubscription.unsubscribe();
  };

}

