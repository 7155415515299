import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../core";
import { FaqComponent } from "./faq.component";
import { FaqResolver } from "./faq-resolver.service";

const routes: Routes = [
  {
    path: "",
    component: FaqComponent,
    resolve: {
      faqList: FaqResolver
    },
    data: {
      title: {
        en_US: "FAQ | HKairport Rewards",
        zh_HK: "常見問題 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "常见问题 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `FAQ | HKairport Rewards`,
          },
          {
            name: "description",
            content: `Learn more about Hong Kong International Airport’s loyalty programme, HKairport Rewards through popular topics and frequently asked questions.`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `常見問題 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
          {
            name: "description",
            content: `請參閱常見問題及答案，了解香港國際機場獎賞計劃。`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `常见问题 | HKairport Rewards 香港国际机场奖赏计划`,
          },
          {
            name: "description",
            content: `请参阅常见问题及答案，了解香港国际机场奖赏计划。`,
          },
        ],
      },
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule {}
