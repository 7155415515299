import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../core';

@Component({
  selector: 'app-earn-now-cancelled',
  templateUrl: './earn-point-cancelled.component.html',
  styleUrls: ['./earn-point-cancelled.component.css']
})
export class EarnPointCancelledComponent implements OnInit {
  @ViewChild('message', null) messageTemplate: ElementRef;
  message: string;
  messageType: string = "cancelled";

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<EarnPointCancelledComponent>
  ) {
    this.userService.getEarnPointMessage(this.messageType).subscribe(
    (data) => {
      this.message = data.responseInfo.replace(/\\n/g,"<br>");
      this.messageTemplate.nativeElement.innerHTML = this.message;
    }); 
  }

  ngOnInit() {
  }

}
