import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RemoteConfigService } from "../../../core";

@Injectable()
export class RegistrationResolver implements Resolve<any> {
  constructor(private remoteConfigService: RemoteConfigService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // Get all configurable items from ISS
    return this.remoteConfigService.getRegistrationPickList().pipe(
      map(
        (data) => {
          return data;
        },
        (error) => {
          this.router.navigateByUrl("/");
        }
      )
    );
  }
}
