import * as _ from "underscore";
import { Component, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../core";

@Component({
  selector: "acc-campaign-calculator-page",
  templateUrl: "./acc-campaign-calculator.component.html",
  styleUrls: ["./acc-campaign-calculator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AccCampaignCalculator {
    constructor(private commonService: CommonService,){
      this.commonService.setShowHeaderFooter(false);
      this.commonService.setHideComponent(false);
    }
}
