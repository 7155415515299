import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { UserService, CommonService } from "../../core";
import { SystemMessage } from "../../core/models/system-message.model";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-system-message",
  templateUrl: "./system-message.component.html",
  styleUrls: ["./system-message.component.scss"],
})
export class SystemMessageComponent implements OnInit, OnDestroy {
  @Input() pageInfo: SystemMessage;
  displayItem: SystemMessage = {
    title: "COMMON.MESSAGE.GENERAL_ERROR",
    subTitle: "",
    icon: "error",
    buttonLabel: null,
    actionType: "",
    link: "",
  };

  formattedErrors: Array<string> = [];
  routerData: any;

  constructor(private userService: UserService, private router: Router, private commonService: CommonService,   private route: ActivatedRoute,) {
    this.routerData = this.router.getCurrentNavigation().extras.state;
    if (this.routerData && this.routerData.displayItem) {
      this.displayItem = this.routerData.displayItem;
    }

    if(this.route.snapshot.queryParams["memberId"] && this.route.snapshot.queryParams["otp"]){
      this.userService.verificationEmailOtp(this.route.snapshot.queryParams["memberId"],this.route.snapshot.queryParams["otp"]).subscribe((reponse)=>{
        this.displayItem = {
          title: "COMMON.message.emailSuccessful",
          subTitle: "COMMON.message.emailVerification",
          icon: "sent_mail_success",
          buttonLabel: "COMMON.LABEL.LOGIN_NOW",
          actionType: "LOGIN",
          link: "",
        }
      },(error)=>{
        this.displayItem = {
          title: "UNSUBSCRIBE.MESSAGE.UNSUCCESSFUL_TITLE",
          subTitle: "UNSUBSCRIBE.MESSAGE.UNSUCCESSFUL_SUBTITLE",
          subTitleData: { hotline: "+85221900900" },
          icon: "error",
          buttonLabel: "",
          actionType: "",
          link: "",
        }
      })

    }
  }

  ngOnInit() {}

  ngOnDestroy() {}

  actionBtn() {
    this.userService.login();
  }

  get errorList() {
    return this.formattedErrors;
  }

  emitEvent() {
    switch (this.displayItem.actionType) {
      case "LOGIN": {
        this.userService.login();
        break;
      }
      case "LINK": {
        this.commonService.redirect(this.displayItem.link, null, this.displayItem.linkRouterData);
        break;
      }
      case "REPLACE": {
        window.location.replace(`${this.displayItem.link}`);
      }
      default: {
        // Do nothing
      }
    }
  }
}
