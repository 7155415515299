import { Injectable } from "@angular/core";


@Injectable()
export class JwtService {

  /**
   * Module for SIT page pascode page
   */

  getToken(): String {
    return window.localStorage["authToken"];
  }

  saveToken(token: String) {
    window.localStorage["authToken"] = token;
  }

  destroyToken() {
    window.localStorage.removeItem("authToken");
  }

}
