import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OffersComponent } from "./offers.component";
import { OfferResolver } from "./offer-resolver.service";
import { OfferDetailComponent } from "./offer-detail/offer-detail.component";
import { GeneralGuard } from "../../core/services/general-guard.service";
import { OfferCardsComponent } from "../../shared/offer-cards/offer-cards.component";

const routes: Routes = [
  {
    path: "",
    data: {
      title: {
        en_US: "Privileges and Rewards | HKairport Rewards",
        zh_HK: "禮遇及獎賞 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "礼遇及奖赏 | HKairport Rewards 香港国际机场奖赏计划",
      },
      metaTitle: {
        en_US: [
          {
            name: "title",
            content: `Privileges and Rewards | HKairport Rewards`,
          },
          {
            name: "description",
            content: `Be rewarded with up to 6% in daily spending rewards, and enjoy a collection of monthly travel surprises, birthday delights and year-round privileges.`,
          },
        ],
        zh_HK: [
          {
            name: "title",
            content: `禮遇及獎賞 | HKairport Rewards 香港國際機場獎賞計劃`,
          },
          {
            name: "description",
            content: `尊享每日高達6%消費獎賞、每月出遊賞、生日禮遇遇及人氣商戶的全年精采優惠。`,
          },
        ],
        zh_CN: [
          {
            name: "title",
            content: `礼遇及奖赏 | HKairport Rewards 香港国际机场奖赏计划`,
          },
          {
            name: "description",
            content: `尊享每日高达6%消费奖赏、每月出游赏、生日礼遇及人气商户的全年精采优惠。`,
          },
        ],
      },
    },

    children: [
      // {
      //   path: "monthly-travel-surprises",
      //   component: OffersComponent
      // },
      {
        path: "rewards-choices",
        component: OffersComponent
      },
      {
        path: "birthday-delights",
        component: OffersComponent
      },
      {
        path: "year-round-privileges",
        component: OffersComponent
      },
      {
        path: "",
        redirectTo: "rewards-choices"
      },
    ]
  },
  {
    path: ":rewardStoreId/:rewardStoreOfferId/:offerId",
    component: OfferDetailComponent,
    resolve: {
      offer: OfferResolver,
    },
    canActivate: [GeneralGuard],
    data: {
      title: {
        en_US: "Privileges and Rewards | HKairport Rewards",
        zh_HK: "禮遇及獎賞 | HKairport Rewards 香港國際機場獎賞計劃",
        zh_CN: "礼遇及奖赏 | HKairport Rewards 香港国际机场奖赏计划",
      },
      isShowBack: true,
      backRoute: "/offers", // TODO: See if can use router state
   },
    children: [
      // {
      //   path: "monthly-travel-surprises",
      //   canActivate: [GeneralGuard],
      //   component: OffersComponent
      // },
      {
        path: "rewards-choices",
        canActivate: [GeneralGuard],
        component: OffersComponent
      },
      {
        path: "birthday-delights",
        canActivate: [GeneralGuard],
        component: OffersComponent
      },
      {
        path: "year-round-privileges",
        canActivate: [GeneralGuard],
        component: OffersComponent
      },
      {
        path: "",
        canActivate: [GeneralGuard],
        redirectTo: "rewards-choices"
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OffersRoutingModule { }
