import { NgModule } from "@angular/core";
import { LegalArticlesComponent } from "./legal-articles.component";
import { SharedModule } from "../../shared";
import { LegalArticlesRoutingModule } from "./legal-articles-routing.module";
import { LegalArticlesResolver } from "./legal-articles-resolver.service";

@NgModule({
  imports: [SharedModule, LegalArticlesRoutingModule],
  declarations: [LegalArticlesComponent],
  providers: [LegalArticlesResolver]
})
export class LegalArticlesModule {}
