import { Component, Input } from "@angular/core";

@Component({
  selector: "app-maps",
  styleUrls: ["maps.component.scss"],
  templateUrl: "./maps.component.html",
})
export class MapsComponent {
  constructor() {}
  @Input() img: string;
}
