import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, EventEmitter, Output, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { NzModalRef } from "ng-zorro-antd";
import { OtpBoxComponent, PPUrlArr } from "../../../shared";
import { Subscription, BehaviorSubject, of, Observable } from "rxjs";
import { differenceInCalendarDays } from "date-fns";
import { map, distinctUntilChanged, debounceTime, catchError } from "rxjs/operators";
import { trigger, transition, animate, style } from "@angular/animations";
import {
  UserService,
  Campaign,
  NewProfile,
  MediaService,
  TimeService,
  FormService,
  emailValidator,
  passwordValidator,
  I18NService,
  phoneValidator,
  ValidateEmailNotTaken,
  CommonService,
  OtpConfig,
  ApiService
} from "../../../core";
import { environment } from "../../../../environments/environment";
import { GlobalConfig } from "../../../core/app-global.config";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalService } from '../../../core/services/modal.service';

@Component({
  selector: "app-auth-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate("0.4s ease-out", style({ height: 203, opacity: 1 }))]),
      transition(":leave", [style({ height: 300, opacity: 1 }), animate("0.4s ease-in", style({ height: 0, opacity: 0 }))]),
    ]),
    trigger("inOutAnimation3", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate("0.2s ease-out", style({ height: 50, opacity: 1 }))]),
      transition(":leave", [style({ height: 100, opacity: 1 }), animate("0.2s ease-in", style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class RegistrationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("otpBox", { static: false }) public otpBox: OtpBoxComponent;

  uploadAcceptTypes = GlobalConfig.PHOTO_UPLOAD.accept_types;

  public timerToggle$ = new BehaviorSubject(false);
  private listData: any;

  tncLink = "";
  picsLink = "";
  isEmailReadOnly: boolean;
  isPhoneReadOnly: boolean;
  redirectPlatformLink: string;
  isOpen: boolean[] = [false, false];
  constructor(
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private deviceService: DeviceDetectorService,
    private fb: FormBuilder,
    public formService: FormService,
    private i18nService: I18NService,
    private localizeRouter: LocalizeRouterService,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    private router: Router,
    private timeService: TimeService,
    private userService: UserService,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService
  ) {
    if (window.location.pathname.indexOf('mobile') !== -1) {
      this.commonService.setShowHeaderFooter(false);
    }
  }

  @Output() isEmailOtpValid = new EventEmitter<boolean>();
  @Output() isMobileOtpValid = new EventEmitter<boolean>();

  // otpModal: any;

  private subscription: Subscription;

  minutesDisplay: string;
  hoursDisplay: string;
  secondsDisplay: string;

  // Form Group
  registrationForm: FormGroup;
  verificationForm: FormGroup;

  tplModal: NzModalRef;
  campaignData: Campaign;
  isLoading = true;
  isSubmitting = false;
  // isSubmitting2 = false;
  // isSubmittingOtp = false;
  passwordVisible = false;
  passwordVisible2 = false;
  // isFirstTimeGetOtp = true;
  fileList = [];
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };
  previewImage: string | undefined = "";
  // previewVisible = false;
  style = {
    height: "30px",
    lineHeight: "30px",
  };
  count = 0;
  interval$: any;

  // Dropdown items
  countryCodeList = [];
  securityQuestionList = [];
  companyList = [];
  // areaList = [];

  visible = false;
  status = "pool";
  progress = 0;
  passwordProgressMap = {
    ok: "success",
    pass: "normal",
    pool: "exception",
  };
  months = this.timeService.monthList;
  yearList = this.timeService.yearList;
  // Airport Staff Section
  permitPhotoFront = ""; // Base64 Photo
  permitPhotoBack = "";
  formattedPermitPhotoFront = ""; // Base64 Photo
  formattedPermitPhotoBack = "";

  // TODO: Temp Solution
  isTriggerEmailValidator = true;
  salutationList = [];
  preferenceList = [];

  getOtpCount = 0;
  isDesktop = this.deviceService.isDesktop();

  dateFormat = environment.APP_CONFIG.dataFormat;
  promoteCode = "";
  registrationChannel = null;

  partnerInfo: {
    partnerName: string,
    imageUrl: string,
    leadInMessage_en: string,
    leadInMessage_tc: string,
    leadInMessage_sc: string,
  };
  translatedLeadInMsg: string = "";
  urlSafe: SafeResourceUrl;
  PPUrlArr = PPUrlArr;
  promoCodeVerifyResult = ""
  ngOnInit() {
    this.initForm();
    this.route.data.subscribe((data: { listData: any[] }) => {
      if (data.listData) {
        // this.areaList = data.listData[3][this.i18nService.getActiveLang];
        this.companyList = data.listData[2][this.i18nService.getActiveLang];
        this.countryCodeList = data.listData[1][this.i18nService.getActiveLang];
        this.listData = data.listData;
        this.picsLink = this.localizeRouter.translateRoute("/legal/personal-information-collection-statement") as string;
        this.salutationList = data.listData[4][this.i18nService.getActiveLang];
        this.securityQuestionList = data.listData[0][this.i18nService.getActiveLang];
        this.tncLink = this.localizeRouter.translateRoute("/legal/terms-and-condition") as string;
        this.preferenceList = data.listData[5][this.i18nService.getActiveLang];
      }
    });

    this.route.queryParams.subscribe(async (value) => {
      if (value["cb"]) {
        this.redirectPlatformLink = decodeURI(value["cb"]);
      }

      if (value["code"]) {
        this.promoteCode = decodeURI(value["code"]);
        this.registrationForm.controls.specialCode.setValue(this.promoteCode);
      }
      //from WOW
      if (value["fn"] && value["ln"] && value["e"] && value["c"] && value["otp"]) {
        const firstName = encodeURIComponent(`${value["fn"]}`);
        const lastName = encodeURIComponent(`${value["ln"]}`);
        const personEmail = encodeURIComponent(`${value["e"]}`);
        const registrationChannel = encodeURIComponent(`${value["c"]}`);
        const verificationCode = encodeURIComponent(`${value["otp"]}`);
        this.verifiedUserInfo(firstName, lastName, personEmail, registrationChannel, verificationCode);
      }
    });

      let partnerCode = this.route.firstChild.snapshot.params["shortCode"];
      if (partnerCode !== undefined && partnerCode !== null) {
      this.getPartnerInfo(partnerCode).subscribe((result) => {
        this.partnerInfo = result;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(result.imageUrl);
        this.translateLeadInMsg();
      });
    }
  }

  getPartnerInfo(partnerCode: any): Observable<any> {
    const result = this.apiService
      .get(`/partner/info?partnerShortCode=${partnerCode}`,
        "member", null, null).pipe(map((data) => data));
    return result;
  }

  translateLeadInMsg(){
    this.i18nService.currentLang.subscribe((lang) => {
      if (lang === "zh_HK") {
        this.translatedLeadInMsg = this.partnerInfo.leadInMessage_tc;
      } else if (lang === "zh_CN") {
        this.translatedLeadInMsg = this.partnerInfo.leadInMessage_sc;
      } else {
        this.translatedLeadInMsg = this.partnerInfo.leadInMessage_en;
      }
    });
  }

  verifiedUserInfo(firstName: string, lastName: string, personEmail: string, registrationChannel: string, verificationCode: string) {
    this.userService.checkUserInfo(firstName, lastName, personEmail, registrationChannel, verificationCode).subscribe(async (data) => {
      this.registrationForm.controls.personEmail.setValue(data.personEmail);
      this.registrationForm.controls.firstName.setValue(data.firstName);
      this.registrationForm.controls.lastName.setValue(data.lastName);
      this.registrationChannel = data.registrationChannel;
      await this.commonService.browserDelay(300);
      this.otpBox.verCode.markAsDirty();
      this.otpBox.verCode.patchValue(data.verificationCode);

    });
  };

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initForm() {
    this.registrationForm = this.fb.group({
      agreeTerms: [false, [Validators.requiredTrue]],
      araPermitExpiryDate: [""],
      araPermitHKCrewMemberCertNo: [null],
      araPermitPhotoBack: [null],
      araPermitPhotoFront: [null],
      // area: [null],
      birthMonth: [null],
      birthYear: [null, [this.birthYearValidator]],
      checkPassword: [
        null,
        {
          validators: [Validators.required, this.passwordConfirmationValidator],
          updateOn: "blur",
        },
      ],
      companyName: [null],
      companyNameOthers: [null],
      directMktOptIn: [false],
      firstName: [null, [Validators.required]],
      isAirportStaff: ["N", [Validators.required]],
      lastName: [null, [Validators.required]],
      password: [
        null,
        {
          validators: [Validators.required, Validators.minLength(8), passwordValidator],
          updateOn: "blur",
        },
      ],
      personEmail: [
        "",
        {
          validators: [Validators.required, emailValidator],
          asyncValidators: ValidateEmailNotTaken.createValidator(this.userService, "create", null),
          updateOn: "blur",
        },
      ],
      personTitle: [null, [Validators.required]],
      phoneNumber: [
        null,
        {
          validators: [Validators.minLength(1), phoneValidator],
          asyncValidators: this.validatePhoneNotTaken,
          updateOn: "blur",
        },
      ],
      phoneNumberPrefix: ["852_Hong Kong (+852)", []],
      referralCode: [null, [this.referralCodeValidator]],
      specialCode: [
        null,        
        {
          asyncValidators: this.validateSpecialCode,
          updateOn: "blur",
        },
      ],
      securityAnswer: [null],
      securityQuestion: [null],
      shoppingPreference: [null],
      verificationChannel: [null],
    });

    this.personEmail.statusChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value === "VALID" && this.personEmail.value && this.personEmail.value.length > 0) {
        this.personEmail.markAsPristine();
        // this.phoneNumber.setValidators([Validators.minLength(1), phoneValidator]);
        // this.phoneNumber.updateValueAndValidity();
      } else if (value === "INVALID") {
        // this.phoneNumber.setValidators([Validators.required, Validators.minLength(1), phoneValidator]);
        // this.phoneNumber.updateValueAndValidity({ emitEvent: false });
      }
      // this.autoSelectVerificationChannel();
    });

    this.phoneNumber.statusChanges.pipe(debounceTime(300)).subscribe((value) => {
      // if (value === "VALID") {
      //   this.phoneNumber.markAsPristine();
      //   const validators = [Validators.required];
      //   if (this.phoneNumber.value && this.phoneNumber.value.length > 0) {
      //     this.personEmail.setValidators([Validators.minLength(3), emailValidator]);
      //     this.personEmail.updateValueAndValidity();
      //     this.securityQuestion.setValidators(validators);
      //     this.securityAnswer.setValidators(validators);
      //     this.securityQuestion.updateValueAndValidity();
      //     this.securityAnswer.updateValueAndValidity();
      //   } else if (this.personEmail.value && this.personEmail.valid) {
      //     this.securityQuestion.setValidators(null);
      //     this.securityAnswer.setValidators(null);
      //     this.securityQuestion.updateValueAndValidity();
      //     this.securityAnswer.updateValueAndValidity();
      //   }
      // } else if (value === "INVALID") {
      //   this.personEmail.setValidators([Validators.required, Validators.minLength(3), emailValidator]);
      //   this.personEmail.updateValueAndValidity({ emitEvent: false });
      //   this.securityQuestion.setValidators(null);
      //   this.securityAnswer.setValidators(null);
      //   this.securityQuestion.updateValueAndValidity();
      //   this.securityAnswer.updateValueAndValidity();
      // }
      // this.autoSelectVerificationChannel();
    });

    this.phoneNumberPrefix.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.phoneNumber.updateValueAndValidity();
    });

    // this.personEmail.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
    //   this.autoSelectVerificationChannel();
    // });

    this.registrationForm.get("birthYear").valueChanges.subscribe((value) => {
        if(value != null){
          this.registrationForm.get("birthMonth").setValidators([Validators.required]);
        }else{
          this.registrationForm.get("birthMonth").setValidators(null);;
        }
        this.registrationForm.get("birthMonth").updateValueAndValidity();
      });

    this.password.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.checkPassword.updateValueAndValidity();
    });

    this.isAirportStaff.valueChanges.subscribe((value) => {
      if (value && value === "Y") {
        const validators = [Validators.required];
        this.araPermitExpiryDate.setValidators(validators);
        this.araPermitHKCrewMemberCertNo.setValidators(validators);
        this.araPermitPhotoBack.setValidators([Validators.requiredTrue]);
        this.araPermitPhotoFront.setValidators([Validators.requiredTrue]);
        this.companyName.setValidators(validators);
      } else if (value && value === "N") {
        this.araPermitExpiryDate.setValidators(null);
        this.araPermitHKCrewMemberCertNo.setValidators(null);
        this.araPermitPhotoBack.setValidators(null);
        this.araPermitPhotoFront.setValidators(null);
        this.companyName.setValidators(null);
        this.companyNameOthers.setValidators(null);
      }
      this.araPermitExpiryDate.updateValueAndValidity();
      this.araPermitHKCrewMemberCertNo.updateValueAndValidity();
      this.araPermitPhotoBack.updateValueAndValidity();
      this.araPermitPhotoFront.updateValueAndValidity();
      this.companyName.updateValueAndValidity();
      this.companyNameOthers.updateValueAndValidity();
    });

    this.companyName.valueChanges.subscribe((value) => {
      if (value && value === "0_Others") {
        this.companyNameOthers.setValidators([Validators.required]);
      } else {
        this.companyNameOthers.reset();
        this.companyNameOthers.setValidators(null);
      }
      this.companyNameOthers.updateValueAndValidity();
    });
  }

  /**
   * Only Email: Email is selected
   * Only Mobile: SMS is selected
   * Both: Email is selected
   */
  // async autoSelectVerificationChannel() {
  //   const email = this.personEmail;
  //   const phone = this.phoneNumber;
  //   if (!email || !phone || this.isEmailReadOnly || this.isPhoneReadOnly) {
  //     return;
  //   }
  //   // Set verificationChannel to empty if email and phone are invalid
  //   if (!email.valid && !phone.valid) {
  //     this.registrationForm.get("verificationChannel").setValue(null);
  //     return;
  //   }
  //   /**
  //    * Set verificationChannel to Email if:
  //    * 1. Both email and phone are valid
  //    * 2. Email is valid and Phone is invalid
  //    */
  //   await this.commonService.browserDelay(300);
  //   if (email.valid && email.value && phone.value && phone.valid) {
  //     this.registrationForm.get("verificationChannel").setValue("Email");
  //   } else if (email.valid && email.value && (!phone.value || !phone.valid)) {
  //     this.registrationForm.get("verificationChannel").setValue("Email");
  //   } else if ((!email.valid || !email.value) && phone.value && phone.valid) {
  //     this.registrationForm.get("verificationChannel").setValue("Mobile");
  //   } else {
  //     this.registrationForm.get("verificationChannel").setValue(null);
  //   }
  // }

  // trimSecurityAnswer() {
  //   const self: any = this;
  //   const value = this.securityAnswer.value;
  //   if (value && value.trim() !== value) {
  //     this.securityAnswer.patchValue(value.trim());
  //     self.isTrimmed = true;
  //   } else {
  //     self.isTrimmed = false;
  //   }
  // }

  birthYearValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    }
    const birthMonth = this.registrationForm.get("birthMonth");
    if (
      control.value &&
      birthMonth &&
      birthMonth.value &&
      Number(control.value) === new Date().getFullYear() &&
      Number(this.registrationForm.get("birthMonth").value) > new Date().getMonth() + 1
    ) {
      return { error: true };
    }
    return null;
  };

  passwordConfirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (this.registrationForm.controls.password.value !== this.registrationForm.controls.checkPassword.value) {
      return { confirm: true, error: true };
    }
    return null;
  };

  referralCodeValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value || this.promoteCode) {
      return null;
    }
    const REFERRAL_CODE_REGEXP = /^\d{10}$/;
    return REFERRAL_CODE_REGEXP.test(control.value) ? null : { maxDigit: true };
  };

  createMemberProfile(): void {
    // If OTP is correct
    const formData = this.transformRegisterForm();
    this.isSubmitting = true;
    this.userService.createProfile(formData).subscribe(
      (feedback) => {
        if (feedback) {
          const successPageData = {
            displayItem: {
              title: "COMMON.message.SUCCESSFULLY_SIGNED_UP",
              subTitle: "COMMON.message.SUCCESSFULLY_SIGNED_UP_SUBTITLE",
              icon: "registration_successful",
              buttonLabel: "COMMON.LABEL.LOGIN_NOW",
              actionType: this.redirectPlatformLink ? "REPLACE" : "LOGIN",
              link: this.redirectPlatformLink ? this.redirectPlatformLink : ""
            },
          };
          this.commonService.redirect("/account/register/success", null, { state: successPageData, queryParams: this.transformEarnPointMessage(feedback) });
        } else {
          this.isSubmitting = false;
        }
      },
      async (error) => {
        this.isSubmitting = false;
        // Duplicated ARA Permit Checking
        if (error.errorCode === "E1054") {
          this.registrationForm.controls.araPermitHKCrewMemberCertNo.setErrors({ duplicateAraCertNo: true });
          await this.commonService.browserDelay(100);
          this.formService.scrollToError();
        } else if (this.otpBox && error.errorCode === "E1048") {
          this.otpBox.verCode.setErrors({ otpExpired: true });
          await this.commonService.browserDelay(100);
          this.formService.scrollToError();
        } else if (error.errorCode === "E1062"){
          this.registrationForm.controls.referralCode.setErrors({maxDigit: true});
          await this.commonService.browserDelay(100);
        } else if (error.errorCode === "E1063"){
          this.registrationForm.controls.referralCode.setErrors({invalidCode: true});
          await this.commonService.browserDelay(100);
        } else if (error.errorCode === "E0000"){
          this.specialCode.setErrors({error: true});
          await this.commonService.browserDelay(100);
        }
      }
    );
  }

  // TODO: Separate transformer?
  transformRegisterForm(): NewProfile {
    const requestBody = {
      acknowledgedTnC: this.registrationForm.controls.agreeTerms.value,
      araPermitExpiryDate: this.registrationForm.controls.araPermitExpiryDate.value,
      araPermitExpiryDateString: this.registrationForm.controls.araPermitExpiryDate.value ? this.timeService.dateFormat(this.registrationForm.controls.araPermitExpiryDate.value) : null,
      araPermitHKCrewMemberCertNo: this.registrationForm.controls.araPermitHKCrewMemberCertNo.value,
      araPermitPhotoBack: this.registrationForm.controls.isAirportStaff.value === "N" ? null : this.formattedPermitPhotoBack,
      araPermitPhotoFront: this.registrationForm.controls.isAirportStaff.value === "N" ? null : this.formattedPermitPhotoFront,
      area:'',
      birthMonth: this.registrationForm.controls.birthMonth.value,
      birthYear: this.registrationForm.controls.birthYear.value,
      companyName: this.registrationForm.controls.companyName.value,
      companyNameOthers: this.registrationForm.controls.companyNameOthers.value,
      directMktOptIn: this.registrationForm.controls.directMktOptIn.value,
      firstName: this.registrationForm.controls.firstName.value,
      lastName: this.registrationForm.controls.lastName.value,
      password: this.registrationForm.controls.password.value.trim(),
      personEmail: this.registrationForm.controls.personEmail.value,
      personTitle: this.registrationForm.controls.personTitle.value,
      phone: this.registrationForm.controls.phoneNumber.value,
      phoneNumberPrefix: this.registrationForm.controls.phoneNumber.value ? this.registrationForm.controls.phoneNumberPrefix.value : "", // Remove country when phone number is missing
      preferredLanguage: this.currentLang,
      promoCode: this.promoteCode ? this.promoteCode : this.registrationForm.controls.specialCode.value,
      referredBy: this.registrationForm.controls.referralCode.value,
      securityAnswer: this.registrationForm.controls.securityAnswer.value,
      securityQuestion: this.registrationForm.controls.securityQuestion.value,
      shoppingPreference: this.registrationForm.controls.shoppingPreference.value ? this.registrationForm.controls.shoppingPreference.value.toString().replaceAll(",", ";") : null,
      staffStatus: this.registrationForm.controls.isAirportStaff.value === "N" ? null : "",
      phoneVerCode: this.otpBox.verificationForm.controls.verCode.value,
      verificationChannel: 'Mobile',
      registrationChannel: this.registrationChannel ? this.registrationChannel : this.getRegistrationChannelsFromURL(), // UAT-1612: return registrationChannel value from URL
      registrationPartner: this.partnerInfo && this.partnerInfo.partnerName ? this.partnerInfo.partnerName: null,
      earnNowId: localStorage.getItem('earn-now'),
      promoCodeVerifyResult:this.promoCodeVerifyResult,
    };
    return _.pick(requestBody, _.identity);
  }

  transformEarnPointMessage(registrationResult){
    let message = 'FAILED'
    if(registrationResult.earnStatus){
      message = 'SUCCESS'
    }
    if(registrationResult.earnStatus == false && !!registrationResult.developerMessage && registrationResult.developerMessage != "UNAUTHORIZED"){
      message = registrationResult.developerMessage
    }
    if (registrationResult.developerMessage == 'UNAUTHORIZED') {
      this.userService.login(null, "smartpos.com");
      return null
    }
    if(localStorage.getItem('earn-now') == undefined){
      return null
    }
    return { 'earnMsg': message }
  }

  //UAT-1612 Fixing: Incorrect source of registration channels
  getRegistrationChannelsFromURL(): string {
    let registrationChannel = null;
    let registrationUrl = window.location.href.toLowerCase();
    if (registrationUrl != null) {
      if ((registrationUrl.search("login_hint%253Den_US%252540memberportal.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540memberportal.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540memberportal.com") != -1)) {
        registrationChannel = "Member Portal";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540emarketplace.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540emarketplace.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540emarketplace.com") != -1)) {
        registrationChannel = "Emarketplace";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540foodordering.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540foodordering.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540foodordering.com") != -1)) {
        registrationChannel = "FOS";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540myhkg.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540myhkg.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540myhkg.com") != -1)) {
        registrationChannel = "MyHKG";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540eshop.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540eshop.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540eshop.com") != -1)) {
        registrationChannel = "Eshop";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540alipayhk.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540alipayhk.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540alipayhk.com") != -1)) {
        registrationChannel = "Alipay HK";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540alipaycn.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540alipaycn.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540alipaycn.com") != -1)) {
        registrationChannel = "Alipay CN";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540language.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540language.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540language.com") != -1)) {
        registrationChannel = "Member Portal";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540smartpos.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540smartpos.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540smartpos.com") != -1)) {
        registrationChannel = "SmartPOS";
      }
      else if ((registrationUrl.search("login_hint%253Den_US%252540CARPARK.com") != -1) || (registrationUrl.search("login_hint%253Dzh_HK%252540CARPARK.com") != -1) || (registrationUrl.search("login_hint%253Dzh_CN%252540CARPARK.com") != -1)) {
        registrationChannel = "Carpark";
      }
      else {
        let loginHintIndex = registrationUrl.search("login_hint");
        if (loginHintIndex != -1) {
          let loginHintKeyword = registrationUrl.substring(loginHintIndex);
          if (loginHintKeyword != null && (loginHintKeyword.search("memberportal") != -1)) {
            registrationChannel = "Member Portal";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("emarketplace") != -1)) {
            registrationChannel = "Emarketplace";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("foodordering") != -1)) {
            registrationChannel = "FOS";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("myhkg") != -1)) {
            registrationChannel = "MyHKG";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("eshop") != -1)) {
            registrationChannel = "Eshop";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("alipayhk") != -1)) {
            registrationChannel = "Alipay HK";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("alipaycn") != -1)) {
            registrationChannel = "Alipay CN";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("language") != -1)) {
            registrationChannel = "Member Portal";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("smartpos") != -1)) {
            registrationChannel = "SmartPOS";
          }
          else if (loginHintKeyword != null && (loginHintKeyword.search("Carpark") != -1)) {
            registrationChannel = "Carpark";
          }
          else {
            registrationChannel = "Member Portal";
          }
        } else {
          registrationChannel = "Member Portal";
        }
      }
    } else {
      registrationChannel = "Member Portal";
    }
    return registrationChannel;
  }
  // End UAT-1612 fixing 

  get currentLang() {
    return this.i18nService.getActiveLangLongName;
  }

  async submitForm() {
    if (this.isAirportStaff.value === "N") {
      this.araPermitExpiryDate.setValue(null);
      this.araPermitHKCrewMemberCertNo.setValue(null);
      this.araPermitPhotoBack.setValue(null);
      this.araPermitPhotoFront.setValue(null);
      this.companyName.setValue(null);
      this.companyNameOthers.setValue(null);
    }

    // Set ARA Permit Photo validation
    if (this.permitPhotoFront.length > 0) {
      this.registrationForm.get("araPermitPhotoFront").setValue(true);
    } else {
      this.registrationForm.get("araPermitPhotoFront").setValue(false);
    }

    // Mark ARA Permit Number as dirty for post duplication check
    if (this.permitPhotoBack.length > 0) {
      this.registrationForm.get("araPermitPhotoBack").setValue(true);
    } else {
      this.registrationForm.get("araPermitPhotoBack").setValue(false);
    }

    if (this.araPermitHKCrewMemberCertNo.value) {
      this.araPermitHKCrewMemberCertNo.markAsDirty();
      this.araPermitHKCrewMemberCertNo.updateValueAndValidity();
    }

    if (this.registrationForm.valid && this.otpBox.verificationForm.valid) {
      this.createMemberProfile();
    } else {
      if (!this.registrationForm.valid) {
        const exceptionList = [this.personEmail, this.phoneNumber, this.phoneNumberPrefix];
        for (const i in this.registrationForm.controls) {
          this.registrationForm.controls[i].markAsDirty();
          if (
            exceptionList.indexOf(this.registrationForm.controls[i] as FormControl) !== -1 &&
            this.registrationForm.controls[i].value &&
            this.registrationForm.controls[i].value.length > 1 &&
            this.registrationForm.controls[i].valid
          ) {
            this.registrationForm.controls[i].markAsPristine();
          }
          this.registrationForm.controls[i].updateValueAndValidity();
        }
      }
      if (this.otpBox && !this.otpBox.verificationForm.valid) {
        for (const i in this.otpBox.verificationForm.controls) {
          this.otpBox.verificationForm.controls[i].markAsDirty();
          // TODO: Common service, component
          if (this.otpBox.verificationForm.controls[i].value && this.otpBox.verificationForm.controls[i].value.length > 1) {
            this.registrationForm.controls[i].markAsPristine();
          }
          this.otpBox.verificationForm.controls[i].updateValueAndValidity();
        }
      }
      await this.commonService.browserDelay(200);
      this.formService.scrollToError();
    }
  }

  // nzBeforeUpload function Not support IE9
  processPermitPhotoFront = (file: File): boolean => {
    this.mediaService.uploadPhotoPreCheck(file).then(
      (isFileValid) => {
        if (isFileValid) {
          this.mediaService.compressPhoto(file).then(
            (compressedFile) => {
              this.mediaService.transformToBase64(compressedFile, (img: string) => {
                const imgString = img.replace(/^data:image\/[a-z]+;base64,/, "");
                this.formattedPermitPhotoFront = imgString;
                this.permitPhotoFront = img as string;
              });
            },
            (error) => {
              alert(this.i18nService.translateLabel("COMMON.message.invalidPhotoFormat"));
            }
          );
        }
      },
      (error) => {
        alert(this.i18nService.translateLabel(error));
      }
    );
    return false;
  };

  processPermitPhotoBack = (file: File): boolean => {
    this.mediaService.uploadPhotoPreCheck(file).then(
      (isFileValid) => {
        if (isFileValid) {
          this.mediaService.compressPhoto(file).then(
            (compressedFile) => {
              this.mediaService.transformToBase64(compressedFile, (img: string) => {
                const imgString = img.replace(/^data:image\/[a-z]+;base64,/, "");
                this.formattedPermitPhotoBack = imgString;
                this.permitPhotoBack = img as string;
              });
            },
            (error) => {
              alert(this.i18nService.translateLabel("COMMON.message.invalidPhotoFormat"));
            }
          );
        }
      },
      (error) => {
        alert(this.i18nService.translateLabel(error));
      }
    );
    return false;
  };

  // Datepicker Config
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, new Date()) < 0;
  };

  validatePhoneNotTaken = (control: AbstractControl) => {
    if (control && !control.pristine && control.value && control.value.trim() !== "") {
      return this.userService.checkDuplicatedMobile(this.phoneNumberPrefix.value.split("_")[0] + control.value).pipe(
        map((isValid) => null),
        catchError(() => of({ error: true, duplicated: true }))
      );
    } else {
      return of(null);
    }
  };

  onCaptchaChanged(isValid: boolean) {
    if(!this.phoneNumber.value){
      this.phoneNumber.setErrors({ required: true });
      return
    }
    if(isValid && !this.verifyFirstInput(this.phoneNumber.value)){
      this.phoneNumber.setErrors({ cannotGetCaptcha: true });
      return
    }
  }

  onOtpChanged(isValid: boolean) {
    // if (this.registrationForm.get("verificationChannel").value === "Email") {
    //   this.isEmailReadOnly = true;
    // } else {
      // this.isPhoneReadOnly = true;
    // }
    if(isValid){
      this.isPhoneReadOnly = true;
    }
  }

  verifyFirstInput(emailOrNumber:string){
    const firstInput = JSON.parse(sessionStorage.getItem('firstInput'))
    const currentTime = new Date().getTime()
    if(!!firstInput && firstInput.emailOrNumber === emailOrNumber && (currentTime - firstInput.time < 300000)){
      return false
    }
    return true
  }

  clickDirectMktOptIn() {
    this.registrationForm.controls.directMktOptIn.setValue(!this.registrationForm.value.directMktOptIn);
  }

  showModal(modalType: "TC" | "PP" | "CU") {
    if (modalType === 'PP') {
      window.open(this.PPUrlArr[this.i18nService.getActiveLang], '_blank');
    } else {
      this.modalService.showModal(modalType);
    }
  }

  validateSpecialCode = (control: AbstractControl) => {
    if (control && !control.pristine && control.value && control.value.trim() !== "") {
      return this.userService.verifySpecialCode(control.value).pipe(
        map((isValid) => {
          this.promoCodeVerifyResult = "promo Code positive and processing"
          return null
        }),
        catchError((error) => {
          if(!error.errorCode){
            this.promoCodeVerifyResult = "promo Code positive and processing"
            return of(null);
          }
         return of({ 'error': true })
        })
      );
    } else {
      return of(null);
    }
  };

  get specialCode() {
    return this.registrationForm.get("specialCode") as FormControl;
  }

  get otpConfig(): OtpConfig {
    if (!this.otpBox) {
      return;
    }
    return {
      type: "Mobile",
      otpType: "registration",
      id: this.registrationForm.controls.phoneNumber.value,
      countryCode: this.registrationForm.controls.phoneNumberPrefix.value,
    };
    // if (this.registrationForm.get("verificationChannel").value === "Email") {
    //   return {
    //     type: "Email",
    //     id: this.registrationForm.controls.personEmail.value,
    //   };
    // } else {
    //   return {
    //     type: "Mobile",
    //     id: this.registrationForm.controls.phoneNumber.value,
    //     countryCode: this.registrationForm.controls.phoneNumberPrefix.value,
    //   };
    // }
  }

  get araPermitExpiryDate() {
    return this.registrationForm.get("araPermitExpiryDate") as FormControl;
  }

  get araPermitHKCrewMemberCertNo() {
    return this.registrationForm.get("araPermitHKCrewMemberCertNo") as FormControl;
  }

  get araPermitPhotoBack() {
    return this.registrationForm.get("araPermitPhotoBack") as FormControl;
  }

  get araPermitPhotoFront() {
    return this.registrationForm.get("araPermitPhotoFront") as FormControl;
  }

  get companyName() {
    return this.registrationForm.get("companyName") as FormControl;
  }

  get companyNameOthers() {
    return this.registrationForm.get("companyNameOthers") as FormControl;
  }

  get personEmail() {
    return this.registrationForm.get("personEmail") as FormControl;
  }

  get isAirportStaff() {
    return this.registrationForm.get("isAirportStaff") as FormControl;
  }

  get phoneNumber() {
    return this.registrationForm.get("phoneNumber") as FormControl;
  }

  get securityQuestion() {
    return this.registrationForm.get("securityQuestion") as FormControl;
  }

  get securityAnswer() {
    return this.registrationForm.get("securityAnswer") as FormControl;
  }

  // get area() {
  //   return this.registrationForm.get("area") as FormControl;
  // }

  get phoneNumberPrefix() {
    return this.registrationForm.get("phoneNumberPrefix") as FormControl;
  }

  get password() {
    return this.registrationForm.get("password") as FormControl;
  }

  get checkPassword() {
    return this.registrationForm.get("checkPassword") as FormControl;
  }

  // get isEmailDisabled() {
  //   return this.otpBox.verificationForm.get("verCode").valid && this.registrationForm.get("verificationChannel").value === "Email";
  // }

  get isEmailRequired() {
    const validator = this.personEmail.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    } else {
      return false;
    }
  }

  get isPhoneRequired() {
    return true;
    // const validator = this.phoneNumber.validator({} as AbstractControl);
    // if (validator && validator.required) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  trackFunc = (index, item) => {
    return index;
  };
}
