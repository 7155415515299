import * as _ from "underscore";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { FormService, UserService, passwordValidator, ApiError, ErrorService, CommonService } from "../../../core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  emailTitle: string;
  forgotPasswordForm: FormGroup;
  isShowEmailResult = false;
  isSubmitting = false;
  pageInfo: any;
  private subscription: Subscription;
  repeatedPassword = false;
  resetPasswordForm: FormGroup;
  // securityQuestion: string;
  userProfile: any;
  isTokenValid: boolean;
  forgotPasswordLink: string;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private formService: FormService,
    private errorService: ErrorService,
    private localizeRouter: LocalizeRouterService,
    private commonService: CommonService
  ) {
    this.route.data.subscribe((data: { isValidToken: boolean }) => {
      const tokenStatus = data.isValidToken;
      if (tokenStatus) {
        this.isTokenValid = tokenStatus;
        this.forgotPasswordLink = this.localizeRouter.translateRoute("/account/forgot-password") as string;
        this.resetPasswordForm = this.fb.group(
          {
            password: [null, [Validators.required, passwordValidator]],
            confirmPassword: [null, [Validators.required, this.passwordConfirmationValidator]],
            userId: [null, [Validators.required]],
            token: [null, [Validators.required]],
          },
          { updateOn: "blur" }
        );

        let userid;
        let token;
        this.route.queryParams.subscribe((value) => {
          userid = decodeURI(value["userid"]);
          token = encodeURI(value["token"]);
        });
        if (userid && token) {
          this.resetPasswordForm.controls.userId.setValue(userid);
          this.resetPasswordForm.controls.token.setValue(token.replace(/%20/g, "+"));
        }
      } else {
        const errorPageData = {
          displayItem: {
            title: "auth.MESSAGE.INVALID_ACTIVATION_TOKEN",
            icon: "error",
            buttonLabel: "COMMON.LABEL.LOGIN_NOW",
            actionType: "LOGIN",
          },
        };
        this.commonService.redirect("/account/reset/error", null, { state: errorPageData });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  transformResetPasswordFormData() {
    const requestBody = {
      newPassword: this.resetPasswordForm.controls.password.value.trim(),
      token: this.resetPasswordForm.controls.token.value,
      userId: this.resetPasswordForm.controls.userId.value,
    };
    return _.pick(requestBody, _.identity);
  }

  submitForm() {
    if (this.resetPasswordForm.valid) {
      this.isSubmitting = true;
      const formData = this.transformResetPasswordFormData();
      this.userService.changePasswordByEmail(formData).subscribe(
        (response) => {
          const successPageData = {
            displayItem: {
              title: "auth.MESSAGE.SUCCESSFULLY_RESET_PASSWORD",
              subTitle: "auth.MESSAGE.SUCCESSFULLY_RESET_PASSWORD_SUBTITLE",
              icon: "password_changed",
              buttonLabel: "COMMON.LABEL.LOGIN_NOW",
              actionType: "LOGIN",
            },
          };
          this.commonService.redirect("/account/reset/success", null, { state: successPageData });
        },
        (error: ApiError) => {
          this.isSubmitting = false;
          if (error.errorCode === "E1023") {
            this.resetPasswordForm.controls.password.setErrors({
              repeatedPassword: true,
            });
          } else if (error.errorCode === "E1039") {
            this.errorService.redirectToErrorPage(error);
          } else {
            this.errorService.redirectToErrorPage(error);
          }
        }
      );
    } else {
      for (const i in this.resetPasswordForm.controls) {
        this.resetPasswordForm.controls[i].markAsDirty();
        this.resetPasswordForm.controls[i].updateValueAndValidity();
      }
      this.formService.scrollToError();
    }
  }

  passwordConfirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return null;
    }
    if (this.resetPasswordForm.controls.password.value !== this.resetPasswordForm.controls.confirmPassword.value) {
      return { passwordMismatch: true };
    }
    return null;
  };
}
