import { Injectable } from "@angular/core";
const CallUp = require("tool-callapp");
import { isAndroid } from "web-launch-app";
import { environment } from "../../../environments/environment";

@Injectable()
export class LaunchAppService {
  constructor() {}

  launchApp(): Promise<boolean> {
    return new Promise((resolve) => {
      window.location.href = environment.MYHKG_UNIVERSAL_LINK + 'ecouponlist';
      // if (isAndroid) {
      //   window.location.href = "intent:///#Intent;scheme=myhkg;package=com.hongkongairport.app.myflight;end";
      // } else {
      //   const options = {
      //     PROTOCAL: "myhkg",
      //     FAILBACK: {
      //       IOS: "https://apps.apple.com/hk/app/my-hkg-official/id1488720261",
      //     },
      //     APK_INFO: {
      //       PKG: "com.hongkongairport.app.myflight",
      //       CATEGORY: 'android.intent.category.DEFAULT',
      //       ACTION: 'android.intent.action.VIEW'
      //     },
      //     LOAD_WAITING: isAndroid ? -1 : 3000,
      //   };
      //   const callApp = new CallUp(options);
      //   callApp.loadSchema({
      //     targetURI: isAndroid ? undefined : "/",
      //     success: function () {
      //       resolve(true);
      //     },
      //     fail: function () {
      //       resolve(false);
      //     },
      //   });
      // }
    });
  }
}
