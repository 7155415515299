import { CampaignCarouselComponent } from './campaign-carousel/campaign-carousel.component';
import { HomeComponent } from "./home.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";

@NgModule({
  imports: [SharedModule],
  declarations: [HomeComponent, CampaignCarouselComponent],
  providers: []
})
export class HomeModule {}
